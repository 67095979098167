import { extendTheme } from '@chakra-ui/react';
import { Button } from './components/button';

const Theme = extendTheme({
  colors: {
    brand: {
      primary: "#1A4FD6",
      secondary: "#F2F2F2",
      danger: "#E90000",
      dark: "#808080",
      white: "#FFFFFF",
      greyPrimary: "#E4E4E4",
      greySecondary: "#999999",
      grey: {
       400: "#B3B3B3"
      },
      bluePrimary: "#0136BC",
      lightBlue: "#F6F8FA",
      green: "#26A17B",
    },
  },
  fonts: {
    body: "DM Sans",
    heading: "DM Sans",
  },
  components: {
    Button: Button
  },
});

export default Theme;