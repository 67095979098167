import { callApiWithToken } from "../utils/http_common";

const getMyAccountList = () => {
    return callApiWithToken.get(`/account/list`);
}

const getWalletAddress = (body) => {
    return callApiWithToken.get(`/account/getWalletAddress`, {
        params: {
            currency: body.currency,
            network: body.network,
            amount: body.amount
        }
    });
}

const getBalance = (currency) => {
    return callApiWithToken.get(`/account/getBalance`, {
        params: {
            currency: currency
        }

    });
}

const AccountServide = {
    getMyAccountList,
    getWalletAddress,
    getBalance
}

export default AccountServide;