import { Heading, Text, VStack } from "@chakra-ui/react";
import { capitalize, find } from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import CustomTable from "../../utils/customTable";

const SuccessOrders = (props) => {
  const { status } = props;
  const transactionData = useSelector((state) => state?.transaction?.list);
  const [successOrder, setSuccessOrder] = useState([]);

  const USformatter = new Intl.NumberFormat('en-US');

  useEffect(() => {
    const resp = transactionData.filter(
      (response) => response.status === status
    );
    setSuccessOrder(resp);
  }, [transactionData]);

  const columns = [
    { Header: "Transaction ID", accessor: "txnId", width: '200' },
    // {Header: 'Name', accessor: 'name'},
    // {Header: 'Remarks', accessor: 'remarks'},
    // { Header: "Completed Date", accessor: "createdAt",  width: '200' },
    { Header: "Crypto Ticker", accessor: "cryptoTicker", Cell: ({ value }) => (
      <Text>{value}</Text>
    )},
    { Header: "Crypto Delivered Unit", accessor: "cryptoAmount",  width: '200', Cell: ({ value }) => (
      <Text>{USformatter.format(value)}</Text>
    )},
    // {Header: 'Network Fees', accessor: 'networkFees'},
    // {Header: 'Processing Fees', accessor: 'processingFee'},
    { Header: "Wallet Address", accessor: "walletAddress" },
    { Header: "Email", accessor: "email" },
    // {Header: 'Country', accessor: 'country'},
    // {Header: 'Type', aaccess: 'type'},
  ];

  return (
    <>
      {successOrder.length !== 0 ? (
        <CustomTable columns={columns} data={successOrder} />
      ) : (
        <VStack>
            <Heading as={'h4'} size={'md'}>No {capitalize(status === 'initiated' ? 'pending' : status)} Transactions Found</Heading>
        </VStack>
      )}
    </>
  );
};

export default SuccessOrders;
