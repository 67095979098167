import { callApiWithToken } from "../utils/http_common";

const getPaymentMethodsService = ({ fiatTicker, product }) => {
    return callApiWithToken.get(`/order/paymentMethods?currency=${fiatTicker}&product=${product}`);
}
const getPaymentMethodList = ({ currency, direction = "deposit", amount = 0 }) => {
    return callApiWithToken.get(`/payment?currency=${currency}&direction=${direction}&amount=${amount}`);
}

const getCurrencyLimit = ({ currency, name, module }) => {
    return callApiWithToken.get(`/payment/currencyLimit?currency=${currency}&name=${name}&module=${module}`);
}

const PaymentMethodsService = {
    getPaymentMethodsService,
    getPaymentMethodList,
    getCurrencyLimit
}

export default PaymentMethodsService;