import {
  Box,
  Divider,
  Flex,
  Text,
  VStack,
  Heading,
  Image,
  HStack,
  Checkbox,
  Button,
  Skeleton,
  Link,
  Stack,
  Grid,
  GridItem,
  useDisclosure
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRecipient } from "../../store/slices/recipientSlices";
import { continueOrder } from "../../store/slices/orderSlices";
import { useNavigate } from "react-router";
import WarningModal from "../../components/modal";

const ConfirmOrder = () => {
  const [showFees, setShowFees] = useState(true);
  const [checked, setChecked] = useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const USformatter = new Intl.NumberFormat("en-US");

  const {
    onOpen: warnModalOpen,
    isOpen: warnModalIsOpen,
    onClose: warnModalClose,
  } = useDisclosure();

  const recipientList = useSelector((state) => state.recipient.list);
  const selectedPaymentMethod = useSelector(
    (state) => state.paymentMethods.seletedPaymentMethod
  );
  const quotes = useSelector((state) => state.order.quotes);
  const orderId = useSelector((state) => state.order.orderId);
  const cryptoTicker = useSelector((state) => state.order.cryptoTicker);
  const fiatTicker = useSelector((state) => state.order.fiatTicker);

  const isLoading = useSelector((state) => state.order?.loading);
  useEffect(() => {
    getRecipientData();
  }, [orderId]);

  const getRecipientData = useCallback(() => {
    dispatch(getRecipient());
  }, [dispatch]);

  const onClickContinue = () => {
    dispatch(continueOrder()).then((resp) => {
      if (resp.payload.success) {
        navigate(`/payin/confirm-order/checkout/`);
      }
    });
  };

  return (
    <Stack
      direction={"column"}
      justifyContent={"space-between"}
      flexGrow={1}
      p={5}
    >
      <Box>
        <Grid templateColumns="repeat(2, 1fr)" gap={5}>
          <GridItem>
            <VStack width={"full"} align={"stretch"} gap={5} pb={5}>
              <Box>
                <Heading as="h4" size="md">
                  Payment Method :
                </Heading>
                <HStack
                  mt="20px"
                  border="1px"
                  borderColor={"#D2D2D299"}
                  p={2}
                  w={"full"}
                >
                  <Image src={selectedPaymentMethod.icon} />
                  <Text>{selectedPaymentMethod.name}</Text>
                </HStack>
              </Box>
              <Box >
                <Heading as="h4" size="md">
                  Recipient :
                </Heading>
                <Box mt="20px" overflowX={'auto'} h={'50vh'}>
                  <VStack spacing={5} align={"stretch"}>
                    {recipientList.map((recipient, idx) => (
                      <HStack
                        key={idx}
                        p={5}
                        align={"stretch"}
                        justifyContent={"space-between"}
                        border="1px"
                        borderColor={"#D2D2D299"}
                      >
                        <Box>
                          <Text>{recipient.name}</Text>
                          <Text fontSize={"sm"}>{recipient.walletAddress}</Text>
                        </Box>
                        <Box>
                          <Text>
                            {USformatter.format(recipient.fiatAmount) +
                              " " +
                              recipient.fiatTicker}
                          </Text>
                          {/* <Text>{recipient.cryptoTicker.network}</Text> */}
                        </Box>
                      </HStack>
                    ))}
                  </VStack>
                </Box>
              </Box>
            </VStack>
          </GridItem>
          <GridItem>
            <Box>
              <>
                <HStack
                  py={5}
                  align={"stretch"}
                  justifyContent={"space-between"}
                >
                  <Text fontSize={"large"} fontWeight={"bold"}>
                    You Get
                  </Text>

                  {quotes ? (
                    <Text>
                      {USformatter.format(quotes?.receiveAmount)}{" "}
                      {cryptoTicker?.value?.symbol}
                    </Text>
                  ) : (
                    <Skeleton width={"100px"} height={"10px"} />
                  )}
                </HStack>
                {showFees ? (
                  <>
                    <HStack justifyContent="space-between" width="full">
                      <Text>Gas fees</Text>
                      {quotes ? (
                        <Text>
                           {quotes?.networkFee}{" "}
                          {fiatTicker?.value?.description}
                        </Text>
                      ) : (
                        <Skeleton width={"100px"} height={"10px"} />
                      )}
                    </HStack>
                    <HStack justifyContent="space-between" width="full">
                      <Text>Processing fees</Text>
                      {quotes ? (
                        <Text> {quotes?.processingFee}{" "}{fiatTicker?.value?.description}</Text>
                      ) : (
                        <Skeleton width={"100px"} height={"10px"} />
                      )}
                    </HStack>
                  </>
                ) : null}
                <HStack justifyContent="space-between" width="full">
                  <Text fontSize="sm" color="black"></Text>
                  <Text
                    onClick={() => setShowFees(!showFees)}
                    fontWeight={"bold"}
                    color="#0C5BF7"
                    fontSize="sm"
                    cursor="pointer"
                  >
                    {showFees ? "Hide fees" : "Show fees"}
                  </Text>
                </HStack>
                <HStack
                  py={5}
                  align={"stretch"}
                  justifyContent={"space-between"}
                >
                  <Text fontSize={"large"} fontWeight={"bold"}>
                    Total you pay
                  </Text>
                  {quotes ? (
                    <Text fontSize={"large"} fontWeight={"bold"}>
                      {USformatter.format(quotes?.sentAmount)} {fiatTicker.label}
                    </Text>
                  ) : (
                    <Skeleton width={"100px"} height={"10px"} />
                  )}
                </HStack>
                <HStack py={5}>
                  <Checkbox colorScheme="blue" onChange={e => setChecked(!checked)}>
                    I have read and agreed with{" "}
                    <Link
                      target="_blank"
                      href="https://www.transfi.com/terms-and-conditions"
                      color={"#1A4FD6"}
                    >
                      terms & conditions
                    </Link>
                  </Checkbox>
                </HStack>
              </>
              <WarningModal path={'/payin'} isOpen={warnModalIsOpen} onClose={warnModalClose} />
            </Box>
          </GridItem>
        </Grid>
      </Box>
      <HStack justifyContent={"flex-end"}>
        <Button
          minW={"200px"}
          color="#525C76"
          border="1px"
          borderColor="#525C76"
          _hover={{ bg: "#ffffff" }}
          variant={"outline"}
          onClick={warnModalOpen}
        >
          Cancel
        </Button>
        <Button
          isLoading={isLoading}
          isDisabled={!checked || !quotes}
          color={"#ffffff"}
          bg={"#1A4FD6"}
          _hover={{ bg: "#232D95" }}
          minW={"200px"}
          onClick={onClickContinue}
        >
          Continue
        </Button>
      </HStack>
    </Stack>
  );

};

export default ConfirmOrder;
