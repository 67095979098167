import { Box, Button, Flex, Stack, useToast, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { IoCopyOutline } from 'react-icons/io5';
import { TbBulb } from 'react-icons/tb';

import { goToPeviousStep } from '../../../store/slices/AddMoneySlice';
import Typography from '../../../components/Common/Typography';
import OrderService from '../../../services/createOrderService';
import { CopyInputBox as CopyBox } from './WalletToWalletTransfer';


const CopyInputBox = ({ label, value, bg }) => {
    return (
        <Flex columnGap={2} w={"100%"} justifyContent={"space-between"} p={"18px 12px"} borderRadius={"4px"} bg={bg || "brand.secondary"} alignItems={"center"}>
            <Typography weight='medium' type='description' color='secondary' colorweight="800" >
                {label}
            </Typography>
            <Flex alignItems={"center"} width={"fit-content"}>
                <Typography weight='regular' type='description' color='headertext' >
                    {value}
                </Typography>
                <IoCopyOutline style={{ cursor: 'pointer', marginLeft: "10px" }} onClick={() => navigator.clipboard.writeText(value)} />
            </Flex>
        </Flex>

    )
}

export default function BankTransfer() {
    const beneficiaryDetails = useSelector((state) => state.addMoney.beneficiaryDetails?.data)
    const type = useSelector((state) => state.addMoney?.beneficiaryDetails?.type)
    const quotes = useSelector((state) => state.addMoney.quotes?.data)
    const currencyToPay = useSelector((state) => state.addMoney.currencyToPay)
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const toast = useToast()
    const handleBack = () => {
        dispatch(goToPeviousStep());
    }
    const handleClick = async () => {
        setLoading(true)
        let orderId;
        if (type === "bank_transfer") {
            const referenceText = beneficiaryDetails.referenceText;
            orderId = referenceText.slice(0, 2) + "-" + referenceText.slice(2);
        } else if (type === "bank") {
            orderId = beneficiaryDetails.orderId;
        }
        const res = await OrderService.getOrderStatus({ orderId });
        if (res?.data?.success === false) {
            toast({
                title: "Order Failed",
                description: "Please try again",
                status: "error",
                position: 'top',
                duration: 2000,
                isClosable: true,
            })
            setLoading(false)
            return;
        }

        const status = res?.data?.data?.status;
        if (status === "initiated") {
            toast({
                title: "Order Initiated",
                description: "Redirecting...",
                status: "success",
                position: 'top',
                duration: 2000,
                isClosable: true,
            })
            setLoading(false)
            navigate(`/my-accounts/add-money/success?orderId=${orderId}`)
        }
        setLoading(false)
    }


    return (
        <Box w={"100%"}>

            {
                beneficiaryDetails?.vaCode && <>
                    <Typography type='description' color='headertext' weight='medium' pb={8}>
                        Please use the below payment code to transfer the amount
                    </Typography>

                    <Flex borderRadius={"9px"} padding={"16px"} justifyContent={"center"} rowGap={4} flexDirection={"column"} alignItems={"center"} bg={"brand.secondary"} w={"full"}>
                        <Typography type='description'>
                            Payment Code
                        </Typography>
                        <Text maxW={"60%"} textAlign={"center"}  type='description'>
                            {beneficiaryDetails?.vaCode}
                        </Text>
                    </Flex>

                    <Box padding={"16px 5px"} my={"10px"} mx={"auto"} width={"250px"}>
                        <CopyBox label="Amount" value={`${quotes?.amountToPay || 0.00} ${currencyToPay?.symbol || ""}`} />
                    </Box>

                    <Flex borderRadius={"9px"} padding={"16px"} justifyContent={"center"} columnGap={2} alignItems={"start"} bg={"brand.secondary"} w={"full"}>
                        <TbBulb width={"20px"} height={"20px"} />
                        <Text fontSize={"14px"} color={"#282F40"}>
                            Please ensure that you send the exact amount mentioned to avoid any discrepancies. The applicable fees may vary depending on the mode of transfer.
                        </Text>
                    </Flex>
                </>
            }

            {type === "bank_transfer" && !beneficiaryDetails?.vaCode && <>
                <Typography type='description' color='headertext' weight='medium' pb={8}>
                    Beneficiary account details
                </Typography>

                <Stack gap={"12px"} w={"100%"}>
                    {
                        beneficiaryDetails?.iban &&
                        <CopyInputBox label="IBAN" value={beneficiaryDetails?.iban} />
                    }
                    {
                        beneficiaryDetails?.number &&
                        <CopyInputBox label="Bank account holder" value={beneficiaryDetails?.number} />
                    }
                    <CopyInputBox label="BIC" value={beneficiaryDetails?.bic} />
                    <CopyInputBox label="Reference text" value={beneficiaryDetails?.referenceText} />
                    <CopyInputBox label="Address" value={beneficiaryDetails?.address} />
                    {
                        beneficiaryDetails?.bank_name &&
                        <CopyInputBox label="Bank Name" value={beneficiaryDetails?.bank_name} />
                    }
                    {
                        beneficiaryDetails?.bank_address &&
                        <CopyInputBox label="Bank Address" value={beneficiaryDetails?.bank_address} />
                    }
                </Stack>
            </>}

            {type === "bank" && !beneficiaryDetails?.vaCode && <>
                <Typography type='description' color='headertext' weight='medium' pb={8}>
                    Please scan the QR code or use the below details to transfer the amount
                </Typography>


                <Flex borderRadius={"9px"} padding={"16px"} justifyContent={"center"} rowGap={4} flexDirection={"column"} alignItems={"center"} bg={"brand.secondary"} w={"full"}>
                    <Typography type='description'>
                        Scan this QR Code to make the transfer
                    </Typography>
                    <img style={{ margin: "auto", width: "147px", height: "147px" }} src={beneficiaryDetails?.imgSrc} alt="qr code" />
                </Flex>

                <Box padding={"16px 5px"} my={"10px"} mx={"auto"} width={"250px"}>
                    <CopyBox label="Amount" value={`${quotes?.amountToPay || 0.00} ${currencyToPay?.symbol || ""}`} />
                </Box>

                <Flex borderRadius={"9px"} padding={"16px"} justifyContent={"center"} columnGap={2} alignItems={"start"} bg={"brand.secondary"} w={"full"}>
                    <TbBulb width={"20px"} height={"20px"} />
                    <Text fontSize={"14px"} color={"#282F40"}>
                        Please ensure that you send the exact amount mentioned to avoid any discrepancies. The applicable fees may vary depending on the mode of transfer.
                    </Text>
                </Flex>


            </>}


            <Stack pt={8} bg={"white"} direction="row" gap={"20px"} justifyContent={"end"} w='100%' marginTop={0}>
                <Button isDisabled={loading} disabled={loading} onClick={handleBack} variant={"secondary"} size={"sm"} type="button" p={"9px 16px 9px 16px"}>
                    Back
                </Button>
                <Button _loading={{ pointerEvents: "none" }} isLoading={loading} variant={"primary"} onClick={handleClick} size={"sm"} p={"9px 16px 9px 16px"}>
                    Click when transfer is done
                </Button>
            </Stack>
        </Box>
    )
}
