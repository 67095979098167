import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CommonService from "../../services/commonService";
import axios from "axios";

const initialStateValues = {
    country: "",
    loading: false,
    error: null
}

export const getCountryByIp = createAsyncThunk(
    "common/getCountry",
    async (body, { rejectWithValue }) => {
        try {
            const ipResponse = await axios.get('https://api.ipify.org?format=json');
            const ip = ipResponse.data.ip;
            const res = await CommonService.getCountryByIp(ip);
            return res?.data;
        } catch (e) {
            return rejectWithValue(e?.response?.data);
        }
    }
);


export const commonSlice = createSlice({
    name: 'common',
    initialState: initialStateValues,
    reducers: {
    },
    extraReducers: {
        [getCountryByIp.pending]: (state) => {
            state.loading = true;
        },
        [getCountryByIp.fulfilled]: (state, action) => {
            state.loading = false;
            localStorage.setItem("country", action?.payload?.data?.country || "United States");
            localStorage.setItem("countryCode", action?.payload?.data?.countryCode || "US");
            state.country = action.payload.data;
        }
    }

})


const { reducer: commonReducer } = commonSlice;

export default commonReducer