import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Img, Skeleton, VStack } from '@chakra-ui/react';

// Custom Function and Components
import Typography from '../../components/Common/Typography';
import ApprovalCard from './PayoutApprovalCard';

// CSS
import './approval.css';

const PayoutApprovals = ({ payoutApprovalList }) => {
    const transactionLoaderState = useSelector((state) => state?.transaction?.loading || false);
    return (
        <>
            {payoutApprovalList.length === 0 ?
                <Box className='al-sub-card'>
                    <VStack gap={"12px"}>
                        <Img className='al-main-img' src={process.env.PUBLIC_URL + "/assets/approval_main_screen.png"} alt="approvalsLogo" />
                        <Typography type='title' weight='medium' color='secondary' colorweight='800' >
                            All good!
                        </Typography>
                        <Typography type='description' weight='regular' color='secondary' colorweight='800' >
                            No approvals pending
                        </Typography>
                    </VStack>
                </Box> :
                !transactionLoaderState ?
                    payoutApprovalList.map((item) => {
                        return <ApprovalCard
                            makerName={item.makerName}
                            contactName={item.contactName}
                            orderId={item.orderId}
                            displayName={item?.paymentAccountDetails?.displayName}
                            depositAmount={item.depositAmount}
                            depositCurrency={item.depositCurrency}
                            withdrawAmount={item.withdrawAmount}
                            withdrawCurrency={item.withdrawCurrency}
                            logo={item.paymentAccountDetails.logo}
                            approvalPendingTime={item?.timestamps?.approvalPendingAt}
                        />
                    }) :
                    <>
                        <Skeleton height={"135px"} />
                        <Skeleton height={"135px"} />
                        <Skeleton height={"135px"} />
                    </>
            }
        </>
    );
};

export default PayoutApprovals;