import { Box } from '@chakra-ui/react'
import React from 'react'
import Typography from './Typography'

export default function MainCard({ isBorder, children,props }) {
    return (
        <Box w={"100%"} bg={"white"} border={isBorder ? "1px solid" : ""} borderColor={isBorder ? "brand.greyPrimary" : ""} borderRadius={"9px"} {...props}>
            {children}
        </Box>
    )
}

export function CardWithTitle({ title, isBorder, children, props, }) {
    return (
        <Box w={"100%"} bg={"white"} border={isBorder ? "1px solid" : ""} borderColor={isBorder ? "brand.greyPrimary" : ""} borderRadius={"9px"} {...props}>
            <Box p={"20px 40px"}>
                <Typography pb={"20px"} textAlign="left" weight='medium' type='subtitle' color='primary' >
                    {title}
                </Typography>
                <Box>
                    {children}
                </Box>
            </Box>
        </Box>
    )
}
