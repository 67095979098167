import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Box, Flex, Heading, Image, Text, useInterval } from "@chakra-ui/react";

import processing from '../../assets/animation/processing.gif';
import { getOrderStatus } from "../../store/slices/orderSlices";

const PaymentProcessing = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const orderStatus = useSelector(state => state.order?.status);
  const [status, setStatus] = useState(useSelector(state => state.order?.status));

  useEffect(() => {
    setTimeout(() => {
      navigate('/payin/payment-summary')
    }, 3000)
     }, []);

  return (
    <Box p={5}>
      <Flex direction={'column'} alignItems={'center'} gap={10}>
        <Image src={processing} width={'500px'} />
        <Heading color={'#1A4FD6'}>Please wait!</Heading>
        <Text>Transactions are being processed and it may take few mins to process.</Text>
      </Flex>
    </Box>
  )
}

export default PaymentProcessing