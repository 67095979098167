import * as Yup from 'yup';

const selectContactTabSchema = Yup.object().shape({
  contactId: Yup.object().required('Contact is required'),
  paymentPurpose: Yup.object().required('Payment Purpose is required'),
  paymentAccountName: Yup.object().required('Payment Account Name is required'),
  walletName: Yup.object().required('Wallet Name is required'),
});

export const validationSchema = [selectContactTabSchema];
