import { callApiWithToken } from "../utils/http_common";

const getCountryByIp = (ip) => {
    return callApiWithToken.get(`/common/getUserCountry?ip=${ip}`);
}

const CommonService = {
    getCountryByIp,
}

export default CommonService;