import { Box, Button, Divider, Flex, HStack, Image, Text } from '@chakra-ui/react';
import React from 'react';
import Typography from '../../../../components/Common/Typography';
import { currencyFormatter } from '../../../../utils/formatter';
import { useNavigate } from 'react-router';

const OrderDetailsCard = ({ orderDetails, handleMakePayment, isAccepted, paymentResponse = {}, btnLoading, selectedBank, handleGotoSummary, isSummary }) => {

    const navigate = useNavigate()

    const orgLogo = orderDetails?.customer?.country?.toLowerCase();
    const { depositCurrency, customer } = orderDetails;
    const { customerCurrency = "" } = customer || {};

    return (
        <Box bg='white' w='30%' p={"20px 20px"} borderRadius={"9px"} mt={5} >

            <Flex justify={'flex-start'}>
                <Typography type='body' weight='medium' color='primary'>Invoice Details</Typography>
            </Flex>
            <Box bg={"#F2F2F2"} rounded={'6px'} w={'full'} p={"5px 20px"} mt={5}>

                <HStack display={"flex"} w={"100%"} justifyContent={"space-between"} mt={5}>
                    <Typography color="secondary" colorweight="600" type='description' weight='medium'>
                        From
                    </Typography>
                    <Flex gap={1} alignItems={'end'} flexDirection={'column'} justify={'start'}>
                        <Flex gap={1} alignItems={'center'}>
                            <Image src={`/assets/fiat/${orgLogo}.svg`} w={'25px'} />
                            <Typography type='body' weight='regular' color='secondary' colorweight={'800'}>{orderDetails?.customer?.name} </Typography>
                        </Flex>
                        <Text fontSize={'12px'} fontWeight={'400'} color={'#A8A8A8'} fontFamily={'DM Sans'}>{orderDetails?.customer?.email}</Text>
                    </Flex>
                </HStack>
                <HStack display={"flex"} w={"100%"} justifyContent={"space-between"} mt={5} >
                    <Typography color="secondary" colorweight="600" type='description' weight='medium'>
                        Txn Id
                    </Typography>
                    <Typography color="secondary" colorweight="800" type='regular' weight='body'>
                        {orderDetails.orderId}
                    </Typography>
                </HStack>

                <HStack display={"flex"} w={"100%"} justifyContent={"space-between"} mt={5} >
                    <Typography color="secondary" colorweight="600" type='description' weight='regular'>
                        Purpose of Txn
                    </Typography>
                    <Typography color="secondary" colorweight="800" type='body' weight='body' mb={3}>
                        {orderDetails.purposeCode}
                    </Typography>
                </HStack>
            </Box>

            <HStack display={"flex"} w={"100%"} justifyContent={"space-between"} mt={8} px={5}>
                <Typography color="secondary" colorweight="600" type='description' weight='regular'>
                    To
                </Typography>
                <Flex gap={1} alignItems={'end'} flexDirection={'column'} justify={'start'}>
                    <Flex gap={1} alignItems={'center'}>
                        <Image src={orderDetails?.additionalDetails?.countryLogo} w={'25px'} />
                        <Typography type='body' weight='regular' color='secondary' colorweight={'800'}>{orderDetails?.senderName?.firstName} </Typography>
                    </Flex>
                    <Text fontSize={'12px'} fontWeight={'400'} color={'#A8A8A8'} fontFamily={'DM Sans'}>{orderDetails?.email}</Text>
                </Flex>
            </HStack>

            <Divider mb={'2'} w={'full'} mt={5} />
            {orderDetails?.additionalDetails?.invoiceUrl &&
                <Flex justify={"flex-end"} mt={5} px={5}>
                    <a target="_blank" rel="noopener noreferrer" href={orderDetails.additionalDetails.invoiceUrl} >
                        <Text color={'#1A4FD6'} textDecoration={'underline'} _hover={{ cursor: 'pointer' }} fontSize={'16px'} fontFamily={'DM Sans'}>View invoice</Text>
                    </a>
                </Flex>}


            <HStack display={"flex"} w={"100%"} justifyContent={"space-between"} mt={5} px={5}>
                <Typography color="secondary" colorweight="800" type='description' weight='medium'>
                    Invoice Amount
                </Typography>
                <Typography color="secondary" colorweight="800" type='description' weight='medium'>
                    {currencyFormatter(Number(orderDetails.depositAmount))}  {orderDetails.depositCurrency}
                </Typography>
            </HStack>

            {depositCurrency !== customerCurrency &&
                <HStack display={"flex"} w={"100%"} justifyContent={"space-between"} mt={5} px={5}>
                    <Typography color="secondary" colorweight="800" type='description' weight='medium'>
                        Conversion
                    </Typography>
                    <Typography color="secondary" colorweight="800" type='description' weight='medium'>
                        1 {orderDetails.depositCurrency} = {currencyFormatter(Number(orderDetails?.customer?.customerCurrencyConversionRate))} {orderDetails?.customer?.customerCurrency}
                    </Typography>
                </HStack>}

            <Divider mb={'2'} w={'full'} mt={5} />

            <HStack display={"flex"} w={"100%"} justifyContent={"space-between"} mt={5} px={5}>
                <Typography color="default" type='body' weight='bold'>
                    Amount to be paid
                </Typography>
                <Typography weight='bold' color='primary' colorweight='500'> {currencyFormatter(Number(orderDetails?.customer?.customerCurrencyAmount))}  {orderDetails?.customer?.customerCurrency}</Typography>
            </HStack>

            {paymentResponse ? <>
                {isSummary ? <Button
                    variant={"primary"}
                    bg={"white"}
                    border={'1px'}
                    borderColor={"#F2F2F2"}
                    _hover={{ bg: 'white', borderColor: '#1A4FD6' }}
                    color="#1A4FD6"
                    height={'40px'}
                    width={'full'}
                    rounded={'6px'}
                    fontSize={'14px'}
                    fontWeight={'400'}
                    mt={8}
                    isLoading={btnLoading}
                    onClick={() => navigate('/customer/home')}
                >
                    Back to homepage
                </Button> :
                    <Button
                        variant={"primary"}
                        bg={"#1A4FD6"}
                        _hover={{ bg: '#366cf5' }}
                        color="white"
                        height={'40px'}
                        width={'full'}
                        rounded={'6px'}
                        fontSize={'14px'}
                        fontWeight={'400'}
                        mt={8}
                        isLoading={btnLoading}
                        isDisabled={!isAccepted}
                        onClick={handleGotoSummary}
                    >
                        I have made the payment
                    </Button>}
            </> : <Button
                variant={"primary"}
                bg={"#1A4FD6"}
                _hover={{ bg: '#366cf5' }}
                color="white"
                height={'40px'}
                width={'full'}
                rounded={'6px'}
                fontSize={'14px'}
                fontWeight={'400'}
                mt={8}
                isLoading={btnLoading}
                isDisabled={!isAccepted || !selectedBank}
                onClick={handleMakePayment}
            >
                Next
            </Button>}
        </Box>
    );
};

export default OrderDetailsCard;