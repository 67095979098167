import React, { useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Center,
    FormControl,
    FormLabel,
    Input,
    Select,
    FormErrorMessage,
    useToast,
} from '@chakra-ui/react'
import { useFormik } from 'formik';
import * as yup from 'yup';

import Typography from '../../../components/Common/Typography';
import { callApiWithToken } from '../../../utils/http_common';
import { useSelector } from 'react-redux';

const InvitationModal = ({ isOpen, onClose, refreshData }) => {
    const { firstName = '' } = useSelector((state) => state?.login?.userDetails) || {}
    const { orgName = '' } = useSelector((state) => state?.login?.orgDetails) || {};
    const orgType = useSelector(
        (state) => state?.login?.orgDetails?.orgType || "default"
    );
    const companyName = orgName.charAt(0).toUpperCase() + orgName.slice(1);
    const [loading, setLoading] = useState(false);
    const toast = useToast();
    let options = ['Admin', 'Approver', 'Maker', 'Finance Manager']
    if (orgType === 'collections') {
        options = ['Admin']
    }
    const validationSchema = yup.object({
        email: yup.string().email("Invalid email").required('Email is required'),
        role: yup.string("Select role").required('Role is required'),
    });
    const formik = useFormik({
        initialValues: {
            email: '',
            role: ''
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {
                setLoading(true);
                const email = values.email;
                const role = values.role.toLowerCase().replace(/ /g, '_');
                const payload = { email, role, companyName, adminName: firstName };
                const { data } = (await callApiWithToken.post("/customer/team/sendInvitation", payload)) || {};
                if (data.data.status) {
                    toast({
                        title: "Invitation Sent",
                        status: 'success',
                        duration: 5000,
                        isClosable: true
                    });
                    refreshData();
                }
                else {
                    toast({
                        title: data.data.message,
                        status: 'error',
                        duration: 5000,
                        isClosable: true
                    });
                }
                setLoading(false);
                formik.resetForm();
                onClose();
            }
            catch (e) {
                formik.resetForm();
                setLoading(false);
                toast({
                    title: e.data.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true
                })
                onClose();
            }
        },
    });

    const onModalClose = () => {
        formik.resetForm();
        setLoading(false);
        onClose();
    }

    return (
        <Modal isOpen={isOpen} onClose={onModalClose}>
            <ModalOverlay />
            <ModalContent>
                <Center mt={4}>
                    <Typography color="primary" colorweight="500" weight='bold'>Invite Teammate</Typography>
                </Center>
                <ModalCloseButton />
                <ModalBody>
                    <FormControl isRequired mt={5} isInvalid={formik?.touched?.email && formik?.errors?.email}>
                        <FormLabel>Invite by email</FormLabel>
                        <Input placeholder='E.g. name@email.com'
                            id='email'
                            name='email'
                            onChange={formik.handleChange} />
                        <FormErrorMessage>{formik?.errors?.email}</FormErrorMessage>
                    </FormControl>
                    <FormControl isRequired mt={5} isInvalid={formik?.touched?.role && formik?.errors?.role}>
                        <FormLabel>Role</FormLabel>
                        <Select placeholder='Select role'
                            id='role'
                            name='role'
                            onChange={formik.handleChange}>
                            {options?.map((option, i) => <option key={i}>{option}</option>)}
                        </Select>
                        <FormErrorMessage>{formik?.errors?.role}</FormErrorMessage>
                    </FormControl>
                </ModalBody>

                <ModalFooter>
                    <Button
                        bg={"#F2F2F2"}
                        _hover={{ bg: "#F2F2F2" }}
                        color="#808080"
                        width={'113px'}
                        height={'40px'}
                        rounded={'6px'}
                        fontSize={'14px'}
                        fontWeight={'400'}
                        fontFamily={'DM Sans'} mr={3} onClick={onModalClose}>
                        Cancel
                    </Button>
                    <Button
                        variant={"primary"}
                        bg={"#1A4FD6"}
                        _hover={{ bg: '#366cf5' }}
                        color="white"
                        width={'113px'}
                        height={'40px'}
                        rounded={'6px'}
                        fontSize={'14px'}
                        fontWeight={'400'}
                        fontFamily={'DM Sans'}
                        onClick={formik.handleSubmit}
                        isLoading={loading}
                    >
                        Send Invite
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default InvitationModal;