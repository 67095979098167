import { Badge, Box, Button, Flex, HStack, Image, Img, Skeleton, Stack, Tbody, Td, Text, Tr, VStack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { STATUS_MAP, colorMapCollections } from '../../../constants';
import FilterButton from '../../transaction/FilterButton';
import TransactionService from '../../../services/transactionService';
import { finalTransactionType } from '../../transaction/collection/CollectionTransactions';
import { currencyFormatter, formatDateTime } from '../../../utils/formatter';
import StickyTable from '../../../components/Common/Table/StickyTable';
import EmptyIcon from '../../../assets/emptypage.svg'
import Typography from '../../../components/Common/Typography';

const filterOptions = [
    { label: "All", value: "all" },
    { label: "Pending", value: "pending" },
    { label: "Initiated", value: "initiated" },
    { label: "Successful", value: "successful" },
    { label: "Failed", value: "failed" },
];

const labels = [
    {
        label: "Sent By",
    },
    {
        label: "Txn ID",
    },
    {
        label: "Payment Initiated On",
    },
    {
        label: "Invoice Amount"
    },
    {
        label: ""
    }
]

const TableColumn = (props) => {
    const { orderId, currency, email, countryLogo, amount, status, createdAT, type, name, invoiceUrl = "" } = props

    return (
        <Tr height={"90px"} py={20}>
            <Td>
                <Flex columnGap={2} alignItems={"center"}>
                    <Flex direction={"column"}>
                        <p color='#414141'>
                            {name}
                        </p>
                        <Typography weight='regular' type='caption' color='secondary'>
                            {email}
                        </Typography>
                    </Flex>
                </Flex>
            </Td>

            <Td>
                <Flex width={"150px"} direction={"column"}>
                    <Typography type='body'>
                        #{orderId}
                    </Typography>
                    <Text size={"12px"} color={colorMapCollections[status || "initiated"].color} bg={colorMapCollections[status || "initiated"].bg} p={"2px 4px"} borderRadius={"3px"} w={"min-content"} mt={2}>
                        {STATUS_MAP[finalTransactionType(type)][status || "initiated"]}
                    </Text>
                </Flex>
            </Td>
            <Td>
                <Typography type='description'>

                    {formatDateTime(createdAT, "hh:mm A")} -  {formatDateTime(createdAT, "DD MMM YYYY")}
                </Typography>
            </Td>

            <Td>
                {currencyFormatter(amount)} {currency}
            </Td>

            <Td>
                {invoiceUrl && <a target="_blank" rel="noopener noreferrer" href={invoiceUrl} >
                    <Text color={'#1A4FD6'} textDecoration={'underline'} _hover={{ cursor: 'pointer' }} fontSize={'16px'} fontFamily={'DM Sans'}>View invoice</Text>
                </a>}
            </Td>

        </Tr>
    )
}

const CustomerTransactions = () => {

    const { email: userEmail } = useSelector((state) => state?.login?.userDetails)
    const [loading, setLoading] = useState(false)
    const [orders, setOrders] = useState([])
    const [searchParams] = useSearchParams();
    const status = searchParams.get('status') || 'all';

    const getOrders = async (status) => {
        try {
            setLoading(true)
            const response = await TransactionService.getCollectionCustomerTransactions(status, userEmail);
            setOrders(response.data?.data?.transactions)
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getOrders(status);
    }, [status]);

    if (loading) return (
        <Box minH={"92vh"} p={"16px 40px"} bg={"#F2F2F2"}>
            <Flex justifyContent='space-between' alignItems='center'>

                <Typography type='largeheader' weight='bold' >
                    Transactions
                </Typography>
                <Box>
                    <FilterButton filterOptions={filterOptions} />
                </Box>
            </Flex>
            <Box bg='white' w='100%' mt={6} borderRadius={"9px"} p={"20px 40px"}>
                <Stack borderRadius={"9px"} mt={"15px"} rowGap={5} bg={"white"}>
                    {Array(4).fill(0).map((_, index) => (
                        <Skeleton key={index} height='60px' />
                    ))}
                </Stack>
            </Box>
        </Box>
    )

    return (
        <Box className='al-main-card'>
            <Flex justifyContent='space-between' alignItems='center'>

                <Typography type='largeheader' weight='bold' >
                    Transactions
                </Typography>
                <Box>
                    <FilterButton filterOptions={filterOptions} />
                </Box>
            </Flex>

            {orders.length === 0 ? <Flex bg={'white'} width={'full'} minHeight={'100vh'} mt={5} rounded={'9px'} justify={'center'} alignItems={'center'} border={'1px'} borderColor={'#E4E4E4'} h={'full'} direction={'column'}>
                <Image src={EmptyIcon} mb={'2'} />
                <Typography color="secondary" colorweight='800' type="title" weight="medium">No Transactions yet</Typography>
            </Flex> :
                <VStack w={"100%"} marginTop={"15px"} gap='24px'>
                    <Box borderRadius={"9px"} bg='white' w='100%' p={"20px 40px"}>
                        <StickyTable labels={labels} maxH={"75vh"}>
                            <Tbody>
                                {orders?.map((item, index) => (
                                    <TableColumn key={index} name={item?.customer?.name} email={item?.customer?.email} type={item?.type} orderId={item?.orderId} createdAT={item?.createdAt} currency={item?.depositCurrency} amount={item?.depositAmount} status={item?.status} invoiceUrl={item?.additionalDetails?.invoiceUrl}
                                    />
                                ))}
                            </Tbody>
                        </StickyTable>
                    </Box>
                </VStack>}
        </Box>
    );
};

export default CustomerTransactions;