import React, { useEffect, useState } from "react";
import { Box, Flex, Image, Text, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

//custom components and fucntions
import Loader from "../../utils/Loader";
import { callApi } from "../../utils/http_common";
import PasswordInput from "../../components/LoginComponents/InputComponents/PasswordInput";
import BackButton from "../../components/LoginComponents/BackButton";
import Button from "../../components/LoginComponents/Button";

//css
import "./Login.css";
import "./PasswordLogin.css";

//icons
import { TiTick } from "react-icons/ti";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import TransfiLogo from "../../assets/transfi-logo.svg"

const SetNewPassword = (props) => {
    const toast = useToast();
    const navigate = useNavigate();

    const { email, reset } = props || {};

    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [viewNewPassword, setViewNewPassword] = useState(false);
    const [viewConfirmPassword, setViewConfirmPassword] = useState(false);
    const [passwordStrength, setPasswordStrength] = useState("weak");
    const [smallCase, setSmallCase] = useState(false);
    const [upperCase, setUpperCase] = useState(false);
    const [digit, setDigit] = useState(false);
    const [specialCharacter, setSpecialCharacter] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);

    useEffect(() => {
        const checkEnabled = () => {
            if (newPassword !== "" && confirmPassword !== "") {
                if (newPassword === confirmPassword && ['strong', 'very strong', 'normal'].includes(passwordStrength))
                    setIsDisabled(false);
                else
                    setIsDisabled(true);
            }
            else
                setIsDisabled(true);
        }
        checkEnabled();
    }, [newPassword, confirmPassword]);

    const handleToast = (title, type) => {
        toast({
            title,
            status: type,
            duration: 10000,
            isClosable: true
        });
    };

    const getPasswordStrength = (password) => {
        let score = 0;
        const minLength = 8;
        const maxLength = 12;

        const hasLowerCase = /[a-z]/.test(password);
        const hasUpperCase = /[A-Z]/.test(password);
        const hasDigit = /\d/.test(password);
        const hasSpecialChar = /[!_@#$%^&*]/.test(password);
        const doNotAllowOtherCharacters = /^[a-zA-Z0-9!_@#$%^&]*$/.test(password);

        if (hasLowerCase) setSmallCase(true); else setSmallCase(false);
        if (hasUpperCase) setUpperCase(true); else setUpperCase(false);
        if (hasDigit) setDigit(true); else setDigit(false);
        if (hasSpecialChar) setSpecialCharacter(true); else setSpecialCharacter(false);
        if (password !== "" && hasSpecialChar && doNotAllowOtherCharacters) setSpecialCharacter(true); else setSpecialCharacter(false);

        if (password.length >= minLength) {
            if (hasLowerCase) score++;
            if (hasUpperCase) score++;
            if (hasDigit) score++;
            if (hasSpecialChar) score++;
            if (password.length >= maxLength) score++;
        }

        return score;
    }

    const setStrengthStatus = (input) => {
        const strength = getPasswordStrength(input);
        switch (strength) {
            case 1:
                setPasswordStrength("weak");
                break;
            case 2:
                setPasswordStrength("weak");
                break;
            case 3:
                setPasswordStrength("normal");
                break;
            case 4:
                setPasswordStrength("strong");
                break;
            case 5:
                setPasswordStrength("very strong");
                break;
            default:
                setPasswordStrength("weak");
                break;
        }
    }

    const handleChangeNewPassword = (e) => {
        setNewPassword(e.target.value);
        setStrengthStatus(e.target.value);

        let confirmColor = "red";
        if (e.target.value === confirmPassword) {
            confirmColor = "green";
        }
        if (confirmPassword === "") {
            confirmColor = "transparent";
        }

        const confirmPasswordElement = document.getElementById("confirm-password-id");
        confirmPasswordElement.style.borderBottomColor = confirmColor;
    }

    const handleChangeConfirmPassword = (e) => {
        setConfirmPassword(e.target.value);
        let color = "red";
        if (e.target.value === newPassword && e.target.value !== "") {
            color = "green";
        }
        if (e.target.value === "") {
            color = "transparent";
        }
        const confirmPasswordElement = document.getElementById("confirm-password-id");
        confirmPasswordElement.style.borderBottomColor = color;
    }

    const onSubmitPassword = async () => {
        if (newPassword === "") {
            handleToast("Please enter new password", "error");
            return;
        }
        if (confirmPassword === "") {
            handleToast("Please confirm your new password", "error");
            return;
        }
        if (newPassword !== confirmPassword) {
            handleToast("Passwords are not matching, Please try again", "error");
            return;
        }
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!_@#$%^&])[a-zA-Z\d!_@#$%^&]{8,}$/;
        if (!regex.test(newPassword)) {
            handleToast("Invalid Password, Please enter valid password", "error");
            return;
        }

        try {
            setIsLoading(true);

            const data = await callApi.post(`/customer/updatePaAccountDetails`, { email, accountDetails: newPassword });
            const { status = false, message } = data;

            if (status) {
                navigate('/login');
                setIsLoading(false);
                handleToast("Your password has been successfully set.", 'success');
            } else {
                throw new Error(message);
            }

        } catch (e) {
            handleToast(e?.response?.data?.error?.message || "Something went wrong", 'error');
            setIsLoading(false);
        }
    }

    const onResetPassword = async () => {
        if (newPassword === "") {
            handleToast("Password enter new password", "error");
            return;
        }
        if (confirmPassword === "") {
            handleToast("Password confirm your new password", "error");
            return;
        }
        if (newPassword !== confirmPassword) {
            handleToast("Passwords are not matching, Please try again", "error");
            return;
        }
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!_@#$%^&])[a-zA-Z\d!_@#$%^&]{8,}$/;
        if (!regex.test(newPassword)) {
            handleToast("Invalid Password, Please enter valid password", "error");
            return;
        }

        try {
            setIsLoading(true);

            const data = await callApi.post(`/customer/resetPaAccountDetails`, { email, accountDetails: newPassword });
            const { status = false, message, error = {} } = data || {};
            const { message: errMessage } = error;

            if (status) {
                navigate('/login');
                setIsLoading(false);
                handleToast("Your password has been successfully reset.", 'success');
            } else {
                throw new Error(errMessage);
            }

        } catch (e) {
            console.log(e);
            handleToast(e?.response?.data?.error?.message || "Something went wrong", 'error');
            setIsLoading(false);
        }
    }

    const handlePaste = (e) => {
        e.preventDefault();
        handleToast("Pasting password is not allowed", "error");
    }

    const handleSubmit = async () => {
        if (reset) {
            await onResetPassword();
        } else {
            await onSubmitPassword();
        }
    }

    return (
        isLoading ? <Loader /> :
            <Flex bg={'white'} alignItems={'center'} h={'full'} w={'full'}>
                <Box bg={'white'} h={"full"} w={'50%'}>
                    <Box>
                        <div className="pa-login-sub-card">
                            <div className="pa-login-header">
                                <BackButton />
                                <img src={TransfiLogo} alt="logo" />
                                <span style={{ fontWeight: '500' }} >Forgot Password?</span>
                            </div>
                            <div className="pa-login-body">
                                <p>New Password</p>
                                <PasswordInput
                                    placeholder={"Enter your password"}
                                    inputChangeHandler={handleChangeNewPassword}
                                    value={newPassword}
                                    viewPassword={viewNewPassword}
                                    setViewPassword={setViewNewPassword}
                                >
                                </PasswordInput>
                                {newPassword.length > 0 && <>
                                    <span className={"pa-login-password-rules"} style={(newPassword.length >= 8) ? { color: 'green' } : { color: 'red' }}>
                                        {(newPassword.length >= 8) ? <TiTick /> : <AiOutlineExclamationCircle />}
                                        Should have atleast 8 characters
                                    </span>
                                    <span className={"pa-login-password-rules"} style={/[a-z]/.test(newPassword) ? { color: 'green' } : { color: 'red' }}>
                                        {/[a-z]/.test(newPassword) ? <TiTick /> : <AiOutlineExclamationCircle />}
                                        Should have atleast 1 small case character
                                    </span>
                                    <span className={"pa-login-password-rules"} style={/[A-Z]/.test(newPassword) ? { color: 'green' } : { color: 'red' }}>
                                        {/[A-Z]/.test(newPassword) ? <TiTick /> : <AiOutlineExclamationCircle />}
                                        Should have atleast 1 upper case character
                                    </span>
                                    <span className={"pa-login-password-rules"} style={/[0-9]/.test(newPassword) ? { color: 'green' } : { color: 'red' }}>
                                        {/[0-9]/.test(newPassword) ? <TiTick /> : <AiOutlineExclamationCircle />}
                                        Should have atleast 1 digit
                                    </span>
                                    <span className={"pa-login-password-rules"} style={/[!_@#$%^&*]/.test(newPassword) ? { color: 'green' } : { color: 'red' }}>
                                        {/[!_@#$%^&*]/.test(newPassword) ? <TiTick /> : <AiOutlineExclamationCircle />}
                                        Should have atleast 1 special character from !_@#$%^&*
                                    </span>
                                </>}
                                <p>Confirm New Password</p>
                                <PasswordInput
                                    id={"confirm-password-id"}
                                    placeholder={"Confirm password"}
                                    inputChangeHandler={handleChangeConfirmPassword}
                                    value={confirmPassword}
                                    viewPassword={viewConfirmPassword}
                                    setViewPassword={setViewConfirmPassword}
                                    paste={handlePaste}
                                >
                                </PasswordInput>
                                {confirmPassword.length > 0 &&
                                    <span className={newPassword === confirmPassword ? "pa-login-password-strength-hidden" : "pa-login-password-strength"} style={{ marginBottom: '5px', color: `red` }}>
                                        {<AiOutlineExclamationCircle />}
                                        Password does not match
                                    </span>}
                                <Button className={"pa-button"} styles={{ marginTop: '60px' }} isDisabled={isDisabled} onClickHandler={handleSubmit}>
                                    {`${reset ? 'Reset' : 'New'} Password`}
                                </Button>
                            </div>
                        </div>
                    </Box>
                </Box>
                <Flex className="gradient-background" flexDirection={'column'} justify={'space-between'} w={'full'} maxH={'100vh'} overflow={'hidden'}>
                    <Box ml={'80px'} mt={'100px'}>
                        <Text color={'#092C4C'} fontFamily={'DM Sans'} fontWeight={'700'} fontSize={'48px'}>Borderless <Text as={'span'} color={'#1A4FD6'}>payments</Text>,</Text>

                        <Text color={'#092C4C'} fontFamily={'DM Sans'} fontWeight={'700'} fontSize={'48px'}>boundless <Text as={'span'} color={'#1A4FD6'}>potential</Text></Text>
                    </Box>
                    <Flex w={'full'} justify={'flex-end'}>
                        <Image src="/assets/login.png" maxH={'809px'} maxW={'992px'} />
                    </Flex>
                </Flex>
            </Flex>
    );
};

export default SetNewPassword;
