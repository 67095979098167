import React from 'react';

// css
import "../../modules/login/PasswordLogin.css";

function Divider (props) {
    return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px', width: '100%' }}>
            <div className="pa-horizontal-line"></div>
            <span className="pa-horizontal-line-text">{props.children}</span>
            <div className="pa-horizontal-line"></div>
        </div>
    );
}

export default Divider;