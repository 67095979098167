import { Box } from '@chakra-ui/react';

const Card = (props) => {
  return (
    <Box
      boxSizing="border-box"
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      p="20px 40px"
      gap="20px"
      w="100%"
      bg="#FFFFFF"
      // border="1px solid #E4E4E4"
      borderRadius="9px"
      flex="1"
    >
      {props.children}
    </Box>
  );
}

export default Card;