import React, { useEffect, useState } from 'react';
import {
    Box,
    Flex,
    Circle,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import Typography from '../../components/Common/Typography';
import { formatDateTime } from '../../utils/formatter';

function formatDate(dateString) {
    if (!dateString) return '';
    return `${formatDateTime(dateString, 'hh:mm A')} | ${formatDateTime(dateString, 'DD MMM YYYY')}`;
}

const StepIcon = ({ status, step }) => {
    switch (status) {
        case 'completed':
            return <CheckIcon />;
        case 'inProgress':
            return step;
        default:
            return step
    }
};

const Step = (props) => {
    const { status, step } = props;

    const backgroundColor = () => {
        switch (status) {
            case 'completed':
                return "brand.green";
            case 'inProgress':
                return "brand.primary";
            case 'expired':
            case 'failed':
                return "brand.danger"; // Assuming there is a 'brand.red' in your theme for red color
            default:
                return "";
        }
    };

    const borderColor = () => {
        if (status === 'completed') {
            return "none";
        } else if (status === 'inProgress') {
            return "brand.primary";
        } else if (status === 'expired' || status === 'failed') {
            return "brand.danger"; // Use red for border when expired or failed
        } else {
            return "brand.grey.400";
        }
    };

    const textColor = () => {
        switch (status) {
            case 'completed':
            case 'inProgress':
            case 'expired':
            case 'failed':
                return "white";
            default:
                return "brand.grey.400";
        }
    }


    return (
        <Flex justifyContent={"center"} alignItems={"center"} w={"35px"} h={"35px"} border={status !== "completed" ? "1px solid" : "none"} borderColor={borderColor()} borderRadius={"50%"} direction="column">
            <Circle
                size="25px"
                bg={backgroundColor()}
                color={textColor()}
            >
                <StepIcon status={status} step={step} />
            </Circle>
        </Flex>
    );
}

const textColors = {
    completed: {
        color: 'secondary',
        colorweight: '400'
    },
    inProgress: {
        color: 'secondary',
        colorweight: '800'
    },
    pending: {
        color: 'secondary',
        colorweight: '400'
    },
    failed: {
        color: 'error',
        colorweight: '400'
    },
}

const StablecoinStepper = ({ data }) => {
    const [steps, setSteps] = useState([
        { label: 'Prefunding', step: 1, description: 'Initiated', time: 'pending', status: 'pending' },
        { label: 'Prefunding', step: 2, description: 'Pending', time: 'pending', status: 'pending' }
    ]);

    const updateSteps = (status, timestamps) => {
        const initialSteps = [
            { label: 'Prefunding', description: 'Initiated', time: 'pending', status: 'pending' },
            { label: 'Prefunding', description: 'Pending', time: 'pending', status: 'pending' }
        ];

        const updateStep = (index, key, value) => {
            initialSteps[index][key] = value;
        };

        switch (status) {
            case 'initiated':
                updateStep(0, 'status', 'completed');
                updateStep(0, 'description', 'Initiated');
                updateStep(0, 'time', formatDate(timestamps.initiatedAt));
                updateStep(1, 'status', 'inProgress');
                break;

            case 'asset_deposit_failed':
            case 'asset_failed':
            case 'rejected':
            case 'failed':
            case 'expired':
                initialSteps.forEach((step, index) => {
                    if (index < 1) {
                        step.status = 'completed';
                    }
                });
                initialSteps[1].status = 'failed';
                initialSteps[1].description = status === "expired" ? 'Expired' : 'Failed';
                initialSteps[0].time = formatDate(timestamps.initiatedAt);
                updateStep(1, 'time', '');
                break;
            case 'completed':
            case 'fund_settled':
                initialSteps.forEach(step => step.status = 'completed');
                initialSteps[1].description = 'Successful';
                initialSteps[1].time = formatDate(timestamps.completedAt || timestamps.fundSettledAt);
                initialSteps[0].time = formatDate(timestamps.initiatedAt || timestamps.fundProcessingAt);
                break;
            default:
                break;
        }

        setSteps(initialSteps.map((item, index) => ({ ...item, step: index + 1 })));
    };

    useEffect(() => {
        if (data?.status) {
            updateSteps(data.status, data.timestamps);
        }

    }, [data])


    return (
        <Box pl={6} h={"156px"} w={"95%"} mx={"auto"} pt={5}>

            <Flex mx={"auto"} columnGap={5}>
                {steps.map((step, index) => (
                    <Flex key={index} alignItems={"center"} flex={1} columnGap={5}>
                        <Step status={step.status} step={step.step} />
                        {index < steps.length - 1 && (
                            <Box flex="1" borderRadius={"24px"} height="3px" bg={step.status === "completed" ? "#26A17B" : "brand.greyPrimary"} />
                        )}
                    </Flex>
                ))}
            </Flex>
            <Flex mx={"auto"} mt={4} columnGap={5}>
                {steps.map((step, index) => (
                    <Flex rowGap={"60px"} key={index} alignItems={"start"} flex={1} columnGap={5}>
                        <Flex textAlign={"start"} flexDir={"column"}>
                            <Typography type="caption">
                                Step {step.step}
                            </Typography>
                            <Typography type="description" weight="bold" color={textColors[step.status].color} colorweight={step.status === "completed" && index === steps.length - 1 ? "800" : textColors[step.status].colorweight}>
                                {step.label}   {step.description}
                            </Typography>
                            <Typography type="caption" color={step.status === "completed" ? "default" : textColors[step.status].color} colorweight={step.status === "completed" ? "default" : textColors[step.status].colorweight}>
                                {step.time}
                            </Typography>
                            {step.status === "inProgress" &&
                                <Typography type="caption" color="primary">
                                    &#40;In Progress&#41;
                                </Typography>
                            }
                        </Flex>
                    </Flex>
                ))}
            </Flex>

        </Box>

    );
};

export default StablecoinStepper;
