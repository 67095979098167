import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import ContactService from '../../services/contactService';

const initialStateValues = {
  step: 1,
  totalSteps: 4,
  values: {},
  loading: false,
  contactList: {},
  contactDetails: {},
  paymentAccounts: {},
  firstStepComplete: false,
  secondStepComplete: false,
  sendAmount: 0,
  receivedAmount: 0,
};

export const getContacts = createAsyncThunk(
  "sendMoney/getContact",
  async (body, { rejectWithValue }) => {
    try {
      const res = await ContactService.getContacts();
      return res?.data;
    } catch (e) {
      return rejectWithValue(e?.response?.data);
    }
  }
);

export const sendMoneySlice = createSlice({
  name: 'sendMoney',
  initialState: initialStateValues,
  reducers: {
    goToNextStep: (state) => {
      state.step += 1;
      if (state.step === 2)
        state.firstStepComplete = true;
      else if (state.step === 3)
        state.secondStepComplete = true;
    },
    goToPreviousStep: (state) => {
      if (state.step !== 1) {
        state.step -= 1;
        if (state.step === 1)
          state.firstStepComplete = false;
        else if (state.step === 2)
          state.secondStepComplete = false;
      }
    },
    setActiveStep: (state, payload) => {
      state.step = payload.payload;

      if (payload.payload === 2) {
        state.secondStepComplete = false;
      } else if (payload.payload === 1) {
        state.firstStepComplete = false;
        state.secondStepComplete = false;
      }
    },
    setValues: (state, payload) => {
      state.values = payload.payload;
    },
    resetState: (state, payload) => {
      state.step = 1;
      state.totalSteps = 4;
      state.values = {};
      state.loading = false;
      state.contactList = {};
      state.contactDetails = {};
      state.paymentAccounts = {};
      state.firstStepComplete = false;
      state.secondStepComplete = false;
      state.sendAmount = 0;
      state.receivedAmount = 0;
    },
    setSendAmount: (state, payload) => {
      state.sendAmount = payload.payload;
    },
    setReceivedAmount: (state, payload) => {
      state.receivedAmount = payload.payload;
    }
  },
  extraReducers: {
    [getContacts.pending]: (state, action) => {
      state['contactList'] = {
        error: false
      };
      state["loading"] = true;
    },
    [getContacts.fulfilled]: (state, action) => {
      const { contacts } = action?.payload?.data || {};
      state['contactList'] = {
        error: false,
        data: contacts
      };
      state['loading'] = false;
    },
    [getContacts.rejected]: (state, action) => {
      state['contactList'] = {
        error: action.payload?.error?.message
      };
      state["loading"] = false;
    },
  }
})

// Action creators are generated for each case reducer function
export const { setActiveStep, goToNextStep, goToPreviousStep, setValues, resetState, setSendAmount, setReceivedAmount } = sendMoneySlice.actions;

const { reducer: sendMoneyReducer } = sendMoneySlice;

export default sendMoneyReducer;
