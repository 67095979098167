import React from 'react';

// css
import "../../modules/signup/Signup.css";

function ProgressBar(props) {
    const { level } = props;
    const styles = { backgroundColor: '#1A4FD6' };
    return (
        <div className="pa-progress-bar">
            <div className="pa-progress-bar-child" style={ level >= 1 ? styles : {} } ></div>
            <div className="pa-progress-bar-child" style={ level >= 2 ? styles : {} } ></div>
            <div className="pa-progress-bar-child" style={ level >= 3 ? styles : {} } ></div>
        </div>
    );
}

export default ProgressBar;
