import React from 'react';
import { useNavigate } from 'react-router';
import { Button, Center, Modal, ModalBody, useClipboard, ModalContent, ModalFooter, ModalOverlay, VStack, useToast } from '@chakra-ui/react';

import Typography from '../../../../components/Common/Typography';

import { CopyIcon } from "@chakra-ui/icons";

const BankDetailsModal = ({ isOpen, onClose, data }) => {
  const { clabe = '', concepto = '', beneficiaryName = '', amount = 0, currency = "", orderId = '' } = data;
  const amountToBePaid = `${amount.toString()} ${currency}`

  const { onCopy: onClabeCopy } = useClipboard(clabe);
  const { onCopy: onConceptoCopy } = useClipboard(concepto);
  const { onCopy: onNameCopy } = useClipboard(beneficiaryName);
  const { onCopy: onAmountCopy } = useClipboard(amountToBePaid);

  const toast = useToast();
  const navigate = useNavigate();

  const handleApprove = async () => {
    try {
      onClose();
      navigate(`/payout-transactions/summary/${orderId}`)
    }
    catch (e) {
      console.log(e);
      toast({
        title: e?.response?.data?.error?.message || "Something went wrong",
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      onClose();
    }
  }

  const onModalClose = () => {
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onModalClose} >
      <ModalOverlay />
      <ModalContent
        minWidth="fit-content"
        height="fit-content"
      >
        <Center mt={4}>
          <Typography color="secondary" colorweight="800" weight='bold'>Payment Checkout</Typography>
        </Center>
        <ModalBody display={"flex"} flexDirection={"column"} alignItems={"center"} mt={"20px"} >
          <VStack>
            <Typography color="primary" type='description' weight='bold'>
              Please Transfer the amount to the below mentioned account, Make sure to enter the right bank details.
            </Typography>
            <VStack width={"100%"} display={"flex"} flexDirection={"column"} alignItems={"flex-start"} mt={"20px"}>
              <Typography color="secondary" type='body' colorweight="800" weight='bold' >
                Clabe:
              </Typography>
              <Button display={"flex"} backgroundColor={"#f6f8fa"} justifyContent={"space-between"} width={"100%"} variant="outline" marginRight={'10px'} onClick={onClabeCopy} textAlign={"left"} >
                <Typography color="secondary" type='body' fontWeight={'bold'}>{clabe}</Typography>
                <CopyIcon />
              </Button>
            </VStack>
            <VStack width={"100%"} display={"flex"} flexDirection={"column"} alignItems={"flex-start"} mt={"20px"}>
              <Typography color="secondary" type='body' colorweight="800" weight='bold' >
                Concepto:
              </Typography>
              <Button display={"flex"} backgroundColor={"#f6f8fa"} justifyContent={"space-between"} width={"100%"} variant="outline" marginRight={'10px'} onClick={onConceptoCopy} textAlign={"left"} >
                <Typography color="secondary" type='body' fontWeight={'bold'}>{concepto}</Typography>
                <CopyIcon />
              </Button>
            </VStack>
            <VStack width={"100%"} display={"flex"} flexDirection={"column"} alignItems={"flex-start"} mt={"0px"}>
              <Typography color="error" type='description' weight='bold'>
                Please note that including concepto is mandatory for the transaction
              </Typography>
            </VStack>
            <VStack width={"100%"} display={"flex"} flexDirection={"column"} alignItems={"flex-start"} mt={"20px"}>
              <Typography color="secondary" type='body' colorweight="800" weight='bold' >
                Beneficiary Name:
              </Typography>
              <Button display={"flex"} backgroundColor={"#f6f8fa"} justifyContent={"space-between"} width={"100%"} variant="outline" marginRight={'10px'} onClick={onNameCopy} textAlign={"left"} >
                <Typography color="secondary" type='body' fontWeight={'bold'}>{beneficiaryName}</Typography>
                <CopyIcon />
              </Button>
            </VStack>
            <VStack width={"100%"} display={"flex"} flexDirection={"column"} alignItems={"flex-start"} mt={"20px"}>
              <Typography color="secondary" type='body' colorweight="800" weight='bold' >
                Amount to deposit:
              </Typography>
              <Button display={"flex"} backgroundColor={"#f6f8fa"} justifyContent={"space-between"} width={"100%"} variant="outline" marginRight={'10px'} onClick={onAmountCopy} textAlign={"left"} >
                <Typography color="secondary" type='body' fontWeight={'bold'}>{amountToBePaid}</Typography>
                <CopyIcon />
              </Button>
            </VStack>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            bg={"#F2F2F2"}
            _hover={{ bg: "#F2F2F2" }}
            color="#808080"
            width={'113px'}
            height={'40px'}
            rounded={'6px'}
            fontSize={'14px'}
            fontWeight={'400'}
            fontFamily={'DM Sans'} mr={3} onClick={onModalClose}>
            Cancel
          </Button>

          <Button
            bg={"#1a4fd6"}
            _hover={{ bg: '#1a4fd6' }}
            color="white"
            width={'113px'}
            height={'40px'}
            rounded={'6px'}
            fontSize={'14px'}
            fontWeight={'400'}
            fontFamily={'DM Sans'}
            onClick={handleApprove}
          >
            Continue
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default BankDetailsModal;