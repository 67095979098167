import React, { useEffect, useState } from 'react'
import { Box, VStack, Tbody, Tr, Td, Flex, Img, SimpleGrid } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

import PersonWithCheck from '../../assets/person_with_check_outline.svg'
import WalletIcon from '../../assets/wallet_icon.svg'

import Typography from '../../components/Common/Typography'
import StickyTable from '../../components/Common/Table/StickyTable'
import Loader from '../../utils/Loader'
import BalanceService from '../../services/balanceService'
import { currencyFormatter } from '../../utils/formatter'

const fiatLabels = [
    {
        label: "Currency",
    },
    {
        label: "Total Invoiced Volume",
        info: "Total Invoice amount you have sent to customers"
    },
    {
        label: "Total settled",
        info: "Total amount of invoices that has been settled by TransFi to you"
    },
    {
        label: "Total unsettled",
        info: "Total amount of invoices that has not been settled by TransFi to you"
    },
]


const TableColumn = (props) => {
    const { currency, logo, availableBalance, transitBalance, lockedBalance } = props
    if (!currency) return null;

    return (
        <Tr height={"46px"}>
            <Td>
                <Flex columnGap={2} alignItems={"center"}>
                    <Img src={encodeURIComponent(process.env.PUBLIC_URL + (logo || `/assets/crypto/${currency}.svg`))} alt={""} width={38} height={9} />
                    <Typography weight='regular' type='description'>{currency}</Typography>
                </Flex>
            </Td>
            <Td>
                <Typography weight='regular' type='description'>{availableBalance}</Typography>
            </Td>
            <Td>
                <Typography weight='regular' type='description'>{transitBalance}</Typography>
            </Td>
            <Td>
                <Typography weight='regular' type='description'>{lockedBalance}</Typography>
            </Td>
        </Tr>
    )
}


const QuickLinkBox = (props) => {
    const navigate = useNavigate()
    const { title, description, icon, link, onClick } = props
    const handleClick = () => {
        navigate(link)
    }
    return (
        <Box cursor={"pointer"} display={"flex"} flexDir={"column"} rowGap={"12px"} w={"100%"} border={"1px solid"} borderColor={"brand.greyPrimary"} bg='white' borderRadius={"9px"} p={"20px 40px"} boxShadow="4px 4px 17.4px 0px #DDDDDD40"
            onClick={onClick || handleClick} _hover={{ borderColor: '#1A4FD6' }}>
            <Img src={icon} alt='icon' w={"32px"} h={"32px"} />
            <Typography type='subtitle' weight='bold' >
                {title}
            </Typography>
            <Typography type='body' weight='medium' color='secondary' >
                {description}
            </Typography>
        </Box>
    )
}

export default function CollectionsHome() {
    const [myCollections, setMyCollections] = useState([])
    const [loading, setLoading] = useState(true)
    const getMyAccounts = async () => {
        try {
            const { data } = await BalanceService.getCollectionOverview();
            if (data?.data?.status === false) {
                setMyCollections([])
                return;
            }
            setMyCollections(data?.data?.list || []);
        } catch (error) {
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getMyAccounts()
    }, []);


    if (loading) return <Loader />

    if (myCollections.length === 0) return (
        <Box minH={"92vh"} p={"16px 40px"} bg={"#F2F2F2"}>
            <Typography type='largeheader' weight='bold' >
                Welcome to TransFi
            </Typography>
            <VStack w={"100%"} marginTop={"15px"} gap='24px'>
                <Box bg='white' w='100%' borderRadius={"9px"} p={"20px 40px"}>
                    <Typography type='body' weight='medium' color='primary' >
                        Overview
                    </Typography>
                    <Typography type='body' weight='medium' color='primary' >
                        No collections found
                    </Typography>
                </Box>
            </VStack>
        </Box>
    )

    return (
        <Box minH={"92vh"} p={"16px 40px"} bg={"#F2F2F2"}>
            <Typography type='largeheader' weight='bold' >
                Welcome to TransFi
            </Typography>
            <VStack w={"100%"} marginTop={"15px"} gap='24px'>
                <Box bg='white' w='100%' borderRadius={"9px"} p={"20px 40px"}>
                    <Typography type='body' weight='medium' color='primary' >
                        Overview
                    </Typography>
                    <StickyTable maxH="40vh" labels={fiatLabels}>
                        <Tbody>
                            {myCollections?.map((item, index) => (
                                <TableColumn key={index} currency={item.currency} logo={item.logo} availableBalance={`${currencyFormatter(item.invoicedVolume)} ${item.currency}`} transitBalance={`${currencyFormatter(item.totalSettled)} ${item.currency}`} lockedBalance={`${currencyFormatter(item.unsettled)} ${item.currency}`} />
                            ))}
                        </Tbody>
                    </StickyTable>
                </Box>
            </VStack>

            <Box mt={8} bg='white' w='100%' borderRadius={"9px"} p={"20px 40px"}>

                <Typography type='body' weight='medium' color='primary' >
                    Quick Links
                </Typography>

                <SimpleGrid mt={10} columns={2} spacing={10}>
                    <QuickLinkBox title="Collect Money" description="Create a link to accept payments from your customer" icon={PersonWithCheck} link="/collection" />
                    <QuickLinkBox title="Add Customer" description="Add customer details to share a payment request" icon={WalletIcon} link="/customers/add-customer " />
                </SimpleGrid>
            </Box>

        </Box>
    )
}
