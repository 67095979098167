import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@chakra-ui/button';
import { Box, HStack, Stack } from '@chakra-ui/layout';
import { Img } from '@chakra-ui/react';


// Custom Function and Components
import Typography from '../../../../components/Common/Typography';
import { resetState } from '../../../../store/slices/sendMoneySlices';

// CSS
import '../../sendmoney.css';
import { currencyFormatter } from '../../../../utils/formatter';


const ApprovalPending = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { contactId = {}, enteredAmount: paidAmount = 0, walletName: walletDetails = {} } = useSelector(state => state.sendMoney?.values || {});
  const { label: contactName } = contactId;
  const { label: walletCurrency = "" } = walletDetails;
  const walletName = `${walletCurrency} Wallet`;
  const paidAmountFormatted = ["BTC"].includes(walletCurrency) ?
    `${currencyFormatter(paidAmount, 6)} ${walletCurrency}` :
    `${currencyFormatter(paidAmount, 2)} ${walletCurrency}`;

  const handleResetStore = () => {
    dispatch(resetState());
  }

  return (
    <Box w={"100%"} display={"flex"} justifyContent={"center"} >
      <Box className='approval-pending-screen'>
        <Box width={"100%"} textAlign={"center"} display={"flex"} flexDirection={"column"} gap={"12px"} justifyContent={"center"} alignItems={"center"} padding={"20px 0px 0px 0px"} >
          <Img src={process.env.PUBLIC_URL + "/assets/approvalScreenLogo.png"} alt='logo' boxSize='120px' />
          <Typography type='title' weight='medium' color='secondary' colorweight='800' >
            Payment approval is pending
          </Typography>
          <Typography type='description' weight='regular' color='secondary' colorweight='800' >
            We have notified your approver to provide a response.
          </Typography>
        </Box>
        <Box className='ap-screen-body' >
          <HStack w={"100%"} display={"flex"} flexDirection={"row"} justifyContent={"space-between"} >
            <Box display={"flex"} flexDirection={"column"} gap={"12px"} >
              <Typography type='description' weight='regular' color='secondary' colorweight='600' >
                Status
              </Typography>
              <Typography type='description' weight='regular' color='headertext' >
                Pending
              </Typography>
            </Box>
            <Box display={"flex"} flexDirection={"column"} gap={"12px"} >
              <Typography type='description' weight='regular' color='secondary' colorweight='600' >
                Receiving contact
              </Typography>
              <Typography type='description' weight='regular' color='headertext' >
                {contactName}
              </Typography>
            </Box>
          </HStack>
          <HStack w={"100%"} display={"flex"} flexDirection={"row"} justifyContent={"space-between"} >
            <Box display={"flex"} flexDirection={"column"} gap={"12px"} >
              <Typography type='description' weight='regular' color='secondary' colorweight='600' >
                Sent from
              </Typography>
              <Typography type='description' weight='regular' color='headertext' >
                {walletName}
              </Typography>
            </Box>
            <Box display={"flex"} flexDirection={"column"} gap={"12px"} >
              <Typography type='description' weight='regular' color='secondary' colorweight='600' >
                Total paid amount
              </Typography>
              <Typography type='description' weight='regular' color='headertext' >
                {paidAmountFormatted}
              </Typography>
            </Box>
          </HStack>
        </Box>
        <Stack direction="row" gap={"20px"} justifyContent={"center"} w='100%'>
          <Button variant={"secondary"} size={"sm"} fontFamily={"DM Sans"} fontSize={"12px"} type="button" p={"9px 16px 9px 16px"} onClick={() => { navigate('/'); handleResetStore(); }}>
            Close
          </Button>
          <Button variant={"primary"} fontFamily={"DM Sans"} size={"sm"} fontSize={"12px"} p={"9px 16px 9px 16px"} onClick={() => { navigate('/send-money'); handleResetStore(); }} >
            Make Payment
          </Button>
        </Stack>
      </Box>
    </Box>
  );
}

export default ApprovalPending;