import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.min.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.min.css"; // Theme
import { useCallback, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";

import './style.css';

export default function GridV2(props) {
    const { columns, getOrderData } = props;
    const quickFilterText = '';
    const gridRef = useRef();
    const orgRoles = useSelector((state) => state?.login?.orgDetails?.orgRoles);

    const [gridApi, setGridApi] = useState(null);

    // Column Definitions: Defines & controls grid columns.
    const [colDefs, setColDefs] = useState(columns.filter(column => (orgRoles?.includes("transfi_admin")) || (column.customerVisibility)));

    // Apply settings across all columns
    const defaultColDef = useMemo(() => ({
        filter: true // Enable filtering on all columns
    }));

    const quickFilterParser = useCallback((quickFilter) => {
        let quickFilterParts = [];
        let lastSpaceIndex = -1;
        const isQuote = (index) => quickFilter[index] === '"';
        const getQuickFilterPart = (lastSpaceIndex, currentIndex) => {
            const startsWithQuote = isQuote(lastSpaceIndex + 1);
            const endsWithQuote = isQuote(currentIndex - 1);
            const startIndex =
                startsWithQuote && endsWithQuote
                    ? lastSpaceIndex + 2
                    : lastSpaceIndex + 1;
            const endIndex =
                startsWithQuote && endsWithQuote ? currentIndex - 1 : currentIndex;
            return quickFilter.slice(startIndex, endIndex);
        };
        for (let i = 0; i < quickFilter.length; i++) {
            const char = quickFilter[i];
            if (char === ' ') {
                if (!isQuote(lastSpaceIndex + 1) || isQuote(i - 1)) {
                    quickFilterParts.push(getQuickFilterPart(lastSpaceIndex, i));
                    lastSpaceIndex = i;
                }
            }
        }
        if (lastSpaceIndex !== quickFilter.length - 1) {
            quickFilterParts.push(
                getQuickFilterPart(lastSpaceIndex, quickFilter.length)
            );
        }
        return quickFilterParts;
    }, []);

    const onGridReady = (params) => {
        setGridApi(params);
        // register datasource with the grid
        const datasource = {
            getRows(params) {
                const { startRow, endRow, filterModel, sortModel, valueCols, pivotMode, pivotCols, groupKeys, rowGroupCols } = params.request;
                const filters = { startRow, endRow, filterModel, sortModel, valueCols, pivotMode, pivotCols, groupKeys, rowGroupCols };
                const responseData = getOrderData(filters);
                responseData.then(response => response.data.data)
                    .then(response => {
                        params.success({
                            rowData: response.list,
                            pivotResultFields: response.pivotFields,
                        });
                    })
                    .catch(error => {
                        console.error(error);
                        params.fail();
                    });
            }
        };
        params.api.setServerSideDatasource(datasource);
    }

    return (
        // Container
        <div className="ag-theme-quartz" style={{ height: 650 }} >
            {/* The AG Grid component */}
            <AgGridReact
                ref={gridRef}
                columnDefs={colDefs}
                defaultColDef={defaultColDef}
                pagination={true}
                onGridReady={onGridReady}
                rowModelType='serverSide'
                serverSideStoreType='partial'
                onCellValueChanged={event => console.log(`New Cell Value: ${event.value}`)}
                quickFilterText={quickFilterText}
                quickFilterParser={quickFilterParser}
                sideBar={true}
                suppressMenuHide={true}
                enableAdvancedFilter={true}
                suppressMultiRangeSelection={true}
                enableRangeSelection={true}
                rowGroupPanelShow={'always'}
                groupIncludeFooter={true}
                paginationPageSize={20}
                cacheBlockSize={20}
            // groupIncludeTotalFooter= {true}
            />
        </div>
    )
}