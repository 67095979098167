import moment from "moment-timezone";

export function currencyFormatter(amount, decimals = 2, separators = true) {
    const number = Number(amount).toFixed(decimals);
    let result = number;

    if (separators) {
        result = parseFloat(number).toLocaleString(undefined, {
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals
        });
    }
    
    return result === "NaN" ? "0.00" : result;
}

export function formatDateTime(date, format = "DD MMM YYYY hh:mm A") {
    if (!date) return ""
    const countryCode = localStorage.getItem("countryCode") || "US";
    const timezones = moment.tz.zonesForCountry(countryCode);
    const bestTimezone = timezones[0];

    return moment(date).format(format);
}
