import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { Box, Button } from '@chakra-ui/react'

import WalletToWalletSuccess from './WalletToWalletSuccess'
import Typography from '../../../components/Common/Typography'
import FiatSuccess from './FiatSuccess'
import { setType } from '../../../store/slices/AddMoneySlice'
import Loader from '../../../utils/Loader'

export default function PrefuncSuccess() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [searchParams] = useSearchParams();
    const orderId = searchParams.get("orderId");
    const type = useSelector((state) => state.addMoney.type)
    const successSummaryLoading = useSelector((state) => state.addMoney.successSummaryLoading)

    if (orderId && !type) {
        dispatch(setType("fiat"))
    }

    const handleDoneClick = () => {
        navigate('/my-accounts')
    }
    if (!type) return <Loader />

    return (
        <Box p={"16px 40px"} bg={"#F2F2F2"}>
            <Typography type='largeheader' weight='bold' >
                My Accounts
            </Typography>
            <Box borderRadius={"9px"} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} bg={"white"} w={"100%"} minH={"774px"}>
                {type === "crypto" && <WalletToWalletSuccess />}
                {(type === "fiat" || type === "fiat_to_crypto") && <Box mt={10}>
                    
                     <FiatSuccess />
                     </Box>
                     }
                {
                    !successSummaryLoading &&
                    <Button disabled={successSummaryLoading} isDisabled={successSummaryLoading} onClick={handleDoneClick} variant={"primary"} size={"md"} p={"9px 16px 9px 16px"} mt={4}>
                        Done
                    </Button>
                }
            </Box>
        </Box>
    )
}
