
import * as Yup from 'yup';

export const sendMoneyDetailsValidationSchema = Yup.object().shape({
    currency: Yup.string().required("Currency is required"),
    amount: Yup.number().required("Amount is required").positive("Amount must be positive").typeError("Amount must be a number").test(
        'max-six-decimal-places',
        'Amount must have at most six decimal places',
        value => /^\d+(\.\d{1,6})?$/.test(value)
    ),
    network: Yup.string().when('currency', (currency, schema) => {
        return JSON.parse(currency).type === "crypto"
            ? schema.required("Network is required")
            : schema.notRequired();
    }),
    // id number should be there when the currency.symbol is COP
    idNumber: Yup.string().notRequired(),
});
