import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import ContactService from '../../services/contactService';
import ConfigurationService from '../../services/configurationService';
import { sortBy } from 'lodash';

const initialStateValues = {
  step: 0,
  totalSteps: 2,
  values: {},
  selectedCountry: null,
  firstStepDone: false,
  loading: false,
  contactDetails: {},
  paymentAccountDetails: {},
  paymentAccounts: [],
  isAddAccount: true,
  fiatList: [],
  countryList: [],
}

export const createContact = createAsyncThunk(
  "contact/createContact",
  async (body, { rejectWithValue }) => {
    try {
      const res = await ContactService.createContact(body);
      return res?.data;
    } catch (e) {
      return rejectWithValue(e?.response?.data);
    }
  }
);

export const getContact = createAsyncThunk(
  "contact/getContact",
  async (body, { rejectWithValue }) => {
    try {
      const res = await ContactService.getContact(body);
      return res?.data;
    } catch (e) {
      return rejectWithValue(e?.response?.data);
    }
  }
);
export const getContacts = createAsyncThunk(
  "contact/getContact",
  async (body, { rejectWithValue }) => {
    try {
      const res = await ContactService.getContacts();
      return res?.data;
    } catch (e) {
      return rejectWithValue(e?.response?.data);
    }
  }
);
export const getFiatList = createAsyncThunk(
  "contact/fiatList",
  async (body, { rejectWithValue }) => {
    try {
      const res = await ContactService.getFiatCurrency();
      return res?.data;
    } catch (e) {
      return rejectWithValue(e?.response?.data);
    }
  }
);
export const getFiatCountryList = createAsyncThunk(
  "contact/fiatList",
  async (body, { rejectWithValue }) => {
    try {
      const res = await ConfigurationService.getConfigs("supported_fiat_countries");
      return res?.data;
    } catch (e) {
      return rejectWithValue(e?.response?.data);
    }
  }
);

export const createPaymentAccount = createAsyncThunk(
  "contact/createPaymentAccount",
  async (body, { rejectWithValue }) => {
    try {
      const res = await ContactService.createPaymentAccount(body);
      return res?.data;
    } catch (e) {
      return rejectWithValue(e?.response?.data);
    }
  }
);
export const updateContact = createAsyncThunk(
  "contact/updateContact",
  async (body, { rejectWithValue }) => {
    try {
      const res = await ContactService.updateContact(body);
      return res?.data;
    } catch (e) {
      return rejectWithValue(e?.response?.data);
    }
  }
);

export const deletePaymentAccount = createAsyncThunk(
  "contact/deletePaymentAccount",
  async (body, { rejectWithValue }) => {
    try {
      const res = await ContactService.deletePaymentAccount(body);
      return res?.data;
    } catch (e) {

      return rejectWithValue(e?.response?.data);
    }
  }
);

export const contactSlice = createSlice({
  name: 'contact',
  initialState: initialStateValues,
  reducers: {
    resetContact: () => initialStateValues,
    goToNextStep: (state) => {
      state.step += 1;
      state.firstStepDone = true;
    },
    goToPreviousStep: (state) => {
      if (state.step !== 0) {
        state.step -= 1;
      }
    },
    setActiveStep: (state, payload) => {
      state.step = payload.payload
    },
    setValues: (state, payload) => {
      state.values = payload.payload;
    },
    setAddAccount: (state, payload) => {
      state.isAddAccount = payload.payload;;
    },
    setSelectedCountry: (state, payload) => {
      state.selectedCountry = payload.payload;
    }
  },
  extraReducers: {
    [createContact.pending]: (state, action) => {
      state['contactDetails'] = {
        error: false,
      };
      state['loading'] = true;
    },
    [createContact.fulfilled]: (state, action) => {
      const { contact } = action?.payload?.data || {};
      state['contactDetails'] = {
        error: false,
        contact
      };
      state['loading'] = false;
      // go to next step after creating contact
      // state.step += 1;
    },
    [createContact.rejected]: (state, action) => {
      state['contactDetails'] = {
        error: action.payload?.error?.message
      };
      state["loading"] = false;
    },
    [getContact.pending]: (state, action) => {
      state['contactDetails'] = {
        error: false
      };
      state["paymentAccounts"] = [];
      state["loading"] = true;
    },
    [getContact.fulfilled]: (state, action) => {
      const { contact, paymentAccounts } = action?.payload?.data || {};
      state['contactDetails'] = {
        error: false,
        contact
      };
      state['paymentAccounts'] = paymentAccounts;
      state['loading'] = false;
    },
    [getContact.rejected]: (state, action) => {
      state['contactDetails'] = {
        error: action.payload?.error?.message
      };
      state["paymentAccounts"] = [];
      state["loading"] = false;
    },
    [getFiatList.fulfilled]: (state, action) => {
      state['fiatList'] = action?.payload?.data?.currencies || [];
    },
    [getFiatCountryList.pending]: (state, action) => {
      state['countryList'] = [];
    },
    [getFiatCountryList.fulfilled]: (state, action) => {
      state['countryList'] = sortBy(action?.payload?.data?.configs, 'symbol') || [];
    },
    [createPaymentAccount.pending]: (state, action) => {
      state["paymentAccountDetails"] = {
        error: false
      };
      state["loading"] = true;
    },
    [createPaymentAccount.fulfilled]: (state, action) => {
      console.log("create fulfileed data of payment account: ", action?.payload?.data)
      const accountDetails = action?.payload?.data || {};
      state['paymentAccountDetails'] = {
        error: false,
        accountDetails
      };

      state['paymentAccounts'] = [...state['paymentAccounts'], accountDetails];
      state['loading'] = false;
      state['isAddAccount'] = false;
    },
    [createPaymentAccount.rejected]: (state, action) => {
      state["paymentAccountDetails"] = {
        error: action.payload?.error?.message
      };
      state["loading"] = false;
    },
    [deletePaymentAccount.pending]: (state, action) => {
      // state["loading"] = true;
    },
    [deletePaymentAccount.fulfilled]: (state, action) => {
      const { paymentAccount } = action?.payload?.data || {};
      const fiteredData = state['paymentAccounts']?.filter((item) => item.paId !== paymentAccount.paId);
      state['paymentAccounts'] = fiteredData;
      console.log("filtered payment accounts... ", state['paymentAccounts'].filter((item) => item.paId !== paymentAccount.paId));
    },

  },
})

// Action creators are generated for each case reducer function
export const { resetContact, setActiveStep, goToNextStep, goToPreviousStep, setValues, setAddAccount,setSelectedCountry } = contactSlice.actions

const { reducer: contactReducer } = contactSlice;

export default contactReducer