import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  VStack,
  Image,
  Stack,
  useDisclosure,
  Text,
  Container,
  useClipboard,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import TransfiLog from "../../assets/transfi-logo.svg";
import { useNavigate } from "react-router";
import WarningModal from "../../components/modal";
import { useDispatch, useSelector } from "react-redux";
import { CopyIcon } from "@chakra-ui/icons";
import moment from "moment";

const PaymentCheckout = () => {
  const dispatch = useDispatch()
  const orderDetails = useSelector((state) => state.order.confirmOrder);
  const orderAmount = useSelector((state) => state.order.quotes);
  const fiatTicker = useSelector((state) => state.order.fiatTicker)
  const orderStatus = useSelector((state) => state.order.status);
  const orderId = useSelector((state) => state.order.statId);
  const [startTime, setStartTime] = useState(false);
  const [referenceText, setReferenceText] = useState("");
  const [amount, setAmount] = useState("");
  const duration = moment.duration(30, "minutes");
  const endingDateTime = moment(startTime).add(duration);
  const [remainingTime, setRemainingTime] = useState(
    endingDateTime.diff(moment())
  );
  const navigate = useNavigate();
  const {
    onOpen: warnModalOpen,
    isOpen: warnModalIsOpen,
    onClose: warnModalClose,
  } = useDisclosure();
  const onSubmitHandler = () => {
    navigate("/payin/payment-status");
  };

  useEffect(() => {
    if(orderStatus === 'initiated')
    dispatch()
  })

  const BankDetails = ({ label, value, icon = '' }) => {
    const { onCopy } = useClipboard(value);
    return (
      <Box
        padding={"1rem"}
        borderBottom={"0.5px solid #D2D2D2"}
        width={"full"}
        position={"relative"}
      >
        <HStack>
          <Text float={"left"} fontWeight={500} fontSize={"0.875rem"}>
            {label}
          </Text>
          <Box
            position={"absolute"}
            right={0}
            display={"flex"}
            flexDirection={"row"}
          >
            <Text marginTop={"0.5rem"} fontWeight={500} fontSize={"0.875rem"}>
               {value}{" "}{icon && icon}
            </Text>
            <Button onClick={onCopy} bg="filled">
              <CopyIcon />
            </Button>
          </Box>
        </HStack>
      </Box>
    );
  };

  return (
    <Stack
      direction={"column"}
      justifyContent={"space-between"}
      flexGrow={1}
      p={5}
    >
      <Container
          p="0"
          maxW={{ base: "100%", md: "40rem" }}
          minH={{ base: "100%", md: "max(90vh);" }}
          height="auto"
          bg={"white"}
          borderRadius={{ base: "none", md: "sm" }}
          display="flex"
          flexDirection="column"
          position="relative"
        >
          <Stack
            direction={"column"}
            justifyContent={"space-between"}
            flexGrow={1}
            p={5}
          >
            
            <Text>
              Please add the <b>“Reference text”</b> in the transaction{" "}
              <b>“Remark”</b> field while doing the bank account transfer. This
              helps us to verify your transaction.
            </Text>
            <Box>
              <BankDetails
                label={"Reference text"}
                value={orderDetails?.beneficiaryDetails?.referenceText}
              />
              <BankDetails
                label={"Amount to be Transferred"}
                value={orderAmount?.sentAmount}
                icon={fiatTicker?.label}
              />
            </Box>

            <Heading as={"h4"} size={"md"} color={"#1A4FD6"}>
              Beneficiary account details
            </Heading>
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"flex-start"}
              border={"1px solid #D2D2D2"}
              marginTop={"0.5rem"}
            >
              
              <BankDetails
                label={orderDetails?.beneficiaryDetails?.type === 'faster_payments' ? "Account Number" : "IBAN"}
                value={orderDetails?.beneficiaryDetails?.number}
              />
              <BankDetails
                label={"Bank Account Holder"}
                value={orderDetails?.beneficiaryDetails?.name}
              />
              <BankDetails
                label={orderDetails?.beneficiaryDetails?.type === 'faster_payments' ? "Sort Code" : "BIC"}
                value={orderDetails?.beneficiaryDetails?.type === 'faster_payments' ? orderDetails?.beneficiaryDetails?.sortCode : orderDetails?.beneficiaryDetails?.bic}
              />
              {orderDetails?.beneficiaryDetails?.type !== 'faster_payments' ? <BankDetails
                label={"Bank Address"}
                value={orderDetails?.beneficiaryDetails?.address}
              /> : null }
              
            </Box>

            <HStack mt={"1rem"} justifyContent={"center"}>
              <img src="https://transfistaticcontent.blob.core.windows.net/coinbuy/info.svg" />
              <Text
                fontSize={"0.875rem"}
                fontWeight={"500"}
                color={"#000"}
                lineHeight={"normal"}
                fontStyle={"italic"}
                textAlign={"center"}
              >
                Bank transfer details are also sent to your email
              </Text>
            </HStack>
            <Button
              minW={"200px"}
              bg={"#1A4FD6"}
              color={"#ffffff"}
              _hover={{ bg: "#232D95" }}
              onClick={onSubmitHandler}
            >
              Continue
            </Button>
          </Stack>
        </Container>
    </Stack>
  );
};

export default PaymentCheckout;
