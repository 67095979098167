import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { Box, Button, Flex, HStack, Img, SimpleGrid, VStack, useDisclosure } from '@chakra-ui/react';
import { IoEyeOutline } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";
import { CheckIcon } from '@chakra-ui/icons';
import { round } from 'lodash';

// Custom Function and Components
import Typography from '../../components/Common/Typography';
import { getPayoutOrderData } from '../../store/slices/transactionSlices';
import TransactionModal from './modals/TransactionModal';

// CSS
import './approval.css';
import { currencyFormatter, formatDateTime } from '../../utils/formatter';

const ApprovalCard = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let { makerName, contactName, orderId, displayName, depositAmount, depositCurrency, withdrawAmount, withdrawCurrency, logo, approvalPendingTime = '' } = props;

  const [type, setType] = useState("");

  approvalPendingTime = new Date(approvalPendingTime);
  const add24Hours = new Date(approvalPendingTime.getTime() + (24 * 60 * 60 * 1000));

  const formattedTime = formatDateTime(add24Hours);

  const handleOpenRejectModal = () => {
    setType("reject");
    onOpen();
  }

  const handleOpenApproveModal = () => {
    setType("approve");
    onOpen();
  }

  const handleNavigateToSummary = () => {
    navigate('/approvals/summary', { state: { orderId } });
  }

  const fetchApprovals = useCallback(() => {
    dispatch(getPayoutOrderData({ filters: { status: "approval_pending" } }));
  }, [dispatch]);

  return (
    <Box className='al-approval-card'>
      <VStack height={"100%"} width={"100%"} display={"flex"} gap={"16px"} >
        <Flex width="100%" justifyContent={"space-between"} alignItems={"center"} >
          <HStack height={"34px"} >
            <Img src={process.env.PUBLIC_URL + "/assets/approval_card_arrow.png"} alt='logo-approval' />
            <VStack display={"flex"} flexDirection={"column"} gap={"0px"} justifyContent={"center"} alignItems={"flex-start"} >
              <Typography type='description' weight='regular'>
                Individual payment
              </Typography>
              <Typography type='caption' weight='regular' color='secondary' >
                - {makerName}
              </Typography>
            </VStack>
          </HStack>
          <HStack display={"flex"} flexDirection={"row"} gap={"20px"} >
            <HStack display={"flex"} flexDirection={"row"} gap={"4px"} >
              <Img src={process.env.PUBLIC_URL + "/assets/approval_pending_calendar.png"} alt='calendarLogo' />
              <Typography type='caption' weight='regular' color='error' >
                Due by {formattedTime}
              </Typography>
            </HStack>
            <Box className='al-transaction-details' onClick={handleNavigateToSummary} >
              <IoEyeOutline className='al-approval-eye-img' />
            </Box>
            <Button variant={"danger"} size={"sm"} rightIcon={<RxCross2 width={"10px"} height={"10px"} />} onClick={handleOpenRejectModal}  >
              Reject
            </Button>
            <Button variant={"primary"} size={"sm"} rightIcon={<CheckIcon width={"10px"} height={"10px"} />} onClick={handleOpenApproveModal} >
              Approve
            </Button>
          </HStack>
        </Flex>
        <SimpleGrid width="100%" columns={3} alignItems={"center"} height={"45px"} >
          <HStack display={"flex"} alignItems={"center"} >
            <Img src={process.env.PUBLIC_URL + logo} alt='countryLogo' width={"32px"} height={"32px"} />
            <VStack display={"flex"} flexDirection={"column"} gap={"0px"} alignItems={"flex-start"} >
              <Typography type='body' weight='bold' >
                {`${contactName} (${displayName})`}
              </Typography>
              <Typography type='caption' weight='regular' color='secondary' >
                Txn Id - {orderId}
              </Typography>
            </VStack>
          </HStack>
          <Box display={"flex"} flexDir={"column"} alignItems={"end"} gap={"0px"} pr={5} >
            <Typography type='description' weight='regular' color='secondary' >
              They receive
            </Typography>
            <Typography type='body' weight='medium' color='secondary' colorweight={"800"} >
              {`${currencyFormatter(withdrawAmount)} ${withdrawCurrency}`}
            </Typography>
          </Box>
          <Box display={"flex"} alignItems={"flex-end"} flexDir={"column"} gap={"0px"} >
            <Typography type='description' weight='regular' color='secondary' >
              You pay
            </Typography>
            <Typography type='body' weight='bold' color='primary' >
              {`${depositCurrency === "BTC" ? currencyFormatter(depositAmount, 5) : currencyFormatter(depositAmount) } ${depositCurrency}`}
            </Typography>
          </Box>
        </SimpleGrid>
      </VStack>
      <TransactionModal onClose={onClose} refreshData={fetchApprovals} isOpen={isOpen} type={type} data={{ orderId, contactName, accountName: displayName, paidFrom: depositCurrency, depositCurrency, depositAmount }} />
    </Box>
  );
}

export default ApprovalCard;