import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import CustomerService from '../../services/customerService';
import ConfigurationService from '../../services/configurationService';
import { saveDataToSession } from '../../utils/session-storing-utils';

const initialState = {
    country: '',
    countryLogo: '',
    name: '',
    regNumber: '',
    email: '',
    billingAddress1: '',
    billingAddress2: '',
    billingCity: '',
    billingPincode: '',
    shippingAddress1: '',
    shippingAddress2: '',
    shippingCity: '',
    shippingPincode: '',
    message: '',
    supportedCountries: [],
    loading: false,
    list: [],
    success: false,
    kybDetails: {}
}

export const addCustomer = createAsyncThunk(
    "customer/addCustomer",
    async (body, { rejectWithValue }) => {
        try {
            const res = await CustomerService.addCustomer(body);
            return res;
        } catch (e) {
            return rejectWithValue(e?.response?.data);
        }
    }
);

export const getCustomer = createAsyncThunk(
    "customer/getCustomer",
    async (body, { rejectWithValue }) => {
        try {
            const res = await CustomerService.getCustomer();
            return res?.data;
        } catch (e) {
            return rejectWithValue(e?.response?.data);
        }
    }
);

export const deleteCustomer = createAsyncThunk(
    "customer/deleteCustomer",
    async (body, { rejectWithValue }) => {
        try {
            const res = await CustomerService.deleteCustomer(body);
            return res?.data;
        } catch (e) {
            return rejectWithValue(e?.response?.data);
        }
    }
);

export const updateCustomer = createAsyncThunk(
    "customer/updateCustomer",
    async (body, { rejectWithValue }) => {
        try {
            const res = await CustomerService.updateCustomer(body);
            return res?.data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
)


export const getSupportedCountries = createAsyncThunk(
    "customer/getSupportedCountries",
    async (body, { rejectWithValue }) => {
        try {
            const res = await ConfigurationService.getConfigurations({ name: 'supported_countries', module: 'collections' });
            return res?.data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
)

export const getKybStatus = createAsyncThunk(
    "customer/getKybStatus",
    async (body, { rejectWithValue }) => {
        try {
            const res = await CustomerService.getKybStatus(body);
            return res?.data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
)

const customerSlice = createSlice({
    name: 'customer',
    initialState,
    extraReducers: {
        [addCustomer.pending]: (state, action) => {
            state.loading = true;
        },
        [addCustomer.fulfilled]: (state, action) => {
            const { message = '', status } = action.payload?.data || {};
            state.loading = false;
            state.message = message;
            state.isSuccess = status;
        },
        [addCustomer.rejected]: (state, action) => {
            const { message } = action?.payload?.error || { message: "Some Internal Error Occurred" };
            state.loading = false;
            state.isSuccess = false;
            state.message = message;
        },
        [getCustomer.pending]: (state, action) => {
            state.loading = true;
        },
        [getCustomer.fulfilled]: (state, action) => {
            const { message = '', list = [] } = action.payload?.data || {};
            state.loading = false;
            state.message = message;
            state.list = list;
        },
        [getCustomer.rejected]: (state, action) => {
            const { message } = action?.payload?.error || { message: "Some Internal Error Occurred" };
            state.loading = false;
            state.isSuccess = false;
            state.message = message
        },
        [deleteCustomer.pending]: (state, action) => {
            state.loading = true;
        },
        [deleteCustomer.fulfilled]: (state, action) => {
            const { message = '', status } = action.payload?.data || {};
            state.loading = false;
            state.message = message;
            state.isSuccess = status;
        },
        [deleteCustomer.rejected]: (state, action) => {
            const { message } = action?.payload?.error || { message: "Some Internal Error Occurred" };
            state.loading = false;
            state.isSuccess = false;
            state.message = message;
        },
        [updateCustomer.pending]: (state, action) => {
            state.loading = true;
        },
        [updateCustomer.fulfilled]: (state, action) => {
            const { message = '', status } = action.payload?.data || {};
            state.loading = false;
            state.message = message;
            state.isSuccess = true;
        },
        [updateCustomer.rejected]: (state, action) => {
            const { message } = action?.payload?.error || { message: "Some Internal Error Occurred" };
            state.loading = false;
            state.isSuccess = false;
            state.message = message;
        },
        [getSupportedCountries.pending]: (state, action) => {
            state.loading = true;
        },
        [getSupportedCountries.fulfilled]: (state, action) => {
            const { configs } = action.payload?.data || {};
            state.loading = false;
            const supportedCountries = configs?.map(item => ({
                label: `${item.symbol}`,
                value: `${item.countryCode2Digit}`,
                logo: `${item.logo}`
            }))
            state.supportedCountries = supportedCountries;
        },
        [getSupportedCountries.rejected]: (state, action) => {
            const { message } = action?.payload?.error || { message: "Some Internal Error Occurred" };
            state.loading = false;
            state.isSuccess = false;
            state.message = message;
        },
        [getKybStatus.pending]: (state, action) => {
            state.loading = true;
        },
        [getKybStatus.fulfilled]: (state, action) => {
            const { kybStatus, accessToken, customerType } = action.payload?.data || {};
            saveDataToSession('tokenData', 'sumsupAccessToken', accessToken)
            state.loading = false;
            state['kybDetails'] = {
                kybStatus, accessToken, customerType
            }
        },
        [getKybStatus.rejected]: (state, action) => {
            const { message } = action?.payload?.error || { message: "Some Internal Error Occurred" };
            state.loading = false;
            state.isSuccess = false;
            state.message = message
        },

    },
});

const { reducer: customerReducer } = customerSlice;

export default customerReducer;