import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import ContactLayout from '../ContactLayout'
import { Box, Button, SimpleGrid, Skeleton, Stack, useToast } from '@chakra-ui/react'
import * as Yup from 'yup'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Formik } from 'formik'

import MainCard from '../add-contact/MainCard'
import Typography from '../../../components/Common/Typography'
import { createPaymentAccount, getContact, resetContact, setAddAccount } from '../../../store/slices/contactSlices'
import WalletViewCard from '../payment-account-details/WalletViewCard'
import PaymentAccountDetails from '../payment-account-details/PaymentAccountDetails'
import { accountValidationSchema } from '../add-contact/validateSchema'
import { walletAddressValidation } from "../../../store/slices/recipientSlices";

import bankFormConfig from '../payment-account-details/Fiat/BankFormConfig'
import { entityType } from '../../../constants'
import moment from 'moment'

const InputCardDetail = (props) => {
    const { label, value } = props
    return (
        <Box display={"flex"} flexDir={"column"} rowGap={2}>
            <Typography type='description' color='secondary'>{label}</Typography>
            <Typography type='description' weight='regular'>{value}</Typography>
        </Box>
    )
}

function EditContact() {
    // get the contact id from the react router
    const { contactId } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const toast = useToast();

    const [addAccountLoader, setAddAccountLoading] = useState(false);

    const isAddAccount = useSelector((state) => state.contact.isAddAccount)
    const contactDetails = useSelector((state) => state.contact.contactDetails)
    const paymentAccountList = useSelector((state) => state.contact?.paymentAccounts) || [];
    const stableCoinsList = useSelector(state => state.order?.cryptoList, shallowEqual);
    const isLoading = useSelector((state) => state.contact.loading)

    const contactTypeMap = {
        "organization": "Business",
        "someone_else": "Individual",
    };

    const handleToast = (title, type) => {
        toast({
            title,
            status: type,
            duration: 2000,
            isClosable: true,
            position: "top",
        });
    };

    function getContactDetails() {
        if (!contactId) return;
        dispatch(setAddAccount(false));
        dispatch(getContact(contactId));
    }

    const handleBack = () => {
        dispatch(setAddAccount(false))
    }

    const initialValue = {
        accountType: "crypto_wallet",
        currency: "",
        bankName: "",
        number: "",
        nickname: "",
        fiatType: "bank",
    };

    const handleSubmit = async (values, actions) => {
        if (!isAddAccount) {
            actions.resetForm();
            dispatch(resetContact());
            navigate("/contact");
            return;
        }

        try {
            setAddAccountLoading(prev => true);
            let finalSchemaToBeValidated = accountValidationSchema;
            let finalValuesToBeValidated = values;

            let extraFieldsWithValues = {};
            if (values.accountType !== "crypto_wallet") {
                const pmGateway = values.bankName !== "" ? JSON.parse(values.bankName || {})?.pgId : {};
                const referenceName = values.bankName !== "" ? JSON.parse(values.bankName || {})?.referenceName : "";
                const currency = values.currency;

                let extraInitialValues = {}, extraValidationSchema = {}
                const { extraInitialValues: extraInitialValue, extraValidationSchema: extraValidationSchemas } = bankFormConfig(pmGateway, currency?.substring(0, 2), referenceName, contactTypeMap[contactDetails?.contact?.type || "organization"]) || {};
                extraInitialValues = extraInitialValue || {};
                extraValidationSchema = extraValidationSchemas || {};


                const schema = Yup.object().shape({
                    ...extraValidationSchema
                });

                function filterFieldsWithDefaults(mainObj, filterObj) {
                    const result = {};
                    Object.keys(filterObj).forEach((key) => {
                        // also set the extraFieldsWithValues to be used in the final payload and only use the values that have something in them
                        if (mainObj[key]) {
                            extraFieldsWithValues[key] = mainObj[key];
                        }
                        result[key] = key in mainObj ? mainObj[key] : '';
                    });
                    return result;
                }

                const extraValues = filterFieldsWithDefaults(values, extraInitialValues);

                finalSchemaToBeValidated = finalSchemaToBeValidated.concat(schema);
                finalValuesToBeValidated = ({
                    ...finalValuesToBeValidated,
                    extraValues,
                });
            }

            if (values.accountType === "crypto_wallet" && values.number !== '') {
                let status = true;

                const matchingObject = stableCoinsList?.find(item => item.value.formattedSymbol === values?.currency || "");
                const network = matchingObject?.value?.network || "";

                await dispatch(walletAddressValidation({ walletAddress: values?.number, currency: values?.currency, network })).then(async (resp) => {
                    if (!resp.payload?.data?.status) {
                        toast({
                            title: resp?.payload?.error?.message || "Something Went Wrong",
                            status: 'error',
                            duration: 5000,
                            position: 'top',
                            isClosable: true,
                        });
                        status = false;
                    }
                })
                if (!status) {
                    actions.setSubmitting(false);
                    return;
                }
            }

            if (!contactId) {
                actions.setSubmitting(false);
                return;
            }

            // Final Validation for Form Values
            const isValid = await finalSchemaToBeValidated.isValid(finalValuesToBeValidated);
            if (!isValid) {
                const errors = await finalSchemaToBeValidated.validate(finalValuesToBeValidated, { abortEarly: false }).catch((err) => {
                    if (err instanceof Yup.ValidationError) {
                        // Create a more informative errors object
                        const errorsInfo = {};
                        err.inner.forEach((error) => {
                            // Only add if the field hasn't been added yet, or if you want to override, remove this check
                            if (!errorsInfo[error.path]) {
                                errorsInfo[error.path] = error.message;
                            }
                        });
                        return errorsInfo;
                    }

                    // Handle unexpected errors
                    return { unexpectedError: "An unexpected error occurred" };
                });

                actions.setErrors(errors);
                actions.setSubmitting(false);
                return;
            }

            const finalPayload = {
                contactId: contactId,
                type: values.accountType === "crypto_wallet" ? "crypto_wallet" : values.fiatType,
                currency: values.currency,
                accountNumber: values.number,
                displayName: values.nickname,
            }

            if (values.accountType !== "crypto_wallet") {
                finalPayload.extraFields = extraFieldsWithValues;
                finalPayload.paymentCode = JSON.parse(values?.bankName || "{}").referenceName;
                const formattedDOB = moment(contactDetails?.dob).format('YYYY-MM-DD');
                extraFieldsWithValues["dateofbirth"] = formattedDOB;
            }

            dispatch(createPaymentAccount(finalPayload)).then(res => res?.payload).then(res => {
                const { error = {}, success = false } = res || {};
                const { message } = error;

                if (!success) {
                    handleToast(message || "Something Went Wrong", 'error');
                    return;
                }
                handleToast("New account has been added", 'success');
            }).catch(err => {
                handleToast("Something Went Wrong", 'error');
            });
        } catch (error) {
            console.log(error);
        } finally {
            setAddAccountLoading(prev => false);
        }
    }

    useEffect(() => {
        getContactDetails();
    }, [contactId]);

    useEffect(() => {
        if (contactDetails?.contact?.status === "pending") {
            handleToast("Our team is verifying these details. Please contact support for assistance", 'success');
        }
    }, [contactDetails]);


    if (isLoading || !contactDetails) {
        return <ContactLayout title="Edit Contact">
            <Box bg="white" mt={10} p={4} borderRadius={10}>
                <Stack spacing={6}>
                    <Skeleton height="100px" />
                    <Skeleton height="150px" />
                </Stack>
            </Box>
        </ContactLayout>
    }

    const finalSchema = isAddAccount ? accountValidationSchema : Yup.object()


    const getEntityTypeLabel = (value) => {
        // Find the object in the entityType array that matches the value
        const entity = entityType.find(entity => entity.value === value);
        // Return the label if found, otherwise return undefined or a default string
        return entity ? entity.label : `Others (${value})`;
    };


    return (
        <ContactLayout title="Edit Contact">
            <Formik
                initialValues={initialValue}
                onSubmit={handleSubmit}
                validateOnChange={false}
            >
                {({ isSubmitting, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        {
                            isAddAccount === true &&
                            <Stack spacing={4}>
                                <PaymentAccountDetails />
                            </Stack>
                        }
                        {
                            isAddAccount === false &&
                            <Stack spacing={4}>
                                <MainCard title="Contact Details">
                                    <SimpleGrid columns={3} spacing={10}>
                                        <InputCardDetail label={contactDetails?.contact?.type === "organization" ? "Entity Name" : "Individual Name"} value={contactDetails?.contact?.firstName} />
                                        <InputCardDetail label="Type" value={contactDetails?.contact?.type === "organization" ? "Business" : "Individual"} />
                                        <InputCardDetail label="Country" value={contactDetails?.contact?.country} />
                                        <InputCardDetail label={contactDetails?.contact?.type === "organization" ? "Entity Email ID" : "Individual Email"} value={contactDetails?.contact?.recipientEmail} />
                                        {
                                            contactDetails?.contact?.type === "organization" &&
                                            <InputCardDetail label="Entity Type" value={getEntityTypeLabel(contactDetails?.contact?.entityType)} />
                                        }
                                    </SimpleGrid>
                                </MainCard>
                                {paymentAccountList.length >= 0 ? <WalletViewCard /> : "No Accounts Found"}
                            </Stack>
                        }
                        <Box bg={"white"} mt={-10} py={2}>
                            {
                                isAddAccount ? (
                                    <Stack direction="row" justifyContent={"end"} marginTop={8}>
                                        <div>
                                            <Button variant={"secondary"} type="button" onClick={handleBack}>
                                                Back
                                            </Button>
                                        </div>
                                        <div>
                                            <Button variant={"primary"} type="submit" isLoading={addAccountLoader}>
                                                Add Account
                                            </Button>
                                        </div>
                                    </Stack>
                                ) : (
                                    <Stack direction="row" justifyContent={"end"} marginTop={8}>
                                        <Button marginY={4} variant={"secondary"} type="submit">Cancel</Button>
                                        <Button margin={4} variant={"primary"} type="submit">Exit</Button>
                                    </Stack>
                                )
                            }
                        </Box>


                    </form>
                )}

            </Formik>
        </ContactLayout>
    )
}

export default EditContact
