import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Flex, Icon, Img, Table, TableContainer, Tbody, Td, Th, Thead, Tooltip, Tr } from '@chakra-ui/react';

import Typography from '../../components/Common/Typography';

import { LuPencil } from "react-icons/lu";
import { BsInfoCircle } from "react-icons/bs";

const labels = [
    {
        label: "Name",
        key: "name"
    },
    {
        label: "Type",
        key: "email"
    },
    {
        label: "Payment Accounts",
        key: "paymentAccounts"
    },
    {
        label: "",
        key: "edit"
    },
]

const ContactTable = ({ data = [] }) => {
    return (
        <Box paddingX={7}>
            <TableContainer maxH="70vh" overflowY="auto">
                <Table>
                    <Thead bg={"white"} position={"sticky"} top={0} zIndex={"sticky"}>
                        <Tr>
                            {labels.map((label) => (
                                <Th fontWeight={"normal"} paddingY={6} color={"brand.greySecondary"} key={label.key}>{label.label}</Th>
                            ))}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            data.map((item, index) => (
                                <Tr key={index}>
                                    <Td>
                                        <Flex columnGap={2} alignItems={"center"}>
                                            {(["pending", "success"].includes(item?.status)) ?
                                                <Img src={process.env.PUBLIC_URL + item?.logo} alt={item?.country} width={38} height={9} /> :
                                                <Tooltip hasArrow label='You cannot send payment to this contact as high risk has been identified. Reach out to our team for more clarity' bg='white' color={"black"} arrowSize={15} placement='right' >
                                                    <Img
                                                        src={process.env.PUBLIC_URL + "/assets/warning.png"}
                                                        alt={item?.country}
                                                        width={"40px"}
                                                        height={"40px"}
                                                        boxShadow={"1px 1px 1px rgba(0, 0, 0, 0.1)"}
                                                        rounded={"50px"}
                                                    />
                                                </Tooltip>
                                            }
                                            <Flex direction={"column"}>
                                                <p color='#414141'>
                                                    {item.firstName}
                                                </p>
                                                <Typography weight='regular' type='caption' color='secondary'>
                                                    {item.recipientEmail}
                                                </Typography>
                                            </Flex>
                                        </Flex>
                                    </Td>
                                    <Td>{item.type === "organization" ? "Business" : "Individual"}</Td>
                                    <Td>{item.paymentAccountsCount}</Td>
                                    <Td>
                                        <Flex justifyContent={"left"} alignItems={"center"} gap={"20px"} >
                                            <Link to={`/contact/edit/${item.recipientId}`}>
                                                <Icon as={LuPencil} color={"#1A4FD6"} fontSize={"20px"} />
                                            </Link>
                                            {(item?.status || "pending") === "pending" &&
                                                <Tooltip hasArrow label='Compliance check in progress. Please check back in 24 hours, Payouts to this contact will be halted in the interim' bg='white' color={"black"} arrowSize={15} placement='top' >
                                                    <Button h={'32px'} size={'sm'} bg={'#FFFFFF'} border={"1px"} borderColor={"#F2F2F2"} rounded={'6px'} >
                                                        <Icon as={BsInfoCircle} color={'red.400'} />
                                                    </Button>
                                                </Tooltip>
                                            }
                                        </Flex>
                                    </Td>
                                </Tr>
                            ))
                        }
                    </Tbody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default ContactTable;
