// export const getThemeForKYCWidget = (buttonColor, backgroundColor) => `:root {\n  --black: #000000;\n  --button-color: ${buttonColor};\n   --grey: #F5F5F5;\n  --grey-darker: #B2B2B2;\n  --border-color: #DBDBDB;\n}\n\np {\n  color: var(--black);\n  font-size: 16px;\n  line-height: 24px;\n}\n\nsection {\n  margin: 40px auto;\n}\n\ninput {\n  color: var(--black);\n  font-weight: 600;\n  outline: none;\n}\n\nsection.content {\n background-color: ${backgroundColor};\n font-style: normal;\n font-weight: 400;\n font-size: 16px;\n line-height: 21px;  color: var(--black);\n  padding: 40px 40px 16px;\n  box-shadow: none;\n  border-radius: 6px;\n}\n\nbutton.submit,\nbutton.back {\n  text-transform: capitalize;\n  border-radius: 6px;\n  height: 48px;\n  padding: 0 30px;\n  font-size: 16px;\n  background-image: none !important;\n  transform: none !important;\n  box-shadow: none !important;\n  transition: all 0.2s linear;\n}\n\nbutton.submit {\n  width: 100%;\n  background: none;\n  background-color: var(--button-color);\n}\n\n.sumsub-logo {\n display: none;\n}\n\n.review-footer-buttons {\n position: absolute;\n bottom: 15px;\n width: 80%;\n}\n\n.review-container {\n min-height: 78vh;\n}\n\n.create-applicant-landing {\n min-height: 85vh;\n}\n\n.btnCreateApplicantNext {\n position: absolute;\n bottom: 0px;\n width: 80%;\n}`

export const getThemeForKYCWidget = (buttonColor, backgroundColor) => `
:root {
  --black: #000000;
  --button-color: ${buttonColor};
  --grey: #F5F5F5;
  --grey-darker: #B2B2B2;
  --border-color: #DBDBDB;
}

p {
  color: var(--black);
  font-size: 16px;
  line-height: 24px;
}

section {
  margin: 20px auto; /* Adjusted for less height */
}

input {
  color: var(--black);
  font-weight: 600;
  outline: none;
}

section.content {
  background-color: ${backgroundColor};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: var(--black);
  padding: 20px 20px 16px; /* Adjusted for less padding */
  box-shadow: none;
  border-radius: 6px;
  max-height: 85vh; /* Added to limit height */
  overflow-y: auto; /* Added for scroll if content exceeds */
}

button.submit,
button.back {
  text-transform: capitalize;
  border-radius: 6px;
  height: 48px;
  padding: 0 30px;
  font-size: 16px;
  background-image: none !important;
  transform: none !important;
  box-shadow: none !important;
  transition: all 0.2s linear;
}

button.submit {
  width: 100%;
  background: none;
  background-color: var(--button-color);
  display: block;
  margin: 0 auto; /* Centering the button */
}

.sumsub-logo {
  display: none;
}

.review-footer-buttons {
  position: absolute;
  bottom: 15px;
  width: 80%;
}

.review-container {
  min-height: 78vh;
}

.create-applicant-landing {
  min-height: 85vh;
}

.btnCreateApplicantNext {
  position: absolute;
  bottom: 0px;
  width: 80%;
  left: 50%;
  transform: translateX(-50%); /* Centering the button */
}
`;