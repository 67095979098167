import React from 'react';

// css
import "../../../modules/login/PasswordLogin.css";

// icons
import { RxEyeClosed } from "react-icons/rx";
import { FaRegEye } from "react-icons/fa";

function PasswordInput(props) {
  const { value, inputChangeHandler, placeholder, viewPassword, setViewPassword, className = '', paste = {}, id = '' } = props;
  return (
    <div style={{ textAlign: 'center', width: '100%' }}>
      <div className="pa-input-box-div">
        {props.children}
        <input
          id={id}
          type={viewPassword ? "text" : "password"}
          className={className || 'pa-input-email'}
          placeholder={placeholder}
          onChange={(e) => inputChangeHandler(e)}
          value={value}
          onPaste={(e) => paste(e)}
        />
      </div>
      {!viewPassword ?
        <span style={{ position: 'relative', fontSize: '20px', float: 'right', marginTop: '-40px', marginRight: '20px' }}>
          <RxEyeClosed onClick={() => { setViewPassword(true) }} />
        </span> :
        <span style={{ position: 'relative', fontSize: '20px', float: 'right', marginTop: '-40px', marginRight: '20px' }}>
          <FaRegEye onClick={() => { setViewPassword(false) }} />
        </span>
      }
    </div>
  );
}

export default PasswordInput