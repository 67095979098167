import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { HStack, VStack, Stack, Button } from '@chakra-ui/react';
import { useField } from 'formik';

// Common Components and Function
import Card from '../../../components/Common/Card';
import Typography from '../../../components/Common/Typography';
import { TextField, DatePickerField } from '../../../components/Common/Input';
import { goToPreviousStep } from "../../../store/slices/contactSlices";

// css
import '../Contact.css';

// Icons and Images
import InformationIcon from '../../../assets/information-circle.svg';

const WalletAddressCard = (props) => {
  const contact = useSelector((state) => state.contact?.contactDetails?.contact);

  const dispatch = useDispatch();

  const { contactType } = useSelector((state) => state.contact)?.values || {};
  const [field1, meta1, stableCoinsHelpers] = useField('stableCoins');
  const [field2, meta2, walletAddressHelpers] = useField('walletAddress');
  const [field3, meta3, walletAddressNameHelpers] = useField('walletAddressName');
  const [field4, meta4, registrationNumberHelpers] = useField('registrationNumber');
  const [field5, meta5, dateHelpers] = useField('date');

  const [selectedDate, setSelectedDate] = useState({ label: "dd-mm-yyyy", value: "" });

  const handleBack = () => {
    const { setValue: setWalletAddress } = walletAddressHelpers;
    const { setValue: setStableCoins } = stableCoinsHelpers;
    const { setValue: setWalletAddressName } = walletAddressNameHelpers;
    const { setValue: setRegistrationNumber } = registrationNumberHelpers;
    const { setValue: setDate } = dateHelpers;
    setWalletAddress("");
    setStableCoins("");
    setWalletAddressName("");
    setRegistrationNumber("");
    setDate("");
    dispatch(goToPreviousStep());
  };

  return (
    <Card>
      <VStack align="stretch" spacing="10px" w='100%'>
        <VStack align="flex-start" >
          <Typography weight='medium' type='subtitle' color='primary' >
            Wallet Details
          </Typography>
          <HStack>
            {/* <img src={InformationIcon} alt="icon" className='wa-information-icon' /> */}
            <Typography weight='regular' type='description' colorweight='800' >
              Please make sure that these details are correct
            </Typography>
          </HStack>
        </VStack>
        <VStack align="stretch" spacing="2px" w='100%'>
          <HStack display={"flex"} w='100%' gap={"40px"}>
            <TextField label={"Wallet Address"} name="number" placeholder="Enter valid wallet address" />
            <TextField label={"Nick Name for wallet"} name="nickname" placeholder="Enter Nick Name" />
          </HStack>
        </VStack>
      </VStack>
    </Card>
  );
};

export default WalletAddressCard;