import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Flex } from '@chakra-ui/react';
import { IoCopyOutline } from 'react-icons/io5';
import { CheckCircleIcon } from '@chakra-ui/icons';

import wallet_address_timer from '../../../assets/wallet_address_timer.svg';
import Typography from '../../../components/Common/Typography';

export const OrderDetailsColumn = ({ label, value, copy = false, ...props }) => {
    const [isCopied, setIsCopied] = useState(false);

    return (
        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={"full"}>
            <Typography weight='medium' type='description' >
                {label}
            </Typography>
            <Flex alignItems={"center"} w='fit-content' bg='white' p='10px' columnGap={2} borderRadius='5px'>
                <Typography weight='regular' type='body' color='secondary' colorweight="800" {...props} >
                    {value}
                </Typography>
                {copy &&
                    <>
                        {isCopied ?
                            <CheckCircleIcon w={4} h={4} color="green.500" /> :
                        <IoCopyOutline style={{ cursor: 'pointer' }}
                            onClick={() => {
                                navigator.clipboard.writeText(value);
                                setIsCopied(prev => true);
                                setTimeout(() => {
                                    setIsCopied(prev => false);
                                }, 1500);
                            }}
                        />
                        }
                    </>
                }
            </Flex>
        </Box>
    )
}

export default function WalletToWalletSuccess() {
    const currencyToPay = useSelector((state) => state.addMoney.currencyToPay);
    const quotes = useSelector((state) => state.addMoney?.quotes?.data);
    const walletAddress = useSelector((state) => state.addMoney?.walletAddress?.data);
    return (
        <Box display={"flex"} flexDirection={"column"} alignItems={"center"} rowGap={5} textAlign={"center"} width={"600px"}>
            <img src={wallet_address_timer} alt="wallet_address_timer" />
            <Typography type='title' weight='bold'>
                Thank you for initiating the prefunding to your wallet
            </Typography>
            <Typography type='body' weight='medium'>
                Please note that your account will be credited upon successful completion of the transaction. Once the payment is received, we will confirm and credit your account accordingly.
            </Typography>

            <Box w={"full"} p={"40px"} border={"1px solid"} borderColor={"brand.greyPrimary"}>
                <Typography pb="40px" textAlign="left" weight='medium' type='subtitle' color='primary' >
                    Order Details
                </Typography>

                <Flex flexDir={"column"}>
                    <OrderDetailsColumn label="We are checking your payment for" value={`${quotes?.amountToPay} ${currencyToPay?.symbol}`} />
                    <OrderDetailsColumn label="Processing fee" value={`${quotes?.processingFee} ${currencyToPay?.symbol}`} />
                    <OrderDetailsColumn label="Amount to be paid" value={`${quotes?.amountToReceive} ${currencyToPay?.symbol}`} copy />
                    <OrderDetailsColumn label="Payment method" value={`${currencyToPay?.symbol} Wallet - ${walletAddress?.walletAddress}`} copy />
                </Flex>
            </Box>
        </Box>
    )
}
