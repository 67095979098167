import React, { useState } from 'react';
import { Button, Center, Flex, HStack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalOverlay, useToast } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import Typography from '../../../components/Common/Typography';
import { callApiWithToken } from '../../../utils/http_common';

const TransactionModal = ({ isOpen, onClose, data, refreshData, type = 'approve' }) => {
  const { contactName = '', accountName = '', paidFrom = '', depositCurrency = '', depositAmount = 0, orderId, date } = data;
  const toast = useToast();

  const [loading, setLoading] = useState(false);

  const orgType = useSelector((state) => state?.login?.orgDetails?.orgType || "default");

  const dateObj = new Date(date)

  const formattedTime = dateObj.toLocaleString('en-GB', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    month: 'short',
    day: '2-digit',
    year: 'numeric'
  });

  const handleReject = async () => {
    try {
      setLoading(true);
      const filter = { type: "reject" };
      const { data } = (await callApiWithToken.get(`/transaction/approve/${orderId}`, {
        params: {
          ...filter
        }
      })) || {};
      if (data?.data?.status === "success") {
        toast({
          title: "Approval rejected",
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      }
      setLoading(false)
      onClose();
      refreshData();
    }
    catch (e) {
      setLoading(false);
      console.log(e);
      toast({
        title: e?.response?.data?.error?.message || "Something went wrong",
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      onClose();
    }
  }

  const handleApprove = async () => {
    try {
      setLoading(true);
      const filter = { type: "approve" };
      const { data, error } = (await callApiWithToken.get(`/transaction/approve/${orderId}`, {
        params: {
          ...filter
        }
      })) || {};
      if (data?.data?.status === "success") {
        toast({
          title: "Approval approved",
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      }
      else {
        const { message } = data?.data;
        toast({
          title: message || "Approval rejected",
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      }
      setLoading(false)
      onClose();
      refreshData();
    }
    catch (e) {
      setLoading(false);
      console.log(e);
      toast({
        title: e?.response?.data?.error?.message || "Something went wrong",
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      onClose();
    }
  }

  const onModalClose = () => {
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onModalClose} >
      <ModalOverlay />
      <ModalContent>
        <Center mt={4}>
          {type === "reject" ?
            <Typography color="primary" colorweight="500" weight='bold'>Reject Transaction</Typography> :
            <Typography color="primary" colorweight="500" weight='bold'>Approve Transaction</Typography>
          }
        </Center>
        <ModalCloseButton />
        <ModalBody display={"flex"} flexDirection={"column"} alignItems={"center"} >
          <Center mt={4} textAlign={"center"} >
            {type === "reject" ?
              <Typography color="secondary" colorweight="800" type='description' weight='medium'>Are you sure you want to reject this transaction? <br />
                You cant undo this.
              </Typography> :
              <Typography color="secondary" colorweight="800" type='description' weight='medium'>Are you sure you want to approve this transaction? <br />
                You cant undo this.
              </Typography>
            }
          </Center>
          {orgType === 'payout' && <Center mt={4} width={"314px"} textAlign={"center"} >
            <Flex w={"100%"} flexDirection={"column"} gap={"20px"}>
              <HStack display={"flex"} w={"100%"} justifyContent={"space-between"} >
                <Typography color="secondary" colorweight="800" type='description' weight='medium'>
                  Contact
                </Typography>
                <Typography color="secondary" colorweight="800" type='description' weight='medium'>
                  {contactName}
                </Typography>
              </HStack>
              <HStack display={"flex"} w={"100%"} justifyContent={"space-between"} >
                <Typography color="secondary" colorweight="800" type='description' weight='medium'>
                  Account
                </Typography>
                <Typography color="secondary" colorweight="800" type='description' weight='medium'>
                  {accountName}
                </Typography>
              </HStack>
              <HStack display={"flex"} w={"100%"} justifyContent={"space-between"} >
                <Typography color="secondary" colorweight="800" type='description' weight='medium'>
                  Paid from
                </Typography>
                <Typography color="secondary" colorweight="800" type='description' weight='medium'>
                  {paidFrom} Wallet
                </Typography>
              </HStack>
              <HStack display={"flex"} w={"100%"} justifyContent={"space-between"} >
                <Typography color="secondary" colorweight="800" type='description' weight='medium'>
                  Amount to be paid
                </Typography>
                <Typography color="secondary" colorweight="800" type='description' weight='medium'>
                  {depositAmount} {depositCurrency}
                </Typography>
              </HStack>
            </Flex>
          </Center>}

          {orgType === 'collections' &&
            <Center mt={4} width={"314px"} textAlign={"center"} >
              <Flex w={"100%"} flexDirection={"column"} gap={"20px"}>
                <HStack display={"flex"} w={"100%"} justifyContent={"space-between"} >
                  <Typography color="secondary" colorweight="800" type='description' weight='medium'>
                    Customer
                  </Typography>
                  <Typography color="secondary" colorweight="800" type='description' weight='medium'>
                    {accountName}
                  </Typography>
                </HStack>
                <HStack display={"flex"} w={"100%"} justifyContent={"space-between"} >
                  <Typography color="secondary" colorweight="800" type='description' weight='medium'>
                    Created on
                  </Typography>
                  <Typography color="secondary" colorweight="800" type='description' weight='medium'>
                    {formattedTime}
                  </Typography>
                </HStack>
                <HStack display={"flex"} w={"100%"} justifyContent={"space-between"} >
                  <Typography color="secondary" colorweight="800" type='description' weight='medium'>
                    Invoice Amount
                  </Typography>
                  <Typography color="secondary" colorweight="800" type='description' weight='medium'>
                    {depositAmount} {depositCurrency}
                  </Typography>
                </HStack>
              </Flex>
            </Center>
          }

        </ModalBody>

        <ModalFooter>
          <Button
            bg={"#F2F2F2"}
            _hover={{ bg: "#F2F2F2" }}
            color="#808080"
            width={'113px'}
            height={'40px'}
            rounded={'6px'}
            fontSize={'14px'}
            fontWeight={'400'}
            fontFamily={'DM Sans'} mr={3} onClick={onModalClose}>
            Cancel
          </Button>
          {type === "reject" ?
            <Button
              bg={"#E90000"}
              _hover={{ bg: '#E90000' }}
              color="white"
              width={'113px'}
              height={'40px'}
              rounded={'6px'}
              fontSize={'14px'}
              fontWeight={'400'}
              fontFamily={'DM Sans'}
              isLoading={loading}
              onClick={handleReject}
            >
              Reject
            </Button> :
            <Button
              bg={"#1a4fd6"}
              _hover={{ bg: '#1a4fd6' }}
              color="white"
              width={'113px'}
              height={'40px'}
              rounded={'6px'}
              fontSize={'14px'}
              fontWeight={'400'}
              fontFamily={'DM Sans'}
              isLoading={loading}
              onClick={handleApprove}
            >
              Approve
            </Button>
          }
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TransactionModal;