import React from 'react';
import { Button, Center, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalOverlay, VStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router';

import Typography from '../../components/Common/Typography';

const BackModal = ({ isOpen, onClose,handleExit }) => {

    const onModalClose = () => {
        onClose();
    };

    return (
        <Modal isOpen={isOpen} onClose={onModalClose}>
            <ModalOverlay />
            <ModalContent>
                <Center mt={4}>
                    <Typography color="primary" colorweight="500" weight='bold'>Unsaved Changes</Typography>
                </Center>
                <ModalCloseButton />
                <ModalBody>
                    <Center mt={4}>
                        <VStack>
                            <Typography color="secondary" colorweight="800" type='description' weight='medium'>There are some unsaved changes.
                            </Typography>
                            <Typography color="secondary" colorweight="800" type='description' weight='medium'>Are you sure you want to exit?
                            </Typography>
                        </VStack>
                    </Center>
                </ModalBody>

                <ModalFooter>
                    <Button
                        bg={"#F2F2F2"}
                        _hover={{ bg: "#F2F2F2" }}
                        color="#808080"
                        width={'113px'}
                        height={'40px'}
                        rounded={'6px'}
                        fontSize={'14px'}
                        fontWeight={'400'}
                        fontFamily={'DM Sans'} mr={3} onClick={onModalClose}>
                        Cancel
                    </Button>
                    <Button
                        bg={"#E90000"}
                        _hover={{ bg: '#E90000' }}
                        color="white"
                        width={'113px'}
                        height={'40px'}
                        rounded={'6px'}
                        fontSize={'14px'}
                        fontWeight={'400'}
                        fontFamily={'DM Sans'}
                        onClick={handleExit}
                    >
                        Exit
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default BackModal;