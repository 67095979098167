import { Flex } from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { Field, useField } from 'formik';

import { CustomSelectField, TextField } from '../../components/Common/Input';
import PaymentMethodsService from '../../services/paymentMethodService';

const AmountInput = ({ filterCurrencies, selectedOption, setSelectedOption }) => {

    const [amountField, amountMeta, amountActions] = useField("amount");
    const [inputValue, setInputValue] = useState('');
    const [amountLimit, setAmountLimit] = useState({})

    function validateAmount(value) {
        let error;
        const currency = selectedOption?.value;

        if (!value) {
            error = 'Amount is required';
        } else if (value < 0) {
            error = 'Amount must be positive';
        }

        if (amountLimit?.minAmount && value < amountLimit?.minAmount) {
            error = `Minimum Amount: ${amountLimit?.minAmount} ${currency}`;
        }
        if (amountLimit?.maxAmount && value > amountLimit?.maxAmount) {
            error = `Maximum Amount: ${amountLimit?.maxAmount} ${currency}`;
        }
        return error;
    }

    useEffect(() => {
        // TODO: Limit logic implementation
        const currentError = validateAmount(amountField.value);
        amountActions.setError(currentError);
    }, [amountField.value, amountMeta.error]);

    useEffect(() => {
        if (selectedOption?.value) {
            getCurrencyLimit(selectedOption.value);
        }
    }, [selectedOption])

    const getCurrencyLimit = useCallback(async (currency) => {
        try {
            const limit = await PaymentMethodsService.getCurrencyLimit({ currency, name: "payment_limits_collections", module: "collections" });
            setAmountLimit(limit.data?.data);
        } catch (error) {
            console.error("Error fetching currency limit:", error);
        }
    }, []);

    return (
        <Flex borderRadius={"9px"} height={"50px"} alignItems={"center"} columnGap={0} border={"2px solid"} borderColor={amountMeta.touched && amountMeta.error ? "red" : "brand.secondary"} maxW={'386px'} justify={'space-between'} pt={'20px'} mt={'3'}>
            <Field name='amount' validate={validateAmount}>
                {({ field, form }) => (
                    <TextField type="number" required={false} name="amount" placeholder="Enter Amount" variant='unstyled' mt={'2'} ml={'4'} mb={'3'}
                        value={inputValue}
                        onChange={(event) => {
                            const { value } = event.target;
                            if (!value || /^\d+(\.\d{0,2})?$/.test(value)) {
                                setInputValue(value);  // Update local state to reflect the input
                                field.onChange(event);  // Update Formik's state
                            }
                        }} />
                )}

            </Field>
            <CustomSelectField MenuItemWidth={"1px"} rightIcon={<MdKeyboardArrowDown />} menuButtonStyles={{ border: "none" }} required={false} name="currency" options={filterCurrencies} selectedOption={selectedOption} setSelectedOption={setSelectedOption} />
        </Flex>
    );
};

export default AmountInput;