import React, { useEffect, useState } from 'react'
import { Box, Button, Flex, Icon, Img, Tbody, Td, Tr, VStack, Text, Image, Stack, Skeleton, Checkbox } from '@chakra-ui/react'
import EmptyIcon from '../../../assets/emptypage.svg';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Typography from '../../../components/Common/Typography'
import TransactionService from '../../../services/transactionService';
import { STATUS_MAP, colorMapCollections } from '../../../constants';
import { isEmpty } from 'lodash';
import { currencyFormatter, formatDateTime } from '../../../utils/formatter';
import StickyTable from '../../../components/Common/Table/StickyTable';
import FilterButton from '../FilterButton';
import { FaEye } from 'react-icons/fa';

export const finalTransactionType = (type) => {
    if (["fiat_to_crypto_prefund", "fiat_to_fiat_prefund", "crypto_to_crypto_prefund"].includes(type)) {
        return "prefunding"
    } else if (["fiat_payout", "crypto_payout"].includes(type)) {
        return "payout"
    } else if (type === "collections") {
        return "collections"
    }
}

const labels = [
    {
        label: "Customer",
    },
    {
        label: "Order ID",
    },
    {
        label: "Issued on",
    },
    {
        label: "Invoice Amount"
    },
    {
        label: ""
    }
]
const TableColumn = (props) => {
    const { orderId, currency, email, countryLogo, amount, status, createdAT, handleClick, type, name } = props

    return (
        <Tr height={"90px"} py={20}>
            <Td>
                <Flex columnGap={2} alignItems={"center"}>
                    <Img src={process.env.PUBLIC_URL + countryLogo} alt={""} width={38} height={9} />

                    <Flex direction={"column"}>
                        <p color='#414141'>
                            {name}
                        </p>
                        <Typography weight='regular' type='caption' color='secondary'>
                            {email}
                        </Typography>
                    </Flex>
                </Flex>
            </Td>

            <Td>
                <Flex width={"150px"} direction={"column"}>
                    <Typography type='body'>
                        #{orderId}
                    </Typography>
                    <Text size={"12px"} color={colorMapCollections[status || "initiated"]?.color} bg={colorMapCollections[status || "initiated"]?.bg} p={"2px 4px"} borderRadius={"3px"} w={"min-content"} mt={2}>
                        {STATUS_MAP[finalTransactionType(type)][status || "initiated"]}
                    </Text>
                </Flex>
            </Td>
            <Td>
                <Typography type='description'>

                    {formatDateTime(createdAT, "hh:mm A")} -  {formatDateTime(createdAT, "DD MMM YYYY")}
                </Typography>
            </Td>

            <Td>
                {currencyFormatter(amount)} {currency}
            </Td>

            <Td>
                <Button onClick={handleClick} variant='outline' size='sm'>
                    <Icon as={FaEye} />
                </Button>
            </Td>
        </Tr>
    )
}

// List of filter options
const filterOptions = [
    { label: "All", value: "all" },
    { label: "Initiated", value: "initiated" },
    { label: "Successful", value: "successful" },
    { label: "Failed", value: "failed" },
];

export default function CollectionTransactions() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [orders, setOrders] = useState([])
    const [searchParams] = useSearchParams();
    const status = searchParams.get('status') || 'all';

    const getOrders = async (status) => {
        try {
            setLoading(true)
            const response = await TransactionService.getCollectionTransactions(status);
            setOrders(response.data?.data?.transactions)

        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    const handleEyeClick = (orderId) => {
        navigate(`/transactions/${orderId}`)
    }

    useEffect(() => {
        getOrders(status);
    }, [status]);

    if (loading) return (
        <Box minH={"92vh"} p={"16px 40px"} bg={"#F2F2F2"}>
            <Flex justifyContent='space-between' alignItems='center'>

                <Typography type='largeheader' weight='bold' >
                    Transactions
                </Typography>
                <Box>
                    <FilterButton filterOptions={filterOptions} />
                </Box>
            </Flex>
            <Box bg='white' w='100%' mt={6} borderRadius={"9px"} p={"20px 40px"}>
                <Stack borderRadius={"9px"} mt={"15px"} rowGap={5} bg={"white"}>
                    {Array(4).fill(0).map((_, index) => (
                        <Skeleton height='60px' />
                    ))}
                </Stack>
            </Box>
        </Box>
    )

    if (isEmpty(orders)) return (
        <Box className='al-main-card'>
            <Flex justifyContent='space-between' alignItems='center'>

                <Typography type='largeheader' weight='bold' >
                    Transactions
                </Typography>
                <Box>
                    <FilterButton filterOptions={filterOptions} />
                </Box>
            </Flex>
            <Flex bg={'white'} width={'full'} minHeight={'100vh'} mt={5} rounded={'9px'} justify={'center'} alignItems={'center'} border={'1px'} borderColor={'#E4E4E4'} h={'full'} direction={'column'}>
                <Image src={EmptyIcon} mb={'2'} />
                <Typography color="secondary" colorweight='800' type="title" weight="medium">No Transactions yet</Typography>
            </Flex>
        </Box>
    );

    return (
        <Box minH={"92vh"} p={"16px 40px"} bg={"#F2F2F2"}>
            <Flex justifyContent='space-between' alignItems='center'>

                <Typography type='largeheader' weight='bold' >
                    Transactions
                </Typography>
                <Box>
                    <FilterButton filterOptions={filterOptions} />
                </Box>
            </Flex>

            <VStack w={"100%"} marginTop={"15px"} gap='24px'>
                <Box borderRadius={"9px"} bg='white' w='100%' p={"20px 40px"}>
                    <StickyTable labels={labels} maxH={"75vh"}>
                        <Tbody>
                            {orders?.map((item, index) => (
                                <TableColumn key={index} name={item?.senderName?.firstName} email={item?.email} type={item?.type} orderId={item?.orderId} createdAT={item?.createdAt} currency={item?.depositCurrency} amount={item?.depositAmount} status={item?.status}
                                    countryLogo={item?.additionalDetails?.countryLogo}
                                    handleClick={() => handleEyeClick(item?.orderId)}
                                />
                            ))}
                        </Tbody>
                    </StickyTable>
                </Box>
            </VStack>
        </Box>
    )
}