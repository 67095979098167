import { Box, Center, Checkbox, Flex, Grid, GridItem, Image, Skeleton, SkeletonCircle } from '@chakra-ui/react';
import React from 'react';
import Typography from '../../../../components/Common/Typography';

const PaymentMethods = ({ loading, paymentMethods, handlePaymentSelect, selectedBank, isAccepted, setIsAccepted }) => {

    return (
        <Box bg='white' w='70%' p={"20px 40px"} borderRadius={"9px"} mt={5}>
            <Typography pb={8} type='body' weight='medium' color='primary' >
                Payment Method
            </Typography>

            {
                !loading && paymentMethods?.length === 0 && (
                    <Typography type={'description'} weight={'regular'} color={'secondary'}>No payment methods available</Typography>
                )

            }

            <Grid templateColumns='repeat(4, 1fr)' gap={8}>
                {loading && (
                    [1, 2, 3, 4].map((_, index) => (
                        <GridItem key={index}>
                            <Center display={'flex'} flexDirection={'column'} justify={'center'} alignItems={'center'}>
                                <SkeletonCircle width={"80px"} height={"80px"} />
                                <Skeleton mt={4} height="20px" width="100px" />
                            </Center>
                        </GridItem>
                    )))}


                {loading === false && paymentMethods?.map((bank, index) =>
                    <GridItem key={index} onClick={() => handlePaymentSelect(bank)} _hover={{ cursor: 'pointer' }}>
                        <Center display={'flex'} flexDirection={'column'} justify={'center'} alignItems={'center'}>
                            <Box p={3} border={(selectedBank?.value === bank.paymentCode) ? '1px' : '0px'} borderColor={'#525C76'} boxShadow={"0px 0px 3.5px 0px #1F29371F"} rounded={'full'}>
                                <Image onError={(e) => {
                                    e.target.src = process.env.PUBLIC_URL + "/assets/apm/bank.svg"
                                }} src={bank.logoUrl} h={'50px'} w={'50px'} />
                            </Box>
                            <Typography pb={8} type={'description'} mt={'1'} weight={selectedBank?.value === bank.paymentCode ? "bold" : "regular"} color={(selectedBank?.value === bank.paymentCode) ? 'headertext' : 'secondary'} colorweight={(selectedBank?.value === bank.paymentCode) ? '400' : 'default'} >
                                {bank.name}
                            </Typography>
                        </Center>
                    </GridItem>
                )}
            </Grid>

            <Flex mt={5} mb={5} gap={5}>
                <Checkbox
                    isChecked={isAccepted}
                    onChange={() => setIsAccepted(!isAccepted)}
                />
                <Typography type='body' weight='medium' color='secondary' colorweight='800' >
                    I accept that the bank account used to make a transfer belongs to the individual or organization that the invoice is issued to.
                </Typography>
            </Flex>
        </Box>
    );
};

export default PaymentMethods;