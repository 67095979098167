import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import OrderService from "../../services/createOrderService";

const initialState = {
    orderId: '',
    loading: false,
    isSuccess: false,
    fiatTicker: "",
    cryptoTicker: "",
    cryptoList: [],
    fiatList: [],
    status: "",
    type: ''
};

export const createOrder = createAsyncThunk(
    'order/createOrder',
    async ({ type }, {rejectWithValue}) => {
        try {
            const resp = await OrderService.createOrder({ type })
            return resp?.data;
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
);

export const getCurrencyConfigs = createAsyncThunk(
    'configuration/',
    async ({ product }, {rejectWithValue}) => {
        try {
            const resp = await OrderService.getConfigs({ product })
            return resp?.data;
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
);

export const resetOrder = createAsyncThunk(
    'order/reset',
    async (body, { rejectWithValue, getState }) => {
        try {
            const resp = await OrderService.resetOrder({ orderId: getState()?.order?.orderId })
            return resp?.data;
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
);

export const cancelOrder = createAsyncThunk(
    'order/cancel',
    async (body, { rejectWithValue, getState }) => {
        try {
            const resp = await OrderService.cancelOrder({ orderId: getState()?.order?.orderId })
            return resp?.data;
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
);

export const getQuotes = createAsyncThunk(
    'order/quotes',
    async (body, { rejectWithValue, getState }) => {
        try {
            const resp = await OrderService.getOrderQuotes({ orderId: getState()?.order?.orderId, type: getState()?.order?.type })
            return resp?.data;
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
);

export const updateOrder = createAsyncThunk(
    'order/update',
    async (body, { rejectWithValue, getState }) => {
        try {
            const resp = await OrderService.update({ orderId: getState()?.order?.orderId, type: getState()?.order?.type })
            return resp?.data;
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
);

export const continueOrder = createAsyncThunk(
    'order/continue',
    async (body, { rejectWithValue, getState }) => {
        try {
            const resp = await OrderService.continueOrder({ orderId: getState()?.order?.orderId, paymentCode: getState()?.paymentMethods?.seletedPaymentMethod?.paymentCode })
            return resp?.data;
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
);

export const getOrderStatus = createAsyncThunk(
    'order/status',
    async (body, { rejectWithValue, getState }) => {
        try {
            const resp = await OrderService.getOrderStatus({ orderId: getState()?.order?.orderId })
            return resp?.data;
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
);

export const getOrders = createAsyncThunk(
    'order/getOrders',
    async({ filters }, { rejectWithValue }) => {
        try {
            const resp = await OrderService.getOrder({filters});
           return resp?.data
            
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

const orderSlice = createSlice({
    name: 'order',
    initialState,
    extraReducers: {
        [createOrder.pending]: (state, action) => {
            state.loading = true;
            state.isSuccess = false;
        },
        [createOrder.fulfilled]: (state, action) => {
            const { orderId, type } = action?.payload?.data
            if(orderId){
                localStorage.setItem('orderId', orderId)
            }
            state.orderId = orderId;
            state.status = "draft";
            state.isSuccess = true;
            state.loading = false;
            state.type = type;
        },
        [createOrder.rejected]: (state, action) => {
            localStorage.removeItem('orderId')
            let newState = {
                "orderDetails": {
                    orderId: '',
                    error: action.payload?.error?.message
                },
                "loading": true
            }
            return newState;
        },
        [getCurrencyConfigs.pending]: (state, action) => {
            state.loading = true;
            state.isSuccess = false;
        },
        [getCurrencyConfigs.fulfilled]: (state, action) => {
            const { fiat = [], crypto = [] } = action?.payload?.data;

            state.fiatList = fiat;
            state.cryptoList = crypto;
            state.isSuccess = true;
            state.loading = false;
        },
        [getCurrencyConfigs.rejected]: (state, action) => {
            state.loading = false;
            state.isSuccess = false;
        },
        [cancelOrder.pending]: (state, action) => {
            state.loading = true;
            state.isSuccess = false;
        },
        [cancelOrder.fulfilled]: (state, action) => {
            return initialState;
        },
        [cancelOrder.rejected]: (state, action) => {
            state.loading = false;
            state.isSuccess = false;
        },
        [resetOrder.pending]: (state, action) => {
            state.loading = true;
            state.isSuccess = false;
        },
        [resetOrder.fulfilled]: (state, action) => {
            state.isSuccess = true;
            state.loading = false;
        },
        [resetOrder.rejected]: (state, action) => {
            state.loading = false;
            state.isSuccess = false;
        },
        [getQuotes.pending]: (state, action) => {
            state.loading = true;
            state.isSuccess = false;
        },
        [getQuotes.fulfilled]: (state, action) => {
            state.quotes = action?.payload?.data;
            state.isSuccess = true;
            state.loading = false;
        },
        [getQuotes.rejected]: (state, action) => {
            state.loading = false;
            state.isSuccess = false;
        },
        [updateOrder.pending]: (state, action) => {
            state.loading = true;
            state.isSuccess = false;
        },
        [updateOrder.fulfilled]: (state, action) => {
            state.isSuccess = true;
            state.loading = false;
        },
        [updateOrder.rejected]: (state, action) => {
            state.loading = false;
            state.isSuccess = false;
        },
        [continueOrder.pending]: (state, action) => {
            state.loading = true;
            state.isSuccess = false;
        },
        [continueOrder.fulfilled]: (state, action) => {
            state.confirmOrder = action?.payload?.data
            state.isSuccess = true;
            state.loading = false;
        },
        [continueOrder.rejected]: (state, action) => {
            state.loading = false;
            state.isSuccess = false;
        },
        [getOrderStatus.pending]: (state, action) => {
            state.loading = true;
            state.isSuccess = false;
        },
        [getOrderStatus.fulfilled]: (state, action) => {
            state.status = action?.payload?.data?.status;
            state.isSuccess = true;
            state.loading = false;
        },
        [getOrderStatus.rejected]: (state, action) => {
            state.loading = false;
            state.isSuccess = false;
        },
        [getOrders.pending]: (state, action) => {
            state.loading = true;
            state.isSuccess = false
        },
        [getOrders.fulfilled]: (state, action) => {
            const { list } = action?.payload?.data;
            state.loading = false;
            state.orderDetails = list;
        },
        [getOrders.rejected]: (state, action) => {
            state.loading = false;
            state.isSuccess = false;
        },
    },
    reducers: {
        updateFiatTicker: (state, action) => {
            state.fiatTicker = action.payload;
        },
        updateCryptoTicker: (state, action) => {
            state.cryptoTicker = action.payload;
        },
    }
})

const { reducer: orderReducer } = orderSlice;
export const { updateFiatTicker, updateCryptoTicker } = orderSlice.actions;

export default orderReducer;