import React, { useEffect, useState } from 'react';
import { Box, Center, Grid, GridItem } from '@chakra-ui/layout';
import { useDispatch, useSelector } from 'react-redux';
import { Image } from '@chakra-ui/image';
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Skeleton, SkeletonCircle } from '@chakra-ui/react';

import { getPaymentMethodList, getPaymentMethods } from '../../../store/slices/paymentMethodsSlices';
import Typography from '../../../components/Common/Typography';
import { setSelectedBank } from '../../../store/slices/AddMoneySlice';
import { Field, useField, useFormikContext } from 'formik';
import { TextField } from '../../../components/Common/Input';

const DocumentIdCopForm = (props) => {
  const loading = useSelector((state) => state.addMoney.loading)
  const { isOpen, onClose } = props;
  const [inputValue, setInputValue] = useState('');
  const { validateForm, submitForm } = useFormikContext();
  const [idField, idMeta, idActions] = useField("idNumber");

  const handleClick = () => {
    if (loading) return;
    if (idField.value === '') {
      idActions.setError('ID is required');
      idActions.setTouched(true);
      return;
    }
    onClose();

    validateForm().then((errors) => {
      if (Object.keys(errors).length === 0) {
        submitForm();
      }
    })
  }

  function validateAmount(value) {
    console.log('value', value)
    let error;
    if (!value) {
      error = 'ID is required';
    }
    return error;
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Enter these Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <TextField name='idType' disabled={true} isDisabled={true} defaultVaue="CC" value="CC" label='ID Type' placeholder='Enter ID Number' />
          <Field name='idNumber' validate={validateAmount}>
            {({ field, form }) => (
              <TextField required={true} name='idNumber' label='ID Number' placeholder='Enter ID Number'
                {...field}
                value={inputValue}
                onChange={(event) => {
                  const { value } = event.target;
                  if (!value || /^\d+(\.\d{0,2})?$/.test(value)) {
                    setInputValue(value);  // Update local state to reflect the input
                    field.onChange(event);  // Update Formik's state
                  }
                }}
              />
            )}
          </Field>


        </ModalBody>

        <ModalFooter>
          <Button _loading={{ pointerEvents: "none" }} isLoading={loading} variant={"primary"} onClick={handleClick} size={"sm"} p={"9px 16px 9px 16px"}>
            Next
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>

  );
};

export default DocumentIdCopForm;