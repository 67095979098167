import React, { useCallback, useEffect, useState } from 'react'
import { Box, Flex, SimpleGrid, Skeleton } from '@chakra-ui/react'
import { Field, useField, useFormikContext } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { debounce } from 'lodash'

import PaymentMethodsService from '../../../services/paymentMethodService';
import { currencyFormatter } from '../../../utils/formatter'
import Typography from '../../../components/Common/Typography'
import { CustomSelectField, TextField } from '../../../components/Common/Input'
import { getQuotes, getStablecoinNetworks, getSupportedPrefundCurrencies, setCurrencyToPay, setFinalAmounts, setQuotes, setSupportedCurrencies } from '../../../store/slices/AddMoneySlice'
import CURRENCY_DECIMALS from './currencyConfig';

import "../my-account.css";


export default function CurrencyForm(props) {
  const dispatch = useDispatch();
  const { setFieldValue } = useFormikContext();

  const { selectedOption, setSelectedOption, selectedNetworkOption, setSelectedNetworkOption } = props;

  const [inputValue, setInputValue] = useState('');
  const [outputValue, setOutputValue] = useState('');
  const [amountLimit, setAmountLimit] = useState({});
  const [direction, setDirection] = useState("forward");

  const quotes = useSelector((state) => state.addMoney.quotes?.data);
  const quotesLoading = useSelector((state) => state.addMoney.quotes?.loading);
  const supportedCurrencies = useSelector((state) => state.addMoney.supportedCurrencies);
  const selectedCurrency = useSelector((state) => state.addMoney.selectedCurrency)
  const stablecoinNetworks = useSelector((state) => state.addMoney.stablecoinNetworks);
  const selectedBank = useSelector(state => state.addMoney.selectedBank);

  const [currencyField, meta, actions] = useField("currency");
  const [amountField, amountMeta, amountActions] = useField("amount");
  const [receiveAmountField, receiveAmountMeta, receiveAmountActions] = useField("receivedAmount");

  function validateAmount(value) {
    let error;
    const currency = JSON.parse(currencyField.value || "{}");

    if (!value) {
      error = 'Amount is required';
    } else if (value < 0) {
      error = 'Amount must be positive';
    }

    if (amountLimit?.minAmount && value < amountLimit?.minAmount) {
      error = `Minimum Amount: ${amountLimit?.minAmount} ${currency?.symbol}`;
    }
    if (amountLimit?.maxAmount && value > amountLimit?.maxAmount) {
      error = `Maximum Amount: ${amountLimit?.maxAmount} ${currency?.symbol}`;
    }
    return error;
  }


  const debouncedApiCall = useCallback(
    debounce(async (currency, amount, direction) => {

      // Reset Quotes on no amount
      if (!amount) {
        dispatch(
          setFinalAmounts({
            amountToPay: 0,
          })
        );
        dispatch(
          setQuotes({
            amountToReceive: 0,
            amountToPay: 0,
            processingFee: 0,
            cryptoPrice: 0,
          })
        );
        amountActions.setError("Amount is required");
        return;
      }

      // Limit Validation checks
      if (amountLimit && amount < amountLimit?.minAmount) {
        amountActions.setError(
          `Minimum Amount: ${amountLimit?.minAmount} ${currency?.symbol}`
        );
        return;
      }
      if (amountLimit && amount > amountLimit?.maxAmount) {
        amountActions.setError(
          `Maximum Amount: ${amountLimit?.maxAmount} ${currency?.symbol}`
        );
        return;
      }

      // Quotes Fetching API Calls
      if (currency.type === "fiat" && selectedCurrency.type === "crypto") {
        dispatch(
          getQuotes({
            product: "prefund",
            cryptoTicker: selectedCurrency.value,
            fiatTicker: currency.symbol,
            amount: amount,
            paymentType: "DEFAULT",
            quoteVersion: "v2",
            direction,
          })
        );
      }
      else if (currency.type === "crypto" && selectedCurrency.type === "crypto") {
        dispatch(
          getQuotes({
            product: "crypto_to_crypto_prefund",
            amount: amount,
            baseCurrency: selectedCurrency.value,
            quoteCurrency: selectedCurrency.value,
            paymentType: "DEFAULT",
            direction,
          })
        );
      }
      else if (currency.type === "fiat" && selectedCurrency.type === "fiat") {
        dispatch(
          getQuotes({
            product: "fiat_to_fiat",
            currency: selectedCurrency.value,
            amount: amount,
            fiatTicker: selectedCurrency.value,
            quoteCurrency: selectedCurrency.value,
            direction,
          })
        ).then((res) => {
          if (!res.payload) {
            // amountActions.setError("");

          }
        });
      }
    }, 500),
    []
  );


  const getCurrencyLimit = useCallback(async (currency) => {
    try {
      const limit = await PaymentMethodsService.getCurrencyLimit({ currency: currency.symbol, name: "payment_limits_prefund", module: "pay_out" });
      setAmountLimit(limit.data?.data);
    } catch (error) {
      console.error("Error fetching currency limit:", error);
    }
  }, []);

  if (!currencyField.value) {
    actions.setValue(selectedOption.value)
  }

  useEffect(() => {
    if (direction === "forward") {
      const currency = JSON.parse(currencyField.value || "{}");
      dispatch(setCurrencyToPay(currency))

      if (currency.symbol) {
        debouncedApiCall(currency, amountField.value, direction);
      }
    }
  }, [currencyField.value, amountField.value])

  useEffect(() => {
    if (direction === "reverse") {
      const currency = JSON.parse(currencyField.value || "{}");
      dispatch(setCurrencyToPay(currency));

      if (currency.symbol) {
        debouncedApiCall(currency, receiveAmountField.value, direction);
      }
    }
  }, [currencyField.value, receiveAmountField.value]);

  useEffect(() => {
    const currency = JSON.parse(currencyField.value || "{}");
    dispatch(setCurrencyToPay(currency));

    if (currency.symbol) {
      getCurrencyLimit(currency);
    }
  }, [currencyField.value]);


  useEffect(() => {
    if (selectedCurrency.value && selectedCurrency.type !== "fiat") {
      dispatch(getStablecoinNetworks({ currency: selectedCurrency.value }));
    }
    if (selectedCurrency.type !== "fiat") {
      dispatch(getSupportedPrefundCurrencies());
    }
    if (selectedCurrency.type === "fiat") {
      const currencies = [{
        value: JSON.stringify({
          symbol: selectedCurrency?.value,
          type: selectedCurrency?.type
        }),
        label: selectedCurrency?.label,
        logo: selectedCurrency?.logo,
        type: selectedCurrency?.type
      }]
      dispatch(setSupportedCurrencies(currencies));
    }
  }, [])

  useEffect(() => {
    if (direction === "forward") {
      const decimalDegree = CURRENCY_DECIMALS[selectedCurrency.label] || CURRENCY_DECIMALS.DEFAULT;
      const amountToBeSet = currencyFormatter(quotes.receiveAmount, decimalDegree, false);
      
      setOutputValue(prev => amountToBeSet);
      setFieldValue("receivedAmount", amountToBeSet);
    }

    else if (direction === "reverse") {
      const decimalDegree = CURRENCY_DECIMALS[selectedCurrency.label] || CURRENCY_DECIMALS.DEFAULT;
      const amountToBeSet = currencyFormatter(quotes.receiveAmount, decimalDegree, false);
      
      setInputValue(amountToBeSet);
      setFieldValue("amount", amountToBeSet);
    }
  }, [quotes.sentAmount, quotes.receiveAmount])


  useEffect(() => {
    // Set or clear error based on quotes state
    const currentError = validateAmount(amountField.value);
    amountActions.setError(currentError); // Update error dynamically based on latest quotes and amount
  }, [quotes, amountField.value, selectedBank]); // Include amountField.value to re-validate when user types

  return (
    <Box>
      <SimpleGrid
        columns={{
          base: 1,
          xl: 2,
        }}
        spacing={6}
      >
        <Box>
          <Typography type="description" weight="regular" color="secondary">
            You Pay
          </Typography>
          <Flex
            justifyContent={"space-between"}
            borderRadius={"9px"}
            paddingX={"8px"}
            height={"60px"}
            alignItems={"center"}
            columnGap={0}
            pt={5}
            border={"1px solid"}
            borderColor={"brand.secondary"}
          >
            <CustomSelectField
              MenuItemWidth={"157px"}
              rightIcon={<MdKeyboardArrowDown />}
              menuButtonStyles={{
                fontSize: "12px",
                border: "none",
                width: "max-content",
              }}
              required={false}
              name="currency"
              options={supportedCurrencies?.data}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
            />
            <Flex justifyContent={"flex-end"}>
              {quotesLoading && direction === "reverse" ?
                <Skeleton borderRadius={"5px"} w={"151px"} h={"40px"} marginBottom={"20px"} /> :
                <Field name="amount" validate={validateAmount}>
                  {({ field, form }) => (
                    <TextField
                      errorStyles={{
                        position: "absolute",
                        width: "max-content",
                        right: 0,
                        bottom: "8px",
                      }}
                      _placeholder={{ fontSize: "13px" }}
                      minW={{ "2xl": "min-content", xl: "140px", sm: "120px" }}
                      maxW={"300px"}
                      {...field}
                      required={false}
                      type="text"
                      name="amount"
                      placeholder="Enter Amount"
                      value={inputValue}
                      onChange={(event) => {
                        const { value } = event.target;
                        setDirection(prev => "forward");
                        let currency = {};
                        try {
                          currency = JSON.parse(currencyField.value || "{}");
                        } catch (e) {
                          console.error(
                            "Error parsing currency field value",
                            e
                          );
                        }
                        const decimalDegree = CURRENCY_DECIMALS[currency.symbol] || CURRENCY_DECIMALS.DEFAULT;
                        if (!value || new RegExp(`^\\d+(\\.\\d{0,${decimalDegree}})?$`).test(value)) {
                          setInputValue(value); 
                          field.onChange(event); 
                        }
                      }}
                    />
                  )}
                </Field>
              }
            </Flex>
          </Flex>
          {JSON.parse(currencyField.value || "{}").type === "crypto" &&
            selectedOption.type === "crypto" &&
            stablecoinNetworks?.data?.length > 0 && (
              <Box maxW={"387px"} mt={8}>
                <CustomSelectField
                  width={"100%"}
                  name="network"
                  label={"Select Network"}
                  required
                  options={stablecoinNetworks?.data}
                  selectedOption={selectedNetworkOption}
                  setSelectedOption={setSelectedNetworkOption}
                />
              </Box>
            )}
        </Box>
        <Box>
          <Typography type="description" weight="regular" color="secondary">
            You Get
          </Typography>
          <Flex
            justifyContent={"space-between"}
            borderRadius={"9px"}
            paddingX={"8px"}
            height={"60px"}
            alignItems={"center"}
            columnGap={4}
            pt={0}
            border={"1px solid"}
            borderColor={"brand.secondary"}
          >
            <Flex
              height={"60px"}
              width={"120px"}
              columnGap={2}
              alignItems={"start"}
              pt={4}
            >
              <img
                src={process.env.PUBLIC_URL + selectedCurrency.logo}
                alt={selectedCurrency.label}
                style={{ width: "24px" }}
              />
              <Typography
                type="body"
                weight="medium"
                color="secondary"
                colorweight="900"
              >
                {selectedCurrency.label}
              </Typography>
            </Flex>

            <Flex justifyContent={"flex-end"} py={2} px={2} width={"180px"} columnGap={2} alignItems={"start"}>
              {quotesLoading && direction === "forward" ?
                <Skeleton borderRadius={"5px"} w={"151px"} h={"40px"} /> :
                <Field name="receivedAmount" >
                  {({ field, form }) => (
                    <TextField
                      errorStyles={{
                        position: "absolute",
                        width: "max-content",
                        right: 0,
                        bottom: "8px",
                      }}

                      _placeholder={{ fontSize: "13px" }}
                      minW={{ "2xl": "min-content", xl: "140px", sm: "120px" }}
                      maxW={"300px"}
                      marginTop={"10px"}
                      {...field}
                      required={false}
                      type="text"
                      name="receivedAmount"
                      placeholder="Enter Amount"
                      value={outputValue}
                      onChange={(event) => {
                        const { value } = event.target;
                        setDirection(prev => "reverse");
                        let currency = {};
                        try {
                          currency = selectedCurrency.label;
                        } catch (e) {
                          console.error(
                            "Error parsing currency field value",
                            e
                          );
                        }
                        const decimalDegree = CURRENCY_DECIMALS[selectedCurrency.label] || CURRENCY_DECIMALS.DEFAULT;
                        if (!value || new RegExp(`^\\d+(\\.\\d{0,${decimalDegree}})?$`).test(value)) {
                          setOutputValue(value);
                          field.onChange(event);
                        }
                      }}
                    />
                  )}
                </Field>
              }
            </Flex>
          </Flex>
        </Box>
      </SimpleGrid>
    </Box>
  );
}
