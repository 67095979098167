import { Button, Center, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalOverlay, useToast } from '@chakra-ui/react';
import React, { useState } from 'react';

import Typography from '../../../components/Common/Typography';
import { callApiWithToken } from '../../../utils/http_common';

const DeleteModal = ({ isOpen, onClose, data, refreshData }) => {
    const { email = "" } = data;
    const toast = useToast();
    const [loading, setLoading] = useState(false);
    const handleDelete = async () => {
        try {
            setLoading(true);
            const payload = { email: email };
            const { data } = (await callApiWithToken.post("/customer/team/delete", payload)) || {};
            if (data?.success) {
                toast({
                    title: "Teammate Removed",
                    status: 'success',
                    duration: 5000,
                    isClosable: true
                });

            }
            setLoading(false)
            onClose();
            refreshData();
        }
        catch (e) {
            setLoading(false)
            toast({
                title: "Something went wrong",
                status: 'error',
                duration: 5000,
                isClosable: true
            });
            onClose();
        }
    }

    const onModalClose = () => {
        onClose();
    };

    return (
        <Modal isOpen={isOpen} onClose={onModalClose}>
            <ModalOverlay />
            <ModalContent>
                <Center mt={4}>
                    <Typography color="primary" colorweight="500" weight='bold'>Remove Teammate</Typography>
                </Center>
                <ModalCloseButton />
                <ModalBody>
                    <Center mt={4}>
                        <Typography color="secondary" colorweight="800" type='description' weight='medium'>Are you sure you want to remove this teammate?</Typography>
                    </Center>
                </ModalBody>

                <ModalFooter>
                    <Button
                        bg={"#F2F2F2"}
                        _hover={{ bg: "#F2F2F2" }}
                        color="#808080"
                        width={'113px'}
                        height={'40px'}
                        rounded={'6px'}
                        fontSize={'14px'}
                        fontWeight={'400'}
                        fontFamily={'DM Sans'} mr={3} onClick={onModalClose}>
                        Cancel
                    </Button>
                    <Button
                        bg={"#E90000"}
                        _hover={"#E90000"}
                        color="white"
                        width={'113px'}
                        height={'40px'}
                        rounded={'6px'}
                        fontSize={'14px'}
                        fontWeight={'400'}
                        fontFamily={'DM Sans'}
                        onClick={handleDelete}
                        isLoading={loading}
                    >
                        Remove
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default DeleteModal;