const getButtonTheme = (variant = "primary", isDisabled) => {
    // return this if the button is disabled
    const disabledBaseStyles = {
        bg: "brand.greyPrimary",
        color: "#B3B3B3",
        _hover: null,
        _active: null,
        opacity: 1
    };
    
    const variantStyles = {
        primary: {
            bg: "brand.primary",
            color: "brand.white",
            _hover: {
                bg: "brand.bluePrimary",
                boxShadow: "0px 4px 6px 0px rgba(31, 41, 55, 0.1), 0px 2px 4px 0px rgba(31, 41, 55, 0.06)",
            },
            _active: {
                bg: "hover.primary",
            },
        },

        secondary: {
            bg: "brand.secondary",
            color: "#808080",
            _hover: {
                bg: "#E5E5E5",
                color: "#666666"
            },
        },
        danger: {
            bg: "brand.danger",
            color: "brand.white",
            _hover: {
                bg: "#B60000",
                boxShadow: "0px 4px 6px 0px rgba(31, 41, 55, 0.1), 0px 2px 4px 0px rgba(31, 41, 55, 0.06)",
            },
            _active: {
                boxShadow: null
            }
        },
        outline: {
            bg: "brand.white",
            border: "1px solid",
            borderColor: "brand.secondary",
            color: "brand.primary",
            _hover: {
                bg: "brand.white",
                color: "brand.bluePrimary",
                borderColor: "brand.greyPrimary",
                boxShadow: "0px 4px 6px 0px rgba(31, 41, 55, 0.1), 0px 2px 4px 0px rgba(31, 41, 55, 0.06)"
            },
            _active: {
                color: "text.bluePrimary",
                bg: "brand.white",
            },
        }
    }

    const finalStyles = isDisabled ? { ...variantStyles[variant], ...disabledBaseStyles } : variantStyles[variant];

    return finalStyles;
};

const getButtonSize = (size = "md") => {
    const sizes = {
        md: {
            h: "48px",
            fontSize: "md",
            px: "32px",
        },
    };
    return sizes[size];
}

export const Button = {
    baseStyle: {
        fontWeight: "normal",
        borderRadius: "6px",
        cursor: "pointer",
        transition: "all 0.2s",
        fontFamily: "DM Sans"
    },
    sizes: {
        md: getButtonSize("md"),
    },
    variants: {
        primary: (props) => getButtonTheme("primary", props.isDisabled),
        secondary: (props) => getButtonTheme("secondary", props.isDisabled),
        danger: (props) => getButtonTheme("danger", props.isDisabled),
        outline: (props) => getButtonTheme("outline", props.isDisabled),
    },
}