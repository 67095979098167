import { Flex, Box, Skeleton, SkeletonText, SkeletonCircle, Stack, VStack } from '@chakra-ui/react';
import React from 'react';
import Typography from '../../../components/Common/Typography';

const IndividualPaymentLoader = () => {
  return (
    <Box px={8} py={5} bg={'#F2F2F2'} minH={'100vh'} >
      <Typography color="default" type="largeheader" weight="bold">Individual Payment</Typography>
        <Flex mt={'38px'} ml={'48px'} gap={6}>
          <Flex direction={'column'} gap={7} borderLeft={"2px solid"} borderLeftColor={"#1A4FD6"} py={'10px'} px={"10px"} h={"136px"} justify={"center"} w={"185px"}>  
            <Typography color="primary" colorweight="500" type='body' weight='bold' _hover={{ cursor: 'pointer' }} >Select Contact </Typography >
            <Typography color="primary" colorweight="200" type='body' weight='bold' _hover={{ cursor: 'pointer' }} >Set Amount</Typography >
            <Typography color="primary" colorweight="200" type='body' weight='bold' _hover={{ cursor: 'pointer' }} >Summary</Typography >
          </Flex>
      <Box w={"full"} minH={"92vh"} bg={"#F2F2F2"}>
        <VStack w={"100%"} >
            <Box bg='white' w='100%' borderRadius={"9px"} p={"20px 40px"}>
              <Typography type='body' weight='medium' color='primary' >Contact Details</Typography>
                <Box display={"flex"} flexDirection={"column"} alignItems={"center"} rowGap={5} textAlign={"center"}>
                    <Skeleton height={"300px"} w={"100%"} />
                </Box>
            </Box> 
            <Box bg='white' w='100%' borderRadius={"9px"} p={"20px 40px"} mt={4}>
              <Typography type='body' weight='medium' color='primary' >Payment Details</Typography>
                <Box display={"flex"} flexDirection={"column"} alignItems={"center"} rowGap={5} textAlign={"center"}>
                    <Skeleton height={"200px"} w={"100%"} />
                </Box>
            </Box> 
        </VStack>
      </Box>
    </Flex>
  </Box>
  );
};

export default IndividualPaymentLoader;
