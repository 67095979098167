import { FaListAlt, FaRegUserCircle } from "react-icons/fa";
import { RiMoneyDollarCircleFill, RiFileList3Line } from "react-icons/ri";
import { FaHandshakeAngle } from "react-icons/fa6";
import { IoHomeOutline, IoSettings } from "react-icons/io5";
import { LiaClipboardListSolid } from "react-icons/lia";
import { MdOutlineMenuBook } from "react-icons/md";
import { BiDollarCircle } from "react-icons/bi";
import { TbClipboardCheck } from "react-icons/tb";
import { useSelector } from 'react-redux';

const NavSelector = () => {

  const orgType = useSelector((state) => state?.login?.orgDetails?.orgType || "default");
  const customerType = useSelector((state) => state?.login?.userDetails?.cxRoles || [])[0];

  const payoutNavItems = [
    {
      name: 'Home',
      icon: IoHomeOutline,
      path: "/home",
      title: 'Home',
      customerVisibility: true,
      orgTypeVisibility: true,
    },
    {
      name: 'My Accounts',
      icon: LiaClipboardListSolid,
      path: "/my-accounts",
      title: 'My Accounts',
      customerVisibility: true,
      orgTypeVisibility: true,
    },
    {
      name: 'Send Money',
      icon: BiDollarCircle,
      path: "/send-money",
      title: 'Send Money',
      customerVisibility: true,
      orgTypeVisibility: (!["finance_manager"].includes(customerType)),
    },
    {
      name: 'Contacts',
      icon: FaRegUserCircle,
      path: "/contact",
      title: 'Contacts',
      customerVisibility: true,
      orgTypeVisibility: true,
    },
    {
      name: 'Approvals',
      icon: TbClipboardCheck,
      path: "/approvals",
      title: 'Approvals',
      customerVisibility: true,
      orgTypeVisibility: (["admin", "approver"].includes(customerType)),
      hasBadge: true,
    },
    {
      name: 'Transactions',
      icon: RiFileList3Line,
      path: "/payout-transactions",
      title: 'Payout Transactions',
      customerVisibility: true,
      orgTypeVisibility: true,
    },
  ];

  const collectionNavItems = [
    {
      name: 'Home',
      icon: IoHomeOutline,
      path: "/home",
      title: 'Home',
      customerVisibility: true,
      orgTypeVisibility: true,
    },
    {
      name: 'Transactions',
      icon: RiFileList3Line,
      path: "/transactions",
      title: 'Collection Transactions',
      customerVisibility: true,
      orgTypeVisibility: true,
    },
    // {
    //   name: 'Settlements',
    //   icon: LiaClipboardListSolid,
    //   path: "/settlements",
    //   title: 'Settlements',
    //   customerVisibility: true,
    //   orgTypeVisibility: true,
    // },
    {
      name: 'Collect Money',
      icon: BiDollarCircle,
      path: "/collection",
      title: 'Collect Money',
      customerVisibility: true,
      orgTypeVisibility: true,
    },
    // {
    //   name: 'Approvals',
    //   icon: TbClipboardCheck,
    //   path: "/approvals",
    //   title: 'Approvals',
    //   customerVisibility: true,
    //   orgTypeVisibility: true,
    //   hasBadge: true,
    // },
    {
      name: 'Customers',
      icon: FaRegUserCircle,
      path: "/customers",
      title: 'Customers',
      customerVisibility: true,
      orgTypeVisibility: true,
    }
  ];

  const invoiceEcomNavItems = [
    {
      name: 'Orders',
      icon: FaListAlt,
      path: "/order",
      title: 'Order page title',
      customerVisibility: true,
      orgTypeVisibility: true,
    },
  ];

  const adminNavItems = [
    {
      name: 'Home',
      icon: IoHomeOutline,
      path: "/home",
      title: 'Home',
      customerVisibility: true,
      orgTypeVisibility: true,
    },
    {
      name: 'Orders',
      icon: FaListAlt,
      path: "/order",
      title: 'Order page title',
      customerVisibility: true,
      orgTypeVisibility: true,
    },
    {
      name: 'Collect Money',
      icon: BiDollarCircle,
      path: "/collection",
      title: 'Collect Money',
      customerVisibility: true,
      orgTypeVisibility: true,
    },
    {
      name: 'Balances',
      icon: RiMoneyDollarCircleFill,
      title: 'Balances',
      path: "/balances",
      customerVisibility: true,
      collapsable: true,
      orgTypeVisibility: true,
      children: [
        {
          name: 'Settlement',
          icon: FaHandshakeAngle,
          title: 'Settlement',
          path: "/balances/settlement",
          customerVisibility: true,
          collapsible: true,
          orgTypeVisibility: true,
        }
      ]
    },
    {
      name: 'My Accounts',
      icon: LiaClipboardListSolid,
      path: "/my-accounts",
      title: 'My Accounts',
      customerVisibility: true,
      orgTypeVisibility: true,
    },
    {
      name: 'Send Money',
      icon: BiDollarCircle,
      path: "/send-money",
      title: 'Send Money',
      customerVisibility: true,
      orgTypeVisibility: true,
    },
    {
      name: 'Contacts',
      icon: FaRegUserCircle,
      path: "/contact",
      title: 'Contacts',
      customerVisibility: true,
      orgTypeVisibility: true,
    },
    {
      name: 'Approvals',
      icon: TbClipboardCheck,
      path: "/approvals",
      title: 'Approvals',
      customerVisibility: true,
      orgTypeVisibility: true,
      hasBadge: true,
    },
    {
      name: 'Transactions',
      icon: RiFileList3Line,
      path: "/transactions",
      title: 'Transactions',
      customerVisibility: true,
      orgTypeVisibility: true,
    },
    {
      name: 'Customers',
      icon: FaRegUserCircle,
      path: "/customers",
      title: 'Customers',
      customerVisibility: true,
      orgTypeVisibility: true,
    },

  ];

  const defaultNavItems = [
    {
      name: 'Orders',
      icon: FaListAlt,
      path: "/order",
      title: 'Order page title',
      customerVisibility: true,
      orgTypeVisibility: true,
    },
    {
      name: 'API Docs',
      icon: MdOutlineMenuBook,
      title: 'API Docs',
      path: "/api-docs",
      customerVisibility: true,
      orgTypeVisibility: true
    },
    {
      name: 'Balances',
      icon: RiMoneyDollarCircleFill,
      title: 'Balances',
      path: "/balances/settlement",
      customerVisibility: true,
      collapsable: false,
      orgTypeVisibility: true,
    },

  ];

  const customerNavItems = [
    {
      name: 'Home',
      icon: IoHomeOutline,
      path: "/customer/home",
      title: 'Order page title',
      customerVisibility: true,
      orgTypeVisibility: true,
    },
    {
      name: 'Transactions',
      icon: RiFileList3Line,
      title: 'Transactions',
      path: "/customer/transactions",
      customerVisibility: true,
      orgTypeVisibility: true
    },
  ];
  if (customerType === "guest")
    return customerNavItems;
  if (orgType === "payout")
    return payoutNavItems;
  if (orgType === "collections")
    return collectionNavItems;
  if (["ecom", "invoice"].includes(orgType))
    return invoiceEcomNavItems;
  if (orgType === "default")
    return defaultNavItems;

  return adminNavItems;
}

export default NavSelector;
