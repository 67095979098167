import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Icon,
  Box,
} from "@chakra-ui/react";
import { PiWarningBold } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";

import { cancelOrder } from "../../store/slices/orderSlices";
import { useNavigate } from "react-router";

const WarningModal = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isSuccess = useSelector((state) => state.order.isSuccess);
  const { isOpen, onClose, path } = props;

  const onConfirmCancel = () => {
    dispatch(cancelOrder());
    if (isSuccess) {
      navigate(path);
      onClose();
    }
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered >
      <ModalOverlay />
      <ModalContent>
            <ModalHeader>
              <HStack>
                <PiWarningBold color="#E90000" />
                <Text>Do you want to cancel this Order</Text>
              </HStack>
            </ModalHeader>
            <ModalBody>
              <Text>Are you sure, you want to cancel this order?</Text>
              <Text>This action cannot be undone</Text>
            </ModalBody>
            <ModalFooter justifyContent={"center"}>
              <HStack>
                <Button
                  color="#525C76"
                  border="1px"
                  borderColor="#525C76"
                  _hover={{ bg: "#ffffff" }}
                  variant={"outline"}
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  onClick={onConfirmCancel}
                  bg="#E90000"
                  color="#ffffff"
                  _hover={{ bg: "#c53030" }}
                >
                  Confirm
                </Button>
              </HStack>
            </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default WarningModal;
