import React from "react";
import { useSelector } from 'react-redux';

import SideNav from "./SideNav";
import NavSelector from "./navSelector";

export default function SideNavBar() {
    const orgType = useSelector((state) => state?.login?.orgDetails?.orgType || "default");

    return <SideNav navItems={NavSelector()} productType={orgType} />;
}
