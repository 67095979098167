import React, { useCallback, useEffect } from "react";
import {
	Box,
	VStack,
	Text,
	Button,
	Flex,
	Heading,
	Image,
	SimpleGrid,
} from "@chakra-ui/react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../utils/Loader";

import reuse from "../../assets/reuse.svg";
import payment from "../../assets/payment.svg";
import confirm from "../../assets/confirm.svg";
import complete from "../../assets/complete.svg";
import { createOrder, resetOrder } from "../../store/slices/orderSlices";
import { resetRecipinetList } from "../../store/slices/recipientSlices";
import { resetPaymentMethod } from "../../store/slices/paymentMethodsSlices";

const Payin = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const isLoading = true;
	const orderId = useSelector((state) => state.order?.orderId)

    useEffect(() => {
        navigate("/order");
    }, []);

	const onSubmitOrderHandler = useCallback(() => {
		dispatch(createOrder({ type: 'payin' }))
			.then((resp) => {
				if (resp.payload?.success) {
					navigate("/payin/create-order");
				}
			})
			.catch(e => console.log(e));
	}, []);

	return (
        <>
            {
                !isLoading ?
                    <Box p={5}>
                        <Flex direction={"column"} alignItems={"center"} gap={5}>
                            <Box
                                bg="#D7ECFF"
                                width={"full"}
                                p="4"
                                borderRadius={"12px"}
                                textAlign={"center"}
                            >
                                <Heading
                                    as="h3"
                                    fontSize={"24px"}
                                    fontWeight={"medium"}
                                    alignItems={"center"}
                                    mb="5"
                                >
                                    Pay in currency, receive crypto
                                </Heading>

                                <SimpleGrid
                                    spacing={5}
                                    templateColumns="repeat(auto-fill, minmax(20%, 1fr))"
                                >
                                    <VStack textAlign={"center"}>
                                        <Image src={reuse} width={"50px"} />
                                        <Heading as="h5" size={"sm"} color={"#1A4FD6"}>
                                            Add or Select recipient
                                        </Heading>
                                        <Text color={"#5F5F5F"} fontSize={"14px"}>
                                            Easily add new payees by providing their name, country of
                                            residence/incorporation, and wallet address, or select from
                                            stored recipient details for a seamless payment experience.
                                        </Text>
                                    </VStack>
                                    <VStack textAlign={"center"}>
                                        <Image src={payment} width={"50px"} />
                                        <Heading as="h5" size={"sm"} color={"#1A4FD6"}>
                                            Select pay in method
                                        </Heading>
                                        <Text color={"#5F5F5F"} fontSize={"14px"}>
                                            Choose your preferred fiat payment method for the transaction,
                                            ensuring a convenient and secure way to pay for the crypto
                                            transfer.
                                        </Text>
                                    </VStack>
                                    <VStack textAlign={"center"}>
                                        <Image src={confirm} width={"50px"} />
                                        <Heading as="h5" size={"sm"} color={"#1A4FD6"}>
                                            Confirm order amount
                                        </Heading>
                                        <Text color={"#5F5F5F"} fontSize={"14px"}>
                                            Double-check and confirm the total order amount, which includes
                                            processing fees to ensure transparency and accuracy.
                                        </Text>
                                    </VStack>
                                    <VStack textAlign={"center"}>
                                        <Image src={complete} width={"50px"} />
                                        <Heading as="h5" size={"sm"} color={"#1A4FD6"}>
                                            Complete the transaction
                                        </Heading>
                                        <Text color={"#5F5F5F"} fontSize={"14px"}>
                                            With just a few clicks, finalize the transaction and initiate
                                            the seamless transfer of the specified crypto amount to the
                                            designated recipients.
                                        </Text>
                                    </VStack>
                                </SimpleGrid>
                            </Box>
                            <Button
                                isLoading={isLoading}
                                color={"#ffffff"}
                                bg={"#1A4FD6"}
                                _hover={{ bg: "#232D95" }}
                                minW={"200px"}
                                size={"md"}
                                onClick={onSubmitOrderHandler}
                            >
                                Create Order
                            </Button>
                        </Flex>
                    </Box> :
                    <Loader/>
            }
        </>
	);
};

export default Payin;
