import React, { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react'
import { useSelector, useDispatch } from 'react-redux';

import AddCustomerForm from '../customers/AddCustomer';
import CustomerDetails from './CustomerDetails';
import { getCustomer } from '../../store/slices/customerSlice';

const AddCustomer = () => {
    const [showNextButton, setShowNextButton] = useState(true);
    const [showAddCustomer, setShowAddCustomer] = useState(false);

    let list = useSelector((state) => state.customer.list?.map(item => ({
        label: `${item.name}`,
        value: `${item.name}`,
        logo: `${item.countryLogo}`,
        data: { ...item }
    })));
    list = list.filter((item) => item?.data?.status !== "manual_review");

    const loading = useSelector((state) => state.customer.loading) || true;

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCustomer());
        if (list.length === 0 && !loading) {
            setShowAddCustomer(true);
        }
    }, [showAddCustomer])

    return (
        <Box>
            {showAddCustomer && (
                <AddCustomerForm showNextButton={showNextButton} setShowAddCustomer={setShowAddCustomer} />
            )}
            {!showAddCustomer && (
                <CustomerDetails customerList={list} showAddCustomer={showAddCustomer} setShowAddCustomer={setShowAddCustomer} />
            )}
        </Box>
    );
};

export default AddCustomer;
