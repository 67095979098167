import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, VStack } from '@chakra-ui/react';

import { Field, useField, useFormikContext } from 'formik';
import { TextField } from '../../../../components/Common/Input';

const MxnIdentificationDetailsFormDialog = (props) => {
  const { validateForm, submitForm } = useFormikContext();
  const [idField, idMeta, idActions] = useField("idNumber");
  const [idFieldClabe, idMetaClabe, idActionsClabe] = useField("clabe");

  const { isOpen, onClose } = props;

  const loading = useSelector((state) => state.addMoney.loading);
  const [inputValue, setInputValue] = useState('');
  const [inputValueClabe, setInputValueClabe] = useState('');


  const handleClick = () => {
    if (loading) return;
    const errorId = validateId(inputValue) || '';
    const errorClabe = validateClabe(inputValueClabe) || "";

    if (errorId !== '' || errorClabe !== '') {
      idActions.setError('ID is required');
      idActions.setTouched(true);
      idActionsClabe.setError('ID is required');
      idActionsClabe.setTouched(true);
      return;
    }

    onClose();
    validateForm().then((errors) => {
      console.log(errors)
      if (Object.keys(errors).length === 0) {
        submitForm();
      }
    })
  }

  function validateId(value) {
    let error;
    if (!value) {
      error = 'ID is required';
    } else if (value?.toString()?.length < 8) {
      error = "ID must be atleast 8 digits"
    }
    return error;
  }

  function validateClabe(value) {
    let error;
    if (!value) {
      error = 'Clabe is required';
    } else if (value?.toString()?.length < 18) {
      error = "Clabe must be atleast 18 digits"
    } 
    return error;
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Enter these Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack gap={"20px"} >
            <TextField name='idType' disabled={true} isDisabled={true} defaultVaue="CURP" value="CURP" label='ID Type' placeholder='Enter ID Number' />
            <Field name='idNumber' validate={validateId}>
              {({ field, form }) => (
                <TextField required={true} name='idNumber' label='ID Number' placeholder='Enter ID Number'
                  {...field}
                  value={inputValue}
                  onChange={(event) => {
                    const { value } = event.target;
                    if (!value || /^[A-Za-z0-9]+$/.test(value)) {
                      setInputValue(value);
                      field.onChange(event);  
                    }
                  }}
                />
              )}
            </Field>
            <Field name='clabe' validate={validateClabe}>
              {({ field, form }) => (
                <TextField required={true} name='clabe' label='Clabe' placeholder='Enter Clabe'
                  {...field}
                  value={inputValueClabe}
                  onChange={(event) => {
                    const { value } = event.target;
                    if (!value || /^\d+(\.\d{0,2})?$/.test(value)) {
                      setInputValueClabe(value);
                      field.onChange(event);
                    }
                  }}
                />
              )}
            </Field>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button _loading={{ pointerEvents: "none" }} isLoading={loading} variant={"primary"} onClick={handleClick} size={"sm"} p={"9px 16px 9px 16px"}>
            Next
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>

  );
};

export default MxnIdentificationDetailsFormDialog;