import { Flex, Box, Skeleton, SkeletonText, SkeletonCircle, Stack } from '@chakra-ui/react';
import React from 'react';
import Typography from '../../components/Common/Typography';

const LoadingSkeleton = ({orgType, customerType}) => {
  return (
    <Box px={8} py={5} bg={'#F2F2F2'} minH={'100vh'} >
      <Typography color="default" type="largeheader" weight="bold">Settings</Typography>
        <Flex mt={'40px'} ml={'40px'} gap={6}>
            {
              (['payout'].includes(orgType)) ?
               <Flex direction={'column'} gap={7} borderLeft={"3px solid"} borderLeftColor={"#1A4FD6"} py={'10px'} px={"10px"} h={"130px"} justify={"center"} w={"216px"}>
                    <Typography color="primary" colorweight="500" type='body' weight='bold' _hover={{ cursor: 'pointer' }} >Team Management</Typography >
                    <Typography color="primary" colorweight="200" type='body' weight='bold' _hover={{ cursor: 'pointer' }} >Finance</Typography >
                    <Typography color="primary" colorweight="200" type='body' weight='bold' _hover={{ cursor: 'pointer' }} >Integration</Typography >
              </Flex> 
              : (['collections'].includes(orgType) && !['guest'].includes(customerType)) ?
              <Flex direction={'column'} gap={7} borderLeft={"3px solid"} borderLeftColor={"#1A4FD6"} py={'10px'} px={"10px"} h={"130px"} justify={"center"} w={"216px"}>
                    <Typography color="primary" colorweight="500" type='body' weight='bold' _hover={{ cursor: 'pointer' }} >Team Management</Typography >
                    <Typography color="primary" colorweight="200" type='body' weight='bold' _hover={{ cursor: 'pointer' }} >Wallet Details</Typography >
                    <Typography color="primary" colorweight="200" type='body' weight='bold' _hover={{ cursor: 'pointer' }} >Integration</Typography >
              </Flex> 
              : (['collections'].includes(orgType) && ['guest'].includes(customerType)) ?
              <Flex direction={'column'} gap={7} borderLeft={"3px solid"} borderLeftColor={"#1A4FD6"} py={'10px'} px={"10px"} h={"100px"} justify={"center"} w={"216px"}>
                    <Typography color="primary" colorweight="500" type='body' weight='bold' _hover={{ cursor: 'pointer' }} >Details</Typography >
                    <Typography color="primary" colorweight="200" type='body' weight='bold' _hover={{ cursor: 'pointer' }} >Personal Details</Typography >
              </Flex>
              :
              <Flex direction={'column'} gap={7} borderLeft={"3px solid"} borderLeftColor={"#1A4FD6"} py={'10px'} px={"10px"} h={"100px"} justify={"center"} w={"216px"}>
                    <Typography color="primary" colorweight="500" type='body' weight='bold' _hover={{ cursor: 'pointer' }} >Integration</Typography >
                    <Typography color="primary" colorweight="200" type='body' weight='bold' _hover={{ cursor: 'pointer' }} >Currencies</Typography >
              </Flex>
            }
          
           <Box bg='white' w='100%' borderRadius={"9px"} p={"20px 40px"}>
                 <Stack borderRadius={"9px"} mt={"15px"} rowGap={5} bg={"white"}>
                    {Array(6).fill(0).map((_, index) => (
                       <Skeleton height='60px' />
                    ))}
                 </Stack>
           </Box>
    </Flex>
    </Box>
  );
};

export default LoadingSkeleton;
