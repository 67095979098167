import React from "react";
import { Box, Text, Image } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const Card = ({ data }) => {
  const navigate = useNavigate();
  return (
    <Box
      position="relative"
      p={4}
      borderRadius="8px"
      backgroundImage={`url(${data?.svgCardPath})`}
      backgroundSize="cover"
      backgroundPosition="center"
      color="white"
      overflow="hidden"
      cursor="pointer"
      onClick={() => {
        data?.path === ""
          ? (window.location.href = "mailto:sales@transfi.com")
          : navigate(`${data?.path}`);
      }}
      _hover={{
        backgroundImage: `url(${data?.svgCardHoverPath})`,
        transition: "background-image 0.3s ease-in-out",
      }}
    >
      <Image
        src={"/assets/Icons.png"}
        width={"35px"}
        zIndex="2"
        position="relative"
      />
      <Text
        mt={3}
        fontSize="xl"
        fontWeight="bold"
        zIndex="2"
        position="relative"
      >
        {data?.title}
      </Text>
      <Text mt={2} mb={3} zIndex="2" position="relative">
        {data?.description}
      </Text>
    </Box>
  );
};

export default Card;
