import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Img, Skeleton, VStack } from '@chakra-ui/react';

// Custom Function and Components
import Typography from '../../components/Common/Typography';
import CollectionApprovalCard from './CollectionApprovalCard';

// CSS
import './approval.css';

const CollectionApprovals = ({ collectionApprovalList }) => {
    const transactionLoaderState = useSelector((state) => state?.transaction?.loading || false);

    return (
        <>
            {collectionApprovalList.length === 0 ?
                <Box className='al-sub-card'>
                    <VStack gap={"12px"}>
                        <Img className='al-main-img' src={process.env.PUBLIC_URL + "/assets/approval_main_screen.png"} alt="approvalsLogo" />
                        <Typography type='title' weight='medium' color='secondary' colorweight='800' >
                            All good!
                        </Typography>
                        <Typography type='description' weight='regular' color='secondary' colorweight='800' >
                            No approvals pending
                        </Typography>
                    </VStack>
                </Box> :
                !transactionLoaderState ?
                    collectionApprovalList.map((item) => {
                        return <CollectionApprovalCard
                            makerName={item.additionalDetails.maker}
                            customerName={item.senderName.firstName}
                            orderId={item.orderId}
                            depositAmount={item.depositAmount}
                            depositCurrency={item.depositCurrency}
                            logo={item.additionalDetails.countryLogo}
                            createdAt={item?.createdAt}
                        />
                    }) :
                    <>
                        <Skeleton height={"85px"} />
                        <Skeleton height={"85px"} />
                        <Skeleton height={"85px"} />
                    </>
            }
        </>
    );
};

export default CollectionApprovals;