import { Box, HStack, VStack, useDisclosure, useToast, Tooltip, Text } from "@chakra-ui/react";


import Typography from "../../../components/Common/Typography";
import DeleteIcon from "../../../assets/delete.svg";
import { AlertModal } from "../../../components/modal/deleteModal";
import { useDispatch } from "react-redux";
import { deletePaymentAccount } from "../../../store/slices/contactSlices";
import InfoIcon from '../../../assets/InfoIcon2.svg';
import ThunderIcon from '../../../assets/thunder.svg';
import isEmpty from 'lodash/isEmpty';
// import { InfoIcon } from '@chakra-ui/icons';

export default function ViewCard(props) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast();
  const dispatch = useDispatch();

  const { type, walletName, paId, currrencyImage, currency, accountNumber, counterPartyStatus='' } = props;
  const handleDelete = () => {
    onOpen();
  }
  const handleDeleteConfirm = () => {
    onClose();
    dispatch(deletePaymentAccount(paId));
    toast({
      title: "Account Deleted",
      position: "top",
      status: "success",
      duration: 2000,
      isClosable: true
    });
  }
  return (
    <Box w={"100%"} maxW={"386px"} borderRadius={"8px"} border={"1px #B3B3B3 solid"} display="flex" flexDirection="column" background={"#FFFFFF"} padding={"12px 16px 12px 16px"} gap="6px" >
      <HStack display={"flex"} flexDirection={"row"} justifyContent={"space-between"} >
        <Box borderRadius={"3px"} padding={"0px 8px 0px 8px"} gap={"6px"} backgroundColor={"#E8EDFB"} display={"flex"} justifyContent={"center"} alignItems={"center"} >
          <Typography weight="medium" type="caption" color="primary" >
            {type}
          </Typography>
          {type === "Bank Account" && !isEmpty(counterPartyStatus) && (
            <Text fontWeight="medium" fontSize="caption" color="primary" display='flex' alignItems='center'>
              {counterPartyStatus ==="pending" ? (
                <>
                
                  <Tooltip label="Waiting third party creation at bank’s end. Can take up to 1 working day">
                    <Box as="span" style={{ cursor: "pointer", marginRight: '4px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <img
                        src={InfoIcon}
                        alt="info"
                        style={{ width: '16px', height: '16px' }}
                      />
                    </Box>
                  </Tooltip>
                  <span style={{ fontSize: '12px', color:'#E90000' }}>Pending Verification</span>
                </>
              ) : (
                <>
                  <img
                    src={ThunderIcon}
                    alt="thunder"
                    style={{ width: '16px', height: '16px', marginRight: '4px' }}
                  />
                  <span style={{ fontSize: '12px', color:'#26A17B' }}>Instant Payout</span>
                </>
              )}
            </Text>
          )}
      </Box>
      <img style={{ cursor: "pointer" }} src={DeleteIcon} alt="delete" onClick={handleDelete} />
      </HStack>
      <VStack align="start" >
        <Typography weight="medium" type="body" color="secondary" colorweight="900" >
          {walletName}
        </Typography>
        <HStack display={"flex"} flexDirection={"row"} justifyContent={"space-between"} w={"100%"} >
          <HStack>
            <img src={process.env.PUBLIC_URL + currrencyImage} style={{ width: '30px', height: '30px', backgroundColor: '#F4F4F4', borderRadius: "50px" }} alt="logo" />
            <Typography weight="regular" type="caption" color="secondary" colorweight="900"  >
              {currency}
            </Typography>
          </HStack>
          <Typography ml={10} weight="regular" type="body" color="secondary" overflow={'hidden'}>
            {accountNumber}
          </Typography>
        </HStack>
      </VStack>
      <AlertModal btnText={"Delete"} handleConfirmClick={handleDeleteConfirm} isOpen={isOpen} title="Delete Account" onClose={onClose}>
        <Typography weight="medium" type="body" color="secondary" >
          Are you sure you want to delete this account?
        </Typography>
      </AlertModal>
    </Box>
  )
}