import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";

import AddCustomer from './AddCustomer';
import AddInvoice from './AddInvoice';
import ConfigurationService from '../../services/configurationService';

const CollectMoneyMain = () => {

    const { step: activeStep } = useSelector((state) => state.collectMoney);

    const [currencyOptions, setCurrencyOptions] = useState([])

    useEffect(() => {
        getSupportedCurrencies()
    }, [])

    const getSupportedCurrencies = async () => {
        const { data } = await ConfigurationService.getConfigurations({ name: 'supported_currencies', module: 'collections' });
        setCurrencyOptions(data?.data?.configs)
    }

    function renderStepComponent(step) {
        switch (step) {
            case 0:
                return <AddCustomer />;
            case 1:
                return <AddInvoice currencyOptions={currencyOptions} />;
            default:
                return <AddCustomer />;
        }
    }
    return (
        renderStepComponent(activeStep)
    );
};

export default CollectMoneyMain;