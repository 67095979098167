import React from 'react';
import {
    Button, Flex, Modal,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    HStack,
    Center,
    Box,
    Text,
    Divider,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { ArrowForwardIcon } from '@chakra-ui/icons';

import Typography from '../../../../components/Common/Typography';
import { currencyFormatter } from '../../../../utils/formatter';

const MakePaymentModal = ({ isOpen, onClose, invoiceDetails }) => {

    const { depositCurrency, depositAmount, orderId, customer } = invoiceDetails;
    const { customerCurrency = "", customerCurrencyAmount = "" } = customer || {};

    const { customerType = "", kybStatus } = useSelector((state) => state?.customer?.kybDetails);
    let type = "KYB"
    if (customerType === 'individual') {
        type = "KYC"
    }

    const navigate = useNavigate()
    return (
        <Modal isOpen={isOpen} onClose={onClose} >
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />
                <ModalBody display={"flex"} flexDirection={"column"} alignItems={"center"} >
                    <Typography color="primary" colorweight="500" weight='bold'>
                        <Center mt={4}>
                            <Typography color="primary" colorweight="500" weight='bold'>Complete {type} to continue</Typography>
                        </Center>
                    </Typography>
                    <Center mt={4} textAlign={"center"} >
                        <Typography color="secondary" colorweight="800" type='description' weight='medium'>In order to continue with this payment, you must complete your {type}.
                        </Typography>
                    </Center>

                    <Box px={4} py={4} bg={"#F2F2F2"} rounded={'6px'} w={'full'} mt={6}>
                        <Flex justify={'flex-start'}>
                            <Typography type='body' weight='bold'>Invoice Details</Typography>

                        </Flex>
                        {invoiceDetails?.additionalDetails?.invoiceUrl &&
                            <Flex justify={"flex-end"} mt={5}>
                                <a target="_blank" rel="noopener noreferrer" href={invoiceDetails.additionalDetails.invoiceUrl} >
                                    <Text color={'#1A4FD6'} textDecoration={'underline'} _hover={{ cursor: 'pointer' }} fontSize={'16px'} fontFamily={'DM Sans'}>View invoice</Text>
                                </a>
                            </Flex>}
                        <HStack display={"flex"} w={"100%"} justifyContent={"space-between"} mt={5}>
                            <Typography color="secondary" colorweight="800" type='description' weight='medium'>
                                Invoice Amount
                            </Typography>
                            <Typography color="secondary" colorweight="800" type='description' weight='medium'>
                                {currencyFormatter(Number(invoiceDetails.depositAmount))}  {invoiceDetails.depositCurrency}
                            </Typography>
                        </HStack>
                        {depositCurrency !== customerCurrency &&
                            <HStack display={"flex"} w={"100%"} justifyContent={"space-between"} mt={5}>
                                <Typography color="secondary" colorweight="800" type='description' weight='medium'>
                                    Conversion
                                </Typography>
                                <Typography color="secondary" colorweight="800" type='description' weight='medium'>
                                    1 {invoiceDetails.depositCurrency} = {currencyFormatter(Number(customer?.customerCurrencyConversionRate))} {customer?.customerCurrency}
                                </Typography>
                            </HStack>}
                        <Divider ml={'13px'} mb={'2'} w={'full'} mt={5} />
                        <HStack display={"flex"} w={"100%"} justifyContent={"space-between"} mt={7} >
                            <Typography color="secondary" colorweight="800" type='body' weight='regular'>
                                Amount to be paid
                            </Typography>
                            <Typography color="secondary" colorweight="800" type='body' weight='bold'>
                                {currencyFormatter(Number(customer?.customerCurrencyAmount))}  {customer?.customerCurrency}
                            </Typography>
                        </HStack>
                    </Box>
                </ModalBody>


                <ModalFooter>
                    <Button
                        bg={"#F2F2F2"}
                        _hover={{ bg: "#F2F2F2" }}
                        color="#808080"
                        height={'40px'}
                        rounded={'6px'}
                        fontSize={'14px'}
                        fontWeight={'400'}
                        fontFamily={'DM Sans'} mr={3} onClick={onClose}>
                        Cancel
                    </Button>
                    <Button
                        bg={"#1a4fd6"}
                        _hover={{ bg: '#1a4fd6' }}
                        color="white"
                        height={'40px'}
                        rounded={'6px'}
                        fontSize={'14px'}
                        fontWeight={'400'}
                        fontFamily={'DM Sans'}
                        rightIcon={<ArrowForwardIcon />}
                        onClick={() => navigate('/customer/home/kyb')}
                    >
                        Complete {type}
                    </Button>

                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default MakePaymentModal;