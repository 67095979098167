import React, { useState } from "react";
import { Box, Button, Flex, Image, Text, useToast } from "@chakra-ui/react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router";
import { FaArrowLeft } from "react-icons/fa";

import TransfiLogo from "../../assets/transfi-logo.svg";

//css
import "./Signup.css"
import ProgressBar from "../../components/LoginComponents/ProgressBar";
import { callApi } from "../../utils/http_common";
import Typography from "../../components/Common/Typography";
import { resetDetails } from "../../store/slices/loginSlices";

const RequestJoin = () => {
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    const orgDetails = useSelector(state => state.login?.loginDetails?.orgDetails || {});
    const cxId = useSelector(state => state.login?.loginDetails?.cxId || "");

    const toast = useToast();

    const navigate = useNavigate();

    const handleToast = (title, type) => {
        toast({
            title,
            status: type,
            duration: 5000,
            isClosable: true
        });
    };

    const { name = '', adminEmail = '', orgMembers = '', orgId = '' } = orgDetails;

    const handleAskToJoin = async () => {
        try {
            setLoading(true)
            const data = await callApi.post(`/customer/joinRequest`, { orgId, cxId });
            if (data.data?.success) {
                handleToast(data?.data?.data?.message, 'success');
                setLoading(false)
                navigate('/login')
            }
        }
        catch (e) {
            setLoading(false)
            handleToast(e.message, 'error');
            navigate('/login')
        }
    }

    const handleGoBack = () => {
        dispatch(resetDetails());
        navigate(-1)
    };

    return (
        <Flex bg={'white'} h={'full'} w={'full'}>
            <Box bg={'white'} h={"full"} w={'50%'}>
                <Box>
                    <div className="pa-login-sub-card">
                        <Flex justify={'flex-start'}>
                            <div className="pa-header-nav" onClick={handleGoBack}>
                                <FaArrowLeft size={14} />
                                <div>
                                    <span style={{ fontSize: '15px' }} >Back</span>
                                </div>
                            </div>
                        </Flex>
                        <Box>
                            <img src={TransfiLogo} alt="logo" />
                            <Text textAlign={'left'} mt={'10'} color={"#092C4C"} fontSize={'36px'} fontWeight={500}>Welcome to TransFi</Text>
                            <Text textAlign={'left'} mt={'5'} color={"#414141"} fontSize={'20px'} fontWeight={500} mb={5}>Your company is already registered with us.</Text>
                            <ProgressBar level={3} />
                            <Flex justifyContent={'space-between'} w={'full'} h={'96px'} bg={"#E8EDFB"} mt={'7'} alignItems={'center'} px={'12px'} rounded={'9px'}>
                                <Box textAlign={'left'}>
                                    <Typography type="body" weight="bold" color="primary">{name}</Typography>
                                    <Typography type="description" weight="regular" color="secondary" colorweight='600'>{orgMembers} members</Typography>
                                </Box>
                                <Box textAlign={'right'} >
                                    <Typography type="description" weight="regular" color="secondary" colorweight='600'>Admin</Typography>
                                    <Typography type="description" weight="regular" color="secondary" colorweight='600'>{adminEmail} </Typography>
                                </Box>
                            </Flex>
                            <Flex gap={1} justify={'flex-end'}>
                                <Text textAlign={'right'} color={"#808080"} fontSize={'14px'} fontWeight={'400'}>Having a problem?</Text>
                                <Text as={'span'} color={"#1A4FD6"} fontSize={'14px'} fontWeight={'400'}>Email us at support@transfi.com</Text>
                            </Flex>
                            <Button className={"pa-button"} bg={'#1A4FD6'} mt={16} textColor={'white'} _hover={{ bg: '#1A4FD6' }} onClick={handleAskToJoin} isLoading={loading}>
                                Ask to join
                            </Button>
                        </Box>

                    </div>
                </Box>
            </Box>
            <Flex className="gradient-background" flexDirection={'column'} justify={'space-between'} w={'full'} maxH={'100vh'} overflow={'hidden'}>
                <Box ml={'80px'} mt={'100px'}>
                    <Text color={'#092C4C'} fontFamily={'DM Sans'} fontWeight={'700'} fontSize={'48px'}>Borderless <Text as={'span'} color={'#1A4FD6'}>payments</Text>,</Text>

                    <Text color={'#092C4C'} fontFamily={'DM Sans'} fontWeight={'700'} fontSize={'48px'}>boundless <Text as={'span'} color={'#1A4FD6'}>potential</Text></Text>
                </Box>
                <Flex w={'full'} justify={'flex-end'}>
                    <Image src="/assets/login.png" maxH={'809px'} maxW={'992px'} />
                </Flex>
            </Flex>
        </Flex>
    );
};

export default RequestJoin;