import { callApiWithToken } from "../utils/http_common";

const getQuotes = (payload) => {
    return callApiWithToken.get(`/quotes/exchange-rates`, {
        params: {
            ...payload
        }
    });
}
const QuoteService = {
    getQuotes,
}

export default QuoteService;