import { Box, Button, Center, Flex, HStack, Img, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalOverlay, Radio, Text, useToast } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from "yup";
import { useEffect } from 'react';
import { FaAsterisk } from 'react-icons/fa';
import { Formik } from 'formik';

import Typography from '../../../components/Common/Typography';
import { callApiWithToken } from '../../../utils/http_common';
import InformationIcon from '../../../assets/information-circle.svg';
import { CustomSelectFieldSearch } from '../../../components/Common/Input';
import ContactService from '../../../services/contactService';
import AddBankForm from '../../contact/payment-account-details/Fiat/AddBankForm';
import bankFormConfig from "../../contact/payment-account-details/Fiat/BankFormConfig";
import { bankValidationSchema } from '../Cards/ValidationSchema';

const AddBankModal = ({ isOpen, onClose, refreshData, setRefreshData }) => {

    const [selectedFiatOption, setSelectedFiatOption] = useState({ label: "Select Currency" });
    const [paymentMethods, setPaymentMethods] = useState([])
    const [selectedOption, setSelectedOption] = useState(paymentMethods[0] || null);
    const [loading, setLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);

    const toast = useToast();
    const { cxId = "" } = useSelector((state) => state?.login?.userDetails)

    const fiatList = useSelector(state => state.order?.fiatList).map((item) => {
        return {
            label: `${item.value.symbol} - ${item?.value?.description || item?.value?.countryCode2Digit}`,
            value: `${item.value.description || item.value?.countryCode2Digit}`,
            logo: `${item.value.logo}`
        };
    });

    const initialValue = {
        currency: "",
        nickname: "",
        number: "",
        bankName: "",
    };

    async function handleSubmit(values, actions) {

        let pmGateway = "";
        let referenceName = ""
        if (selectedOption) {
            pmGateway = JSON.parse(selectedOption?.value || {})?.pgId || "";
            referenceName = JSON.parse(selectedOption?.value || {})?.referenceName || null;
            values.bankName = JSON.parse(selectedOption?.value || {})?.referenceName;
        }

        const currency = values.currency;

        let extraFieldsWithValues = {};

        let finalSchemaToBeValidated = bankValidationSchema;
        let finalValuesToBeValidated = values;

        let extraInitialValues = {}, extraValidationSchema = {};

        const { extraInitialValues: extraInitialValue, extraValidationSchema: extraValidationSchemas } = bankFormConfig(pmGateway, currency?.substring(0, 2), referenceName) || {};
        extraInitialValues = extraInitialValue || {};
        extraValidationSchema = extraValidationSchemas || {};

        const schema = Yup.object().shape({
            ...extraValidationSchema
        });

        finalSchemaToBeValidated = finalSchemaToBeValidated.concat(schema);

        function filterFieldsWithDefaults(mainObj, filterObj) {
            const result = {};
            Object.keys(filterObj).forEach((key) => {
                // also set the extraFieldsWithValues to be used in the final payload and only use the values that have something in them
                if (mainObj[key]) {
                    extraFieldsWithValues[key] = mainObj[key];
                }
                result[key] = key in mainObj ? mainObj[key] : '';
            });
            return result;
        }

        const extraValues = filterFieldsWithDefaults(values, extraInitialValues);
        finalValuesToBeValidated = ({
            ...finalValuesToBeValidated,
            extraValues,
        })

        // Validating Final Validation Values
        const isValid = await finalSchemaToBeValidated.isValid(finalValuesToBeValidated);
        if (!isValid) {
            const errors = await finalSchemaToBeValidated.validate(finalValuesToBeValidated, { abortEarly: false }).catch((err) => {
                if (err instanceof Yup.ValidationError) {
                    // Create a more informative errors object
                    const errorsInfo = {};
                    err.inner.forEach((error) => {
                        // Only add if the field hasn't been added yet, or if you want to override, remove this check
                        if (!errorsInfo[error.path]) {
                            errorsInfo[error.path] = error.message;
                        }
                    });
                    return errorsInfo;
                }

                // Handle unexpected errors
                return { unexpectedError: "An unexpected error occurred" };
            });

            actions.setErrors(errors);
            actions.setSubmitting(false);
            return;
        }
        const bank = JSON.parse(selectedOption?.value || {})?.referenceName;

        const payload = {
            currency: values.currency,
            currencyLogo: selectedFiatOption.logo,
            accountHolderName: values.nickname,
            accountNumber: values.number,
            bankDetails: {
                bank,
                ...extraFieldsWithValues,
                pgId: pmGateway
            },
            cxId
        }
        setLoading(true);
        const { data } = (await callApiWithToken.post("/customer/saveBankDetails", payload)) || {};
        if (data?.success) {
            toast({
                title: "Bank details added",
                status: 'success',
                duration: 5000,
                isClosable: true
            });
        }
        setLoading(false);
        setRefreshData(!refreshData)
        onClose();
    }

    const getPaymentMethods = async () => {
        try {
            const resp = await ContactService.getFiatPaymentMethods({ fiatTicker: selectedFiatOption.value, type: "sell", pmType: "bank_transfer" });
            setPaymentMethods(resp?.data?.data?.docs.map((pm) => ({ logo: pm.icon, value: JSON.stringify({ referenceName: pm.referenceName, pgId: pm?.pgId, pmId: pm?.pmId }), label: pm.name })));
        } catch (error) {
        }
    }

    useEffect(() => {
        if (paymentMethods.length === 1) {
            setIsDisabled(true);
            setSelectedOption(paymentMethods[0])
        } else {
            setIsDisabled(false);
        }

    }, [paymentMethods])

    useEffect(() => {
        setPaymentMethods([])
        setSelectedOption(null)
        getPaymentMethods()
        setIsDisabled(false)
    }, [selectedFiatOption.value])

    const handleClose = () => {
        setSelectedFiatOption({ label: "Select Currency" })
        setLoading(false)
        onClose()
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'4xl'} >
            <ModalOverlay />
            <ModalContent py={3}>
                <Center mt={4}>
                    <Typography color="primary" colorweight="500" weight='bold'>Add Bank Details</Typography>
                </Center>
                <ModalCloseButton />
                <ModalBody py={3} px={6}>
                    <Formik
                        initialValues={initialValue}
                        onSubmit={handleSubmit}
                        validateOnChange={false}
                    >

                        {({ isSubmitting, handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                                <HStack mt={3}>
                                    <img src={InformationIcon} alt="icon" className='wa-information-icon' />
                                    <Typography weight='regular' type='description' colorweight='800' >
                                        Please make sure that these details are correct
                                    </Typography>
                                </HStack>

                                <Box mt={5}>
                                    <CustomSelectFieldSearch EmptyMenuLabel={"No Currency Found"} label={"Select Currency"} options={fiatList} name="currency" placeholder="Select Currency" selectedOption={selectedFiatOption} setSelectedOption={setSelectedFiatOption} />
                                </Box>

                                {paymentMethods.length > 0 &&
                                    <>
                                        <Flex justifyContent={"space-between"} mt={8} >
                                            <Flex gap={1}>
                                                <Typography type="description" weight="regular" color="secondary" colorweight={"800"}>
                                                    Select Bank
                                                </Typography>
                                                <FaAsterisk size={7} style={{ marginTop: 2 }} color="#F50100" /></Flex>
                                            <Box>
                                                <Img src={process.env.PUBLIC_URL + "/assets/bank_grid_icons.svg"} />
                                            </Box>
                                        </Flex>

                                        <Box maxW={"387px"}>

                                            <CustomSelectFieldSearch EmptyMenuLabel={"No Banks Found"} required={false} width={"100%"} selectedOption={selectedOption} setSelectedOption={setSelectedOption} name="bankName" placeholder="Select Bank" options={paymentMethods} disabled={isDisabled} />
                                        </Box>
                                    </>
                                }
                                {
                                    paymentMethods.length > 0 && selectedFiatOption.value && <AddBankForm bank={selectedOption?.value} isAdditionalRequired={false} />
                                }
                                <Flex gap={2} justify={'flex-end'} mt={5}>
                                    <Button
                                        bg={"#F2F2F2"}
                                        _hover={{ bg: "#F2F2F2" }}
                                        color="#808080"
                                        width={'113px'}
                                        height={'40px'}
                                        rounded={'6px'}
                                        fontSize={'14px'}
                                        fontWeight={'400'}
                                        fontFamily={'DM Sans'} mr={3}
                                        onClick={handleClose}>
                                        Cancel
                                    </Button>
                                    <Button
                                        bg={"#1A4FD6"}
                                        _hover={{ bg: '#366cf5' }}
                                        color="white"
                                        width={'113px'}
                                        height={'40px'}
                                        rounded={'6px'}
                                        fontSize={'14px'}
                                        fontWeight={'400'}
                                        fontFamily={'DM Sans'}
                                        onClick={handleSubmit}
                                        isLoading={loading}
                                    >
                                        Add
                                    </Button>
                                </Flex>
                            </form>
                        )}
                    </Formik>
                </ModalBody>


            </ModalContent>
        </Modal>
    );
};

export default AddBankModal;