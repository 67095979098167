import PaymentMethodsService from "../../services/paymentMethodService";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const initialState = {
    list: [],
    loading: false,
    isSuccess: false,
    message: '',
    seletedPaymentMethod: '',
    processingFee: ""
};

export const getPaymentMethods = createAsyncThunk(
    "paymentMethods/getPaymentMethods",
    async ({ fiatTicker, product }, { rejectWithValue }) => {
        try {
            const resp = await PaymentMethodsService.getPaymentMethodsService({ fiatTicker, product });
            return resp?.data
        } catch (err) {
            return rejectWithValue(err.response.data)
        }
    }
);

export const getPaymentMethodList = createAsyncThunk(
    "paymentMethods/getPaymentMethods",
    async ({ currency, direction,amount }, { rejectWithValue }) => {
        try {
            const resp = await PaymentMethodsService.getPaymentMethodList({ currency, direction,amount });
            return resp?.data
        } catch (err) {
            return rejectWithValue(err.response.data)
        }
    }
);

const paymentMethodsSlice =  createSlice({
    name: 'paymentMethods',
    initialState,
    extraReducers: {
        [getPaymentMethods.pending]: (state, action) => {
            state.loading = true;
        },
        [getPaymentMethods.fulfilled]: (state, action) => {
            const { paymentMethods: list = [], processingFee = 0 } = action.payload?.data;
            state.list = list;
            state.loading = false;
            state.processingFee = processingFee;
        },
        [getPaymentMethods.rejected]: (state, action) => {
            state.loading = false;
            state.isSuccess = false;
            state.message = 'error found in action payload'
        },
        [getPaymentMethodList.pending]: (state, action) => {
            state.loading = true;
        },
        [getPaymentMethodList.fulfilled]: (state, action) => {
            const list = action?.payload?.data?.paymentMethods;
            state.list = list || [];
            state.loading = false;
        },
    },
    reducers: {
        updatePaymentMethod: (state, action) => {
            state.seletedPaymentMethod = action.payload;
        },
        resetPaymentMethod: (state, action) => {
            state.seletedPaymentMethod = ''
        }
    }
});

const { reducer: paymentMethodsReducer } = paymentMethodsSlice;

export const { updatePaymentMethod, resetPaymentMethod } = paymentMethodsSlice.actions;
export default paymentMethodsReducer;