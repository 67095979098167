import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { Box, Button, Flex, Heading, Skeleton, Stack, calc, Image } from '@chakra-ui/react'
import { BiPlus } from 'react-icons/bi'
import EmptyIcon from '../../assets/emptypage.svg';

// css
import './Contact.css'
import ContactLayout from './ContactLayout'
import ContactService from '../../services/contactService'
import ContactTable from './ContactTable'
import Typography from '../../components/Common/Typography'
import { useDispatch } from 'react-redux'
import { resetContact, setAddAccount } from '../../store/slices/contactSlices'

const Contact = () => {
    // it will be changed after redux
    const dispatch = useDispatch();
    const [isContactsEmpty, setIsContactsEmpty] = useState(false)
    const [loading, setLoading] = useState(true);
    const [contacts, setContacts] = useState([]);
    const navigate = useNavigate();

    const getAllContacts = async () => {
        try {
            setLoading(true);
            const response = await ContactService.getContacts();
            if (!response?.data?.data?.contacts.length) {
                setIsContactsEmpty(true);
                return;
            }
            setContacts(response?.data?.data?.contacts)
        } catch (error) {
        } finally {
            setLoading(false);
            dispatch(resetContact());
            dispatch(setAddAccount(true))
        }
    }

    useEffect(() => {
        getAllContacts();
    }, [])

    if (loading) {
        return (
            <ContactLayout>
                <Typography color="default" type="largeheader" weight="bold">Contacts</Typography>
                <Box bg="white" mt={10} p={2} borderRadius={10}>
                    <Stack rowGap={5} bg={"white"} padding={6}>
                        {Array(4).fill(0).map((_, index) => (
                            <Skeleton height='60px' />
                        ))}

                    </Stack>
                </Box>
            </ContactLayout>
        )
    }


    if (isContactsEmpty) {
        return (
            <ContactLayout title="Contacts">
                <Box bg="white" p={4} borderRadius={10}>
                    <div className='no-contact'>
                        <Image src={EmptyIcon} />
                        <Heading as='h3' size='md'>
                            No Contacts added
                        </Heading>
                        <p style={{ marginBottom: "10px" }}>To send money start by adding contact</p>
                        <Button onClick={() => navigate("/contact/add")} variant={"primary"}>
                            Add Contact
                            <BiPlus style={{ marginLeft: "5px" }} />
                        </Button>
                    </div>
                </Box>

            </ContactLayout>

        )
    }
    return (
        <ContactLayout>
            <Flex justifyContent={"space-between"}>
                <Typography color="default" type="largeheader" weight="bold">Contacts</Typography>
                <Button onClick={() => navigate("/contact/add")} variant={"primary"}>
                    Add Contact
                    <BiPlus style={{ marginLeft: "5px" }} />
                </Button>
            </Flex>
            <Box bg="white" mt={10} p={4} borderRadius={10}>
                <ContactTable data={contacts} />
            </Box>
        </ContactLayout>
    )
}

export default Contact