import { Box, Heading } from "@chakra-ui/react";
import Typography from "../../components/Common/Typography";

export default function ContactLayout(props) {

    return (
        <Box px={8} py={5} bg={'#F2F2F2'} minH={'110vh'}>
            {
                props.title &&
                <Box mb={4}>
                <Typography color="default" type="largeheader" weight="bold">{props.title}</Typography>
                </Box>

            }
            <Box mb={4}>
            </Box>
            {props.children}
        </Box>
    )
}