import { Box, Button, Flex, HStack, Img, Stack, Text, VStack, useToast } from '@chakra-ui/react';
import React, { useState } from 'react';
import { CopyInputBox as CopyBox } from '../../../my-account/Prefund/WalletToWalletTransfer';
import Typography from '../../../../components/Common/Typography';
import { TbBulb } from 'react-icons/tb';
import { IoCopyOutline } from 'react-icons/io5';
import InfoIcon from '../../../../assets/InfoIcon.svg'
import { callApiWithToken } from '../../../../utils/http_common';
import { useNavigate } from 'react-router';

const CopyInputBox = ({ label, value, bg }) => {
    return (
        <Flex columnGap={2} w={"100%"} justifyContent={"space-between"} p={"18px 12px"} borderRadius={"4px"} bg={bg || "brand.secondary"} alignItems={"center"}>
            <Typography weight='medium' type='description' color='secondary' colorweight="800" >
                {label}
            </Typography>
            <Flex alignItems={"center"} width={"fit-content"}>
                <Typography weight='regular' type='description' color='headertext' >
                    {value}
                </Typography>
                <IoCopyOutline style={{ cursor: 'pointer', marginLeft: "10px" }} onClick={() => navigator.clipboard.writeText(value)} />
            </Flex>
        </Flex>

    )
}

const BankDetails = ({ beneficiaryDetails, type, orderDetails }) => {
    const [loading, setLoading] = useState(false);
    const [failLoading, setFailLoading] = useState(false);
    const { orderId } = orderDetails;
    const toast = useToast()
    const navigate = useNavigate()

    const confirmPayment = async (type) => {
        // call api to mock the webhook
        try {
            if (type === "success")
                setLoading(true);
            else
                setFailLoading(true);
            const response = await callApiWithToken.get(`/transaction/confirm?orderId=${orderId}&orderStatus=${type}`);

            if (response.data.success) {
                const data = response.data.data;
                toast({
                    title: "Order Initiated",
                    description: "Redirecting...",
                    status: "success",
                    position: 'top',
                    duration: 2000,
                    isClosable: true,
                })
                navigate('/customer/home/make-payment/summary', { state: { orderDetails } })
            }
        } catch (error) {
            toast({
                title: "Order Failed",
                description: "Please try again",
                status: "error",
                position: 'top',
                duration: 2000,
                isClosable: true,
            })
        } finally {
            if (type === "success")
                setLoading(true);
            else
                setFailLoading(true);
        }
    }

    const handleBackNavigation = () => {
        window.history.replaceState({}, '');
        navigate(-1);
    }
    return (
        <>
            {["sandbox"].includes(process.env.REACT_APP_NODE_ENV) ?

                <Box borderRadius={"9px"} bg='white' w='70%' p={"20px 40px"} mt={5}>
                    <HStack width={"100%"} align="center" >
                        <Img h={"300px"} src={process.env.PUBLIC_URL + "/transfi_logo.png"} alt='logo' />
                        <Typography weight='bold' type='bigtitle' color='primary' >
                            Sandbox Test Payment
                        </Typography>
                    </HStack>
                    <HStack gap={"30px"} width={"100%"} justifyContent={"flex-end"} display={"flex"} >
                        <Button isDisabled={loading} disabled={loading} onClick={handleBackNavigation} variant={"secondary"} size={"md"} type="button" p={"9px 16px 9px 16px"}>
                            Back
                        </Button>
                        <Button variant={"primary"} isLoading={loading} onClick={() => { confirmPayment("success") }} >
                            Proceed to Pay
                        </Button>
                        <Button variant={"danger"} isLoading={failLoading} onClick={() => { confirmPayment("fail") }} >
                            Proceed to Fail
                        </Button>
                    </HStack>
                </Box>
                :
                <Box bg='white' w='70%' p={"20px 40px"} borderRadius={"9px"} mt={5}>
                    <VStack mb={"20px"} align="flex-start" >
                        <Typography weight='medium' type='subtitle' color='primary' >
                            Send Money
                        </Typography>
                        <HStack>
                            <img src={InfoIcon} alt="icon" className='wa-information-icon' />
                            <Typography weight='regular' type='description' colorweight='800' >
                                Please make sure that these details are correct
                            </Typography>
                        </HStack>
                    </VStack>
                    <Box w={"100%"}>

                        {
                            beneficiaryDetails?.vaCode && <>
                                <Typography type='description' color='headertext' weight='medium' pb={8}>
                                    Please use the below payment code to transfer the amount
                                </Typography>

                                <Flex borderRadius={"9px"} padding={"16px"} justifyContent={"center"} rowGap={4} flexDirection={"column"} alignItems={"center"} bg={"brand.secondary"} w={"full"}>
                                    <Typography type='description'>
                                        Payment Code
                                    </Typography>
                                    <Text maxW={"60%"} textAlign={"center"} type='description'>
                                        {beneficiaryDetails?.vaCode}
                                    </Text>
                                </Flex>

                                <Flex borderRadius={"9px"} padding={"16px"} justifyContent={"center"} columnGap={2} alignItems={"start"} bg={"brand.secondary"} w={"full"}>
                                    <TbBulb width={"20px"} height={"20px"} />
                                    <Text fontSize={"14px"} color={"#282F40"}>
                                        Please ensure that you send the exact amount mentioned to avoid any discrepancies. The applicable fees may vary depending on the mode of transfer.
                                    </Text>
                                </Flex>
                            </>
                        }

                        {type === "bank_transfer" && !beneficiaryDetails?.vaCode && <>
                            <Typography type='description' color='headertext' weight='medium' pb={8}>
                                Beneficiary account details
                            </Typography>

                            <Stack gap={"12px"} w={"100%"}>
                                {
                                    beneficiaryDetails?.iban &&
                                    <CopyInputBox label="IBAN" value={beneficiaryDetails?.iban} />
                                }
                                <CopyInputBox label="Bank account holder" value={beneficiaryDetails?.number} />
                                <CopyInputBox label="BIC" value={beneficiaryDetails?.bic} />
                                <CopyInputBox label="Reference text" value={beneficiaryDetails?.referenceText} />
                                <CopyInputBox label="Address" value={beneficiaryDetails?.address} />
                            </Stack>
                        </>}

                        {type === "bank" && !beneficiaryDetails?.vaCode && <>
                            <Typography type='description' color='headertext' weight='medium' pb={8}>
                                Please scan the QR code or use the below details to transfer the amount
                            </Typography>


                            <Flex borderRadius={"9px"} padding={"16px"} justifyContent={"center"} rowGap={4} flexDirection={"column"} alignItems={"center"} bg={"brand.secondary"} w={"full"}>
                                <Typography type='description'>
                                    Scan this QR Code to make the transfer
                                </Typography>
                                <img style={{ margin: "auto", width: "147px", height: "147px" }} src={beneficiaryDetails?.imgSrc} alt="qr code" />
                            </Flex>

                            <Box padding={"16px 5px"} my={"10px"} mx={"auto"} width={"250px"}>
                                {/* <CopyBox label="Amount" value={`${quotes?.amountToPay || 0.00} ${currencyToPay?.symbol || ""}`} /> */}
                            </Box>

                            <Flex borderRadius={"9px"} padding={"16px"} justifyContent={"center"} columnGap={2} alignItems={"start"} bg={"brand.secondary"} w={"full"}>
                                <TbBulb width={"20px"} height={"20px"} />
                                <Text fontSize={"14px"} color={"#282F40"}>
                                    Please ensure that you send the exact amount mentioned to avoid any discrepancies. The applicable fees may vary depending on the mode of transfer.
                                </Text>
                            </Flex>
                        </>}
                    </Box>
                </Box>}
        </>
    );
};

export default BankDetails;



