import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useDispatch } from 'react-redux'
import { Box, Button, Flex, Icon, Img, Tbody, Td, Tr, VStack, TableContainer, Table, Text, Menu, MenuItem, MenuButton, MenuList, Image, Stack, Skeleton, Spinner } from '@chakra-ui/react'
import { FaArrowUp, FaEye } from "react-icons/fa";
import { BiPlus } from 'react-icons/bi';
import EmptyIcon from '../../assets/emptypage.svg';
import { useSearchParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';

import Typography from '../../components/Common/Typography'
import Loader from '../../utils/Loader'
import TransactionService from '../../services/transactionService';
import { setType } from '../../store/slices/AddMoneySlice';
import { STATUS_MAP, colorMapPrefund, colorMapPayout } from '../../constants';
import moment from 'moment';
import { IconLabel, lastStatusMapPayout } from './TransactionSummary';
import { CiWallet } from 'react-icons/ci';
import { callApiWithToken } from '../../utils/http_common';
import { isEmpty } from 'lodash';
import { currencyFormatter, formatDateTime } from '../../utils/formatter';
import FilterButton from './FilterButton';

const filterOptions = [
  { label: "All", value: "all" },
  { label: "Add Money", value: "prefund" },
  { label: "Individual Payment", value: "payout" },
];

export const finalTransactionType = (type) => {
  if (["fiat_to_crypto_prefund", "fiat_to_fiat_prefund", "crypto_to_crypto_prefund"].includes(type)) {
    return "prefunding"
  } else if (["fiat_payout", "crypto_payout"].includes(type)) {
    return "payout"
  } else if (type === "collections") {
    return "collections"
  }
}
const TableColumn = (props) => {
  const { currency, amount, depositAmount, depositCurrency, status, createdAT, handleClick, type, name, txId } = props
  const color = finalTransactionType(type) === "prefunding" ? colorMapPrefund[status || "initiated"] : colorMapPayout[lastStatusMapPayout(status) || "initiated"]

  const [buttonLoading, setButtonLoading] = useState(false)

  const confirmPayout = async (orderId) => {
    // call api to mock the webhook
    try {
      setButtonLoading(true)
      const response = await callApiWithToken.get(`/transaction/confirm?orderId=${orderId}&type=payout`);
      if (response.data.success) {
        const data = response.data.data;
      }
    } catch (error) {
    } finally {
      setButtonLoading(false);
    }
  };


  return (
    <Tr height={"90px"} py={20}>
      <Td>
        {["fiat_to_crypto_prefund", "fiat_to_fiat_prefund", "crypto_to_crypto_prefund"].includes(type) &&
          <IconLabel currency={currency} label="Add Money" icon={BiPlus} />
        }
        {type === "collections" && <IconLabel currency={currency} label="Collections" icon={CiWallet} />
        }
        {["fiat_payout", "crypto_payout"].includes(type) &&
          <IconLabel currency={`Txn Id - ${txId}`} label={`Payment to ${name || ""} (${currency || ""} acc)`} icon={FaArrowUp} />
        }
      </Td>

      <Td>
        <Flex width={"150px"} direction={"column"}>
          <Typography color='secondary' colorweight='800' textAlign="right" type='body'>
            {[`fiat_payout`, `crypto_payout`].includes(type) ? "- " : "+ "}
            {currency === "BTC" ? currencyFormatter(amount, 5) : currencyFormatter(amount) } {currency}
          </Typography>
          <Typography textAlign="right" weight='regular' type='caption' color='secondary'>
            {currency === "BTC" ? currencyFormatter(depositAmount, 5) : currencyFormatter(depositAmount)} {depositCurrency}
          </Typography>
          {/* <Typography textAlign="right" weight='regular' type='caption' color='secondary'>
            {balance} {currency}
          </Typography> */}
        </Flex>
      </Td>
      {/* <Td>
        <Flex justifyContent={"right"} w={"200px"} columnGap={2} alignItems={"center"}>
          <Box bg={colorMapPrefund[status || "initiated"]} width={"8px"} height={"8px"} borderRadius={"100%"}></Box>
          <Text textAlign={"right"} size={"14px"} color={colorMapPrefund[status || "initiated"]}>
            {STATUS_MAP[finalTransactionType(type)][status || "initiated"]}
          </Text>
        </Flex>
      </Td> */}

      {/* <Td>
        <Typography textAlign="right" type='description'> */}
      {/* {moment(createdAT).format("DD MMM YYYY - hh:mm A")} */}

      {/* </Typography> */}
      {/* <Typography textAlign="right" weight='regular' type='caption' color='secondary'>
            {balance} {currency}
          </Typography> */}
      {/* </Td> */}

      <Td>
        <Flex columnGap={2} alignItems={"center"}>
          <Flex direction={"column"}>
            <Typography type='description'>
              <Flex justifyContent={"right"} w={"200px"} columnGap={2} alignItems={"center"}>
                <Box bg={color} width={"8px"} height={"8px"} borderRadius={"100%"}></Box>
                <Text textAlign={"right"} size={"14px"} color={color}>
                  {finalTransactionType(type) === "prefunding" ? STATUS_MAP[finalTransactionType(type)][status || "initiated"] : STATUS_MAP[finalTransactionType(type)][type][lastStatusMapPayout(status) || "initiated"]}
                </Text>
              </Flex>
            </Typography>
            <Typography textAlign="right" weight='regular' type='caption' color='secondary'>
              {formatDateTime(createdAT)}
            </Typography>
          </Flex>
        </Flex>
      </Td>

      <Td>
        <Box display={"flex"} columnGap={2} justifyContent={"start"}>

          <Button onClick={handleClick} variant='outline' size='sm'>
            <Icon as={FaEye} />
          </Button>
          {["fiat_payout", "crypto_payout"].includes(type) && ["development", "sandbox"].includes(process.env.REACT_APP_NODE_ENV) && status === "fund_processing" &&
            <Button onClick={() => confirmPayout(txId)} variant='outline' size='sm' isLoading={buttonLoading}>
              Confirm Payout
            </Button>}
        </Box>
      </Td>
    </Tr>
  )
}

export default function Transaction() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [loader, setLoader] = useState(false);
  const [orders, setOrders] = useState([])
  const [searchParams] = useSearchParams();
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const status = searchParams.get('status');
  const currency = searchParams.get('currency');
  const type = searchParams.get('type') || 'all';

  const getOrders = async () => {
    try {
      setLoader(true);
      const response = await TransactionService.getPrefundTransactions({ type, status, currency, limit: 20, page })
      setOrders(prevData => [...prevData, ...response.data?.data?.transactions]);
      setHasMore(response?.data?.data?.transactions?.length > 0);
    } catch (error) {

    } finally {
      setLoading(false)
      setLoader(false);
    }
  }

  const fetchMoreData = () => {
    setPage(prevPage => prevPage + 1);
  };

  const handleEyeClick = (type, orderId) => {
    dispatch(setType(type))
    navigate(`/payout-transactions/summary/${orderId}`)
  }

  useEffect(() => {
    getOrders();
  }, [type, status, currency, page]); 

  useEffect(()=>{
    setPage(1);
    setOrders([]);
    setLoading(true);
  },[type])


  if (loading) return (
    <Box minH={"92vh"} p={"16px 40px"} bg={"#F2F2F2"}>
      <Typography type='largeheader' weight='bold' >
        Transactions
      </Typography>
      <Box bg='white' w='100%' mt={6} borderRadius={"9px"} p={"20px 40px"}>
        <Typography type='body' weight='medium' color='primary' >
          Recent Activity
        </Typography>
        <Stack borderRadius={"9px"} mt={"15px"} rowGap={5} bg={"white"}>
          {Array(4).fill(0).map((_, index) => (
            <Skeleton height='60px' />
          ))}
        </Stack>
      </Box>
    </Box>
  )

  if (isEmpty(orders)) return (
    <Box className='al-main-card'>
      <Typography type='largeheader' weight='bold' >
        Transactions
      </Typography>
      <Flex bg={'white'} width={'full'} minHeight={'100vh'} mt={5} rounded={'9px'} justify={'center'} alignItems={'center'} border={'1px'} borderColor={'#E4E4E4'} h={'full'} direction={'column'}>
        <Image src={EmptyIcon} mb={'2'} />
        <Typography color="secondary" colorweight='800' type="title" weight="medium">No Transactions yet</Typography>
      </Flex>
    </Box>
  );

  return (
    <Box minH={"92vh"} p={"16px 40px"} bg={"#F2F2F2"}>
      <Flex justifyContent='space-between' alignItems='center'>

        <Typography type='largeheader' weight='bold' >
          Transactions
        </Typography>
        <Box>
          <FilterButton queryKey={"type"} filterOptions={filterOptions} />
        </Box>
      </Flex>

      <VStack w={"100%"} marginTop={"15px"} gap='24px'>
        <Box borderRadius={"9px"} bg='white' w='100%' p={"20px 40px"}>
          <Typography type='body' weight='medium' color='primary' >
            Recent Activity
          </Typography>
          <InfiniteScroll
            dataLength={orders?.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={
              loader && (
                <Flex justify="center" align="center" py="4">
                  <Spinner size="md" />
                </Flex>
              )
            }
            scrollThreshold={0.7} 
            endMessage={
              <Box p="4" textAlign="center">
                <Text fontSize="md" color="gray.500">You have seen all transactions.</Text>
              </Box>
            }
            style={{ overflow: 'hidden' }} 
          >
            <Table>
              <Tbody>
                {orders?.map((item, index) => (
                  <TableColumn key={index} name={item?.recipientName?.firstName} type={item?.type} txId={item?.orderId} createdAT={item?.createdAt} currency={item?.withdrawCurrency} amount={Number(item.withdrawAmount || 0).toFixed(6)} depositAmount={item?.depositAmount} depositCurrency={item?.depositCurrency} status={item?.status}
                    handleClick={() => handleEyeClick(item?.type, item?.orderId)}
                  />
                ))}
              </Tbody>
            </Table>
          </ InfiniteScroll>
        </Box>
      </VStack>
    </Box>
  )
}
