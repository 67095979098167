import React, { useEffect, useState } from 'react';
import {
    Box,
    Flex,
    Circle,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';

import Typography from '../../components/Common/Typography';
import { formatDateTime } from '../../utils/formatter';


function formatDate(dateString) {
    return `${formatDateTime(dateString, 'hh:mm A')} | ${formatDateTime(dateString, 'DD MMM YYYY')}`;
}

const StepIcon = ({ status, step }) => {
    switch (status) {
        case 'completed':
            return <CheckIcon />;
        case 'inProgress':
            return step;
        default:
            return step
    }
};

const Step = (props) => {
    const { status, step } = props;

    const backgroundColor = () => {
        switch (status) {
            case 'completed':
                return "brand.green";
            case 'inProgress':
                return "brand.primary";
            case 'expired':
            case 'failed':
                return "brand.danger"; // Assuming there is a 'brand.red' in your theme for red color
            default:
                return "";
        }
    };

    const borderColor = () => {
        if (status === 'completed') {
            return "none";
        } else if (status === 'inProgress') {
            return "brand.primary";
        } else if (status === 'expired' || status === 'failed') {
            return "brand.danger"; // Use red for border when expired or failed
        } else {
            return "brand.grey.400";
        }
    };

    const textColor = () => {
        switch (status) {
            case 'completed':
            case 'inProgress':
            case 'expired':
            case 'failed':
                return "white";
            default:
                return "brand.grey.400";
        }
    }


    return (
        <Flex justifyContent={"center"} alignItems={"center"} w={"32px"} h={"32px"} border={status !== "completed" ? "1px solid" : "none"} borderColor={borderColor()} borderRadius={"50%"} direction="column">
            <Circle
                size="25px"
                bg={backgroundColor()}
                color={textColor()}
            >
                <StepIcon status={status} step={step} />
            </Circle>
        </Flex>
    );
}

const textColors = {
    completed: {
        color: 'secondary',
        colorweight: '400'
    },
    inProgress: {
        color: 'secondary',
        colorweight: '800'
    },
    pending: {
        color: 'secondary',
        colorweight: '400'
    },
    failed: {
        color: 'error',
        colorweight: '400'
    },
}

const PayoutStepper = ({ data }) => {
    const [steps, setSteps] = useState([
        { label: 'Payment Initiated', step: 1, description: 'Initiated', time: '', status: 'pending' },
        { label: 'Approval Pending', step: 2, description: 'Pending', time: '', status: 'pending' },
        { label: 'Delivery', step: 3, description: 'Pending', time: '', status: 'pending' }, // Description updated in updateSteps
        { label: 'Payment Successful', step: 4, description: 'Pending', time: '', status: 'pending' }
    ]);


    const updateSteps = (status, timestamps, payoutType) => {
        const initialSteps = [
            { label: 'Payment', step: 1, description: 'Initiated', time: '', status: 'pending' },
            { label: 'Approval', step: 2, description: 'Pending', time: '', status: 'pending' },
            { label: payoutType === "crypto_payout" ? "Crypto Delivery" : 'Payment', step: 3, description: payoutType === "crypto_payout" ? "Initiated" : 'Released', time: '', status: 'pending' },
            { label: 'Payment', step: 4, description: 'Successful', time: '', status: 'pending' }
        ];

        // Helper function to update individual step details
        const updateStep = (index, key, value) => {
            if (index < initialSteps.length) {
                initialSteps[index][key] = value;
            }
        };

        // Helper function to mark all steps up to a certain index as completed and update their times
        const completeStepsUntil = (index) => {
            for (let i = 0; i <= index; i++) {
                initialSteps[i].status = 'completed';
                if (i === 0 && timestamps.approvalPendingAt) {
                    updateStep(i, 'time', formatDate(timestamps.approvalPendingAt));
                }
                if (i === 1 && timestamps.approvedAt) {
                    updateStep(i, 'time', formatDate(timestamps.approvedAt));
                    updateStep(i, 'description', "Accepted");
                }
                if (i === 2 && (timestamps.assetSettledAt || timestamps.fundProcessingAt || timestamps.initiatedAt)) {
                    updateStep(i, 'time', formatDate(timestamps.assetSettledAt || timestamps.fundProcessingAt || timestamps.initiatedAt));
                }
                if (i === 3 && timestamps.fundSettledAt) {
                    updateStep(i, 'time', formatDate(timestamps.fundSettledAt));
                }
            }
        };

        switch (status) {
            case 'initiated':
                completeStepsUntil(0);
                updateStep(1, 'status', 'inProgress');
                break;
            case 'approval_pending':
                completeStepsUntil(0);
                updateStep(1, 'status', 'inProgress');
                break;
            case 'approved':
                completeStepsUntil(1);
                updateStep(2, 'status', 'inProgress');
                break;
            case 'fund_processing':
                completeStepsUntil(2);
                updateStep(3, 'status', 'inProgress');
                break;
            case 'asset_settled':
                completeStepsUntil(2);
                updateStep(2, 'description', payoutType === 'crypto_payout' ? 'Initiated' : 'Released');
                updateStep(3, 'status', 'inProgress');
                break;

            case 'asset_processing':
                completeStepsUntil(1);
                updateStep(2, 'description', payoutType === 'crypto_payout' ? 'Initiated' : 'Released');
                updateStep(2, 'status', 'inProgress');
                break;

            case 'fund_settled':
                completeStepsUntil(3);
                updateStep(3, 'status', 'completed');
                break;
            case 'rejected':
                completeStepsUntil(1);
                updateStep(1, 'status', 'failed');
                updateStep(1, 'description', 'Rejected');
                updateStep(1, "time", formatDate(timestamps?.rejectedAt))
                break;
            case 'asset_deposit_failed':
            case 'fund_failed':
                completeStepsUntil(2);
                updateStep(2, 'status', 'failed');
                updateStep(2, 'description', 'Failed');
                updateStep(2, "time", formatDate(timestamps?.fundFailedAt))

                break;
            default:
                console.log('Unhandled status:', status);
                break;
        }

        setSteps(initialSteps);
    };

    useEffect(() => {
        if (data?.status) {
            updateSteps(data.status, data.timestamps, data.type);
        }

    }, [data])


    return (
        <Box pl={6} h={"156px"} w={"100%"} pt={4}>

            <Flex mx={"auto"} columnGap={5}>
                {steps.map((step, index) => (
                    <Flex key={index} alignItems={"center"} flex={1} columnGap={5}>
                        <Step status={step.status} step={step.step} />
                        {index < steps.length - 1 && (
                            <Box flex="1" borderRadius={"24px"} height="3px" bg={step.status === "completed" ? "#26A17B" : "brand.greyPrimary"} />
                        )}
                    </Flex>
                ))}
            </Flex>
            <Flex mx={"auto"} mt={4} columnGap={5}>
                {steps.map((step, index) => (
                    <Flex rowGap={"60px"} key={index} alignItems={"start"} flex={1} columnGap={5}>
                        <Flex textAlign={"start"} flexDir={"column"}>

                            <Typography type="description" weight="bold" color={textColors[step.status].color} colorweight={step.status === "completed" && index === steps.length - 1 ? "800" : textColors[step.status].colorweight}>
                                {step.label}
                            </Typography>
                            <Typography type="description" weight="bold" color={textColors[step.status].color} colorweight={step.status === "completed" && index === steps.length - 1 ? "800" : textColors[step.status].colorweight}>
                                {step.description}
                            </Typography>
                            <Typography type="caption" color={step.status === "completed" ? "default" : textColors[step.status].color} colorweight={step.status === "completed" ? "default" : textColors[step.status].colorweight}>
                                {step.time}
                            </Typography>
                            {step.status === "inProgress" &&
                                <Typography type="caption" color="primary">
                                    &#40;In Progress&#41;
                                </Typography>
                            }
                        </Flex>
                    </Flex>
                ))}
            </Flex>

        </Box>

    );
};

export default PayoutStepper;