import React from 'react';
import { Button as CustomButton } from "@chakra-ui/react";

const normalButtonStyles = {
    width: "100%",
    height: "45px",
    bg: "#1A4FD6",
    borderRadius: "8px",
    color: "white",
    mt: "32px",
    fontSize: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
};
const googleButtonStyles = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "32px",
    padding: "14px 10px",
    gap: "20px",
    width: "100%",
    height: "45px",
    background: "#FFFFFF",
    boxShadow: "0px 0px 6.2069px rgba(31, 41, 55, 0.12)",
    borderRadius: "10px",
    fontFamily: "'DM Sans'",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "20px",
    color: "rgba(0, 0, 0, 0.54)",
};

function Button(props) {
    const { onClickHandler, className, styles = {}, isLoading = false } = props;
    let activeStyles = (className === "pa-button") ? normalButtonStyles : googleButtonStyles;

    activeStyles = ({
        ...activeStyles,
        ...styles,
    });

    return (
        <CustomButton
            {...activeStyles}
            onClick={() => onClickHandler()}
            isLoading={isLoading}
            {...props}
            _hover={{}}
            _loading={{
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
            }}
        >
            {props.children}
        </CustomButton>
    );
}

export default Button