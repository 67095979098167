import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import TransactionService from "../../services/transactionService";

export const getTransactionAction = createAsyncThunk(
    'transaction/getTransaction',
    async ({ filters }, { rejectWithValue, getState }) => {
        try {
            const resp = await TransactionService.getTransactionData({ filters })
            return resp?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const getPayoutOrderData = createAsyncThunk(
    'transaction/getPayoutOrderdata',
    async ({ filters }, { rejectWithValue, getState }) => {
        try {
            const resp = await TransactionService.getPayoutOrders({ filters });
            return resp?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const createIndividualTransaction = createAsyncThunk(
    'transaction/createIndividualTransaction',
    async (payload, { rejectWithValue }) => {
        try {
            const resp = await TransactionService.createIndividualTransaction(payload);
            return resp?.data;
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

const transactionSlice = createSlice({
    name: 'transaction',
    initialState: {
        list: [],
        loading: false,
        isSuccess: false,
        message: "",
        transactionId: "",
        type: "",
    },
    reducers: {
        setTransactionType: (state, action) => {
            state.type = action.payload;
        }
    },
    extraReducers: {
        [getTransactionAction.pending]: (state, action) => {
            state.loading = true;
            state.isSuccess = false;
        },
        [getTransactionAction.fulfilled]: (state, action) => {
            const { list } = action?.payload?.data;
            state.loading = false;
            state.list = list;
            state.isSuccess = true;
        },
        [getTransactionAction.rejected]: (state, action) => {
            state.loading = false;
            state.isSuccess = false;
        },
        [getPayoutOrderData.pending]: (state, action) => {
            state.loading = true;
            state.isSuccess = false;
        },
        [getPayoutOrderData.fulfilled]: (state, action) => {
            const { list } = action?.payload?.data || {};
            state.loading = false;
            state.list = list;
            state.isSuccess = true;
        },
        [getPayoutOrderData.rejected]: (state, action) => {
            state.loading = false;
            state.isSuccess = false;
        },
        [createIndividualTransaction.pending]: (state, action) => {
            state.loading = true;
            state.isSuccess = false;
        },
        [createIndividualTransaction.fulfilled]: (state, action) => {
            const { transactionId } = action?.payload?.data || {};
            state.loading = false;
            state.isSuccess = true;
        },
        [createIndividualTransaction.rejected]: (state, action) => {
            const { message = "Something went wrong" } = action?.payload?.error || {};
            state.loading = false;
            state.isSuccess = false;
            state.message = message;
        }
    }
})

export const { setTransactionType } = transactionSlice.actions

const { reducer: transactionReducer } = transactionSlice
export default transactionReducer;