import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { isEmpty } from 'lodash'
import { Box, Button, HStack, Img, VStack, useToast } from '@chakra-ui/react'

import InfoIcon from '../../../assets/InfoIcon.svg'

import Typography from '../../../components/Common/Typography'
import WalletToWalletTransfer from './WalletToWalletTransfer'
import BankTransfer from './BankTransfer'
import { callApiWithToken } from '../../../utils/http_common'
import { goToPeviousStep } from '../../../store/slices/AddMoneySlice'

export default function SendMoney() {
    const toast = useToast();
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const type = useSelector((state) => state.addMoney.type);
    const transferType = useSelector((state) => state.addMoney?.beneficiaryDetails?.type)
    const beneficiaryDetails = useSelector((state) => state.addMoney.beneficiaryDetails?.data)
    const orderIdAlt = useSelector((state) => state.addMoney.beneficiaryDetails.orderId);
    let orderId = orderIdAlt;

    const [loading, setLoading] = useState(false);
    const [failLoading, setFailLoading] = useState(false);

    const handleBack = () => {
        dispatch(goToPeviousStep());
    }

    const confirmPayment = async (type) => {
        // call api to mock the webhook
        try {
            if (type === "success")
                setLoading(true);
            else
                setFailLoading(true);

            if (transferType === "bank_transfer" && !isEmpty(beneficiaryDetails)) {
                const referenceText = beneficiaryDetails.referenceText;
                orderId = referenceText.slice(0, 2) + "-" + referenceText.slice(2);
            } else if (transferType === "bank" && !isEmpty(beneficiaryDetails)) {
                orderId = beneficiaryDetails.orderId;
            }

            const response = await callApiWithToken.get(`/transaction/confirm?orderId=${orderId}&orderStatus=${type}`);

            if (response.data.success) {
                const data = response.data.data;
                toast({
                    title: "Order Initiated",
                    description: "Redirecting...",
                    status: "success",
                    position: 'top',
                    duration: 2000,
                    isClosable: true,
                })

                navigate(`/payout-transactions/summary/${orderId}`)
            }
        } catch (error) {
            toast({
                title: "Order Failed",
                description: "Please try again",
                status: "error",
                position: 'top',
                duration: 2000,
                isClosable: true,
            })
        } finally {
            if (type === "success")
                setLoading(true);
            else
                setFailLoading(true);
        }
    }

    return (
        <>  {["sandbox"].includes(process.env.REACT_APP_NODE_ENV) && ["fiat_to_crypto", "fiat"].includes(type) ?
            <Box borderRadius={"9px"} bg='white' w='100%' p={"20px 40px"}>
                <HStack width={"100%"} align="center" >
                    <Img h={"300px"} src={process.env.PUBLIC_URL + "/transfi_logo.png"} alt='logo' />
                    <Typography weight='bold' type='bigtitle' color='primary' >
                        Sandbox Test Payment
                    </Typography>
                </HStack>
                <HStack gap={"30px"} width={"100%"} justifyContent={"flex-end"} display={"flex"} >
                    <Button isDisabled={loading} disabled={loading} onClick={handleBack} variant={"secondary"} size={"md"} type="button" p={"9px 16px 9px 16px"}>
                        Back
                    </Button>
                    <Button variant={"primary"} isLoading={loading} onClick={() => { confirmPayment("success") }} >
                        Proceed to Pay
                    </Button>
                    <Button variant={"danger"} isLoading={failLoading} onClick={() => { confirmPayment("fail") }} >
                        Proceed to Fail
                    </Button>
                </HStack>
            </Box> :
            <Box borderRadius={"9px"} bg='white' w='100%' p={"20px 40px"}>
                <VStack mb={"20px"} align="flex-start" >
                    <Typography weight='medium' type='subtitle' color='primary' >
                        Send Money
                    </Typography>
                    <HStack>
                        <img src={InfoIcon} alt="icon" className='wa-information-icon' />
                        <Typography weight='regular' type='description' colorweight='800' >
                            Please make sure that these details are correct
                        </Typography>
                    </HStack>
                </VStack>
                {type === "crypto" && <WalletToWalletTransfer />}
                {type === "fiat_to_crypto" && <BankTransfer />}
                {type === "fiat" && <BankTransfer />}
            </Box>
        }
        </>
    )
}
