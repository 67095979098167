import { Box, Button, Flex, Image, Input, InputGroup, InputLeftElement, Table, TableContainer, Tbody, Td, Tr, Icon, useDisclosure, Stack, Skeleton, Tooltip } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { SearchIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { MdOutlineModeEdit } from "react-icons/md";
import { BsInfoCircle, BsTrash } from "react-icons/bs";

import Typography from '../../components/Common/Typography';
import { getCustomer } from '../../store/slices/customerSlice';
import DeleteModal from './DeleteModal';

const Customer = () => {
    const [searchText, setSearchText] = useState('')
    const [filteredList, setFilteredList] = useState([])
    const [isAdmin, setIsAdmin] = useState(false)
    const [modalData, setModalData] = useState({})
    const [refreshData, setRefreshData] = useState(false)

    const navigate = useNavigate()

    const { isOpen, onOpen, onClose } = useDisclosure();

    const dispatch = useDispatch()

    const list = useSelector((state) => state.customer.list) || [];
    const loading = useSelector((state) => state.customer.loading) || false;
    const userDetails = useSelector((state) => state?.login?.userDetails)

    useEffect(() => {
        if (userDetails?.cxRoles.includes('admin')) {
            setIsAdmin(true)
        }
    }, [userDetails])

    useEffect(() => {
        dispatch(getCustomer())
    }, [])

    useEffect(() => {
        dispatch(getCustomer())
    }, [dispatch, refreshData])

    useEffect(() => {
        if (searchText && searchText.length > 0) {
            const filteredItems = list.filter(item => item.name && item.name.toLowerCase().includes(searchText?.toLowerCase()))
            setFilteredList(filteredItems)
        } else {
            setFilteredList([])
        }
    }, [searchText, setSearchText])

    const handleEditCustomer = (e) => {
        navigate('/customers/update-customer', { state: { customer: e } });
    }

    const handleDelete = (e) => {
        setModalData(e);
        onOpen();
    }

    return (
        <Box px={8} py={5} bg={'#F2F2F2'} minH={'100vh'} >
            <Flex justify={'space-between'} alignItems={'center'}>
                <Typography color="default" type="largeheader" weight="bold">Customers</Typography>
                <Flex justify={'flex-end'} alignItems={'center'} gap={3}>
                    <InputGroup>
                        <InputLeftElement pointerEvents='none'>
                            <SearchIcon color='brand.primary' />
                        </InputLeftElement>
                        <Input
                            placeholder="Search"
                            name="search"
                            id="search-input"
                            bg={'white'}
                            onChange={(e) => {
                                setSearchText(e.target.value);
                            }}
                        />
                    </InputGroup>

                    <Button
                        variant={"primary"}
                        bg={"#1A4FD6"}
                        _hover={{ bg: '#366cf5' }}
                        color="white"
                        width={'193px'}
                        height={'40px'}
                        rounded={'6px'}
                        fontSize={'14px'}
                        fontWeight={'400'}
                        onClick={() => navigate('/customers/add-customer')}
                    >
                        Add Customer  +
                    </Button>
                </Flex>
            </Flex>
            {list.length === 0 && <Flex bg={'white'} width={'full'} minHeight={'100vh'} mt={5} rounded={'9px'} justify={'center'} alignItems={'center'} border={'1px'} borderColor={'#E4E4E4'}>
                <Flex justify={'center'} alignItems={'center'} h={'full'} direction={'column'}>
                    <Image src='/assets/empty.svg' mb={'2'} />
                    <Typography color="secondary" colorweight='800' type="title" weight="medium">No customers added</Typography>
                    <Typography color="secondary" colorweight='800' type="description" weight="regular">Add a customer to start collecting money</Typography>
                    <Button
                        variant={"primary"}
                        bg={"#1A4FD6"}
                        _hover={{ bg: '#366cf5' }}
                        color="white"
                        width={'193px'}
                        height={'40px'}
                        rounded={'6px'}
                        fontSize={'14px'}
                        fontWeight={'400'}
                        onClick={() => navigate('/customers/add-customer')}
                        mt={4}
                    >
                        Add Customer  +
                    </Button>
                </Flex>
            </Flex>}

            {list.length > 0 &&
                <Box bg={'white'} width={'full'} minHeight={'100vh'} mt={5} rounded={'9px'} border={'1px'} borderColor={'#E4E4E4'} px={'20px'}>
                    {loading ?
                        <Stack rowGap={5} bg={"white"} padding={6}>
                            <Skeleton height='60px' />
                            <Skeleton height='30px' />
                            <Skeleton height='30px' />
                            <Skeleton height='30px' />
                            <Skeleton height='30px' />
                        </Stack> :

                        <TableContainer mt={10} height={'70vh'} overflowY={'scroll'} position={'relative'}>
                            <Table variant='simple'>
                                <Tbody >
                                    <Tr position={'sticky'} zIndex={2} top={0} bg={'#FFFFFF'}>
                                        <Td>
                                            <Typography color="secondary" colorweight="400" type='body' weight='medium'>Name</Typography>
                                        </Td>
                                        <Td>
                                            <Typography color="secondary" colorweight="400" type='body' weight='medium'>Type</Typography>
                                        </Td>
                                        <Td>

                                        </Td>

                                    </Tr>
                                    {(filteredList.length > 0 ? filteredList : list)?.map((e, index) =>
                                        <Tr key={index}>
                                            <Td>
                                                <Flex gap={4}>
                                                    <Image src={e?.countryLogo} w={'38px'} />
                                                    <Box>
                                                        <Typography color="secondary" colorweight="800" type='description' weight='medium'>{e.name}</Typography>
                                                        <Typography color="secondary" type='caption' weight='regular'>{e.email}</Typography>
                                                    </Box>
                                                </Flex>
                                            </Td>
                                            <Td>
                                                <Typography color="secondary" colorweight="800" type='description' weight='medium'>{e.type}</Typography>
                                            </Td>
                                            <Td>
                                                <Flex justify={'left'} align={'center'} gap={5}>
                                                    <Button h={'32px'} size={'sm'} bg={'#FFFFFF'} border={"1px"} borderColor={"#F2F2F2"} rounded={'6px'} _hover={{ cursor: 'pointer' }} onClick={() => handleEditCustomer(e)} isDisabled={!isAdmin} >
                                                        <Icon as={MdOutlineModeEdit} color={'#1A4FD6'} />
                                                    </Button>

                                                    <Button h={'32px'} size={'sm'} bg={'#FFFFFF'} border={"1px"} borderColor={"#F2F2F2"} rounded={'6px'} _hover={{ cursor: 'pointer' }} onClick={() => handleDelete(e)} isDisabled={!isAdmin}>
                                                        <Icon as={BsTrash} color={'red.400'} />
                                                    </Button>

                                                    {(e?.status || "active") === "manual_review" &&
                                                        <Tooltip hasArrow label='Compliance check in progress. Please check back in 24 hours, Collections from this customer will be halted in the interim' bg='grey.800' color={"black"} arrowSize={15} placement='top' >
                                                            <Button h={'32px'} size={'sm'} bg={'#FFFFFF'} border={"1px"} borderColor={"#F2F2F2"} rounded={'6px'} >
                                                                <Icon as={BsInfoCircle} color={'red.400'} />
                                                            </Button>
                                                        </Tooltip>
                                                    }
                                                </Flex>
                                            </Td>
                                        </Tr>
                                    )}
                                </Tbody>
                            </Table>
                        </TableContainer>}
                </Box>}
            <DeleteModal onClose={onClose} isOpen={isOpen} data={modalData} setRefreshData={setRefreshData} />
        </Box >
    );
};

export default Customer;
