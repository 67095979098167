import * as Yup from 'yup';

export const collectionOrderValidationSchema = Yup.object().shape({
    amount: Yup.string().required('Amount is required'),
    currency: Yup.string().required('Currency is required'),
    refId: Yup.string(),
    paymentPurpose: Yup.object().required('Purpose of transaction is required'),
});

export const collectionOrderPartialValidationSchema = Yup.object().shape({
    amount: Yup.string().required('Amount is required'),
    currency: Yup.string().required('Currency is required'),
    refId: Yup.string().required('Reference Id is required'),
    paymentPurpose: Yup.object().required('Purpose of transaction is required'),
    linkValidity: Yup.object().required('Link validity is required'),
});

export const selectCustomerValidationSchema = Yup.object().shape({
    customer: Yup.object().required('Select a customer')
})