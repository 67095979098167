import { useEffect, useState } from 'react';
import { HStack, VStack, Stack, Button, Img } from '@chakra-ui/react';
import { useDispatch, useSelector } from "react-redux";

// Common Components and Function
import Card from '../../../components/Common/Card';
import Typography from '../../../components/Common/Typography';
import { getContact, goToPreviousStep, setAddAccount } from "../../../store/slices/contactSlices";
import ViewCard from './ViewCard';

// css and logos
import '../Contact.css';
import { FaPlus } from "react-icons/fa6";
import { useFormikContext } from 'formik';

const WalletViewCard = (props) => {
  const dispatch = useDispatch();
  const {resetForm} = useFormikContext();
  const handleAddAccount = () => {
    // setAddAccount(true);
    resetForm();
    dispatch(setAddAccount(true));
  };

  const { name: displayName } = useSelector((state) => state.contact).values;
  const contactDetails = useSelector((state) => state.contact.contactDetails);
  const paymentAccountList = useSelector((state) => state.contact?.paymentAccounts) || [];

  const cryptoWalletType = paymentAccountList?.filter((item) => item.type === "crypto_wallet") || [];
  const bankType = paymentAccountList?.filter((item) => item.type === "bank") || [];
  const walletType = paymentAccountList?.filter((item) => item.type === "local_wallet") || [];
  console.log("paymentAccountList: ", paymentAccountList)


  const handleBack = () => {
    dispatch(goToPreviousStep());
  };

  useEffect(() => {
    if(paymentAccountList.length >= 0) {
      return;
    }
    if (contactDetails?.contact?.recipientId) {
      dispatch(getContact(contactDetails?.contact?.recipientId || ""));
    }
  }, [])

  return (
    <Card>
      <VStack align="stretch" spacing="40px" w={"100%"}>
        <HStack display={"flex"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"} >
          <HStack>
            <Typography weight='medium' type='body' color='primary' >
              Account Details
            </Typography>
            <div className='ad-line' ></div>
            <Img src={process.env.PUBLIC_URL + contactDetails?.contact?.logo} borderRadius={"50px"} border={"1px solid #F5F5F5"} w={"20px"} h={"20px"} />
            <Typography weight='medium' type='description' color='secondary' >
              {contactDetails?.contact?.firstName}
            </Typography>
          </HStack>
          <Button variant={"primary"} size={"md"} onClick={() => handleAddAccount()} >
            Add Account
            <FaPlus className='icon-add-account' />
          </Button>
        </HStack>
        <HStack maxH={"60vh"} overflowY={"auto"} display={"flex"} alignItems={"flex-start"} justifyContent={"flex-start"} gap={"100px"} >
          {
            cryptoWalletType?.length > 0 &&
            <VStack align="flex-start" >
              <Typography weight='regular' type='description' color='secondary' colorweight='800' >
                Digital Asset Wallets
              </Typography>
              {
                cryptoWalletType?.map((item) => {
                  return <ViewCard paId={item.paId} type='Wallet' walletName={item?.displayName} currrencyImage={item?.logo} currency={`${item?.metaData?.symbol || item?.currency} (${item?.metaData?.network || item?.currency})`} accountNumber={item?.metaData?.number} />
                })
              }
            </VStack>
          }
          {
            walletType?.length > 0 && <VStack align="flex-start" >
              <Typography weight='regular' type='description' color='secondary' colorweight='800' >
                Wallets
              </Typography>
              {
                walletType?.map((item) => {
                  return <ViewCard paId={item.paId} counterPartyStatus={item.counterPartyStatus} type='Wallets' walletName={item?.displayName} currrencyImage={item?.logo} currency={`${item?.currency} (${item?.bankName})`} accountNumber={item?.metaData?.number} />
                })
              }
            </VStack>
          }
          {
            bankType.length > 0 &&
            <VStack align="flex-start" >
              <Typography weight='regular' type='description' color='secondary' colorweight='800' >
                Bank Accounts
              </Typography>
              {
                bankType?.map((item) => {
                  return <ViewCard paId={item.paId} type='Bank Account' counterPartyStatus={item.counterPartyStatus} walletName={item?.displayName} deleteHandler={""} currrencyImage={item?.logo} currency={`${item?.currency} (${item?.metaData?.bankName || item?.bankName})`} accountNumber={item?.metaData?.number} />
                })
              }
            </VStack>
          }
          {
            paymentAccountList.length === 0 &&
            <Typography type='body' color='secondary' >
                No Accounts Found!
            </Typography>
          }

        </HStack>
        {/* <Stack direction="row" justifyContent={"end"} w='100%' marginTop={0}>
          <Button variant={"secondary"} size={"md"} type="button" onClick={handleBack}>
            Back
          </Button>
          <Button variant={"primary"} type="submit" size={"md"}>
            Next
          </Button>
        </Stack> */}
      </VStack>
    </Card>
  );
};

export default WalletViewCard;