import React, {  useState } from 'react';
import { Formik } from 'formik';
import { Box, Button, Stack, useToast, Grid, GridItem} from '@chakra-ui/react';
import { CustomSelectFieldSearch, TextField } from '../../contact/add-contact/InputField';
import { callApiWithToken } from '../../../utils/http_common';

import RecipientService from '../../../services/recipientService';

export default function AddWallet(data,setWalletAddress){
    
    const [selectedCryptoOption,setSelectedCryptoOption] = useState({});
    const [network,setNetwork] = useState([]);
    const [selectedNetworkOption,setSelectedNetworkOption] = useState({});
    const [loading, setLoading] = useState(false);

    const Crypto = [
        {
            label:"USDC",
            logo:"/assets/crypto/usdc.svg",
            value:"USDC",
        },
        {
            label:"USDT",
            logo:"/assets/crypto/usdt.svg",
            value:"USDT"
        }
    ];
    const toast =useToast();
    const handleToast = (title, type) => {
        toast({
            title,
            status: type,
            duration: 5000,
            isClosable: true
        });
    };
    const initialValues = {
        network :"",
        walletAddress: "",
        crypto: "",

    }
    async  function handleSubmit(values){
        const validation =  await RecipientService.postWalletAddress({
            address:values.walletAddress,
            cryptoTicker:values.crypto,
            cryptoNetwork:values.network});
        if(validation?.response?.status === 400){
            handleToast( 'Invalid Wallet Address', 'error');
        }
        else{
            const payload ={
                walletAddress:values.walletAddress,
                formattedSymbol:values.network,
            }
            const response = await callApiWithToken.post(`/setting/addWalletDetails`,{ ...payload });
            setWalletAddress(values.walletAddress);
        }

    }

    return(
        <Box px={1} py={5} bg={'#white'} >
            <Box bg={"white"} px={'20px'} py={'20px'} border={'0px'} borderColor={'#E4E4E4'} rounded={'9px'} mt={5}>
            <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validateOnChange={false}
                >
                {({ isSubmitting, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <Box>
                        <Grid templateColumns={{ md: 'repeat(1, 1fr)', lg: 'repeat(2, 1fr)' }} gap={8}>
                            <GridItem maxW={'386px'}>
                                <CustomSelectFieldSearch EmptyMenuLabel={"No Crypto Available"} label={"Select Crypto to Settle"} options={Crypto||[]} name="crypto" placeholder="Select " selectedOption={selectedCryptoOption} setSelectedOption={setSelectedCryptoOption} data ={data?.data} setNetwork={setNetwork} />        
                            </GridItem>
                            <GridItem maxW={'386px'}>
                                <CustomSelectFieldSearch EmptyMenuLabel={"No Network Available"} label={"Select Network"} options={network||[]} name="network" placeholder="Select " selectedOption={selectedNetworkOption} setSelectedOption={setSelectedNetworkOption}/>        
                            </GridItem>
                            <GridItem maxW={'386px'}>
                            <TextField label="Wallet Address" name="walletAddress" placeholder="Enter WalletAddress" required={true} />
                            </GridItem>
                        </Grid>
                        </Box>
                        <Stack pt={8} bg={"white"} direction="row" gap={"20px"} justifyContent={"end"} w='100%' marginTop={0}>
                            <Button  variant={"secondary"} size={"sm"} type="button" p={"9px 16px 9px 16px"}>
                                Back
                            </Button>
                            <Button type='submit' textColor={'white'} bg={'#1A4FD6'} size={"sm"} p={"9px 16px 9px 16px"} isLoading={loading} _hover={{ bg: '#1A4FD6' }}>
                                Add
                            </Button>
                        </Stack>
                    </form>
                )}
                </Formik>
            </Box>
        </Box>
    )
}