import React, { useState } from 'react';
import MFASetup from '../login/MFASetup';
import VerifyMFA from '../login/VerifyMFA';
import CustomerSignup from './CustomerSignUp';

const CustomerSignUpFlow = (props) => {
    const { setToken } = props || {};
    const [userEmail, setUserEmail] = useState(false);
    const [mfaStatus, setMfaStatus] = useState(false);
    const [password, setPassword] = useState("");
    return (

        <>
            {!userEmail && <CustomerSignup setMfaStatus={setMfaStatus} setUserEmail={setUserEmail} setUserPassword={setPassword} />}
            {(userEmail && !mfaStatus) && <MFASetup email={userEmail} setMfaStatus={setMfaStatus} />}
            {(userEmail && mfaStatus) && <VerifyMFA email={userEmail} password={password} redirectLogin={true} setToken={setToken} />}
        </>

    );
};

export default CustomerSignUpFlow;