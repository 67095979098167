import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Box, Button, HStack, Stack } from '@chakra-ui/react';
import { BiReset } from 'react-icons/bi';
import moment from 'moment';

import GridV2 from '../../../../utils/agTablesV2';
import OrderService from '../../../../services/createOrderService';
import Loader from '../../../../components/Loader';

const PayinSettlement = () => {

    const [refreshGrid, setRefreshGrid] = useState(false);
    const orgId = useSelector((state) => state?.login?.orgDetails?.orgId);
    const isLoading = useSelector((state) => state?.order?.loading);

    const getSettlementData = async (filters) => {
        filters["orgId"] = orgId;
        filters["type"] = "payin";
        const responseData = await OrderService.getSettlementData({ filters });
        return responseData;
    }

    const handleReset = () => {
        setRefreshGrid(!refreshGrid);
    };

    const columnsAgTable = [
        {
            headerName: "Fiat Currency",
            field: "currency",
            getQuickFilterText: params => {
                return params.value.name;
            },
            customerVisibility: true,
            width: '250',
        },
        {
            headerName: "Total collections amount",
            field: "payin.totalAmount",
            cellDataType: 'number',
            getQuickFilterText: params => {
                return params.value.name;
            },
            aggFunc: 'sum',
            customerVisibility: true,
            width: '250',
        },
        {
            headerName: "Total payout amount",
            field: "payout.sentAmount",
            cellDataType: 'number',
            getQuickFilterText: params => {
                return params.value.name;
            },
            aggFunc: 'sum',
            customerVisibility: true,
            width: '250',
        },
        {
            headerName: "Total payout fees",
            field: "payoutFees",
            cellDataType: 'number',
            getQuickFilterText: params => {
                return params.value.name;
            },
            aggFunc: 'sum',
            customerVisibility: true,
            width: '250',
        },
        {
            headerName: "Total settled amount",
            field: "settlement.totalAmount",
            cellDataType: 'number',
            getQuickFilterText: params => {
                return params.value.name;
            },
            aggFunc: 'sum',
            customerVisibility: true,
            width: '250',
        },
        {
            headerName: "Total unsettled amount",
            field: "balance.availableBalance",
            cellDataType: 'number',
            aggFunc: 'sum',
            getQuickFilterText: params => {
                return params.value.name;
            },
            customerVisibility: true,
            width: '250',
        }
    ];

    return (
        <Stack
            direction={"column"}
            justifyContent={"space-between"}
            flexGrow={1}
            p={5}
        >

            {!isLoading ? <Box >
                <HStack pb={5} justifyContent={"flex-end"}>
                    <Button
                        leftIcon={<BiReset style={{ fontSize: "20px" }} />}
                        color={"#525C76"}
                        variant={"outline"}
                        borderColor={"#d2d2d2"}
                        _hover={{ bg: "#ffffff" }}
                        onClick={handleReset}
                    >
                        Reset
                    </Button>
                </HStack>
                <GridV2 columns={columnsAgTable} key={refreshGrid.toString()} getOrderData={getSettlementData} />
            </Box> : <Loader />}
        </Stack>
    );
};

export default PayinSettlement;