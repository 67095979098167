import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { Box, Flex, HStack, Skeleton, SkeletonText } from '@chakra-ui/react';

import "../my-account.css"

import Typography from '../../../components/Common/Typography';
import { currencyFormatter } from '../../../utils/formatter';
import CURRENCY_DECIMALS from './currencyConfig';

const getDecimalPlaces = (symbol) => CURRENCY_DECIMALS[symbol] || CURRENCY_DECIMALS.DEFAULT;
const Column = ({ type, label, value }) => {
    return (
        <HStack w={"100%"} display={"flex"} alignItems={"center"} justifyContent={'space-between'} >
            <Typography type='description' weight='regular' color='secondary' >
                {label}
            </Typography>
            <Typography textAlign={"right"} type={type || 'description'} weight='regular' color='headertext' >
                {value}
            </Typography>
        </HStack>
    )
}
const ColumnLoading = ({ type, label, value }) => {
    return (
        <HStack w={"100%"} display={"flex"} alignItems={"center"} justifyContent={'space-between'} >
            <Typography type='description' weight='regular' color='secondary' >
                {label}
            </Typography>
            <Typography textAlign={"right"} type={type || 'description'} weight='regular' color='headertext' >
                <Skeleton w={"100px"} height='20px' />
            </Typography>
        </HStack>
    )
}

const LoadingSkeleton = () => {
    return (
        <Flex className='summary-side-card' flexDirection={"column"} >
            <Typography type='title' weight='bold' color='headertext' >
                Summary
            </Typography>
            <Box display={"flex"} flexDirection={'column'} gap={'12px'} >
                <ColumnLoading type='description' label='You get' value={<Skeleton mt='4' noOfLines={1} spacing='4' skeletonHeight='2' />} />
                <HStack w={"100%"} display={"flex"} justifyContent={'flex-end'} >
                    <Typography type='description' weight='regular' color='primary' textDecoration={"underline"} cursor={"pointer"} >
                        Show fee
                    </Typography>
                </HStack>
                <Box border={"1px solid #B3B3B3"} w={"100%"}  ></Box>
            </Box>
            <ColumnLoading type='title' label='You pay' value={<SkeletonText mt='4' noOfLines={1} spacing='4' skeletonHeight='2' />} />
        </Flex>
    )
}

export default function SummaryBox(props) {
    const { selectedCurrency, currencyToPay } = useSelector((state) => state?.addMoney);
    const [showQuotes, setShowQuotes] = useState(false)
    // const { loading, data: quotes } = useSelector((state) => state?.addMoney?.quotes);
    const loading = useSelector((state) => state?.addMoney?.quotes?.loading);
    const quotes = useSelector((state) => state?.addMoney?.quotes?.data);
    if (loading) return <LoadingSkeleton />;
    return (
        <Flex className='summary-side-card-prefund' style={{ minWidth: "none" }} flexDirection={"column"}>
            <Typography type='title' weight='bold' color='headertext' >
                Summary
            </Typography>
            <Box display={"flex"} flexDirection={'column'} gap={'12px'} >
                {(currencyToPay?.type === "fiat" && selectedCurrency.type !== "fiat") && (
                    <Column type='description' label='' value={`1 ${selectedCurrency.label} = ${quotes?.cryptoPrice || 0} ${currencyToPay.symbol}`} />
                )}
                <Column type='description' label='You get' value={`${currencyFormatter(quotes?.amountToReceive, getDecimalPlaces(selectedCurrency.label))} ${selectedCurrency.label || ""}`} />
                <HStack w={"100%"} display={"flex"} justifyContent={'flex-end'} >
                    <Typography type='description' weight='regular' color='primary' textDecoration={"underline"} cursor={"pointer"} onClick={() => { setShowQuotes((prev) => !prev); }} >
                        {showQuotes ? "Hide fee" : "Show fee"}
                    </Typography>
                </HStack>
                {showQuotes &&
                    <>
                   <Column type='description' label='Processing Fee' value={`${currencyFormatter(quotes?.processingFee, getDecimalPlaces(currencyToPay?.symbol))} ${currencyToPay?.symbol || ""}`} />
                        {currencyToPay?.type === "fiat" && (
                            <>
                                <Column type='description' label='Network Fee' value={`${currencyFormatter(quotes?.networkFee)} ${currencyToPay?.symbol || ""}`} />
                                <Column type='description' label='Total Fee' value={`${currencyFormatter(quotes?.totalFee)} ${currencyToPay?.symbol || ""}`} />
                            </>
                                              )}
                    </>
                }
                <Box border={"1px solid #B3B3B3"} w={"100%"}  ></Box>
            </Box>
            <Column type='title' label='You pay' value={`${currencyFormatter(quotes?.amountToPay, getDecimalPlaces(currencyToPay?.symbol))} ${currencyToPay?.symbol || ""}`} />
        </Flex>
    )
}
