import React, { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Box, Flex, Image, Text, useToast } from "@chakra-ui/react";

//custom components and fucntions
import OTPInputBoxes from "./OTPInputBoxes";
import Loader from "../../utils/Loader";
import { callApi } from "../../utils/http_common";
import { verifyUser } from "../../store/slices/loginSlices";
import TransfiLogo from "../../assets/transfi-logo.svg";
import BackButtton from "../../components/LoginComponents/BackButton";

//css
import "./Login.css"
import "./PasswordLogin.css"


const VerifyMFA = (props) => {
  const toast = useToast();

  const { email, password, setOTPVerified, redirectLogin = false, setToken } = props || {};
  const [otp, setOtp] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const dispatch = useDispatch();

  const handleToast = (title, type) => {
    toast({
      title,
      status: type,
      duration: 5000,
      isClosable: true,
    });
  };

  const verifyAccount = useCallback((encryptedPassword, userEmail) => {
    dispatch(verifyUser({ email: userEmail, accountDetails: encryptedPassword }));
  }, [dispatch]);

  const verify = async (email, password) => {
    try {
      const encryptedPassword = password;
      verifyAccount(encryptedPassword, email);
    } catch (e) {
      handleToast(e?.message || "Something went wrong", 'error');
      setIsLoading(false);
    }
  };

  const verifyOtp = async () => {
    if (otp.length < 6) {
      handleToast("Please Enter Valid OTP", "error");
      return;
    }
    try {
      setIsButtonLoading(true);
      const { data } = await callApi.post(`/customer/verify2FA`, { email, otp, needLogin: true });
      const { otpVerified = false, message, tokenData } = data?.data;

      if (otpVerified) {
        setIsLoading(true);
        if (redirectLogin) {
          await verify(email, password);
        } else {
          setOTPVerified(otpVerified);
          setIsLoading(false);
        }
      } else {
        throw new Error(message);
      }
      handleToast("MFA Successfully Verified", 'success');
    } catch (e) {
      handleToast(e?.response?.data?.error?.message || "Something went wrong", 'error');
      setIsButtonLoading(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (otp.length === 6) {
      verifyOtp();
    }
  }, [otp]);

  return (
    isLoading ? <Loader zIndex={"0"} /> :
      <Flex bg={'white'} h={'full'} w={'full'}>
        <Box bg={'white'} h={"full"} w={'50%'}>
          <Box>
            <div className="pa-login-sub-card">
              <div className="pa-login-header" style={{ marginBottom: '-50px' }} >
                <BackButtton />
                <img src={TransfiLogo} alt="logo" />
                <span style={{ fontWeight: '500' }} >Verify 2FA</span>
              </div>
              <div className="pa-login-body" >
                <p className="pa-login-sub-heading">Enter Code from your two factor authenticator APP</p>
                <OTPInputBoxes setOtp={setOtp} />
                <p className="pa-login-sub-heading" style={{ marginTop: '32px', color: '#1A4FD6' }}>To reset 2FA please contact TransFi support</p>
              </div>
            </div>
          </Box>
        </Box>
        <Flex className="gradient-background" flexDirection={'column'} justify={'space-between'} w={'full'} maxH={'100vh'} overflow={'hidden'}>
          <Box ml={'80px'} mt={'100px'}>
            <Text color={'#092C4C'} fontFamily={'DM Sans'} fontWeight={'700'} fontSize={'48px'}>Borderless <Text as={'span'} color={'#1A4FD6'}>payments</Text>,</Text>

            <Text color={'#092C4C'} fontFamily={'DM Sans'} fontWeight={'700'} fontSize={'48px'}>boundless <Text as={'span'} color={'#1A4FD6'}>potential</Text></Text>
          </Box>
          <Flex w={'full'} justify={'flex-end'}>
            <Image src="/assets/login.png" maxH={'809px'} maxW={'992px'} />
          </Flex>
        </Flex>
      </Flex>
  );
};

export default VerifyMFA;