import { Badge, Stack, Text, Box, HStack, Button, useDisclosure } from "@chakra-ui/react";
import { startCase, round } from 'lodash';
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { HiOutlineFilter } from "react-icons/hi";
import { BiReset } from 'react-icons/bi';
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme For AgTables

import OrderFilter from "../OrderFilter";
import OrderService from '../../../services/createOrderService';
import { getOrders } from "../../../store/slices/orderSlices";
import CustomTable from "../../../utils/customTable";
import { statusMap, colorMap } from "../../../constants";
import Loader from "../../../components/Loader";
import GridV2 from "../../../utils/agTablesV2";
import SummaryTable from "../SummaryTable";

const PayinOrders = (props) => {
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [refreshGrid, setRefreshGrid] = useState(false);
  const orderData = useSelector((state) => state?.order?.orderDetails);
  const orgId = useSelector((state) => state?.login?.orgDetails?.orgId);
  const isLoading = useSelector((state) => state?.order?.loading);
  const orgType = useSelector((state) => state?.login?.orgDetails?.orgType || "default");
  const [summaryData, setSummaryData] = useState([]);
  const navigate = useNavigate();

    const USformatter = new Intl.NumberFormat('en-US');

    useEffect(() => {
        if (orgType === "ecom" || orgType === "invoice")
            getOrdersData()
    }, []);

    const getOrdersData = useCallback(() => {
        dispatch(getOrders({ filters: { orgId, type: "payin" } }))
	}, [dispatch]);
	
    const redirectToCreateOrder = () => {
        window.history.replaceState(null, '', '');
        navigate("/payin/create-order", { replace: true });
    }
    
    const getOrderData = async (filters) => {
        filters["orgId"] = orgId;
        filters["type"] = "payin";
        const responseData = await OrderService.getOrder({ filters });
        setSummaryData(responseData.data.data.summaryArray);
        return responseData;
    }

  var filterParams = {
      comparator: (filterLocalDateAtMidnight, cellValue) => {
          var dateAsString = cellValue;
          if (dateAsString == null) return -1;
          var cellDate = new Date(cellValue);
          if (filterLocalDateAtMidnight.toLocaleDateString() === cellDate.toLocaleDateString()) {
              return 0;
          }
          if (cellDate < filterLocalDateAtMidnight) {
              return -1;
          }
          if (cellDate > filterLocalDateAtMidnight) {
              return 1;
          }
          return 0;
      },
      minValidYear: 2000,
    };

    const handleReset = () => {
        setRefreshGrid(!refreshGrid);
    };

  const summaryColumns = [
    {
      headerName: "Currency",
      field: "fiatTicker",
      width: '200',
      headerClass: 'center-column',
      flex: 1,
      cellRenderer: ({ value }) => (
        <Text>{value ? value : "N/A"}</Text>
      )
    },
    {
      headerName: "Total Count",
      field: "count",
      width: '200',
      headerClass: 'center-column',
      flex: 1,
      cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : '0'}</Text>
      )
    },
    {
      headerName: "Sum Amount Received",
      field: "fiatAmount",
      width: '200',
      headerClass: 'center-column',
      flex: 1,
      cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : '0'}</Text>
      )
    },
    {
      headerName: "Sum Processing Fee",
      field: "processingFeesAmountFiat1",
      width: '200',
      headerClass: 'center-column',
      flex: 1,
      cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : '0'}</Text>
      )
    },
    {
      headerName: "Sum Net Amount",
      field: "netAmount",
      width: '200',
      headerClass: 'center-column',
      flex: 1,
      cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : '0'}</Text>
      )
    },
  ];
  const columnsCustomTable = [
    {
      Header: "Order ID",
      accessor: "orderId",
	    customerVisibility: true,
      width: '200',
      Cell: ({ value }) => {
          return <Link to={`/order/pay-in-order/${value}`} style={{ color: "#1A4FD6", fontWeight: "bold", fontSize: '14px' }} >{value}</Link>;
      },
    },
    {
      Header: "Order Status",
      accessor: "status",
      customerVisibility: true,
      Cell: ({ value }) => {
        const newStatus = statusMap[value] || value;
        const color = colorMap[value];
        return <Badge colorScheme={color}>{newStatus}</Badge>;
      },
    },
    {
      Header: "Initiated Date",
      accessor: "createdAt",
      customerVisibility: true,
      width: '220',
      Cell: ({ value }) => (
        <Text>{moment(value).format("DD-MMM-YYYY @ h:mm A")}</Text>
      ),
    },
    {
      Header: "Updated Date",
      accessor: "timestamps.fundSettledAt",
      customerVisibility: true,
      width: '220',
      Cell: ({ value }) => (
        <Text>{moment(value).format("DD-MMM-YYYY @ h:mm A")}</Text>
      ),
    },
    {
      Header: "Total Transactions",
      accessor: "recipientCount",
      customerVisibility: true,
      width: '200'
    },
    {
      Header: "Currency",
      accessor: "fiatTicker",
      customerVisibility: true,
    },
    {
      Header: "Crypto",
      accessor: "cryptoTicker",
      customerVisibility: true,
    },
    {
      Header: "Fiat Amount",
      accessor: "fiatAmount",
      customerVisibility: true,
      Cell: ({ value }) => (
        <Text>{value ? USformatter.format(value) : '0'}</Text>
    )},
  ];
    const columnsAgTable = [
    {
      headerName: "Order ID TransFi",
      field: "orderId",
      getQuickFilterText: params => {
        return params.value.name;
      },
      customerVisibility: true,
      width: '250',
      cellRenderer: ({ value }) => {
        return <Link style={{ color: "#1A4FD6", fontWeight: "bold", fontSize: '14px' }} >{value}</Link>;
      },
    },
    {
      headerName: "Order Status",
      field: "status",
      customerVisibility: true,
      enableRowGroup: true,

      filterParams: {
        applyMiniFilterWhileTyping: true,
      },
      cellRenderer: ({ value }) => {
        const newStatus = statusMap[value] || value;
        const color = colorMap[value];
        return <Badge width={"100%"} textAlign={"center"} colorScheme={color}>{newStatus}</Badge>;
      },
    },
    // { headerName: "Order ID Customer", field: "customer.orgId", customerVisibility: true, width: '200' },
    {
      headerName: "Settlement Type", field: "type", customerVisibility: false, cellRenderer: ({ value }) => (
        <Text>{'Manual'}</Text>
      )
    },
    {
      headerName: "Settlement Status",
      field: "settlementStatus",
      customerVisibility: true,
      enableRowGroup: false,
      filterParams: {
        applyMiniFilterWhileTyping: true,
      },
      cellRenderer: ({ value }) => {
        if (value === 'settled') {
          return <Badge width={"100%"} textAlign={"center"} colorScheme={'green'}>{value}</Badge>;
        }
        return <Badge width={"100%"} textAlign={"center"} colorScheme={'red'}>Unsettled</Badge>;
      },
    },
    {
      headerName: "Customer Name", field: "customer.formattedName", enableRowGroup: true, customerVisibility: false, width: '200', cellRenderer: ({ value }) => (
        <Text>{value ? startCase(value) : ''}</Text>
      )
    },
    { headerName: "Customer User ID/Email", field: "customer.email", enableRowGroup: true, customerVisibility: false, width: '300' },
    { headerName: "Sender Name", field: "senderName.firstName", customerVisibility: true, width: '200' },
    { headerName: "Sender Email", field: "email", enableRowGroup: true, customerVisibility: true, width: '200' },
    {
      headerName: "Purpose of Transaction", field: "purposeCode", enableRowGroup: true, customerVisibility: true, cellRenderer: ({ value }) => (
        <Text>{value ? startCase(value) : ''}</Text>
      )
    },
    {
      headerName: "Nature of Sender", field: "senderDetails.type", enableRowGroup: true, customerVisibility: true, cellRenderer: ({ value }) => (
        <Text>{value ? startCase(value) : ''}</Text>
      )
    },
    {
      headerName: "Nature of Recipient", field: "", enableRowGroup: true, customerVisibility: false, cellRenderer: ({ value }) => (
        <Text>{value ? startCase(value) : 'N/A'}</Text>
      )
    },
    { headerName: "Jurisdiction of Sender", field: "userCountry", enableRowGroup: true, customerVisibility: true, width: '200' },
    { headerName: "Jurisdiction of Recipient", field: "", enableRowGroup: true, customerVisibility: false, width: '200', cellRenderer: ({ value }) => (
        <Text>{value ? startCase(value) : 'N/A'}</Text>
    )},
    {
      headerName: "Initiated Date",
      field: "createdAt",
      filterParams: filterParams,
      enableRowGroup: false,
      customerVisibility: true,
      width: '220',
      cellDataType: 'date',
      filter: 'agDateColumnFilter',
      cellRenderer: ({ value }) => (
        <Text>{moment(value).format("DD-MMM-YYYY @ h:mm A")}</Text>
      ),
    },
    {
      headerName: "Updated Date",
      field: "updatedAt",
      filter: 'agDateColumnFilter',
      filterParams: filterParams,
      enableRowGroup: false,
      customerVisibility: true,
      width: '220',
      cellDataType: 'date',
      cellRenderer: ({ value }) => (
        <Text>{moment(value).format("DD-MMM-YYYY @ h:mm A")}</Text>
      ),
    },
    {
      headerName: "Fiat Payment Partner", field: "paymentGateway", customerVisibility: false, cellRenderer: ({ value }) => (
        <Text>{value ? startCase(value) : ''}</Text>
      )
    },
    {
      headerName: "Fiat Payment Partner Order ID", field: "fiat.orderId", customerVisibility: false, width: '200', cellRenderer: ({ value }) => (
        <Text>{value}</Text>
      )
    },
    {
      headerName: "Sender's Payment Method", field: "paymentFormattedName", customerVisibility: true, cellRenderer: ({ value }) => (
        <Text>{value ? startCase(value) : ''}</Text>
      )
    },
    { headerName: "Sender's Currency", field: "fiatTicker", customerVisibility: true, width: '200' },
    {
      headerName: "Gross Fiat Amount",
      field: "fiatAmount",
      customerVisibility: true,
      cellDataType: 'number',
      filter: 'agNumberColumnFilter',
      cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : '0'}</Text>
      )
    },
    {
      headerName: "Processing Fees %",
      field: "processingFeesPercentage",
      customerVisibility: false,
      aggFunc: 'avg',
      cellDataType:'number',
      filter: 'agNumberColumnFilter',
      cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value * 100) + '%' : '0'}</Text>
      )
      },
      {
        headerName: "Fixed Fees",
        field: "fixedFeeDetails",
        customerVisibility: false,
        cellRenderer: ({ value }) => (
          <Text>{value ? value : 'N/A'}</Text>
        )
      },
    {
      headerName: "Processing Fees Amount", field: "processingFeesAmountFiat1", aggFunc: 'sum', cellDataType:'number', customerVisibility: true, filter: 'agNumberColumnFilter', cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : '0'}</Text>
      )
    },
    {
      headerName: "Net Amount",
      field: "netAmount",
      customerVisibility: true,
      aggFunc: 'sum',
      cellDataType:'number',
      filter: 'agNumberColumnFilter',
      cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : '0'}</Text>
      )
    },
    {
      headerName: "Payment Partner Fees",
      field: "paymentPartnerFeesFiat1",
      filter: 'agNumberColumnFilter',
      cellDataType: 'number',
      customerVisibility: false,
      cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : '0'}</Text>
      )
    },
    {
      headerName: "Sub-Account Receipt Date", field: "subAmountReceiptDate", customerVisibility: false, cellDataType: 'date', cellRenderer: ({ value }) => (
        <Text>{value ? value : "N/A"}</Text>
      )
    },
    {
      headerName: "Conversion Rate to USD",
      field: "conversionRateToUsd",
      filter: 'agNumberColumnFilter',
      cellDataType: 'number',
      customerVisibility: false,
      cellRenderer: ({ value }) => (
        <Text>{value ? round(value, 8) : '0'}</Text>
      )
    },
    {
      headerName: "GTV",
      field: "gtv",
      customerVisibility: false,
      aggFunc: 'sum',
      cellDataType: 'number',
      filter: 'agNumberColumnFilter',
      cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : '0'}</Text>
      )
    },
    {
      headerName: "Amount Received in Sub-Account",
      field: "amountReceivedSubAccountUsd",
      aggFunc: 'sum',
      filter: 'agNumberColumnFilter',
      cellDataType: 'number',
      customerVisibility: false,
      cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : '0'}</Text>
      )
    },
    {
      headerName: "Crypto to be sold if zero forex markup",
      field: "cryptoSoldIfZeroForexMarkup",
      aggFunc: 'sum',
      filter: 'agNumberColumnFilter',
      cellDataType: 'number',
      customerVisibility: false,
      cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : '0'}</Text>
      )
    },
    {
      headerName: "Conversion rate charged by TransFi",
      field: "conversionRatesChargedTransfi",
      filter: 'agNumberColumnFilter',
      cellDataType: 'number',
      customerVisibility: false,
      cellRenderer: ({ value }) => (
        <Text>{value ? round(value, 8) : '0'}</Text>
      )
    },
    {
      headerName: "Crypto To Be Sold",
      field: "cryptoToBeSold",
      aggFunc: 'sum',
      customerVisibility: false,
      filter: 'agNumberColumnFilter',
      cellDataType: 'number',
      cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : '0'}</Text>
      )
    },
    {
      headerName: "Revenue from Forex", field: "revenueFromForex", aggFunc: 'sum', customerVisibility: false, filter: 'agNumberColumnFilter', cellDataType: 'number', cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : '0'}</Text>
      )
    },
    {
      headerName: "Crypto Payment Partner", field: "crypto.partner", customerVisibility: false, cellRenderer: ({ value }) => (
        <Text>{value ? startCase(value) : 'N/A'}</Text>
      )
    },
    {
      headerName: "Crypto Partner Order ID", field: "crypto.orderId", customerVisibility: false, cellRenderer: ({ value }) => (
        <Text>{value ? value : 'N/A'}</Text>
      )
    },
    {
      headerName: "Digital Asset Sent",
      field: "crypto.orderAmount",
      cellDataType: 'number',
      customerVisibility: true,
      filter: 'agNumberColumnFilter',
      cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : 'N/A'}</Text>
      )
    },
    {
      headerName: "Crypto Ticker", field: "crypto.currency", customerVisibility: false, cellRenderer: ({ value }) => (
        <Text>{ value ? value : 'N/A'}</Text>
      )
    },
    {
      headerName: "Gas Fees Actual", field: "", customerVisibility: false, filter: 'agNumberColumnFilter', cellDataType: 'number', cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : 'N/A'}</Text>
      )
    },
    {
      headerName: "Net (of gas fees) Crypto paid", field: "", filter: 'agNumberColumnFilter', aggFunc: 'sum', customerVisibility: false, cellDataType: 'number', cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : 'N/A'}</Text>
      )
    },
    {
      headerName: "Receiver's Crypto Wallet Address", field: "walletAddress", customerVisibility: true, cellRenderer: ({ value }) => (
        <Text>{value ? value : "N/A"}</Text>
      )
    },
    {
      headerName: "Transaction hash", field: "metaData.txnIdForCryptoReceived[0]", customerVisibility: true, cellRenderer: ({ value }) => (
        <Text>{value ? value : "N/A"}</Text>
      )
    },
  ];

    return (
        <Stack
            direction={"column"}
            justifyContent={"space-between"}
            flexGrow={1}
            p={5}
        >

            {!isLoading ? <Box >
				<HStack p={5} justifyContent={"flex-end"}>
					{
						orgType === "invoice" ?
							<Button
								color={"#ffffff"}
								variant={"outline"}
								backgroundColor={"#1a4fd6"}
								borderColor={"#1a4fd6"}
								_hover={{ bg: "#1a4fd6" }}
								onClick={redirectToCreateOrder}
							>
								Create Order
							</Button> :
							null
                    }
                    {
                        (orgType === "ecom" || orgType === "invoice") ?
                            <Button
                                leftIcon={<BiReset style={{ fontSize: "20px" }} />}
                                color={"#525C76"}
                                variant={"outline"}
                                borderColor={"#d2d2d2"}
                                _hover={{ bg: "#ffffff" }}
                                onClick={getOrdersData}
                            >
                                Reset
                            </Button> :
                            <Button
                                leftIcon={<BiReset style={{ fontSize: "20px" }} />}
                                color={"#525C76"}
                                variant={"outline"}
                                borderColor={"#d2d2d2"}
                                _hover={{ bg: "#ffffff" }}
                                onClick={handleReset}
                            >
                                Reset
                            </Button>
                    }
                    
                    {
                        (orgType === "ecom" || orgType === "invoice") ?
                            <Button
                                leftIcon={<HiOutlineFilter style={{ fontSize: "20px" }} />}
                                color={"#525C76"}
                                variant={"outline"}
                                borderColor={"#d2d2d2"}
                                _hover={{ bg: "#ffffff" }}
                                onClick={onOpen}
                            >
                                Filter
                            </Button> :
                            null
                    }
                    
                </HStack>
                {
					(orgType === "ecom" || orgType === "invoice") ?
                        <CustomTable columns={columnsCustomTable} data={orderData} /> :
                        <>
                            <GridV2 columns={columnsAgTable} key={refreshGrid.toString()} getOrderData={getOrderData} />
                            <SummaryTable summaryColumns={summaryColumns} data={summaryData} />
                        </>
                }
                <OrderFilter isOpen={isOpen} onClose={onClose} type={"payin"} />
            </Box> : <Loader />}
        </Stack>
    );
};

export default PayinOrders;