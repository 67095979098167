import { toLower } from "lodash";
import { Formik } from "formik";
import { MdOutlineModeEdit } from "react-icons/md";
import React, { useState, useEffect } from "react";
import { CustomSelectFieldSearch, TextField } from "../../contact/add-contact/InputField";
import { Box, Button, Flex, Icon, Table, TableContainer, Tbody, Td, Tr, Image, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useToast } from '@chakra-ui/react';

import { callApiWithToken } from "../../../utils/http_common";
import Typography from '../../../components/Common/Typography';
import RecipientService from '../../../services/recipientService';
import AddWallet from "./AddWallet";

export default function WalletDetails() {

    const [loading, setLoading] = useState(false);
    const [crypto, setCrypto] = useState("");
    const [selectedOption, setSelectedOption] = useState({ label: "Select" });
    const [walletAddress, setWalletAddress] = useState(null);
    const [network, setNetwork] = useState("");
    const [config, setConfig] = useState({});
    const [logo, setLogo] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedCryptoOption, setSelectedCryptoOption] = useState({});
    const [networkOption, setNetworkOption] = useState([]);
    const [selectedNetworkOption, setSelectedNetworkOption] = useState({});
    const [finalValues, setFinalvalues] = useState({});
    const cryptoOptions = [
        {
            label: "USDC",
            logo: "/assets/crypto/usdc.svg",
            value: "USDC",
        },
        {
            label: "USDT",
            logo: "/assets/crypto/usdt.svg",
            value: "USDT"
        }
    ];
    const toast = useToast();
    const handleToast = (title, type) => {
        toast({
            title,
            status: type,
            duration: 5000,
            isClosable: true
        });
    };
    useEffect(() => {
        const fetchData = async () => {
            const response = (await callApiWithToken.get(`/setting/walletDetails`))?.data || {};
            setWalletAddress(response?.data?.walletAddress);
            if (walletAddress) {
                setLogo(`/assets/crypto/${toLower(response?.data?.crypto)}.svg`);
                setCrypto(response?.data?.crypto);
                setNetwork(response?.data?.network);
                setSelectedCryptoOption({
                    value: crypto,
                    label: crypto,
                    logo: `/assets/crypto/${toLower(crypto)}.svg`
                });
                setSelectedNetworkOption({
                    value: network,
                    label: network,
                    logo: `/assets/crypto/${toLower(network)}.svg`
                })
                const filtereddata = config.filter(item => item.symbol === crypto).map(({ network: value, logo, network: label }) => ({ value, logo, label }));
                setNetworkOption(filtereddata);
            }
            setConfig(response?.data?.config);
        }
        fetchData();
    }, [walletAddress, logo]);
    const initialValues = {
        walletAddress: walletAddress,
        crypto: crypto,
        network: network,
    }
    async function handleSubmit(values) {
        try {
            const validation = await RecipientService.postWalletAddress({
                address: values.walletAddress,
                cryptoTicker: values.crypto,
                cryptoNetwork: values.network
            });
            if (validation?.response?.status === 400) {
                handleToast('Invalid Wallet Address', 'error');
                setIsModalOpen(false);
                setFinalvalues(initialValues);
                return;
            }
            const response = await callApiWithToken.post(`/setting/updateWalletDetails`, {
                walletAddress: values.walletAddress,
                formattedSymbol: values.network,
            });
            if (response?.status !== 200) throw response;
            handleToast('Updated Wallet Details', 'success');
            setWalletAddress(values.walletAddress);
            setCrypto(values.crypto);
            setNetwork(values.network);
            setLogo(`/assets/crypto/${toLower(values.crypto)}.svg`);
            setLoading(false);
            setIsModalOpen(false);
        } catch (error) {
            handleToast('Error Occurred while updating wallet details', 'error');
            setIsModalOpen(false);
        }
    }
    const handleEditClick = () => {
        setIsModalOpen(true);
    };

    return (
        <Box py={10}>
            <Flex alignItems={"center"} justify={'space-between'}>
                <Box>
                    <Typography color="primary" colorweight="500" type='body' weight='medium'>Wallet Details</Typography>
                    <Typography color="secondary" colorweight="800" type='caption' weight='regular'>The money collected from your customers will be settled to the wallet you add here</Typography>
                </Box>
            </Flex>
            {!walletAddress ? <AddWallet data={config} setWalletAddress={setWalletAddress} /> :
                <TableContainer mt={10} height={'70vh'} overflowY={'scroll'} position={'relative'}>
                    <Table variant='simple'>
                        <Tbody >
                            <Tr position={'sticky'} zIndex={2} top={0} bg={'#FFFFFF'}>
                                <Td>
                                    <Typography color="secondary" colorweight="400" type='body' weight='medium'>Wallet</Typography>
                                </Td>
                                <Td>
                                    <Typography color="secondary" colorweight="400" type='body' weight='medium'>Wallet Address</Typography>
                                </Td>
                                <Td>
                                    <Typography color="secondary" colorweight="400" type='body' weight='medium'>Status</Typography>
                                </Td>
                            </Tr>
                            <Tr >
                                <Td>
                                    <Flex gap={4}>
                                        <Image src={logo} w={'38px'} />
                                        <Box>
                                            <Typography color="secondary" colorweight="800" type='description' weight='medium'>{crypto}</Typography>
                                            <Typography color="secondary" type='caption' weight='regular'>{network}</Typography>
                                        </Box>
                                    </Flex>
                                </Td>
                                <Td>
                                    <Typography color="secondary" colorweight="800" type='description' weight='medium'>{walletAddress}</Typography>
                                </Td>
                                <Td>
                                    <Typography color="secondary" colorweight="800" type='description' weight='medium'>{"Active"}</Typography>
                                </Td>
                                <Td>
                                    <Flex justify={'center'} align={'center'} gap={5}>
                                        <Button h={'32px'} size={'sm'} bg={'#FFFFFF'} border={"1px"} borderColor={"#F2F2F2"} rounded={'6px'} _hover={{ cursor: 'pointer' }} onClick={handleEditClick} >
                                            <Icon as={MdOutlineModeEdit} color={'#1A4FD6'} />
                                        </Button>
                                    </Flex>
                                </Td>
                            </Tr>
                        </Tbody>
                    </Table>
                </TableContainer>
            }
            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <ModalOverlay />
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validateOnChange={false}
                    setFinalvalues={setFinalvalues}
                >
                    {({ isSubmitting, handleSubmit }) => (
                        <form onSubmit={handleSubmit}>

                            <ModalContent>
                                <ModalHeader align="center" colorScheme="#E8EDFB">Wallet Details</ModalHeader>
                                <ModalCloseButton />
                                <ModalBody>
                                    <CustomSelectFieldSearch EmptyMenuLabel={"No Crypto Available"} label={"Select Crypto to Settle"} options={cryptoOptions || []} name="crypto" placeholder="Select " selectedOption={selectedCryptoOption} setSelectedOption={setSelectedCryptoOption} data={config} setNetwork={setNetworkOption} />
                                    <CustomSelectFieldSearch EmptyMenuLabel={"No Network Available"} label={"Select Network"} options={networkOption || []} name="network" placeholder="Select " selectedOption={selectedNetworkOption} setSelectedOption={setSelectedNetworkOption} />
                                    <TextField label="Wallet Address" name="walletAddress" placeholder="Enter WalletAddress" required={true} />
                                </ModalBody>
                                <ModalFooter>
                                    <Button type="submit" colorScheme="blue" mr={3} onClick={() => handleSubmit} isLoading={loading}>
                                        Save
                                    </Button>
                                    <Button variant="ghost" onClick={() => setIsModalOpen(false)}>Cancel</Button>
                                </ModalFooter>
                            </ModalContent>

                        </form>
                    )}
                </Formik>
            </Modal>
        </Box>
    );
}