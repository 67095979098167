import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Grid, GridItem, VStack, useDisclosure, useToast } from '@chakra-ui/react'
import { Formik } from 'formik'
import { isEmpty } from 'lodash'

import "../my-account.css"

import { createPrefundOrder, getWalletAddress, goToNextStep, setShowBanks, setType } from '../../../store/slices/AddMoneySlice'
import Typography from '../../../components/Common/Typography'
import SummaryBox from './SummaryBox'
import Details from './Details'
import SendMoney from './SendMoney'
import { sendMoneyDetailsValidationSchema } from './validationSchema'
import DocumentIdCopForm from './DocumentIdCopForm'
import BankDetailsModal from './Models/BankDetailsModal'
import MxnIdentificationDetailsFormDialog from './Models/MxnIdentificationDetailsFormDialog'

const initialValue = {
    currency: "",
    amount: "",
    network: "",
    idNumber: "",
    clabe: "",
    // bank: "",
}
export default function Prefund() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isOpenBankDetailsModal, onOpen: onOpenBankDetailsModal, onClose: onCloseBankDetailsModal } = useDisclosure();
    const { isOpen: isOpenMxnDocumentDialog, onOpen: onOpenMxnDocumentDialog, onClose: onCloseMxnDocumentDialog } = useDisclosure();

    const toast = useToast();
    const dispatch = useDispatch();

    const quotes = useSelector((state) => state.addMoney.quotes?.data);
    const step = useSelector((state) => state.addMoney.step)
    const selectedCurrency = useSelector((state) => state.addMoney.selectedCurrency)
    const selectedBank = useSelector(state => state.addMoney.selectedBank);
    const [selectedNetworkOption, setSelectedNetworkOption] = useState({
        label: "Select Network",
    });
    const [dataForBankDetailsModal, setDataForBankDetailsModal] = useState({ clabe: '', concepto: '', beneficiaryName: '', amount: 0, currency: "", orderId: "" });
    const [selectedOption, setSelectedOption] = useState({
        value: JSON.stringify({
            symbol: selectedCurrency?.value,
            type: selectedCurrency?.type
        }),
        label: selectedCurrency?.label,
        logo: selectedCurrency?.logo,
        type: selectedCurrency?.type
    })

    function renderStepComponent(step) {
        switch (step) {
            case 0:
                return <Details selectedOption={selectedOption} setSelectedOption={setSelectedOption} selectedNetworkOption={selectedNetworkOption} setSelectedNetworkOption={setSelectedNetworkOption} />
            case 1:
                return <SendMoney />

            default:
                return <Details />
        }
    }


    const handleSubmit = (values, actions) => {
        if (quotes.errorCode) {
            actions.setErrors({ amount: quotes.message })
            actions.setSubmitting(false);
            actions.setTouched({ amount: true })
            return;
        }
        if (step === 2) {
            return;
        }
        actions.setTouched({});
        actions.setSubmitting(false);
        const formattedCurrency = JSON.parse(values.currency);
        if (formattedCurrency.type === "crypto" && selectedCurrency.type === "crypto" && formattedCurrency.symbol === selectedCurrency.value) {
            dispatch(setType("crypto"))
            dispatch(getWalletAddress({ currency: formattedCurrency.symbol, network: values.network,amount: values.amount })).then(res => res?.payload).then(res => {
                if (!res || res.status === false) {
                    toast({
                        title: "Something Went Wrong",
                        status: "error",
                        position: 'top',
                        duration: 2000,
                        isClosable: true,
                    })
                    return;
                }
                dispatch(goToNextStep());
            })
        } else if ((formattedCurrency.type === "fiat" && selectedCurrency.type === "crypto") || (formattedCurrency.type === "fiat" && selectedCurrency.type === "fiat")) {

            dispatch(setType("fiat_to_crypto"))
            if (!selectedBank?.value) {
                dispatch(setShowBanks(true))
                return;
            }
            let identificationDetailsRequired = formattedCurrency.symbol === "MXN" ? true : false;
            if(formattedCurrency.symbol === "COP" && !values.idNumber){
                onOpen();
                return;
            } else if (formattedCurrency.symbol === "MXN" && (isEmpty(values.idNumber) || isEmpty(values.clabe))) {
                onOpenMxnDocumentDialog();
                return;
            }
            onClose();
            dispatch(createPrefundOrder({
                "amount": values.amount,
                "currency": formattedCurrency.symbol,
                "paymentCode": selectedBank?.value,
                "withdrawCurrency": selectedCurrency.value,
                "type": selectedCurrency.type === "crypto" ? "crypto" : "fiat",
                "redirectUrl":window.location.origin,
                "idNumber": values.idNumber,
                ...(identificationDetailsRequired ? {identificationDetails: {
                    idNumber: values.idNumber,
                    number: values.clabe,
                    idType: "CURP"
                }} : {}),
            })).then(res => res?.payload).then(res => {
                const { error = {} } = res?.data || {};
                if (!isEmpty(error)) {
                    toast({
                        title: error?.message || "Something went wrong",
                        status: "error",
                        position: 'top',
                        duration: 2000,
                        isClosable: true,
                    });
                    return;
                }
                if (!isEmpty(res?.data?.bankDetails)) {
                    const { externalOrderId, countryCode, redirectType = '', payUrl = '', htmlForm = '',
                        identificationDetails = [], orderId, qrCode = "", imgSrc = "", showCheckout = false,
                        vaCode = '', bankTransferDetails = undefined, redirectPage = "customer" } = res?.data?.bankDetails;
                    
                    if(!isEmpty(payUrl)){
                        window.open(res?.data?.bankDetails?.payUrl, "_blank");
                        return;
                    } else if (bankTransferDetails) {
                        toast({
                            title: "Prefund Order Created Successfully",
                            status: "success",
                            position: 'top',
                            duration: 2000,
                            isClosable: true,
                        });
                        setDataForBankDetailsModal({
                            clabe: bankTransferDetails?.clabe,
                            concepto: bankTransferDetails?.concepto,
                            beneficiaryName: bankTransferDetails?.beneficiaryName,
                            amount: values.amount,
                            currency: formattedCurrency.symbol,
                            orderId
                        })
                        onOpenBankDetailsModal();
                        return;
                    } else if (isEmpty(imgSrc) && isEmpty(vaCode)) {
                        // Show the toast only if all specified properties are missing
                        toast({
                            title: "Something Went Wrong",
                            status: "error",
                            position: 'top',
                            duration: 2000,
                            isClosable: true,
                        });
                        return;
                    }
                
                }
                dispatch(goToNextStep());
            })
        }
    }

    return (
        <Box className='sm-main-card' p={"16px 40px"} bg={"#F2F2F2"}>
            <Typography type='largeheader' weight='bold' >
                Add Money
            </Typography>

            <VStack w={"100%"} marginTop={"15px"} gap='24px'>
                <Formik
                    initialValues={initialValue}
                    onSubmit={handleSubmit}
                    validateOnChange={false}
                    validationSchema={sendMoneyDetailsValidationSchema}
                >
                    {({ isSubmitting, handleSubmit }) => (
                        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                            <Grid templateColumns='repeat(3, 1fr)'
                                gap={8}
                            >
                                <GridItem colSpan={2}>
                                    {renderStepComponent(step)}
                                </GridItem>
                                <DocumentIdCopForm isOpen={isOpen} onClose={onClose} />
                                <MxnIdentificationDetailsFormDialog isOpen={isOpenMxnDocumentDialog} onClose={onCloseMxnDocumentDialog} />
                                <BankDetailsModal
                                    onClose={onCloseBankDetailsModal}
                                    isOpen={isOpenBankDetailsModal}
                                    data={
                                        {
                                            clabe: dataForBankDetailsModal.clabe,
                                            concepto: dataForBankDetailsModal.concepto,
                                            beneficiaryName: dataForBankDetailsModal.beneficiaryName,
                                            amount: dataForBankDetailsModal.amount,
                                            currency: dataForBankDetailsModal.currency,
                                            orderId: dataForBankDetailsModal.orderId,
                                        }
                                    }
                                />
                                <GridItem maxWidth={"410px"}>
                                    <SummaryBox />
                                </GridItem>
                            </Grid>

                        </form>
                    )}
                </Formik>
            </VStack>
        </Box>
    )
}
