import React, { useCallback, useEffect, useState } from "react";
import { Box, Flex, Image, Text, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//Custom Components and functions
import { createOrganization } from "../../store/slices/loginSlices";
import { callApi } from "../../utils/http_common";
import Loader from "../../components/Loader";
import Button from "../../components/LoginComponents/Button";
import BackButton from "../../components/LoginComponents/BackButton";
import ProgressBar from "../../components/LoginComponents/ProgressBar";
import Input from "../../components/LoginComponents/InputComponents/TextField";

//css
import "./Signup.css";

//icons
import TransfiLogo from "../../assets/transfi-logo.svg";

const FillDetails = (props) => {
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const accountCreated = useSelector(state => state.login?.loginDetails?.created || false);
  const message = useSelector(state => state.login?.loginDetails?.altmessage || false);
  const error = useSelector(state => state.login?.loginDetails?.alterror || false);
  const customerId = useSelector(state => state.login?.loginDetails?.cxId || false);
  const orgExists = useSelector(state => state.login?.loginDetails?.exist || false);

  const [isLoading, setIsLoading] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [companyDetails, setCompanyDetails] = useState({ companyName: '', linkedinUrl: '', websiteUrl: '', country: '' });
  const [signupCheck, setSignupCheck] = useState({ newsletter: false, toc: false });
  const [countries, setCountries] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);

  const handleToast = (title, type) => {
    toast({
      title,
      status: type,
      duration: 5000,
      isClosable: true
    });
  };

  const handleInputChange = (e) => {
    setCompanyDetails(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const handleCheckboxChange = (e) => {
    setSignupCheck((prev) => ({
      ...prev,
      [e.target.name]: !prev[e.target.name]
    }));
  };

  const handleCountrySelect = (e) => {
    setCompanyDetails((prev) => ({
      ...prev,
      country: e.target.value
    }));
  };

  const handleSubmit = useCallback(({ data, condition }) => {
    if (condition) {
      const { companyName, country } = data;

      if (companyName === "" || !companyName) {
        handleToast("Please fill Company Name to continue", 'error');
        return;
      }

      if (country === "" || !country) {
        handleToast("Please fill Country to continue", 'error');
        return;
      }
      setIsButtonLoading(true);
      dispatch(createOrganization({ ...data, cxId: customerId }));
      setIsButtonLoading(false);
    }
    else {
      setIsButtonLoading(false);
      handleToast("Please accept the Terms and Conditions to continue", "error");
    }
  }, [dispatch]);

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  useEffect(() => {
    if (!accountCreated && message && error) {
      handleToast(message, "error");
      navigate('/login');
    }
    if (!accountCreated && message && !error && orgExists) {
      handleToast(message, "success");
      return navigate('/request-join');
    }
    if (!accountCreated && !message && !error && orgExists) {
      return navigate('/fill-details');
    }
    if (!accountCreated && message && !error) {
      handleToast(message, "success");
      navigate('/login');
    }
    if (!accountCreated && !message && !error) {
      navigate('/login');
    }
  }, [accountCreated, message, error]);

  useEffect(() => {
    async function getSupportedCountries() {
      const { data } = await callApi.post(`/configuration/getSignupConfigs`, { name: "supported_countries", module: "user_signup" });
      const { configs } = data?.data || {};
      const { countries } = configs || {};
      setCountries(configs);
    };
    getSupportedCountries();
  }, []);

  useEffect(() => {
    const checkEnabled = () => {
      if ((companyDetails?.companyName || "") !== "" && (companyDetails?.country || "") !== "")
        setIsDisabled(false);
      else
        setIsDisabled(true);
    };
    checkEnabled();
  }, [companyDetails?.companyName, companyDetails?.country]);

  return (
    isLoading ? <Loader /> :
      <Flex bg={'white'} h={'full'} w={'full'}>
        <Box bg={'white'} h={"full"} w={'50%'}>
          <Box px={'16'} pt={'5'}>
            <div className="pa-signup-sub-card">
              <div className="pa-login-header">
                <BackButton />
                <img src={TransfiLogo} alt="logo" />
                <span>Welcome To TransFi</span>
                <p>Fill out company info</p>
                <ProgressBar level={3} />
              </div>
              <div className="pa-login-body">
                <p className="pa-login-email-heading">Registered Company Name<span className="pa-mandatory-asterisk" > *</span></p>
                <Input
                  type={"text"}
                  placeholder={"Enter Name"}
                  inputChangeHandler={handleInputChange}
                  name={"companyName"}
                  value={companyDetails.companyName}
                />
                <p className="pa-login-email-heading">Company Website URL</p>
                <Input
                  type={"text"}
                  placeholder={"http://company.com"}
                  inputChangeHandler={handleInputChange}
                  name={"websiteUrl"}
                  value={companyDetails.websiteUrl}
                />
                <p className="pa-login-email-heading">Company Linkedin URL</p>
                <Input
                  type={"text"}
                  placeholder={"http://www.linkedin.com/companyname"}
                  inputChangeHandler={handleInputChange}
                  name={"linkedinUrl"}
                  value={companyDetails.linkedinUrl}
                />
                <p className="pa-login-email-heading">Country of Registration<span className="pa-mandatory-asterisk" > *</span></p>
                <div style={{ textAlign: 'center', width: '100%' }}>
                  <select
                    name="Countries"
                    onChange={e => handleCountrySelect(e)}
                    value={companyDetails.country}
                    className="pa-input-dropdown"
                  >
                    <option value="">Select the country</option>
                    {countries.map((country, key) => (
                      <option key={key} value={country}>
                        {country}
                      </option>
                    ))}
                  </select>
                </div>
                <div style={{ textAlign: 'left', width: '100%', display: 'flex', flexDirection: 'column', rowGap: '10px', marginTop: '20px' }}>
                  <div className="pa-checkbox-div">
                    <input
                      type="checkbox"
                      checked={signupCheck.newsletter}
                      onChange={handleCheckboxChange}
                      name="newsletter"
                    />
                    <span>Send me news, exclusive offers & emails from TransFi</span>
                  </div>
                  <div className="pa-checkbox-div">
                    <input
                      type="checkbox"
                      checked={signupCheck.toc}
                      onChange={handleCheckboxChange}
                      name="toc"
                    />
                    <span>To proceed, users must agree to our&nbsp;
                      <span onClick={() => openInNewTab('https://www.transfi.com/policies')} style={{ marginLeft: '0px', color: '#1A4FD6', cursor: 'pointer' }} >privacy policy</span>
                      &nbsp;and&nbsp;
                      <span onClick={() => openInNewTab('https://www.transfi.com/terms-and-conditions')} style={{ marginLeft: '0px', color: '#1A4FD6', cursor: 'pointer' }}>terms & conditions</span>
                    </span>
                  </div>
                </div>
                <Button className={"pa-button"} onClickHandler={() => { handleSubmit({ data: companyDetails, condition: signupCheck.toc }) }} isDisabled={isDisabled || !signupCheck.toc} isLoading={isButtonLoading} >
                  Finish
                </Button>
              </div>
            </div>
          </Box>
        </Box>
        <Flex className="gradient-background" flexDirection={'column'} justify={'space-between'} w={'full'} maxH={'full'}>
          <Box ml={'80px'} mt={'80px'}>
            <Text color={'#092C4C'} fontFamily={'DM Sans'} fontWeight={'700'} fontSize={'48px'}>Borderless <Text as={'span'} color={'#1A4FD6'}>payments</Text>,</Text>

            <Text color={'#092C4C'} fontFamily={'DM Sans'} fontWeight={'700'} fontSize={'48px'}>boundless <Text as={'span'} color={'#1A4FD6'}>potential</Text></Text>
          </Box>
          <Flex w={'full'} justify={'flex-end'}>
            <Image src="/assets/login.png" maxH={'809px'} maxW={'992px'} />
          </Flex>
        </Flex>
      </Flex>
  );
};

export default FillDetails;
