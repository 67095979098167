import React, { useState } from 'react'
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import QRCode from 'react-qr-code';
import { IoCopyOutline } from "react-icons/io5";
import { TbBulb } from "react-icons/tb";
import { Box, Button, Flex, Stack, Text, VStack, useToast } from '@chakra-ui/react'

import { goToPeviousStep } from '../../../store/slices/AddMoneySlice';
import Typography from '../../../components/Common/Typography'
import Divider from '../../../components/LoginComponents/Divider';
import { callApiWithToken } from '../../../utils/http_common';

// copy value reusable component
export const CopyInputBox = ({ label, value }) => {
    return (
        <VStack spacing={2} align="flex-start">
            <Typography weight='medium' type='description' color='secondary' colorweight="800" >
                {label}
            </Typography>
            <Flex justifyContent={"space-between"} alignItems={"center"} border={"1px solid"} borderColor={"brand.greyPrimary"} w='100%' bg='white' p='10px' borderRadius='5px'>
                <Typography weight='regular' type='description' color='headertext' >
                    {value}
                </Typography>
                <IoCopyOutline style={{ cursor: 'pointer', marginLeft: "10px" }} onClick={() => navigator.clipboard.writeText(value)} />
            </Flex>
        </VStack>

    )
}

export default function WalletToWalletTransfer() {
    const navigate = useNavigate()
    const toast = useToast()
    const dispatch = useDispatch()
    const quotes = useSelector((state) => state.addMoney.quotes?.data)
    const walletAddress = useSelector((state) => state.addMoney.walletAddress);
    const currencyToPay = useSelector((state) => state.addMoney.currencyToPay);
    const [buttonLoading, setButtonLoading] = useState(false);

    const handleBack = () => {
        dispatch(goToPeviousStep());
    }

    const handleClick = async() => {
        if(["development", "sandbox"].includes(process.env.REACT_APP_NODE_ENV)){
            try {
                setButtonLoading(true)
                const response = await callApiWithToken.post(`/order/cryptoPrefundOrder`,{
                    orderId:walletAddress?.data?.orderId
                });
                if (response.data.success) {
                    const data = response.data.data;
                    toast({
                        title: "Success",
                        // description: "Redirecting to success page",
                        status: "success",
                        position: 'top',
                        duration: 2000,
                        isClosable: true,
                    })
                    navigate('/my-accounts/add-money/success')
                }
            } catch (error) {
                toast({
                    title: "Failed",
                    description: "Please try again",
                    status: "error",
                    position: 'top',
                    duration: 2000,
                    isClosable: true,
                })
            } finally {
                setButtonLoading(false);
            }
        } else {
            setButtonLoading(true)
            toast({
                title: "Success",
                // description: "Redirecting to success page",
                status: "success",
                position: 'top',
                duration: 2000,
                isClosable: true,
            })
            navigate('/my-accounts/add-money/success')
            setButtonLoading(false);
        }
       
    }

    return (
        <Box w={"100%"}>
            <Flex borderRadius={"9px"} padding={"16px"} justifyContent={"center"} rowGap={4} flexDirection={"column"} alignItems={"center"} bg={"brand.secondary"} w={"full"}>
                <Typography type='description'>
                    Scan this QR Code from your wallet to make the transfer
                </Typography>
                <QRCode size={147} radius={"7px"} value={walletAddress?.data?.walletAddress} />
            </Flex>
            <Divider>
                Or send amount to this wallet
            </Divider>
            <Flex padding={"16px 5px"} mt={"20px"} gap={10}>

                <Box width={"max-content"}>
                    <CopyInputBox label="Wallet Address" value={walletAddress?.data?.walletAddress} />
                </Box>
                <Box width={"full"}>
                    <CopyInputBox label="Amount" value={quotes?.amountToPay} />
                </Box>
            </Flex>
            <Flex borderRadius={"9px"} padding={"16px"} justifyContent={"center"} columnGap={2} alignItems={"start"} bg={"brand.secondary"} w={"full"}>
                <TbBulb width={"20px"} height={"20px"} />
                <Text fontSize={"14px"} color={"#282F40"}>
                    Please ensure that you send the exact amount mentioned to avoid any discrepancies. The applicable fees may vary depending on the mode of transfer.
                </Text>
            </Flex>

            <Stack pt={8} bg={"white"} direction="row" gap={"20px"} justifyContent={"end"} w='100%' marginTop={0}>
                <Button onClick={handleBack} variant={"secondary"} size={"sm"} type="button" p={"9px 16px 9px 16px"}>
                    Back
                </Button>
                <Button variant={"primary"} onClick={handleClick} size={"sm"} p={"9px 16px 9px 16px"} isLoading={buttonLoading}>
                    Click when transfer is done
                </Button>
            </Stack>

        </Box>
    )
}
