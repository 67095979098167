/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from "react";
import { Box, Center, Text, Stack, Button, FormControl, FormLabel, Input, HStack, useToast, Flex, VStack } from "@chakra-ui/react";
import {
    Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Popover, PopoverTrigger, PopoverContent, PopoverBody, PopoverArrow,
} from "@chakra-ui/react";
import { InfoOutlineIcon, CopyIcon } from "@chakra-ui/icons";
import { ModalCloseButton, Container, Divider, useDisclosure, useClipboard } from "@chakra-ui/react";
import { BiCopy } from "react-icons/bi";
import { useSelector } from "react-redux";

import { callApiWithToken } from "../../../utils/http_common";
import Loader from "../../../utils/Loader";

import ".././styles.css";

export default function Integration({ data }) {

    const [language, setLanguage] = useState("");
    const [webhookData, setWebhookData] = useState({});
    const [loading, setLoading] = useState(false);
    const [webhookUrl, setWebhookUrl] = useState("");
    const [redirectUrl, setRedirectUrl] = useState("");
    const [orgName, setOrgName] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [updateLoading, setUpdateLoading] = useState(false);

    const userDetails = useSelector((state) => state?.login?.userDetails)

    const toast = useToast();

    const { isOpen, onOpen, onClose } = useDisclosure();

    const { onCopy, value, setValue, hasCopied } = useClipboard(webhookData.secret);
    const { onCopy: onUsernameCopy } = useClipboard(username);
    const { onCopy: onPasswordCopy } = useClipboard(password);

    const url = process.env.REACT_APP_NODE_ENV === "Sandbox" ? webhookData.url || "" : webhookData.prdUrl || "";
    useEffect(() => {
        getCustomerData();
        getDefaultBrowserLanguage();
    }, []);

    const updateSettings = async () => {
        const payload = {
            impersonatedOrg: 'all',
            language,
            webhookUrl,
            redirectUrl,
            key: process.env.REACT_APP_NODE_ENV === "Sandbox" ? "webhook.prdUrl" : "webhook.url",
        };

        if (!validateForm()) {
            setUpdateLoading(true);
            const response = (await callApiWithToken.post("/organization/update", payload)) || {};
            toast({
                title: "Updated Successfully..",
                status: "success",
                duration: 2000,
                isClosable: true
            });
            setUpdateLoading(false);
        }
    };
    const getCustomerData = async () => {
        setLoading(true);
        const { orgResponse = {}, webhookResponse = {} } = data?.data || {};
        const { language, formattedName, merchantKey } = orgResponse;
        const { webhook = {}, redirectUrl = "" } = webhookResponse;
        setWebhookData(webhook);
        setValue(webhook.secret);
        setLanguage(language);
        setLoading(false);
        setOrgName(formattedName);
        setRedirectUrl(redirectUrl);
        setWebhookUrl(webhook?.url);
        setUsername(formattedName);
        setPassword(merchantKey);
    };

    const getDefaultBrowserLanguage = () => {
        /* Call in case default language is not set. This picks up browser language and uses it as default */
        const browserLangComplete = navigator.language || navigator.userLanguage;
        const browserLang = browserLangComplete.split("-")[0];

        if (browserLang && typeof browserLang === "string" && browserLang.toLowerCase() === "en") {
            setLanguage("english");
        }
    };

    const validateForm = () => {
        let isError = false;

        return isError;
    };

    const handleUrlChange = (event) => {
        setWebhookUrl(event.target.value);
    };

    const handleRedirectUrlChange = (event) => {
        setRedirectUrl(event.target.value);
    };

    return (
        loading ?
            <Loader /> :
            <Box py={5} fontFamily={"DM Sans"}>
                <Stack spacing={2}>
                    <Box bg="white" borderRadius={10}>
                        <Stack spacing={2}>
                            <Stack direction={"column"} justifyContent="space-between" flexGrow={1}
                                borderRadius={10}
                                maxWidth={"full"}
                                width={"full"}
                            >
                                <Stack w="100%" gap={'5'}>
                                    <FormControl size="sm">
                                        <FormLabel htmlFor="text" pt={2}>
                                            Webhook URL
                                        </FormLabel>
                                        <HStack>
                                            <Input
                                                value={webhookUrl || url}
                                                width={"50%"}
                                                variant="outline"
                                                placeholder="Enter webhook url"
                                                onChange={handleUrlChange}
                                            />

                                        </HStack>
                                    </FormControl>
                                    <FormControl size="sm">
                                        <FormLabel htmlFor="text" pt={2}>
                                            Redirect URL
                                        </FormLabel>
                                        <HStack>
                                            <Input
                                                value={redirectUrl}
                                                width={"50%"}
                                                variant="outline"
                                                placeholder="Enter Redirect url"
                                                onChange={handleRedirectUrlChange}
                                            />

                                        </HStack>
                                        <Box display={'flex'} justifyContent={'end'} width={'50%'}>
                                            <Button
                                                isLoading={updateLoading}
                                                variant={"primary"}
                                                bg={"#1A4FD6"}
                                                _hover={{ bg: '#366cf5' }}
                                                color="white"
                                                style={{ marginTop: '20px' }}
                                                onClick={updateSettings}
                                                size='md'
                                                width={'122px'}
                                                height={'46px'}
                                                rounded={'10px'}
                                            >
                                                Update
                                            </Button>
                                        </Box>
                                    </FormControl>
                                </Stack>

                            </Stack>

                        </Stack>

                        <Modal isOpen={isOpen} onClose={onClose} size="xl">
                            <ModalOverlay />
                            <ModalContent>
                                <ModalHeader>Webhook Secret Key</ModalHeader>
                                <ModalCloseButton top={3} />
                                <Divider />
                                <ModalBody>
                                    <FormControl>
                                        <FormLabel>Secret Key</FormLabel>
                                        <Input value={webhookData.secret} isReadOnly />
                                    </FormControl>
                                </ModalBody>
                                <Divider />
                                <ModalFooter>
                                    <HStack gap="5">
                                        <Button
                                            leftIcon={<BiCopy />}
                                            variant={"primary"}
                                            bg={"#1A4FD6"}
                                            _hover={{ bg: '#366cf5' }}
                                            color="white"
                                            onClick={onCopy}
                                            size='md'
                                            width={'122px'}
                                            height={'46px'}
                                            rounded={'10px'}
                                        >
                                            {hasCopied ? "Copied" : "Copy"}
                                        </Button>
                                        <Button onClick={onClose} color="#1A4FD6"
                                            border={'1px'}
                                            borderColor={"#1A4FD6"}
                                            rounded={'10px'}
                                            _hover={{ bg: "#366cf5", textColor: "white" }}
                                            width={'122px'}
                                            height={'46px'}>
                                            Exit
                                        </Button>
                                    </HStack>
                                </ModalFooter>
                            </ModalContent>
                        </Modal>
                    </Box>

                    <Box bg="white" p={7} borderRadius={10}>
                        <Flex w={'50%'} justifyContent={'space-between'} alignItems={'center'}>
                            <Text fontWeight={'semibold'}>Webhook Secret Key</Text>
                            <Button
                                color="#1A4FD6"
                                border={'1px'}
                                borderColor={"#1A4FD6"}
                                _hover={{ bg: "#1A4FD6", textColor: "white" }}
                                onClick={onOpen}
                                rounded={'10px'}
                                width={'122px'}
                                height={'46px'}
                            >
                                Show Key
                            </Button>
                        </Flex>

                    </Box>
                    <Box bg="white" p={7} borderRadius={10}>
                        <Flex w={'100%'} justifyContent={{ md: 'flex-start' }} alignItems={{ md: 'flex-start' }} direction={{ md: 'column' }} gap={4}>
                            <Flex w={{ lg: '50%' }} justifyContent={'flex-start'} alignItems={'center'}>
                                <Text fontWeight={'semibold'} marginRight={'10px'}>Username</Text>
                                <Button display={"flex"} justifyContent={"space-between"} width={"300px"} variant="outline" marginRight={'10px'} rightIcon={<CopyIcon />} onClick={onUsernameCopy} >
                                    <Text fontWeight={'normal'}>{username}</Text>
                                </Button>
                                <Popover placement="right">
                                    <PopoverTrigger>
                                        <InfoOutlineIcon
                                            style={{ marginBottom: "6px", fontSize: "18px" }}
                                        />
                                    </PopoverTrigger>
                                    <PopoverContent>
                                        <PopoverArrow />
                                        <PopoverBody>
                                            "It's your unique user name to create the authentication
                                            token for using TransFi API."
                                        </PopoverBody>
                                    </PopoverContent>
                                </Popover>
                            </Flex>
                            <Flex w={{ lg: '50%' }} justifyContent={'flex-start'} alignItems={'center'}>
                                <Text fontWeight={'semibold'} marginRight={'10px'}>Password</Text>

                                <Button display={"flex"} justifyContent={"space-between"} width={"300px"} variant="outline" marginRight={'10px'} onClick={onPasswordCopy} >
                                    <Text fontWeight={'normal'}>{password}</Text>
                                    <CopyIcon />
                                </Button>

                                <Popover placement="right">
                                    <PopoverTrigger>
                                        <InfoOutlineIcon
                                            style={{ marginBottom: "6px", fontSize: "18px" }}
                                        />
                                    </PopoverTrigger>
                                    <PopoverContent>
                                        <PopoverArrow />
                                        <PopoverBody>
                                            "It's your unique secret to create the authentication
                                            token for using TransFi API."
                                        </PopoverBody>
                                    </PopoverContent>
                                </Popover>
                            </Flex>
                        </Flex>
                    </Box>
                </Stack >
            </Box >
    );
}