import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Text,
  HStack,
  SimpleGrid,
  Image,
  SkeletonCircle,
  Skeleton,
} from "@chakra-ui/react";

import "./Home.css";

import Typography from "../../components/Common/Typography";
import AccountServide from "../../services/accountService";
import { quickLinks } from "./constants";
import RecentActivityTable from "./RecentActivityTable";
import Card from "./Card";

const Home = () => {
  const [balance, setBalance] = useState([]);
  const [loading, setLoading] = useState(true);

  const getMyAccounts = async () => {
    try {
      const res = await AccountServide.getMyAccountList();
      const filteredArray = res?.data?.data?.response.filter((d) => {
        return d.currency === "USD" || d.currency === "EUR";
      });
      setBalance(filteredArray);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMyAccounts();
  }, []);

  return (
    <Box className="sm-main-card">
      <Flex justify="space-between" align="center" mb={2}>
        <Typography type="largeheader" weight="bold">
          Welcome to TransFi
        </Typography>
        <Box pt={6} textAlign="right">
          <Text fontSize="md" pb={1} color="#999999">
            Account Balance
          </Text>
          <HStack paddingX={0}>
            {loading ? (
              <>
                {Array(2)
                  .fill(0)
                  .map((_, index) => (
                    <>
                      <SkeletonCircle size="20px" />
                      <Skeleton height="20px" width="60px" ml={2} />
                    </>
                  ))}
              </>
            ) : (
              balance?.map((bal, index) => {
                return (
                  <>
                    <Image
                      boxSize="20px"
                      borderRadius="full"
                      src={`${bal?.logo}`}
                      alt="Logo"
                    />
                    <Text fontSize="md" color="#525c76">
                      {bal?.currency} {bal?.balance?.availableBalance}
                    </Text>
                    {index < balance?.length - 1 && (
                      <Box
                        height="20px"
                        width="1px"
                        backgroundColor="#9E9E9E"
                        mx={2}
                      />
                    )}
                  </>
                );
              })
            )}
          </HStack>
        </Box>
      </Flex>

      <Box bg="white" w="100%" mt={1} borderRadius={"9px"} p={"20px 40px"}>
        <Typography type="body" weight="medium" color="primary">
          Quick Links
        </Typography>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={6} mt={6}>
          {quickLinks.map((link) => {
            return <Card data={link} />;
          })}
        </SimpleGrid>
      </Box>

      <Box>
        <RecentActivityTable />
      </Box>
    </Box>
  );
};

export default Home;
