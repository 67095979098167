import { useRef } from "react";
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, Center, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalOverlay } from "@chakra-ui/react";
import Typography from "../Common/Typography";

export function AlertModal(props) {
    const { isOpen, onClose, title, btnText, children, handleConfirmClick } = props;
    const cancelRef = useRef()

    return (
        <>
            <Modal
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <ModalOverlay />
                <ModalContent>

                    <Center mt={4}>
                        <Typography color="primary" colorweight="500" weight='bold'>{title}</Typography>
                    </Center>
                    <ModalCloseButton />

                    <ModalBody>
                        <Center mt={4}>
                            {children}
                        </Center>
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            bg={"#F2F2F2"}
                            _hover={{ bg: "#F2F2F2" }}
                            color="#808080"
                            width={'113px'}
                            height={'40px'}
                            rounded={'6px'}
                            fontSize={'14px'}
                            fontWeight={'400'}
                            fontFamily={'DM Sans'} mr={3}
                            ref={cancelRef}
                            onClick={onClose}>
                            Cancel
                        </Button>
                        <Button colorScheme='red'
                            onClick={handleConfirmClick} bg={"#E90000"}
                            _hover={{ bg: '#E90000' }}
                            color="white"
                            width={'113px'}
                            height={'40px'}
                            rounded={'6px'}
                            fontSize={'14px'}
                            fontWeight={'400'}
                            fontFamily={'DM Sans'}>
                            {btnText}
                        </Button>
                    </ModalFooter>
                </ModalContent>

            </Modal>
        </>
    )
}