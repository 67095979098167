import React from 'react';
import { useDispatch } from 'react-redux';
import { Box, VStack, Button, Text, StackDivider } from '@chakra-ui/react';

import { setActiveStep } from '../../store/slices/contactSlices';
import Typography from '../Common/Typography';

const VerticalStepper = ({ steps, currentStep }) => {
  const dispatch = useDispatch();
  return (
    <VStack borderLeft={"2px solid"} paddingX={2} borderLeftColor={"brand.primary"} spacing={2} align="stretch">
      {steps.map((step, index) => (
        <Button
          key={index}
          variant="ghost"
          justifyContent="flex-start"
          _hover={{}} _active={{}} className='stepper-button'
          // color={currentStep === index ? 'brand.primary' : '#BACAF3'}
          onClick={() => { dispatch(setActiveStep(index)) }}
          isDisabled={index > currentStep} // Disable future steps
        >
          <Typography color="primary" colorweight={currentStep === index ? "500" : "200"} type='body' weight='bold' >
            {step.name}
          </Typography >

          {/* <Typography>{step.name}</Typography> */}
        </Button>
      ))}
    </VStack>
  );
};

export default VerticalStepper;
