import { callApiWithToken, callApiWithTokenForFile } from "../utils/http_common";

const getTransactionData = ({ filters }) => {
    return callApiWithToken.get(`/transaction`, {
        params: {
            filters
        }
    });
}

const getPayoutOrders = ({ filters }) => {
    return callApiWithToken.post(`/transaction`, { filters });
}

const getQuotesData = ({ filters }) => {
    return callApiWithToken.get(`/quotes/exchange-rates`, {
        params: {
            ...filters
        }
    });
}

const createIndividualTransaction = (payload) => {
    return callApiWithTokenForFile.post(`/transaction/createIndividualTransaction`, { ...payload });
}

const getTransactions = (type) => {
    return callApiWithToken.get(`/order/transactions?type=${type}`);
}
const getCollectionTransactions = (status = "") => {
    return callApiWithToken.get(`/order/collectionTransactions?status=${status}`);
}

const getCollectionCustomerTransactions = (status = "", email = "") => {
    return callApiWithToken.get(`/order/collectionCustomerTransactions?status=${status}&email=${email}`);
}
const getPrefundTransactions = (filters) => {
    return callApiWithToken.get(`/order/prefundTransactions`, { params: filters });
}

const getCollectionInvoices = (payload) => {
    return callApiWithToken.post(`/transaction/collectionOrders`, { ...payload });
}

const TransactionService = {
    getTransactionData,
    getQuotesData,
    createIndividualTransaction,
    getTransactions,
    getPrefundTransactions,
    getCollectionTransactions,
    getPayoutOrders,
    getCollectionInvoices,
    getCollectionCustomerTransactions
}

export default TransactionService