import { Button, HStack, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react'
import React from 'react'
import { PiWarningBold } from 'react-icons/pi'
import { useDispatch, useSelector } from 'react-redux';
import { createOrder, resetOrder } from '../../store/slices/orderSlices';
import { getRecipient } from '../../store/slices/recipientSlices';

const ResetModal = (props) => {
    const { isOpen, onClose, onConfirm } = props;

    const onConfirmReset = () => {
        onConfirm(true)
    }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>
                <HStack alignItems={'center'}><PiWarningBold color='#FCB312' /><Text>Do you want to reset the recipient list</Text></HStack>
            </ModalHeader>
            <ModalBody>
                <Text>Are you sure, you want to reset the recipient list?</Text>
                <Text>This action cannot be undone</Text>
            </ModalBody>
            <ModalFooter justifyContent={'center'}>
                <HStack>
                    <Button color="#525C76" border="1px" borderColor='#525C76' _hover={{ bg: '#ffffff' }} variant={'outline'} onClick={onClose}>Cancel</Button>
                    <Button onClick={onConfirmReset} bg={"#1A4FD6"} color="#ffffff" _hover={{ bg: '#232D95' }}>Confirm</Button>
                </HStack>
            </ModalFooter>
        </ModalContent>
    </Modal>
  )
}

export default ResetModal