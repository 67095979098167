/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from "react";
import { isEmpty, startCase } from "lodash";
import { Formik } from "formik";
import { Box, Stack, Button, HStack, useToast, VStack, TableContainer, Table, Thead, Tr, Td, Th, Tbody, Image, useDisclosure, Flex } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";

import Typography from "../../../components/Common/Typography";
import { TextField, CustomSelectFieldSearch } from "../../../components/Common/Input";
import DeleteIcon from "../../../assets/delete.svg";
import Loader from "../../../utils/Loader";

import ".././styles.css";
import AddBankModal from "../modals/AddBankModal";
import { callApiWithToken } from "../../../utils/http_common";
import { walletValidationSchema } from "./ValidationSchema";
import RecipientService from "../../../services/recipientService";
import AddWalletModal from "../modals/AddWalletModal";
import DeleteWalletAndBank from "../modals/DeleteWalletAndBank";
import { getCurrencyConfigs } from "../../../store/slices/orderSlices";
import FinanceLoader from "../FinanceLoader";

export default function Integration({ data }) {

    const [fiatAccounts, setFiatAccounts] = useState([]);
    const [cryptoAccounts, setCryptoAccounts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [addWalletLoading, setAddWalletLoading] = useState(false);
    const [refreshData, setRefreshData] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [deleteData, setDeleteData] = useState({});

    const stableCoins = useSelector(state => state.order?.cryptoList).map((item) => {
        return {
            label: `${item.value.symbol} - ${item.value.description} ( ${item.value.network} )`,
            value: `${item.value.formattedSymbol}`,
            logo: `${item.value.logo}`,
            network: `${item.value.network}`
        };
    });

    const dispatch = useDispatch();

    const toast = useToast();
    const handleToast = (title, type) => {
        toast({
            title,
            status: type,
            duration: 5000,
            isClosable: true
        });
    };

    const { isOpen: addBankIsOpen, onOpen: addBankOnOpen, onClose: addBankOnClose } = useDisclosure();
    const { isOpen: addWalletIsOpen, onOpen: addWalletOnOpen, onClose: addWalletOnClose } = useDisclosure();
    const { isOpen: deleteIsOpen, onOpen: deleteOnOpen, onClose: deleteOnClose } = useDisclosure();

    const initialValue = {
        stableCoin: "",
        walletAddress: "",
        walletName: "",
    };

    const validationSchema = walletValidationSchema;

    const handleAddBank = () => {
        addBankOnOpen()
    }

    const handleAddWallet = () => {
        addWalletOnOpen()
    }

    const handleDelete = (data, type) => {
        setDeleteData({ ...data, type: type })
        deleteOnOpen();
    }

    useEffect(() => {
        getBankAndWalletDetails()
        setSelectedOption({})
        dispatch(getCurrencyConfigs({ product: "pay_out" }));
    }, [refreshData]);

    const getBankAndWalletDetails = async () => {
        setLoading(true);
        const { data } = (await callApiWithToken.get(`/customer/getBankAndWallet`)) || {};
        const { bankList = [], walletList = [] } = data?.data || {};
        setFiatAccounts(bankList)
        setCryptoAccounts(walletList)
        setLoading(false);
    }

    const BankAccountList = () => {
        return (
            <TableContainer maxH={"50vh"} overflowY="auto">
                <Table variant='simple'>
                    <Thead>
                        <Tr>
                            <Th><Typography type='secondary' weight='regular' mt={"1px"} color='secondary'>Currency</Typography></Th>
                            <Th ><Typography type='secondary' weight='regular' mt={"1px"} color='secondary'>Bank Account</Typography></Th>
                            <Th ><Typography type='secondary' weight='regular' mt={"1px"} color='secondary'>Status</Typography></Th>
                            <Th ><Typography type='secondary' weight='regular' mt={"1px"} color='secondary'></Typography></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {fiatAccounts.map((bank, index) => (
                            <Tr h={"69px"} key={index}>
                                <Td>
                                    <HStack>
                                        <Image src={bank.currencyLogo} alt='logo-approval' width={"25px"} />
                                        <Typography type='description' weight='medium' color='secondary' colorweight='800'>{bank.currency}</Typography>
                                    </HStack>
                                </Td>
                                <Td>
                                    <Typography type='description' weight='medium' color='secondary' colorweight='800'>{bank.accountNumber}</Typography>
                                </Td>
                                <Td>
                                    <Typography type='description' weight='medium' color='secondary' colorweight='800'>{startCase(bank.status)}</Typography>
                                </Td>
                                <Td isNumeric>
                                    <Image style={{ cursor: "pointer" }} src={DeleteIcon} alt="delete" onClick={() => handleDelete(bank, "bank")} />
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
        );
    };

    const walletAccountList = () => {
        return (
            <TableContainer maxH={"50vh"} overflowY="auto">
                <Table variant='simple'>
                    <Thead>
                        <Tr>
                            <Th><Typography type='secondary' weight='regular' mt={"1px"} color='secondary'>Nick Name</Typography></Th>
                            <Th ><Typography type='secondary' weight='regular' mt={"1px"} color='secondary'>Wallet Address</Typography></Th>
                            <Th ><Typography type='secondary' weight='regular' mt={"1px"} color='secondary'>Status</Typography></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {cryptoAccounts.map((wallet, index) => (
                            <Tr h={"69px"} key={index}>
                                <Td>
                                    <HStack>
                                        <Image src={wallet?.currencyLogo} alt='logo-approval' width={"25px"} />
                                        <Typography type='description' weight='medium' color='secondary' colorweight='800'>{wallet.nickname}</Typography>
                                    </HStack>
                                </Td>
                                <Td>
                                    <Typography type='description' weight='medium' color='secondary' colorweight='800'>{wallet.walletAddress}</Typography>
                                </Td>
                                <Td>
                                    <Typography type='description' weight='medium' color='secondary' colorweight='800'>{startCase(wallet.status)}</Typography>
                                </Td>
                                <Td isNumeric>
                                    <Image style={{ cursor: "pointer" }} src={DeleteIcon} alt="delete" onClick={() => handleDelete(wallet, "wallet")} />
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
        );
    };

    const AddBankAccount = () => {
        return (
            <TableContainer>
                <Table variant='simple'>
                    <Thead>
                        <Tr>
                            <Th><Typography type='secondary' weight='regular' mt={"1px"} color='secondary'>Currency</Typography></Th>
                            <Th ><Typography type='secondary' weight='regular' mt={"1px"} color='secondary'>Bank Account</Typography></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        <Tr h={"69px"}>
                            <Td>
                                <HStack>
                                    <Image src={process.env.PUBLIC_URL + "/assets/any-currency.svg"} alt='logo-approval' width={"58px"} />
                                    <Typography type='description' weight='medium' mt={"1px"} color='default'>Any Currency</Typography>
                                </HStack>
                            </Td>
                            <Td>
                                <VStack alignItems={"start"}>
                                    <Typography type='description' weight='medium' color='default'>Bank Account </Typography>
                                    <Typography type='caption' weight='regular' color='secondary'>Any currency bank account which accepts payment for you</Typography>
                                </VStack>
                            </Td>
                            <Td isNumeric>
                                <Button variant={"primary"} alignSelf={"right"} size={"md"} w="180px" height={"32px"} p={"9px 16px 9px 16px"} onClick={handleAddBank}>
                                    Add Bank Account +
                                </Button>
                            </Td>
                        </Tr>
                    </Tbody>
                </Table>
            </TableContainer>
        );
    };

    const handleSubmit = async (values, actions) => {
        try {
            setAddWalletLoading(true)
            const validation = await RecipientService.postWalletAddress({
                address: values.walletAddress,
                cryptoTicker: selectedOption.value,
                cryptoNetwork: selectedOption.network
            });
            setAddWalletLoading(false)
            const payload = {
                currency: selectedOption.value,
                network: selectedOption.network,
                walletAddress: values.walletAddress,
                currencyLogo: selectedOption.logo,
                nickname: values.walletName
            }
            setLoading(true);
            const { data } = (await callApiWithToken.post("/customer/saveWalletDetails", payload)) || {};
            if (data?.success) {
                toast({
                    title: "Wallet details added",
                    status: 'success',
                    duration: 5000,
                    isClosable: true
                });
            }
            setAddWalletLoading(false);
            setRefreshData(!refreshData)

        } catch (err) {
            console.log(err?.response?.data?.error?.message, "Error");
            if (err?.response?.data?.error?.message) {
                handleToast(err?.response?.data?.error?.message, 'error');
            }
            setAddWalletLoading(false)
        }
    }

    const AddWalletAccount = () => {
        return (
            <Formik
                initialValues={initialValue}
                onSubmit={handleSubmit}
                validateOnChange={false}
                validationSchema={validationSchema}
            >
                {({ isSubmitting, handleSubmit }) => (
                    <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                        <Flex direction={'column'} spacing={10}>
                            <Box maxW={"48%"}>
                                <CustomSelectFieldSearch EmptyMenuLabel={"No Digital Assets Found"} required={false} width={"100%"} selectedOption={selectedOption} setSelectedOption={setSelectedOption} name="stableCoin" placeholder="Select Digital Asset" options={stableCoins} />
                            </Box>
                            <HStack justifyContent={"space-between"} w="full" spacing={10}>
                                <TextField label="Wallet Address" name="walletAddress" placeholder="Enter Wallet Address" required={false} />
                                <TextField label="Nick Name for wallet" name="walletName" placeholder="Enter Nick Name for wallet" required={false} />
                            </HStack>
                            <Button _loading={isSubmitting} variant={"primary"} alignSelf={"end"} size={"md"} w="180px" height={"32px"} p={"9px 16px 9px 16px"} onClick={handleSubmit} mt={5} isLoading={addWalletLoading}>
                                Add Wallet Details +
                            </Button>
                        </Flex>
                    </form>
                )}
            </Formik>
        );
    };

    return (
        loading ?
            <FinanceLoader /> : <Box w={"full"} minH={"92vh"} bg={"#F2F2F2"}>
                <VStack w={"100%"} >
                    <Box bg='white' w='100%' borderRadius={"9px"} p={"20px 40px"}>
                        <HStack justifyContent={"space-between"}>
                            <VStack alignItems={"start"}>
                                <Typography type='body' weight='medium' color='primary' >
                                    Bank Details
                                </Typography>
                                <Typography type='caption' weight='regular' mt={"1px"} color='default' >
                                    Bank account from which prefunding will be done
                                </Typography>
                            </VStack>
                            {!isEmpty(fiatAccounts) && <Button variant={"primary"} alignSelf={"right"} size={"md"} w="180px" height={"32px"} p={"9px 16px 9px 16px"} onClick={handleAddBank}>
                                Add Bank Account +
                            </Button>}
                        </HStack>
                        <Stack borderRadius={"9px"} mt={"15px"} rowGap={5} bg={"white"}>
                            {isEmpty(fiatAccounts) ? AddBankAccount() : BankAccountList()}
                        </Stack>
                    </Box>
                    <Box bg='white' w='100%' borderRadius={"9px"} mt={"20px"} p={"20px 40px"}>
                        <HStack justifyContent={"space-between"}>
                            <VStack alignItems={"start"}>
                                <Typography type='body' weight='medium' color='primary' >
                                    Wallet Details
                                </Typography>
                                <Typography type='caption' weight='regular' mt={"1px"} color='default' >
                                    Details of wallets from which prefunding will be done
                                </Typography>
                            </VStack>
                            {!isEmpty(cryptoAccounts) && <Button variant={"primary"} alignSelf={"right"} size={"md"} w="180px" height={"32px"} p={"9px 16px 9px 16px"} onClick={handleAddWallet}>
                                Add Wallet Details +
                            </Button>}
                        </HStack>
                        <Stack borderRadius={"9px"} mt={"15px"} rowGap={5} bg={"white"}>
                            {/* {Array(4).fill(0).map((_, index) => (
                            <Skeleton height='60px' />
                        ))} */}
                            {isEmpty(cryptoAccounts) ? AddWalletAccount() : walletAccountList()}
                        </Stack>
                    </Box>
                </VStack >
                <AddBankModal
                    isOpen={addBankIsOpen}
                    onClose={addBankOnClose}
                    refreshData={refreshData}
                    setRefreshData={setRefreshData}
                />
                <AddWalletModal
                    isOpen={addWalletIsOpen}
                    onClose={addWalletOnClose}
                    refreshData={refreshData}
                    setRefreshData={setRefreshData}
                    selectedOption={selectedOption}
                    stableCoins={stableCoins}
                    setSelectedOption={setSelectedOption}
                />
                <DeleteWalletAndBank
                    isOpen={deleteIsOpen}
                    onClose={deleteOnClose}
                    refreshData={refreshData}
                    data={deleteData}
                    setRefreshData={setRefreshData}
                />
            </Box>
    );
}