import React, { useRef, useState } from 'react';
import { Box, Button, useToast } from '@chakra-ui/react';
import { GoUpload } from "react-icons/go";
import { isUndefined } from 'lodash';
import Typography from '../../../../components/Common/Typography';
import { useFormikContext } from 'formik';
import { callApiWithToken } from '../../../../utils/http_common';

const UploadInvoice = () => {
  const fileInput = useRef(null);
  const toast = useToast();
  const { setFieldValue } = useFormikContext();
  const [fileDetails, setFileDetails] = useState("png, jpeg, pdf only");
  const [loading, setLoading] = useState(false);

  const handleToast = (title, type) => {
    toast({
      title,
      status: type,
      duration: 5000,
      isClosable: true,
      position: "top",
    });
  };

  const allowedFileTypeMap = {
    "application/pdf": "pdf",
    "image/png": "png",
    "image/jpeg": "jpeg"
  };

  const handleFileUpload = async (event) => {
    setLoading(true);
    const file = event.target.files[0];

    if (isUndefined(allowedFileTypeMap[file.type])) {
      handleToast("File should be png, jpeg, pdf only", "error");
      setLoading(false);
      return;
    }

    const filename = await uploadFileToServer(file);

    if (filename) {
      setFieldValue("taxInvoice", filename); // Adjusted to match server-side expectations
      setFileDetails(file.name);
      handleToast("File uploaded successfully", "success");
    } else {
      handleToast("File upload failed", "error");
    }

    setLoading(false);
  };

  const uploadFileToServer = async (file) => {
    if (!file) {
      return null;
    }

    let timestamp = Math.floor(Date.now() / 1000);
    const filename = `invoice_${timestamp}.` + file?.name?.split('.').pop()//`invoice_${timestamp}.${file.name.split('.').pop()}`;

    try {
      const response = await callApiWithToken.get(`/transaction/uploadFile?fileName=${filename}&fileType=${file.type}`);
      const { uploadUrl } = JSON.parse(response?.data?.data);

      await fetch(uploadUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': file.type,
        },
        body: file,
      });

      return filename;
    } catch (err) {
      console.error('Error uploading to S3:', err);
      return null;
    }
  };

  return (
    <Box marginTop={"8px"}>
      <Typography type='description' weight='regular' color='secondary' fontSize="14px" marginBottom="16px" marginTop="16px">
        Upload any older invoice for reference purpose only
      </Typography>
      <input
        type="file"
        accept=".pdf,.png,.jpeg"
        style={{ display: 'none' }}
        ref={fileInput}
        onChange={handleFileUpload}
      />
      <Button
        variant={"secondary"}
        size={"sm"}
        padding={"9px 16px"}
        width={"154px"}
        height={"40px"}
        border={"1px solid #E4E4E4"}
        marginBottom={"5px"}
        rightIcon={<GoUpload />}
        onClick={() => fileInput.current.click()}
        isLoading={loading}
      >
        Upload Invoice
      </Button>
      <Typography type='description' weight='regular' color='secondary'>
        {fileDetails}
      </Typography>
    </Box>
  );
};

export default UploadInvoice;