import { ArrowForwardIcon, CloseIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

const KybCard = () => {

    const navigate = useNavigate();
    const [isHidden, setIsHidden] = useState(false);
    const { customerType = "", kybStatus } = useSelector((state) => state?.customer?.kybDetails);
    let type = "KYB"
    if (customerType === 'individual') {
        type = "KYC"
    }

    const statusMapping = {
        "": {
            color: "#F04C4C",
            header: `${type} Application pending !`,
            subheader: `Completing your ${type} is required before you can make payments, we need to verify your identity.`,
            buttonText: `Complete ${type}`
        },
        "completed": {
            color: "#26A17B",
            header: `${type} completed !`,
            subheader: `You can see the ${type} details under settings menu.`
        },
        "pending": {
            color: "#1A4FD6",
            header: `${type} Application under review !`,
            subheader: "Your application may take up to 24 hrs to be reviewed.",
            buttonText: "View"
        },
        "failed": {
            color: "#F04C4C",

            header: `${type} Failed!`,
            subheader: `Your ${type} application failed, please try again.`,
            buttonText: `"Complete ${type}`
        },
        "sandbox": {
            color: "#26A17B",
            header: `Sandbox ${type} completed !`,
            subheader: ""
        },

    }

    const handleKybAction = () => {
        navigate('/customer/home/kyb')
    };
    return (
        <Box minH={'123px'} bg={'white'} rounded={'12px'} boxShadow={'sm'} mt={'5'} borderLeftColor={statusMapping[kybStatus]?.color} borderLeftWidth={'26px'} px={'16px'} py={'20px'} display={isHidden ? 'none' : ''}>
            <Flex justify={'space-between'} alignItems={'flex-start'}>
                <Text color={statusMapping[kybStatus]?.color} fontFamily={'DM Sans'} fontSize={'24px'} fontWeight={'700'}>
                    {statusMapping[kybStatus]?.header}
                </Text>
                <Box onClick={() => setIsHidden(true)} _hover={{ cursor: 'pointer' }}>
                    {(kybStatus === 'completed' || (process.env.REACT_APP_NODE_ENV === 'sandbox' && kybStatus === 'sandbox')) && <CloseIcon boxSize={3} />}
                </Box>
            </Flex>
            <Flex justify={'space-between'} alignItems={'center'}>
                <Text color={'#414141'} fontFamily={'DM Sans'} fontSize={'18px'} fontWeight={'400'} mt={4}>
                    {statusMapping[kybStatus]?.subheader}
                </Text>
                {statusMapping[kybStatus]?.buttonText &&
                    <Button
                        variant={"primary"}
                        bg={"#1A4FD6"}
                        _hover={{ bg: '#366cf5' }}
                        color="white"
                        height={'40px'}
                        rounded={'6px'}
                        fontSize={'14px'}
                        fontWeight={'400'}
                        onClick={handleKybAction}
                        rightIcon={<ArrowForwardIcon />}
                    >
                        {statusMapping[kybStatus]?.buttonText}
                    </Button>}
            </Flex>
            <Box>

            </Box>
        </Box >

    );
};

export default KybCard;