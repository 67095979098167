import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import OrderDetailsCard from './OrderDetailsCard';
import { Box, Flex, HStack } from '@chakra-ui/react';
import { GoArrowLeft } from 'react-icons/go';
import Typography from '../../../../components/Common/Typography';
import SummaryDetailsCard from './SummaryDetailsCard';
import { callApiWithToken } from '../../../../utils/http_common';
import OrderService from '../../../../services/createOrderService';

const Summary = () => {
    const { state } = useLocation() || {};
    const { orderDetails } = state || {};
    const navigate = useNavigate()
    const handleBackNavigation = () => {
        window.history.replaceState({}, '');
        navigate(-1);
    }
    const { orderId } = orderDetails;
    const [summary, setSummary] = useState({});
    const [lastStatus, setLastStatus] = useState(null);
    const [loading, setLoading] = useState(null);
    const intervalIdRef = useRef(null);
    const getOrderSummary = async () => {
        try {
            const response = await callApiWithToken.get(`/order/orderSummary/${orderId}`);
            if (response.data.success) {
                const data = response.data.data;
                setSummary(data);
            }
        } catch (error) {
        } finally {

            setLoading(false)
        }
    };

    const checkOrderStatus = async () => {
        try {
            const res = await OrderService.getOrderStatus({ orderId });
            const currentStatus = res?.data?.data?.status;
            if (currentStatus !== lastStatus) {
                setLastStatus(currentStatus);  // Update the last status

                // Stop the interval if the status is completed or failed and there is no need to check further
                if (["fund_settled", "failed", "rejected", "fund_failed", "completed"].includes(currentStatus)) {
                    clearInterval(intervalIdRef.current);
                }

            }
        } catch (error) {
        }
    };


    useEffect(() => {
        const initFetch = async () => {
            // Initial check to set status and fetch summary once
            const res = await OrderService.getOrderStatus({ orderId });
            const initialStatus = res?.data?.data?.status;
            setLastStatus(initialStatus);  // Set the initial status
            // await getOrderSummary();  // Initial detailed fetch based on the first status
        };

        initFetch();

        intervalIdRef.current = setInterval(checkOrderStatus, 5000);

        // Cleanup function to clear the interval when the component unmounts
        return () => {
            clearInterval(intervalIdRef.current);
        };
    }, [orderId]); // Dependencies only include orderId

    // Effect to handle when status changes
    useEffect(() => {
        if (lastStatus !== null) {
            getOrderSummary();  // Call this function whenever lastStatus changes
        }
    }, [lastStatus]); // Depend on lastStatus

    return (
        <Box px={8} py={5} bg={'#F2F2F2'} minH={'92vh'} >
            <Typography color="default" type="largeheader" weight="bold">Payment Summary</Typography>

            <HStack alignItems={'flex-start'} gap={5}>
                <SummaryDetailsCard orderDetails={orderDetails} summary={summary} />
                <OrderDetailsCard orderDetails={orderDetails} isSummary={true} />
            </HStack>

        </Box>
    );
};

export default Summary;