import { find, isEmpty } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
    Badge,
    Box,
    Divider,
    Flex,
    Grid,
    GridItem,
    HStack,
    Heading,
    SimpleGrid,
    Stack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    VStack,
    Link
} from "@chakra-ui/react";
import moment from "moment";

import { colorMap, statusMap } from "../../../constants";
import { getTransactionAction } from "../../../store/slices/transactionSlices";
import FilteredOrders from "../FilteredOrders";
import Loader from '../../../components/Loader';
import { getOrders } from "../../../store/slices/orderSlices";

const PayOrderSummary = () => {
    const orderId = useParams();
    const dispatch = useDispatch();
    const [orders, setOrders] = useState([]);
    const orderData = useSelector((state) => state?.order?.orderDetails);
    const transactionData = useSelector((state) => state?.transaction?.list);
    const orgId = useSelector((state) => state?.login?.orgDetails?.orgId);
    const newStatus = !isEmpty(orderData) ? statusMap[orders.status] : "";

    const USformatter = new Intl.NumberFormat('en-US');

    useEffect(() => {
        getTransactionHandler();
        if (orderData) {
            const filterOrder = find(orderData, orderId);
            if (filterOrder) {
                setOrders(filterOrder)
            }
        }
    }, [])
    const getTransactionHandler = useCallback(() => {
        dispatch(getOrders({ filters: orderId, type: "pay" }));
        dispatch(getTransactionAction({ filters: orderId }));
    }, [dispatch]);

    return (
        <Stack
            direction={"column"}
            justifyContent={"space-between"}
            flexGrow={1}
            p={5}
        >
            {orders ? (
                <Flex direction={"column"} gap={5}>
                    <Grid
                        templateColumns={{
                            base: "repeat(1, 1fr)",
                            sm: "repeat(1, 1fr)",
                            md: "repeat(2, 1fr)",
                        }}
                        gap={10}
                    >
                        <GridItem width={"100%"}>
                            <Flex direction={"column"} gap={3}>
                                <HStack justifyContent={"space-between"}>
                                    <Heading as={"h5"} size="sm">
                                        Order ID
                                    </Heading>
                                    <Text>{orders?.orderId}</Text>
                                </HStack>
                                <HStack justifyContent={"space-between"}>
                                    <Heading as={"h5"} size="sm">
                                        Initiated Date
                                    </Heading>
                                    <Text>
                                        {moment(orders?.createdAt).format("DD-MMM-YYYY @ h:mm A")}
                                    </Text>
                                </HStack>
                                <HStack justifyContent={"space-between"}>
                                    <Heading as={"h5"} size="sm">
                                        Initiated Amount
                                    </Heading>
                                    <Text>
                                        {orders?.depositCurrency} {USformatter.format(orders?.depositAmount)}
                                    </Text>
                                </HStack>
                            </Flex>
                            {
                                orders?.status === 'initiated' && process.env.REACT_APP_NODE_ENV === "production" ?

                                    <HStack mt={"1rem"} justifyContent={"center"}>
                                        <img src="https://transfistaticcontent.blob.core.windows.net/coinbuy/info.svg" />
                                        <Link href="/payin/confirm-order/checkout/">


                                            <Text
                                                fontSize={"0.875rem"}
                                                fontWeight={"500"}
                                                color={"#000"}
                                                lineHeight={"normal"}
                                                fontStyle={"italic"}
                                                textAlign={"center"}
                                            >
                                                View beneficiary details
                                            </Text>
                                        </Link>
                                    </HStack>
                                    : null}

                        </GridItem>

                        <GridItem width={"100%"}>
                            <Flex direction={"column"} gap={3}>
                                <Heading as={"h5"} size={"md"}>
                                    Status of Initiated Amount
                                </Heading>
                                <HStack justifyContent={'space-between'}>
                                    <Heading as={"h5"} size="sm">Status</Heading>
                                    <Badge colorScheme={orders ? colorMap[orders?.status] || 'blue' : 'grey'}>{newStatus}</Badge>
                                </HStack>
                                <HStack justifyContent={"space-between"}>
                                    <Heading as={"h5"} size="sm">
                                        Total Successful
                                    </Heading>
                                    <Text>{orders?.depositCurrency} {USformatter.format(orders?.depositAmount)}</Text>
                                </HStack>
                                <HStack justifyContent={"space-between"}>
                                    <Heading as={"h5"} size="sm">
                                        Total Pending
                                    </Heading>
                                    <Text>{orders?.depositCurrency} 0</Text>
                                </HStack>

                            </Flex>
                        </GridItem>
                    </Grid>
                    <Box border={"1px"} borderColor={"#D2D2D2"} p={3}>
                        <Tabs>
                            <TabList>
                                <Tab>Successful Transactions</Tab>
                                <Tab>Pending Transactions</Tab>
                                <Tab>Failed Transactions</Tab>
                            </TabList>
                            <TabPanels>
                                <TabPanel>
                                    <FilteredOrders status={"completed"} />
                                </TabPanel>
                                <TabPanel>
                                    <FilteredOrders status={"initiated"} />
                                </TabPanel>
                                <TabPanel>
                                    <FilteredOrders status={"failed"} />
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </Box>
                </Flex>
            ) : <Loader />}
        </Stack>
    );
};

export default PayOrderSummary;