import React from 'react';
import { isEmpty } from 'lodash';

import formFields from './fieldsConfigurations';
import { InputControl } from '../../../../components/Common/Input';

const DynamicFields = (props) => {
  const { pmGateway = '', currency, paymentCode = '', contactType = '' } = props;

  const extraFieldsPartners = [
    'PG-230824305141379',
    'PG-230824305141380',
    "PG-230914305141379",
    "PG-240321191632238",
    "PG-231226142412429",
    'PG-231017161345295',
    "PG-290124014234498",
    'PG-231226142202982',
    'PG-240226201619253',
    'PG-240312134719876',
    "PG-240416072834585",
    "PG-240702052513488"
  ];

  if (!(extraFieldsPartners.includes(pmGateway))) {
    return null
  }
  const fields = formFields(pmGateway, currency, paymentCode);

  return (
    (fields || []).map((field) => {
      const { control = '', name = '', label = '', placeholder = '', options = [], type = 'text', allowedContactTypes = [] } = field;
      let inputElement = 
        <InputControl
          key={name}
          control={control}
          name={name}
          label={label}
          placeholder={placeholder}
          isTouch={false}
          options={options}
          required={true}
        />;
      
      inputElement = isEmpty(allowedContactTypes) ? inputElement : allowedContactTypes.includes(contactType) ? inputElement : null;

      return inputElement;
    })
  )
}

export default DynamicFields;
