import React, { useEffect, useState } from 'react';
import { Box, Flex, Center, Image, Icon, useClipboard, Text, Divider, Button, useToast, HStack, Img } from '@chakra-ui/react'
import { CopyIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from 'react-redux';
import { MdOutlineMailOutline } from "react-icons/md";
import { FaRegCheckCircle } from "react-icons/fa";
import { useNavigate } from 'react-router';

import CollectMoneyLayout from './CollectMoneyLayout';
import Typography from '../../components/Common/Typography';
import { sendPaymentLink } from '../../store/slices/collectMoneySlice';
import { getFormattedDate } from '../../utils/getFormattedDate';

const CollectionSummary = () => {

    const userDetails = useSelector((state) => state?.login?.userDetails)

    const [isAdmin, setIsAdmin] = useState(false);
    const [sendingMail, setSendingMail] = useState(false);
    const [mailSentTime, setMailSentTime] = useState('');

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const toast = useToast();

    const orderDetails = useSelector((state) => state.collectMoney.orderData) || {};

    useEffect(() => {
        if (userDetails?.cxRoles.includes('admin') || userDetails?.cxRoles.includes('approver')) {
            setIsAdmin(true)
        }
    }, [userDetails])

    useEffect(() => {
        if (!orderDetails.amount) {
            navigate('/collection')
        }
    }, [orderDetails]);

    const { paymentUrl = '', referenceId = '', orderId = '', countryLogo = '', senderName = {}, email = '', netAmount = '', processingFees = '', amount = '', currency = '', invoiceUrl = '', withdrawCurrency = '', withdrawAmount = '', conversionRate = '' } = orderDetails;

    const { onCopy, hasCopied } = useClipboard(paymentUrl);

    function formatNumber(num) {
        return num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    const handleSendMail = async () => {
        const payload = {
            name: senderName.firstName,
            paymentUrl,
            email,
            orderId
        }
        setSendingMail(true);
        const response = await dispatch(sendPaymentLink(payload))
        if (response.payload.data.status) {
            setSendingMail(false);
            setMailSentTime(getFormattedDate(response.payload.data.data.mailSentAt));
        }
        setSendingMail(false);
    }

    return (
        <CollectMoneyLayout title="Summary">
            <Flex direction={'column'} justify={'center'} alignItems={'center'} gap={5}>

                <Box bg={'white'} p={'40px'} border={'1px'} borderColor={'#E4E4E4'} rounded={'10px'} w={'609px'}>
                    <Center>
                        {isAdmin ? <Image src='/assets/tick.svg' /> : <Image src='/assets/pending.svg' />}
                    </Center>
                    <Flex mt={'5'} justify={'center'} alignItems={'center'} flexDirection={'column'} gap={'2'}>
                        <Typography color='secondary' colorweight={'800'}>
                            {isAdmin ? 'Your payment link is ready to be shared!' : 'Transaction approval is pending'}
                        </Typography>


                        <Typography type='description' weight='regular' color='secondary' colorweight={'800'}>
                            {isAdmin ? 'Copy and paste the link below into emails, chats or apps to share it.' : 'We have notified your approver to provide a response.'}
                        </Typography>
                    </Flex>
                    {isAdmin && <Box bg={'#F2F2F2'} border={'1px'} borderColor={'#E4E4E4'} rounded={'7px'} w={'529px'} mt={'5'} p={'12px'}>
                        <Flex justify={'space-between'} alignItems={'center'}>
                            <Typography type='description' weight='regular' color='secondary' colorweight={'800'}>
                                Payment link
                            </Typography>
                            {/* <Flex>
                                {mailSentTime &&
                                    <Typography type='caption' weight='regular' color='secondary' colorweight={'800'}>
                                        Email sent on {mailSentTime}
                                    </Typography>}
                            </Flex> */}
                        </Flex>
                        <Box mt='3' p='1' rounded={'6px'} display={"flex"} bg={'white'} w='full' justifyContent={"space-between"} marginRight={'10px'} >
                            <Box h={'50px'} w={'full'}>
                                <Text fontWeight={'normal'}>{paymentUrl}</Text>
                            </Box>
                            <Flex gap={1}>
                                {hasCopied ? <Icon as={FaRegCheckCircle} color={'#26A17B'} boxSize={9} /> : <Icon as={CopyIcon} onClick={onCopy} _hover={{ cursor: 'pointer' }} boxSize={9} />}
                                {/* {sendingMail ? <Icon as={FaRegCheckCircle} color={'#26A17B'} /> :
                                    <Icon as={MdOutlineMailOutline} _hover={{ cursor: 'pointer' }} onClick={handleSendMail} />} */}

                            </Flex>
                        </Box>
                    </Box>}

                </Box>
                <Box bg={'white'} p={'40px'} border={'1px'} borderColor={'#E4E4E4'} rounded={'10px'} w={'609px'}>
                    <Flex justify={'start'}>
                        <Typography type='body' color='primary'>Order Details</Typography>
                    </Flex>

                    <Flex mt={6} flexDirection={'column'} gap={7}>
                        {referenceId &&
                            <Flex justify={'space-between'} alignItems={'center'}>
                                <Typography type='description' weight='medium'>Reference Id</Typography>
                                <Typography type='body' weight='regular' color='secondary' colorweight={'800'}>{referenceId}</Typography>
                            </Flex>}
                        <Flex justify={'space-between'} alignItems={'center'}>
                            <Typography type='description' weight='medium'>Transaction Id</Typography>
                            <Typography type='body' weight='regular' color='secondary' colorweight={'800'}>{orderId}</Typography>
                        </Flex>
                        <Flex justify={'space-between'} alignItems={'center'}>
                            <Typography type='description' weight='medium'>Customer</Typography>
                            <Flex gap={1} alignItems={'end'} flexDirection={'column'} justify={'start'}>
                                <Flex gap={1} alignItems={'center'}>
                                    <Image src={countryLogo} w={'25px'} />
                                    <Typography type='body' weight='regular' color='secondary' colorweight={'800'}>{senderName?.firstName} {senderName?.lastName}</Typography>
                                </Flex>
                                <Text fontSize={'12px'} fontWeight={'400'} color={'#A8A8A8'} fontFamily={'DM Sans'}>{email}</Text>
                            </Flex>
                        </Flex>

                    </Flex>

                    <Divider mt={'5'} mb={'2'} w={'full'} />

                    <Flex mt={6} flexDirection={'column'} gap={7}>
                        {invoiceUrl &&
                            <Flex justify={'space-between'} alignItems={'center'}>
                                <Typography type='description' weight='medium'>Invoice</Typography>
                                <a target="_blank" rel="noopener noreferrer" href={invoiceUrl} >
                                    <Text color={'#1A4FD6'} textDecoration={'underline'} _hover={{ cursor: 'pointer' }}>View invoice</Text>
                                </a>
                            </Flex>}
                        <Flex justify={'space-between'} alignItems={'center'}>
                            <Typography type='description' weight='medium'>Invoice amount</Typography>
                            <Typography type='body' weight='regular' color='secondary' colorweight={'800'}>{formatNumber(amount)}  {currency}</Typography>
                        </Flex>
                        <Flex justify={'space-between'} alignItems={'center'}>
                            <Typography type='description' weight='medium'>Processing fee</Typography>
                            <Typography type='body' weight='regular' color='secondary' colorweight={'800'}>{formatNumber(processingFees)}  {currency}</Typography>
                        </Flex>
                        <Flex justify={'space-between'} alignItems={'center'}>
                            <Typography type='description' weight='medium'>Amount to be received</Typography>
                            <Typography type='body' weight='regular' color='secondary' colorweight={'800'}>{formatNumber(netAmount)}  {currency}</Typography>
                        </Flex>
                        {/* <Flex justify={'space-between'} alignItems={'center'}>
                            <Typography type='description' weight='medium'>Amount to be received in {withdrawCurrency}</Typography>
                            <Flex direction={'column'} alignItems={'flex-end'}>
                                <Typography type='caption' weight='regular' color='secondary' colorweight={'800'}> 1 {currency} = {Number(1 / Number(conversionRate)).toFixed(8)} {withdrawCurrency}</Typography>
                                <Typography type='body' weight='regular' color='secondary' colorweight={'800'}>{formatNumber(withdrawAmount)}  {withdrawCurrency}</Typography>
                            </Flex>
                        </Flex> */}
                    </Flex>
                </Box>
            </Flex>
        </CollectMoneyLayout>
    );
};

export default CollectionSummary;