import { Box, Button, Flex, Heading, Image, Text } from "@chakra-ui/react";
import paymentSuccess from "../../assets/animation/success.gif";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getOrderStatus, resetOrder } from "../../store/slices/orderSlices";
import { resetRecipinetList } from "../../store/slices/recipientSlices";
import { resetPaymentMethod } from "../../store/slices/paymentMethodsSlices";
import { useEffect } from "react";

const PaymentSuccess = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrderStatus())
  }, [])

    const onContinue = () => {
      dispatch(resetRecipinetList());
      dispatch(resetPaymentMethod());
      navigate(`/order`)
  }
  return (
    <Box p={5}>
      <Flex direction={"column"} alignItems={"center"} gap={5}>
        <Image src={paymentSuccess} width={"500px"} />
        <Heading color={"#1A4FD6"}>Congratulations</Heading>
        <Text>Your order has been placed successfully</Text>
        <Button
          color={"#ffffff"}
          bg={"#1A4FD6"}
          _hover={{ bg: "#232D95" }}
          minW={"200px"}
          onClick={onContinue}
        >
          Go to Orders Page
        </Button>
      </Flex>
    </Box>
  );
};

export default PaymentSuccess;
