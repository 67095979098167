import React, { useEffect, useState } from 'react';
import {
    Button, Skeleton, Stack, Table, TableContainer, Tbody, Td, Th, Tr, useDisclosure
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import TransactionService from '../../../../services/transactionService';
import Typography from '../../../../components/Common/Typography';
import MakePaymentModal from './MakePaymentModal';
import { currencyFormatter } from '../../../../utils/formatter';

const InvoicesTable = ({ kybStatus }) => {
    const userEmail = useSelector((state) => state?.login?.userDetails?.email)

    const [invoiceData, setInvoiceData] = useState()
    const [invoiceDetails, setInvoiceDetails] = useState({})

    const [loading, setLoading] = useState(false)
    useEffect(() => {
        getInvoices();
    }, [])

    const { isOpen, onOpen, onClose } = useDisclosure()

    const navigate = useNavigate();

    const getInvoices = async () => {
        setLoading(true)
        const { data } = await TransactionService.getCollectionInvoices({ email: userEmail });
        setInvoiceData(data.data.list)
        setLoading(false)
    }

    const handleMakePayment = async (orderDetails) => {
        setInvoiceDetails(orderDetails);
        if ((process.env.REACT_APP_NODE_ENV === 'sandbox' && kybStatus === 'sandbox') || kybStatus === 'completed') {
            navigate('/customer/home/make-payment', { state: { orderDetails } })
        } else {
            onOpen()
        }

    }
    return (
        loading ? <Stack borderRadius={"9px"} mt={"15px"} rowGap={5} bg={"white"}>
            {Array(4).fill(0).map((_, index) => (
                <Skeleton key={index} height='60px' />
            ))}
        </Stack> :
            <TableContainer mt={10} overflowY={'scroll'} position={'relative'} height={'50vh'}>
                <Table variant='simple'>
                    <Tbody >
                        <Tr position={'sticky'} zIndex={2} top={0} bg={'#FFFFFF'}>
                            <Td>
                                <Typography color="secondary" colorweight="400" type='body' weight='medium'>Sent by</Typography>
                            </Td>
                            <Td>
                                <Typography color="secondary" colorweight="400" type='body' weight='medium'>Txn ID</Typography>
                            </Td>
                            <Td>
                                <Typography color="secondary" colorweight="400" type='body' weight='medium'>Invoice Amount</Typography>
                            </Td>
                            <Th></Th>
                        </Tr>

                        {invoiceData?.map((e, index) =>
                            <Tr key={index}>
                                <Td>
                                    <Typography weight={'regular'} type='body' color='secondary' colorweight='800'>{e.customer?.name}</Typography>
                                    <Typography weight={'regular'} type='caption' color='secondary'>{e.customer?.email}</Typography>
                                </Td>
                                <Td>
                                    <Typography weight={'regular'} type='body' color='secondary' colorweight='800'>{e.orderId}</Typography>
                                </Td>
                                <Td>
                                    <Typography weight='bold' color='primary' colorweight='500'>{currencyFormatter(Number(e.depositAmount))}   {e.depositCurrency}</Typography>
                                </Td>
                                <Td>
                                    <Button
                                        variant={"primary"}
                                        bg={"#1A4FD6"}
                                        _hover={{ bg: '#366cf5' }}
                                        color="white"
                                        height={'40px'}
                                        rounded={'6px'}
                                        fontSize={'14px'}
                                        fontWeight={'400'}
                                        onClick={() => handleMakePayment(e)}
                                    >
                                        Make Payment
                                    </Button>
                                </Td>
                            </Tr>

                        )}
                    </Tbody>
                </Table>
                <MakePaymentModal isOpen={isOpen} onOpen={onOpen} onClose={onClose} invoiceDetails={invoiceDetails} />
            </TableContainer>
    );
};

export default InvoicesTable;