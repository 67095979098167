import { Box, Heading } from "@chakra-ui/react";
import Typography from "../../../components/Common/Typography";

export default function MainCard(props) {
    const { children, title, ...styles } = props;
    return (
        <Box {...styles} px={8} py={5} bg={'white'} >
            <Box mb={4}>
                <Typography weight='medium' type='subtitle' color='primary' >
                    {title}
                </Typography>            </Box>
            <Box p={4} borderRadius={10}>
                {children}
            </Box>
        </Box>
    )
}