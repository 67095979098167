import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Flex, Skeleton, Stack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams, useParams } from 'react-router-dom'
import { isUndefined, startCase } from 'lodash'

import { callApiWithToken } from '../../../utils/http_common'
import OrderService from '../../../services/createOrderService'
import Stepper from './Stepper'
import Typography from '../../../components/Common/Typography'
import { OrderDetailsColumn } from './WalletToWalletSuccess'
import { getBalance, setSuccessSummaryLoading } from '../../../store/slices/AddMoneySlice'
import { currencyFormatter } from '../../../utils/formatter'

export default function FiatSuccess(props) {
    const { summary: externalSummary = {}, isExternal = false } = props;
    const dispatch = useDispatch();
    const quotes = useSelector((state) => state.addMoney.quotes?.data);
    const [searchParams] = useSearchParams();
    const [summary, setSummary] = useState(externalSummary || {});
    const [lastStatus, setLastStatus] = useState(null);
    const orderId = useParams()?.orderId || searchParams.get("orderId")
    const balance = useSelector((state) => state.addMoney.balance);
    const successSummaryLoading = useSelector((state) => state.addMoney.successSummaryLoading)

    const [loading, setLoading] = useState(false);

    const getOrderSummary = async () => {
        try {
            const response = await callApiWithToken.get(`/order/orderSummary/${orderId}`);
            if (response.data.success) {
                const data = response.data.data;
                setSummary(data);
            }
        } catch (error) {
        } finally {
            if (successSummaryLoading) {
                dispatch(setSuccessSummaryLoading(false))
            }
        }
    };

    const checkOrderStatus = async () => {
        try {
            const res = await OrderService.getOrderStatus({ orderId });
            const currentStatus = res?.data?.data?.status;
            if (currentStatus !== lastStatus) {
                setLastStatus(currentStatus);  // Update the last status
            }
        } catch (error) {
        }
    };

    useEffect(() => {
        if (!summary || !summary.withdrawCurrency) return;
        dispatch(getBalance(summary?.withdrawCurrency))
    }, [summary, externalSummary])

    useEffect(() => {
        if (!isExternal) return;
        setSummary(externalSummary)
      
    }, [externalSummary])
    
    useEffect(() => {
        if (isExternal) return;
        const initFetch = async () => {
            // Initial check to set status and fetch summary once
            const res = await OrderService.getOrderStatus({ orderId });
            const initialStatus = res?.data?.data?.status;
            setLastStatus(initialStatus);  // Set the initial status
            // await getOrderSummary();  // Initial detailed fetch based on the first status
        };

        initFetch();

        let intervalId = setInterval(checkOrderStatus, 5000);

        // Cleanup function to clear the interval when the component unmounts
        return () => {
            clearInterval(intervalId);
        };
    }, [orderId]); // Dependencies only include orderId

    // Effect to handle when status changes
    useEffect(() => {
        if (isExternal) return;
        if (lastStatus !== null) {
            getOrderSummary();  // Call this function whenever lastStatus changes
        }
    }, [lastStatus]); // Depend on lastStatus

    useEffect(() => {
        if (isExternal) return;
        dispatch(setSuccessSummaryLoading(true))
    }, [])


    if (successSummaryLoading) {
        return (
            <Box mt={6} display={"flex"} flexDirection={"column"} alignItems={"center"} w={"100%"} >
                <Box display={"flex"} flexDirection={"column"} alignItems={"center"} rowGap={4} textAlign={"center"} width={"756px"}>
                    <Skeleton height={"150px"} w={"100%"} />
                    <Skeleton height={"350px"} w={"100%"} />
                </Box>
            </Box>
        )
    }

    const confirmPayment = async () => {
        // call api to mock the webhook
        try {
            setLoading(true)
            const response = await callApiWithToken.get(`/transaction/confirm?orderId=${orderId}&type=collections`);
            if (response.data.success) {
                const data = response.data.data;
            }
        } catch (error) {
        } finally {
            setLoading(false);
        }
    }

    return (
        <Box bg={"white"} borderRadius={"9px"} pt={10} border={"1px solid"} borderColor={"brand.greyPrimary"} display={"flex"} flexDirection={"column"} alignItems={"center"} rowGap={5} textAlign={"center"} w={"800px"}>
            <Stepper orderId={orderId} data={summary} />
            <Box w={"800px"} my={"0px"} p={"40px"} borderTop={"1px solid"} borderColor={"brand.greyPrimary"}>
                <Typography pb="40px" textAlign="left" weight='medium' type='subtitle' color='primary' >
                    Order Details
                </Typography>

                <Flex flexDir={"column"} rowGap={0}>
                    <OrderDetailsColumn label={["completed", "fund_settled"].includes(summary?.status) ? "We received your payment for" : "We are checking your payment for"} value={`${summary?.withdrawCurrency === "BTC" ? currencyFormatter(summary?.withdrawAmount, 5) : currencyFormatter(summary?.withdrawAmount) } ${summary?.withdrawCurrency}`} />

                    {
                        quotes?.processingFee > -1 &&
                        <OrderDetailsColumn label="Processing fee" value={`${summary?.depositCurrency === "BTC" ? currencyFormatter(summary?.fees?.processingFee, 5) : currencyFormatter(summary?.fees?.processingFee) } ${summary?.depositCurrency || ""}`} />
                    }
                    <OrderDetailsColumn label="Amount to be paid" value={`${currencyFormatter(summary.depositAmount) || currencyFormatter(summary?.fiatAmount) || 0} ${summary?.depositCurrency || summary?.fiatTicker}`} />
                    <OrderDetailsColumn label="Payment Method" value={startCase(summary?.paymentType)} />
                    {
                        !isUndefined(summary?.timestamps?.fundSettledAt) ?
                            <OrderDetailsColumn label="Bank Reference Number" value={summary?.fiat?.orderId} /> :
                            null
                    }
                </Flex>
                {/* Seperator */}
                {
                    (summary?.paymentType === "bank" && summary?.bank && !["sandbox", "development"].includes(process.env.REACT_APP_NODE_ENV)) && <>
                        <Box mb={"40px"}>
                            <hr />
                        </Box>
                        <Accordion allowToggle>
                            <AccordionItem>
                                <h2>
                                    <AccordionButton shadow={"none"} borderX={"1.5px solid"} borderColor={"brand.secondary"}>
                                        <Box as="span" flex='1' textAlign='left'>
                                            Beneficiary account details
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                    <Stack gap={"12px"} w={"100%"}>
                                        {
                                            summary?.bank?.iban &&
                                            <OrderDetailsColumn label="IBAN" value={summary?.bank?.iban} copy />
                                        }
                                        {
                                            summary?.bank?.number &&
                                            <OrderDetailsColumn label="Bank account holder" value={summary?.bank?.number} copy />
                                        }
                                        <OrderDetailsColumn label="BIC" value={summary?.bank?.bic} copy />
                                        <OrderDetailsColumn label="Reference text" value={summary?.bank?.referenceText} copy />
                                        <OrderDetailsColumn label="Address" value={summary?.bank?.address} copy />
                                        {
                                            summary?.bank?.bank_name &&
                                            <OrderDetailsColumn label="Bank name" value={summary?.bank?.bank_name} copy />
                                        }
                                        {
                                            summary?.bank?.bank_address &&
                                            <OrderDetailsColumn label="Bank Address" value={summary?.bank?.bank_address} copy />
                                        }
                                    </Stack>
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>
                    </>
                }


                <Box mt={"40px"}>
                    <hr />
                </Box>
                {summary?.withdrawCurrency === "BTC" ?
                    <OrderDetailsColumn label="Current Wallet Balance" value={`${(currencyFormatter(balance?.availableBalance + balance?.intransitBalance,5)) || 0} ${summary?.withdrawCurrency}`} /> :

                    <OrderDetailsColumn label="Current Wallet Balance" value={`${(currencyFormatter(balance?.availableBalance + balance?.intransitBalance)) || 0} ${summary?.withdrawCurrency}`} />
                }
                {/* {["development", "sandbox"].includes(process.env.REACT_APP_NODE_ENV) && !["fund_settled", "completed"].includes(lastStatus) &&
                        <Button disabled={successSummaryLoading} isDisabled={successSummaryLoading} isLoading={loading} onClick={confirmPayment} variant={"primary"} size={"md"} p={"9px 16px 9px 16px"} mt={4}>
                            Confirm Payment
                        </Button>} */}
            </Box>
        </Box>
    )
}