import { Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';

import { getDataFromSession, saveDataToSession } from '../../utils/session-storing-utils';

const ProfileBadge = () => {
    const userDetails = useSelector((state) => state?.login?.userDetails)
    const { firstName, lastName, name } = userDetails || {};
    const firstLetter = firstName?.charAt(0) || name?.charAt(0);
    const lastLetter = lastName?.charAt(0) || "";
    let colorCode;
    colorCode = getDataFromSession("connectionData", "colorCode");
    if (!colorCode) {
        const randomNumber = Math.floor(Math.random() * (5 - 1 + 1)) + 1;
        saveDataToSession("connectionData", "colorCode", randomNumber);
        colorCode = getDataFromSession("connectionData", "colorCode");
    };
    const colorMapping = {
        "1": { text: "#1A4FD6", bg: "#E8EDFB" },
        "2": { text: "#E57936", bg: "#FFF4ED" },
        "3": { text: "#B60000", bg: "#FDE5E5" },
        "4": { text: "#006E48", bg: "#E9F6F2" },
        "5": { text: "#CC8C00", bg: "#FFF9EB" }
    }
    const color = colorMapping[colorCode]

    return (
        <Flex justify={'center'} alignItems={'center'} textAlign={'center'} color={color?.text} bg={color?.bg} fontWeight={'400'} fontSize={'16px'} h={'36px'} w={'36px'} rounded={'5px'}>
            <Text>{firstLetter}{lastLetter}</Text>
        </Flex>
    );
};

export default ProfileBadge;