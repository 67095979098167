import React, { useState } from "react";
import { useSelector } from "react-redux";
import { upperCase } from "lodash";
import Loader from "react-js-loader";
import {
    Box,
    Flex,
    Text
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { MdAccountBalanceWallet } from "react-icons/md";

export default function SettlementDashboard() {

    const [loading, setLoading] = useState(false);
    const orgType = useSelector((state) => state?.login?.orgDetails?.orgType || "default");

    const dashboards = [
        { dashboard: "Balance Overview", icon: MdAccountBalanceWallet, link: "/balances/settlement/payin", visibility: true },
    ];

    const Dashboard = (data) => {
        const details = data?.data;
        return <>
            {
                details.visibility && <Box className="balance-card-zoom" width={'250px'} height={'130px'} borderRadius={'4px'} px={'20px'} py={'15px'} boxShadow={'lg'}  >
                    <Link to={`${details.link}`}>
                        <Box display={'flex'} justifyContent={'center'} pt={'10px'} >
                            <Box height={'85px'}>
                                <Text fontSize={'20px'} fontWeight={'600'}>{upperCase(details?.dashboard)}</Text>
                            </Box>
                            <Flex width={'150px'} height={'85px'} direction={'column'} align={'end'}  >
                                <details.icon style={{ fontSize: '50px', marginBottom: '10px' }} />
                            </Flex>
                        </Box>
                    </Link>
                    <Box style={{ position: 'relative', marginTop: '10px' }}>
                        <Text
                            fontSize={'14px'}
                            fontWeight={'500'}
                            position={"absolute"}
                            bottom={0}
                            right={0}
                        >{details.subtext}</Text>
                    </Box>
                </Box>
            }
        </>
    }

    const renderTransfiDashboards = () => {
        const transfiDashboards = Object.keys(dashboards).sort();

        return <Box display={'flex'} justifyContent={'left'} py={'20px'} px={'10px'} flexWrap={'wrap'} gap={'4.45%'}>
            {transfiDashboards.map(value => {
                return <Box mb={"40px"} ><Dashboard data={dashboards[value]} /></Box>
            })}
        </Box>
    }

    return !loading ? (
        <>
            <Box w="100%" p={4}>
                <Text fontSize={'20px'} mb={'5px'} fontWeight={500}>Balances Dashboard</Text>
                {renderTransfiDashboards()}
            </Box>
        </>
    ) : (
        <Loader
            type="spinner-cub"
            bgColor={"#FFFFFF"}
            title={"Loading..."}
            color={"cyan.400"}
            size={100}
        />
    );
};