const settings = [
    {
      label: "Country",
      key: "country",
    },
    {
      label: "Currency",
      key: "currency",
    },
    {
      label: "Payment Method (Deposit)",
      key: "paymentMethodDeposit",
    },
    {
      label: "Payment Method (Withdrawal)",
      key: "paymentMethodWithdrawal",
    },
    {
      label: "Deposit",
      key: "deposit",
    },
    {
      label: "Withdrawal",
      key: "withdrawal",
    },
    {
      label: "Actions",
      key: "Actions",
    },
  ];

  module.exports = { settings };