import { actions } from "react-table";
import RecipientService from "../../services/recipientService";
import { getItem } from "../../utils/local_storage";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const getRecipient = createAsyncThunk(
  "recipient/getRecipient",
  async (body, { rejectWithValue, getState }) => {
    const orderId = getState().order.orderId || getItem("orderId");
    try {
      const resp = await RecipientService.getRecipientData(orderId);
      return resp?.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const postRecipient = createAsyncThunk(
  "recipient/postRecipient",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await RecipientService.postRecipientData(data);
      return resp?.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const walletAddressValidation = createAsyncThunk(
  "recipient/walletAddressValidation",
  async ({ walletAddress, currency, network }, { rejectWithValue }) => {
    try {
      const resp = await RecipientService.postWalletAddress({
        address: walletAddress,
        cryptoTicker: currency,
        cryptoNetwork: network,
      });
      return resp?.data;
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export const getCountryList = createAsyncThunk(
  "countries",
  async (body, { rejectWithValue }) => {
    try {
      const resp = await RecipientService.getCountries();
      return resp?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getRecipientData = createAsyncThunk(
  "recipient/recipientList",
  async (body, { rejectWithValue, getState }) => {
    const orgDetails = getState().login.orgDetails;
    try {
      const resp = await RecipientService.getRecipientList(orgDetails.orgId);
      return resp?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getRecipientTemplate = createAsyncThunk(
  "recipient/recipientTemplate",
  async ({product}, { rejectWithValue }) => {
    try {
      const resp = await RecipientService.getRecipientTemplate({product});
      return resp?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
)

const recipientSlice = createSlice({
  name: "recipient",
  initialState: {
    list: [],
    loading: false,
    isSuccess: false,
    message: "",
  },
  extraReducers: {
    [getRecipient.pending]: (state, action) => {
      state.loading = true;
    },
    [getRecipient.fulfilled]: (state, action) => {
      const { list } = action.payload?.data;
      state.list = list;
      state.loading = false;
    },
    [getRecipient.rejected]: (state, action) => {
      state.loading = false;
      state.isSuccess = false;
      state.message = "error found in action payload";
    },
    [getRecipientData.pending]: (state, action) => {
      state.loading = true;
    },
    [getRecipientData.fulfilled]: (state, action) => {
      const { list = [] } = action.payload?.data;
      state.recipientList = list;
      state.loading = false;
    },
    [getRecipientData.rejected]: (state, action) => {
      state.loading = false;
      state.isSuccess = false;
      state.message = "error found in action payload";
    },
    [getCountryList.pending]: (state, action) => {
      state.loading = true;
    },
    [getCountryList.fulfilled]: (state, action) => {
      const { countries = [] } = action.payload.data;
      state.countries = countries;
      state.loading = false;
    },
    [getCountryList.rejected]: (state, action) => {
      state.loading = false;
      state.isSuccess = false;
    },
    [getRecipientTemplate.pending]: (state, action) => {
      state.loading = true;
    },
    [getRecipientTemplate.fulfilled]: (state, action) => {
      const { list = [] } = action.payload?.data;
      state.loading = false;
      state.template = list;
      state.isSuccess = true;
    },
    [getRecipientTemplate.rejected]: (state, action) => {
      state.loading = false;
      state.isSuccess = false;
    },
    [walletAddressValidation.pending]: (state, action) => {
      state.loading = true;
    },
    [walletAddressValidation.fulfilled]: (state, action) => {
      state.loading = false;
      state.isSuccess = true;
    },
    [walletAddressValidation.rejected]: (state, action) => {
      state.loading = false;
      state.isSuccess = false;
      state.message = action.payload?.error?.message;
    },
  },
  reducers: {
    countryList: (state, action) => {
      state.countries = action.payload;
    },
    resetRecipinetList: (state, action) => {
      state.list = [];
      state.recipientList = [];
    }
  },
});

const { reducer: recipientReducer } = recipientSlice;
export const { countryList, resetRecipinetList } = recipientSlice.actions;
export default recipientReducer;
