import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, Flex, Icon, Img, Skeleton, Stack, Tbody, Td, Tr, VStack, useToast, Image, Heading, Tooltip } from '@chakra-ui/react'
import { BiPlus } from 'react-icons/bi'
import { Link } from 'react-router-dom'
import EmptyIcon from '../../assets/emptypage.svg';

import { resetAddMoney, setBalance, setSelectedCurrency } from '../../store/slices/AddMoneySlice'
import Typography from '../../components/Common/Typography'
import StickyTable from '../../components/Common/Table/StickyTable'

import "./my-account.css"
import AccountServide from '../../services/accountService'
import { isEmpty } from 'lodash'
import { currencyFormatter, formatDateTime } from '../../utils/formatter'
import { getCountryByIp } from '../../store/slices/commonSlice'
import { InfoOutlineIcon } from '@chakra-ui/icons'

const fiatLabels = [
    {
        label: "Currency",
    },
    {
        label: "Available Balance",
        info: "The amount of funds that are available for use in your account."
    },
    {
        label: "In Transit",
        info: "The amount of funds that are currently in transit."
    },
    {
        label: "Locked Balance",
        info: "The amount of funds that are locked in your account."
    },
    {
        label: ""
    },
    {
        label: ""
    }
]
const stableCoinLabel = [
    {
        label: "Digital Asset",

    },
    ...fiatLabels.slice(1, fiatLabels.length),
]


const TableColumn = (props) => {
    const { currency, logo, availableBalance, transitBalance, lockedBalance, addMoneyClick, settlementTime } = props
    return (
        <Tr height={"46px"}>
            <Td>
                <Box display={"flex"} alignItems={"center"} columnGap={1} w={"max-content"}>
                    <Flex columnGap={2} alignItems={"center"}>
                        <Img src={process.env.PUBLIC_URL + (logo || `/assets/crypto/${currency}.svg`)} alt={""} width={38} height={9} />
                        <Typography w="28px" weight='regular' type='description'>{currency}</Typography>
                    </Flex>
                    {
                        settlementTime &&
                        <Box fontSize={"16px"} h={"max-content"} w={"max-content"}>

                            <Tooltip hasArrow fontSize={"12px"} p={"8px 10px"} textColor={"black"} bg={"brand.white"} label={`Prefund will reflect after ${formatDateTime(settlementTime)}`} placement='top'>
                                <InfoOutlineIcon w={5} h={3.5} color='brand.greySecondary' />
                            </Tooltip>
                        </Box>
                    }
                </Box>
            </Td>
            <Td>
                <Box w={"50%"}>
                    { currency === "BTC" ?
                        <Typography weight='regular' type='description' textAlign="right">{currencyFormatter(availableBalance,5)}</Typography> :
                        <Typography weight='regular' type='description' textAlign="right">{currencyFormatter(availableBalance)}</Typography>
                    }
                    </Box>
            </Td>
            <Td>
                <Box w={"50%"}>
                    {currency === "BTC" ?
                        <Link to={`/payout-transactions?status=in-transit&currency=${currency}`}><Typography textAlign="right" weight='regular' type='description'>{currencyFormatter(transitBalance,5)}</Typography></Link> :
                        <Link to={`/payout-transactions?status=in-transit&currency=${currency}`}><Typography textAlign="right" weight='regular' type='description'>{currencyFormatter(transitBalance)}</Typography></Link>
                    }
                </Box>
            </Td>
            <Td>
                <Box w={"50%"}>
                    {currency === "BTC" ?
                        <Link to={`/payout-transactions?status=locked&currency=${currency}`}><Typography textAlign="right" weight='regular' type='description'>{currencyFormatter(lockedBalance, 5)}</Typography></Link> :
                        <Link to={`/payout-transactions?status=locked&currency=${currency}`}><Typography textAlign="right" weight='regular' type='description'>{currencyFormatter(lockedBalance)}</Typography></Link>
                    }
                </Box>
            </Td>
            {/* {
                settlementTime ? <Td>
                    <Typography weight='regular' type='description'>{`Prefund will reflect after ${formatDateTime(settlementTime)}`}</Typography>
                </Td> :
                    <Td>
                        <Typography width="312px" weight='regular' type='description'></Typography>
                    </Td>
            } */}

            <Td>
                <Button onClick={addMoneyClick} variant='outline' size='sm'>
                    Add Money <Icon ml={2} as={BiPlus} />
                </Button>
            </Td>
        </Tr >
    )
}

export default function MyAccounts() {
    const toast = useToast()
    const userDetails = useSelector((state) => state?.login?.userDetails || {});
    const [loading, setLoading] = useState(true);
    const [accounts, setAccounts] = useState({
        fiat: [],
        crypto: []
    });
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const handleMoneyAdd = (type, value, label, logo, balance) => {
        if (userDetails.cxRoles.includes("maker") || userDetails.cxRoles.includes("finance_manager")) {
            toast({
                title: "You are not allowed to add money",
                status: "error",
                position: "top",
                duration: 2000,
                isClosable: true,
            })
            return;
        }
        dispatch(setSelectedCurrency({ type, value, label, logo }))
        dispatch(setBalance({
            availableBalance: balance?.availableBalance,
            intransitBalance: balance?.intransitBalance,
            lockedBalance: balance?.lockedBalance,
        }))
        navigate("/my-accounts/add-money")
    }

    const getMyAccounts = async () => {
        try {
            const res = await AccountServide.getMyAccountList();
            if (res?.data?.success === false) {
                setAccounts({
                    fiat: [],
                    crypto: []
                });
                return;
            }
            const cryptoAccounts = []
            const fiatAccounts = [];
            res?.data?.data?.response?.forEach((account) => {
                if (account.type === "fiat") {
                    fiatAccounts.push(account)
                }
                if (account.type === "crypto") {
                    cryptoAccounts.push(account)
                }
            })
            setAccounts({
                fiat: fiatAccounts || [],
                crypto: cryptoAccounts || []
            });
        } catch (error) {
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        dispatch(resetAddMoney())
        getMyAccounts()
        if (!localStorage.getItem("country") || !localStorage.getItem("countryCode")) {
            dispatch(getCountryByIp())
        }
    }, []);



    if (loading) {
        return <Box minH={"92vh"} p={"16px 40px"} bg={"#F2F2F2"}>
            <Typography type='largeheader' weight='bold' >
                My Accounts
            </Typography>
            <VStack w={"100%"} marginTop={"15px"} gap='24px'>
                <Box bg='white' w='100%' mt={6} borderRadius={"9px"} p={"20px 40px"}>
                    <Typography type='body' weight='medium' color='primary' >
                        Digital Asset Balances
                    </Typography>
                    <Stack borderRadius={"9px"} mt={"15px"} rowGap={5} bg={"white"}>
                        {/* <Skeleton height='60px' /> */}
                        {Array(2).fill(0).map((_, index) => (
                            <Skeleton height='60px' />
                        ))}
                    </Stack>
                </Box>
                <Box bg='white' w='100%' mt={6} borderRadius={"9px"} p={"20px 40px"}>
                    <Typography type='body' weight='medium' color='primary' >
                        Fiat Balances
                    </Typography>
                    <Stack borderRadius={"9px"} mt={"15px"} rowGap={5} bg={"white"}>
                        {/* <Skeleton height='60px' /> */}
                        {Array(4).fill(0).map((_, index) => (
                            <Skeleton height='60px' />
                        ))}
                    </Stack>
                </Box>
            </VStack >
        </Box>
    }

    if (isEmpty(accounts?.fiat) && isEmpty(accounts?.crypto)) return (
        <Box className='al-main-card'>
            <Typography type='largeheader' weight='bold' >
                My Accounts
            </Typography>
            <Flex bg={'white'} width={'full'} minHeight={'100vh'} mt={5} rounded={'9px'} justify={'center'} alignItems={'center'} border={'1px'} borderColor={'#E4E4E4'} h={'full'} direction={'column'}>
                <Image src={EmptyIcon} mb={'2'} />
                <Typography color="secondary" colorweight='800' type="title" weight="medium">No Accounts found</Typography>
            </Flex>
        </Box>
    )

    return (
        <Box minH={"92vh"} p={"16px 40px"} bg={"#F2F2F2"}>
            <Typography type='largeheader' weight='bold' >
                My Accounts
            </Typography>

            <VStack w={"100%"} marginTop={"15px"} gap='24px'>
                {
                    accounts?.crypto?.length > 0 && <Box bg='white' w='100%' borderRadius={"9px"} p={"20px 40px"}>
                        <Typography type='body' weight='medium' color='primary' >
                            Digital Asset Balances
                        </Typography>
                        <StickyTable maxH={"40vh"} labels={stableCoinLabel}>
                            <Tbody>
                                {accounts.crypto?.map((item, index) => (
                                    <TableColumn key={index} currency={item.currency} logo={item.logo} availableBalance={item?.balance?.availableBalance} transitBalance={item?.balance?.intransitBalance} lockedBalance={item?.balance?.lockedBalance}
                                        addMoneyClick={() => handleMoneyAdd(item.type, item.currency, item.currency, (item.logo || `/assets/crypto/${item.currency}.svg`), item.balance)}
                                    />
                                ))}
                            </Tbody>
                        </StickyTable>
                    </Box>
                }
                {
                    accounts?.fiat?.length > 0 && <Box bg='white' w='100%' borderRadius={"9px"} p={"20px 40px"}>
                        <Typography type='body' weight='medium' color='primary' >
                            Fiat Balances
                        </Typography>
                        <StickyTable maxH={"40vh"} labels={fiatLabels}>
                            <Tbody>
                                {accounts.fiat?.map((item, index) => (
                                    <TableColumn key={index} currency={item.currency} logo={item.logo} availableBalance={item?.balance?.availableBalance} transitBalance={item?.balance?.intransitBalance} lockedBalance={item?.balance?.lockedBalance} settlementTime={item.settlementTime}
                                        addMoneyClick={() => handleMoneyAdd(item.type, item.currency, item.currency, item.logo, item.balance)}
                                    />
                                ))}
                            </Tbody>
                        </StickyTable>
                    </Box>
                }


                {/* <Box bg='white' w='100%' p={"20px 40px"}>
                    <Typography type='body' weight='medium' color='primary' >
                        Digital Asset Balances
                    </Typography>

                    <StickyTable maxH={"33vh"} labels={stableCoinLabel}>
                        <Tbody>
                            {cryptoData.map((item, index) => (
                                <TableColumn key={index} currency={item.currency} logo={item.icon} availableBalance={item.availableBalance} transitBalance={item.inTransit} lockedBalance={item.lockedBalance}
                                    addMoneyClick={() => handleMoneyAdd("stablecoin", item.currency, item.currency, item.icon)} />
                            ))}
                        </Tbody>
                    </StickyTable>
                </Box> */}
            </VStack>
        </Box>
    )
}
