import React, { useState } from 'react'
import { Box, Button, Flex, Stack } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import Typography from '../../../components/Common/Typography'
import CurrencyForm from './Form'
import { goToPeviousStep, setSelectedBank, setShowBanks } from '../../../store/slices/AddMoneySlice'
import PaymentMethods from './PaymentMethods'
import { currencyFormatter } from '../../../utils/formatter'

export default function Details(props) {
    const { selectedOption, setSelectedOption, selectedNetworkOption, setSelectedNetworkOption } = props;
    const navigate = useNavigate()
    const step = useSelector((state) => state.addMoney.step)
    const loading = useSelector((state) => state.addMoney.loading)
    const showBanks = useSelector((state) => state.addMoney.showBanks);
    const currencyToPay = useSelector((state) => state.addMoney.currencyToPay);
    const selectedCurrency = useSelector((state) => state.addMoney.selectedCurrency);
    const balance = useSelector((state) => state.addMoney.balance);

    const dispach = useDispatch()
    const handleBack = () => {
        if (showBanks) {
            dispach(setShowBanks(false))
            dispach(setSelectedBank({
                value: "",
                label: "",
                logo: ""
            }))
            return;
        }
        if (step === 0) {
            navigate('/my-accounts')
            return;
        }
        dispach(goToPeviousStep());
    }
    return (
        <>
            <Box>
                <Box borderTopRadius={"9px"} w={"100%"} bg='white' p={"20px 40px"}>
                    <Flex justifyContent={"space-between"} alignItems={"center"}>
                        <Typography pb={8} type='body' weight='medium' color='primary' >
                            Details
                        </Typography>

                        <Flex columnGap={2} alignItems={"center"}>
                            <Typography color='headertext' weight='medium' type='caption'>
                                Current balance
                            </Typography>
                            <Typography color='headertext' weight='medium' type='body' textTransform={"uppercase"}>
                                {
                                    selectedCurrency?.value === "BTC" ?
                                        currencyFormatter((balance?.availableBalance + balance.intransitBalance) || 0, 5) :
                                        currencyFormatter((balance?.availableBalance + balance.intransitBalance) || 0)
                                }
                                {" " + selectedCurrency?.value}
                            </Typography>
                        </Flex>
                    </Flex>

                    <CurrencyForm selectedOption={selectedOption} setSelectedOption={setSelectedOption} selectedNetworkOption={selectedNetworkOption} setSelectedNetworkOption={setSelectedNetworkOption} />
                </Box>

                {(showBanks && currencyToPay?.type === "fiat" && balance) && <PaymentMethods />}
                <Stack borderBottomRadius={"9px"} pt={8} bg={"white"} direction="row" p={"20px 40px"} gap={"20px"} justifyContent={"end"} w='100%' marginTop={0}>
                    <Button onClick={handleBack} variant={"secondary"} size={"sm"} type="button" p={"9px 16px 9px 16px"}>
                        Back
                    </Button>
                    <Button _loading={{ pointerEvents: "none" }} isLoading={loading} variant={"primary"} type="submit" size={"sm"} p={"9px 16px 9px 16px"}>
                        Next
                    </Button>
                </Stack>

            </Box>
        </>

    )
}
