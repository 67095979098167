import { callApi, callApiWithToken } from "../utils/http_common";

const verifyUser = (data) => {
    return callApi.post("/customer/verifyCustomer", data);
};

const verifyCustomer = (data) => {
    return callApi.post("/customer/verifyCustomerV2", data);
};

const googleLogin = (data) => {
    return callApi.post("/customer/googleLogin", data);
};

const check2FA = (data) => {
    return callApi.post("/customer/check2FA", data);
};

const createOrganization = (data) => {
    return callApi.post(`/organization/createOrganization`, data);
};

const createCustomer = (data) => {
    return callApi.post(`/customer/createCustomer`, data);
};

const getUser = () => {
    return callApiWithToken.get(`/customer/getCustomer`);
};

const heartBeat = async () => {
    return await callApiWithToken.get("/customer/heartBeat");
}

const LoginService = {
    verifyUser,
    check2FA,
    getUser,
    createOrganization,
    createCustomer,
    heartBeat,
    verifyCustomer,
    googleLogin
};

export default LoginService;