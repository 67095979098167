import { Badge, Stack, Text, Box, HStack, Button, useDisclosure } from "@chakra-ui/react";
import { startCase, round } from 'lodash';
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { HiOutlineFilter } from "react-icons/hi";
import { BiReset } from 'react-icons/bi';
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme For AgTables

import OrderFilter from "../OrderFilter";
import OrderService from '../../../services/createOrderService';
import { getOrders } from "../../../store/slices/orderSlices";
import CustomTable from "../../../utils/customTable";
import { gamingStatusMap, colorMap } from "../../../constants";
import Loader from "../../../components/Loader";
import GridV2 from "../../../utils/agTablesV2";

const GamingOrders = () => {
    const dispatch = useDispatch();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [refreshGrid, setRefreshGrid] = useState(false);
    const orderData = useSelector((state) => state?.order?.orderDetails);
    const orgId = useSelector((state) => state?.login?.orgDetails?.orgId);
    const isLoading = useSelector((state) => state?.order?.loading);
    const orgType = useSelector((state) => state?.login?.orgDetails?.orgType || "default");
    const [, setSummaryData] = useState([]);
    const navigate = useNavigate();

    const USformatter = new Intl.NumberFormat('en-US');

    useEffect(() => {
        if (orgType === "ecom" || orgType === "invoice")
            getOrdersData()
    }, [orgType]);

    const getOrdersData = useCallback(() => {
        dispatch(getOrders({ filters: { orgId, type: "gaming" } }))
    }, [dispatch]);

    const redirectToCreateOrder = () => {
        window.history.replaceState(null, '', '');
        navigate("/gaming/create-order", { replace: true });
    }

    const getOrderData = async (filters) => {
        filters["orgId"] = orgId;
        filters["type"] = "gaming";
        const responseData = await OrderService.getOrder({ filters });
        setSummaryData(responseData.data.data.summaryArray);
        return responseData;
    }

    var filterParams = {
        comparator: (filterLocalDateAtMidnight, cellValue) => {
            var dateAsString = cellValue;
            if (dateAsString == null) return -1;
            var cellDate = new Date(cellValue);
            if (filterLocalDateAtMidnight.toLocaleDateString() === cellDate.toLocaleDateString()) {
                return 0;
            }
            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            }
            if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            }
            return 0;
        },
        minValidYear: 2000,
    };

    const handleReset = () => {
        setRefreshGrid(!refreshGrid);
    };

    const columnsCustomTable = [
        {
            Header: "Order ID",
            accessor: "orderId",
            customerVisibility: true,
            width: '200',
            Cell: ({ value }) => {
                return <Link to={`/order/pay-in-order/${value}`} style={{ color: "#1A4FD6", fontWeight: "bold", fontSize: '14px' }} >{value}</Link>;
            },
        },
        {
            Header: "Order Status",
            accessor: "status",
            customerVisibility: true,
            Cell: ({ value }) => {
                const newStatus = gamingStatusMap[value] || value;
                const color = colorMap[value];
                return <Badge colorScheme={color}>{newStatus}</Badge>;
            },
        },
        {
            Header: "Initiated Date",
            accessor: "createdAt",
            customerVisibility: true,
            width: '220',
            Cell: ({ value }) => (
                <Text>{moment(value).format("DD-MMM-YYYY @ h:mm A")}</Text>
            ),
        },
        {
            Header: "Updated Date",
            accessor: "timestamps.fundSettledAt",
            customerVisibility: true,
            width: '220',
            Cell: ({ value }) => (
                <Text>{moment(value).format("DD-MMM-YYYY @ h:mm A")}</Text>
            ),
        },
        {
            Header: "Total Transactions",
            accessor: "recipientCount",
            customerVisibility: true,
            width: '200'
        },
        {
            Header: "Currency",
            accessor: "fiatTicker",
            customerVisibility: true,
        },
        {
            Header: "Crypto",
            accessor: "cryptoTicker",
            customerVisibility: true,
        },
        {
            Header: "Fiat Amount",
            accessor: "fiatAmount",
            customerVisibility: true,
            Cell: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
    ];

    const columnsAgTable = [
        {
            headerName: "Order ID TransFi",
            field: "orderId",
            getQuickFilterText: params => {
                return params.value.name;
            },
            customerVisibility: true,
            width: '250',
            cellRenderer: ({ value }) => {
                return <Link style={{ color: "#1A4FD6", fontWeight: "bold", fontSize: '14px' }} >{value}</Link>;
            },
        },
        {
            headerName: "Customer Order ID",
            field: "customer.orderId",
            customerVisibility: true,
            width: '250',
        },
        {
            headerName: "Order Status",
            field: "status",
            customerVisibility: true,
            enableRowGroup: true,
            filterParams: {
                applyMiniFilterWhileTyping: true,
            },
            cellRenderer: ({ value }) => {
                const newStatus = gamingStatusMap[value] || value;
                const color = colorMap[value];
                return <Badge width={"100%"} textAlign={"center"} colorScheme={color}>{newStatus}</Badge>;
            },
        },
        {
            headerName: "Sender Name", customerVisibility: true, width: '200',
            cellRenderer: ({ data }) => {
                const { firstName = "", lastName = "" } = data?.senderName ?? {};
                return `${firstName} ${lastName}`;
            }
        },
        {
            headerName: "Purpose of Transaction", field: "purposeCode", enableRowGroup: true, customerVisibility: true, cellRenderer: ({ value }) => (
                <Text>{value ? startCase(value) : ''}</Text>
            )
        },
        {
            headerName: "Initiated Date",
            field: "timestamps",
            filterParams: filterParams,
            enableRowGroup: false,
            customerVisibility: true,
            width: '220',
            cellDataType: 'date',
            filter: 'agDateColumnFilter',
            cellRenderer: ({ value }) => (
                value?.initiatedAt ? <Text>{moment(value?.initiatedAt).format("DD-MMM-YYYY @ h:mm A")}</Text> : '-'
            ),
        },
        {
            headerName: "Settled Date",
            field: "timestamps",
            filterParams: filterParams,
            enableRowGroup: false,
            customerVisibility: true,
            width: '220',
            cellDataType: 'date',
            filter: 'agDateColumnFilter',
            cellRenderer: ({ value }) => (
                value?.fundSettledAt ? <Text>{moment(value?.fundSettledAt).format("DD-MMM-YYYY @ h:mm A")}</Text> : '-'
            ),
        },
        { headerName: "Fiat Payment Method", field: "paymentName", customerVisibility: true, width: '200' },
        { headerName: "Fiat Currency", field: "fiat.currency", customerVisibility: true, width: '200' },
        {
            headerName: "Gross Fiat Amount (Received from the user)",
            field: "fiat.orderAmount",
            customerVisibility: true,
            width: '200',
            cellRenderer: ({ value }) => (
                <Text>{value}</Text>
            )
        },
        {
            headerName: "Processing Fees Amt.",
            customerVisibility: true,
            width: '200',
            cellRenderer: ({ data }) => {
                const { fees = {} } = data;
                if (fees?.feeMode === "hybrid") {
                    const processingFeeAmount = (fees?.processingFee ?? 0) + (fees?.fixedFee?.totalFixedFees ?? 0);
                    return USformatter.format(processingFeeAmount);
                }

                return USformatter.format(data?.processingFeesAmountFiat1 ?? 0);
            }
        },
        {
            headerName: "Net Amount",
            customerVisibility: true,
            width: '200',
            cellRenderer: ({ data }) => {
                if (data?.fees?.feeMode === "hybrid") {
                    return USformatter.format(data?.fiat?.netAmount ?? 0);
                }
                return USformatter.format(data?.netAmount ?? 0);
            }
        },
        {
            headerName: "Conversion rate charged by TransFi",
            field: "conversionRatesChargedTransfi",
            filter: 'agNumberColumnFilter',
            cellDataType: 'number',
            customerVisibility: true,
            cellRenderer: ({ value }) => (
                <Text>{value ? round(value, 8) : '0'}</Text>
            )
        },
        {
            headerName: "Crypto To Be Sold",
            aggFunc: 'sum',
            customerVisibility: true,
            filter: 'agNumberColumnFilter',
            cellDataType: 'number',
            cellRenderer: ({ data }) => {
                if (data?.fees?.feeMode === "hybrid") {
                    return USformatter.format((data?.fiat?.netAmount ?? 0) * (data?.conversionRatesChargedTransfi ?? 0));
                }

                return USformatter.format(data?.cryptoToBeSold ?? 0);
            }
        },
        {
            headerName: "Digital Asset Sent",
            field: "crypto.orderAmount",
            cellDataType: 'number',
            customerVisibility: true,
            filter: 'agNumberColumnFilter',
            cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : 'N/A'}</Text>
            )
        },
        { headerName: "Crypto Ticker", field: "withdrawCurrency", customerVisibility: true, width: '200' },
        { headerName: "Crypto Purchase Rate", field: "conversionRateCryptoToUsd", customerVisibility: true, width: '200' },
        {
            headerName: "Crypto Wallet Address", field: "crypto.walletAddress", customerVisibility: true, cellRenderer: ({ value }) => (
                <Text>{value ? value : "N/A"}</Text>
            )
        },
        {
            headerName: "Transaction hash", field: "crypto.transactionHash", customerVisibility: true, cellRenderer: ({ value }) => (
                <Text>{value ? value : "N/A"}</Text>
            )
        },
    ];

    return (
        <Stack
            direction={"column"}
            justifyContent={"space-between"}
            flexGrow={1}
            p={5}
        >

            {!isLoading ? <Box >
                <HStack p={5} justifyContent={"flex-end"}>
                    {
                        orgType === "invoice" ?
                            <Button
                                color={"#ffffff"}
                                variant={"outline"}
                                backgroundColor={"#1a4fd6"}
                                borderColor={"#1a4fd6"}
                                _hover={{ bg: "#1a4fd6" }}
                                onClick={redirectToCreateOrder}
                            >
                                Create Order
                            </Button> :
                            null
                    }
                    {
                        (orgType === "ecom" || orgType === "invoice") ?
                            <Button
                                leftIcon={<BiReset style={{ fontSize: "20px" }} />}
                                color={"#525C76"}
                                variant={"outline"}
                                borderColor={"#d2d2d2"}
                                _hover={{ bg: "#ffffff" }}
                                onClick={getOrdersData}
                            >
                                Reset
                            </Button> :
                            <Button
                                leftIcon={<BiReset style={{ fontSize: "20px" }} />}
                                color={"#525C76"}
                                variant={"outline"}
                                borderColor={"#d2d2d2"}
                                _hover={{ bg: "#ffffff" }}
                                onClick={handleReset}
                            >
                                Reset
                            </Button>
                    }

                    {
                        (orgType === "ecom" || orgType === "invoice") ?
                            <Button
                                leftIcon={<HiOutlineFilter style={{ fontSize: "20px" }} />}
                                color={"#525C76"}
                                variant={"outline"}
                                borderColor={"#d2d2d2"}
                                _hover={{ bg: "#ffffff" }}
                                onClick={onOpen}
                            >
                                Filter
                            </Button> :
                            null
                    }

                </HStack>
                {
                    (orgType === "ecom" || orgType === "invoice") ?
                        <CustomTable columns={columnsCustomTable} data={orderData} /> :
                        <>
                            <GridV2 columns={columnsAgTable} key={refreshGrid.toString()} getOrderData={getOrderData} />
                            {/* <SummaryTable summaryColumns={summaryColumns} data={summaryData} /> */}
                        </>
                }
                <OrderFilter isOpen={isOpen} onClose={onClose} type={"gaming"} />
            </Box> : <Loader />}
        </Stack>
    );
};

export default GamingOrders;