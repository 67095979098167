import { Badge, Stack, Text, Box, HStack, Button, useDisclosure } from "@chakra-ui/react";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import { HiOutlineFilter } from "react-icons/hi";
import { BiReset } from 'react-icons/bi'
import { startCase, round } from 'lodash';

import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme

import { getOrders } from "../../../store/slices/orderSlices";
import Loader from "../../../components/Loader";
import OrderFilter from "../OrderFilter";
import { statusMap, colorMap } from "../../../constants";
import Grid from "../../../utils/agTable";

const PayinOrdersV2 = (props) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const orderData = useSelector((state) => state?.order?.orderDetails);
    const orgId = useSelector((state) => state?.login?.orgDetails?.orgId);
    const isLoading = useSelector((state) => state?.order?.loading)
    const dispatch = useDispatch();

    const USformatter = new Intl.NumberFormat('en-US');
    useEffect(() => {
        getOrdersData();
    }, []);

    const getOrdersData = useCallback(() => {
        dispatch(getOrders({ filters: { orgId, type: "payin" } }))
    }, [dispatch, orgId])

    var filterParams = {
        comparator: (filterLocalDateAtMidnight, cellValue) => {
            var dateAsString = cellValue;
            if (dateAsString == null) return -1;
            var cellDate = new Date(cellValue);
            if (filterLocalDateAtMidnight.toLocaleDateString() === cellDate.toLocaleDateString()) {
                return 0;
            }
            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            }
            if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            }
            return 0;
        },
        minValidYear: 2000,
    };
    

    const columns = [
        {
            headerName: "Order ID TransFi",
            field: "orderId",
            getQuickFilterText: params => {
                return params.value.name;
            },
            customerVisibility: true,
            width: '250',
            cellRenderer: ({ value }) => {
                return <Link 
                // to={`/order/pay-in-order/${value}`} 
                style={{ color: "#1A4FD6", fontWeight: "bold", fontSize: '14px' }} >{value}</Link>;
            },
        },
        {
            headerName: "Order Status",
            field: "status",
            customerVisibility: true,
            enableRowGroup: true,

            filterParams: {
                applyMiniFilterWhileTyping: true,
            },
            cellRenderer: ({ value }) => {
                const newStatus = statusMap[value] || value;
                const color = colorMap[value];
                return <Badge width={"100%"} textAlign={"center"} colorScheme={color}>{newStatus}</Badge>;
            },
        },
        // { headerName: "Order ID Customer", field: "customer.orgId", customerVisibility: true, width: '200' },
        {
            headerName: "Settlement Type", field: "type", customerVisibility: false, cellRenderer: ({ value }) => (
                <Text>{value ? startCase(value) : ''}</Text>
            )
        },
        {
            headerName: "Customer Name", field: "customer.formattedName", enableRowGroup: true, customerVisibility: true, width: '200', cellRenderer: ({ value }) => (
                <Text>{value ? startCase(value) : ''}</Text>
            )
        },
        { headerName: "Customer User ID/Email", field: "customer.email", enableRowGroup: true, customerVisibility: false, width: '300' },
        { headerName: "Sender Name", field: "senderName.firstName", customerVisibility: true, width: '200' },
        { headerName: "Sender Email", field: "email", enableRowGroup: true, customerVisibility: true, width: '200' },
        {
            headerName: "Purpose of Transaction", field: "purposeCode", enableRowGroup: true, customerVisibility: true, cellRenderer: ({ value }) => (
                <Text>{value ? startCase(value) : ''}</Text>
            )
        },
        {
            headerName: "Nature of Sender", field: "senderDetails.type", enableRowGroup: true, customerVisibility: true, cellRenderer: ({ value }) => (
                <Text>{value ? startCase(value) : ''}</Text>
            )
        },
        {
            headerName: "Nature of Recipient", field: "", enableRowGroup: true, customerVisibility: false, cellRenderer: ({ value }) => (
                <Text>{value ? startCase(value) : ''}</Text>
            )
        },
        { headerName: "Jurisdiction of Sender", field: "userCountry", enableRowGroup: true, customerVisibility: true, width: '200' },
        { headerName: "Jurisdiction of Recipient", field: "", enableRowGroup: true, customerVisibility: false, width: '200' },
        {
            headerName: "Initiated Date",
            field: "createdAt",
            filterParams: filterParams,
            enableRowGroup: true,
            customerVisibility: true,
            width: '220',
            filter: 'agDateColumnFilter',
            cellRenderer: ({ value }) => (
                <Text>{moment(value).format("DD-MMM-YYYY @ h:mm A")}</Text>
            ),
        },
        {
            headerName: "Updated Date",
            field: "updatedAt",
            filter: 'agDateColumnFilter',
            filterParams: filterParams,
            enableRowGroup: true,
            customerVisibility: true,
            width: '220',
            cellRenderer: ({ value }) => (
                <Text>{moment(value).format("DD-MMM-YYYY @ h:mm A")}</Text>
            ),
        },
        {
            headerName: "Fiat Payment Partner", field: "senderPaymentMethodDetails.paymentGateway", customerVisibility: false, cellRenderer: ({ value }) => (
                <Text>{value ? startCase(value) : ''}</Text>
            )
        },
        {
            headerName: "Fiat Payment Partner Order ID", field: "senderPaymentPartnerOrderId", customerVisibility: false, width: '200', cellRenderer: ({ value }) => (
                <Text>{value}</Text>
            )
        },
        {
            headerName: "Fiat Payment Method", field: "paymentFormattedName", customerVisibility: true, cellRenderer: ({ value }) => (
                <Text>{value ? startCase(value) : ''}</Text>
            )
        },
        { headerName: "Fiat Currency", field: "fiatTicker", customerVisibility: true, width: '200' },
        {
            headerName: "Gross Fiat Amount",
            field: "fiatAmount",
            customerVisibility: true,
            
            filter: 'agNumberColumnFilter',
            cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            headerName: "Processing Fees %",
            field: "processingFeesPercentage",
            customerVisibility: true,
            aggFunc: 'avg',
            filter: 'agNumberColumnFilter',
            cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value * 100) + '%' : '0'}</Text>
            )
        },
        {
            headerName: "Processing Fees Amount", field: "processingFeesAmountFiat1", aggFunc: 'sum', customerVisibility: true, filter: 'agNumberColumnFilter', cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            headerName: "Net Amount",
            field: "netAmount",
            customerVisibility: true,
            aggFunc: 'sum',
            filter: 'agNumberColumnFilter',
            cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            headerName: "Amount Received in Sub-Account",
            field: "amountReceivedSubAccountFiat1",
            filter: 'agNumberColumnFilter',
            aggFunc: 'sum',
            customerVisibility: true,
            cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            headerName: "Payment Partner Fees",
            field: "paymentPartnerFeesFiat1",
            filter: 'agNumberColumnFilter',
            
            customerVisibility: false,
            cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            headerName: "Sub-Account Receipt Date", field: "subAmountReceiptDate", customerVisibility: false, cellRenderer: ({ value }) => (
                <Text>{ value ? value : "N/A"}</Text>
            )
        },
        {
            headerName: "Conversion Rate to USD",
            field: "conversionRateToUsd",
            filter: 'agNumberColumnFilter',
            
            customerVisibility: false,
            cellRenderer: ({ value }) => (
                <Text>{value ? round(value, 8) : '0'}</Text>
            )
        },
        {
            headerName: "GTV",
            field: "gtv",
            customerVisibility: false,
            aggFunc: 'sum',
            filter: 'agNumberColumnFilter',
            cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            headerName: "Amount Received in Sub-Account",
            field: "amountReceivedSubAccountUsd",
            aggFunc: 'sum',
            filter: 'agNumberColumnFilter',
            customerVisibility: false,
            cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            headerName: "Processing Fees Amount",
            field: "processingFeesAmountUsd",
            aggFunc: 'sum',
            filter: 'agNumberColumnFilter',
            customerVisibility: false,
            cellRenderer: ({ value }) => (
                <Text>{value ? round(value, 8) : '0'}</Text>
            )
        },
        {
            headerName: "Crypto to be sold if zero forex markup",
            field: "cryptoSoldIfZeroForexMarkup",
            aggFunc: 'sum',
            filter: 'agNumberColumnFilter',
            
            customerVisibility: false,
            cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            headerName: "Conversion rate charged by TransFi",
            field: "conversionRatesChargedTransfi",
            filter: 'agNumberColumnFilter',
            
            customerVisibility: true,
            cellRenderer: ({ value }) => (
                <Text>{value ? round(value, 8) : '0'}</Text>
            )
        },
        {
            headerName: "Crypto To Be Sold",
            field: "cryptoToBeSold",
            aggFunc: 'sum',
            customerVisibility: false,
            filter: 'agNumberColumnFilter',
            cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            headerName: "Revenue from Forex", field: "revenueFromForex", aggFunc: 'sum', customerVisibility: false, filter: 'agNumberColumnFilter', cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            headerName: "Crypto Payment Partner", field: "crypto.partner", customerVisibility: false, cellRenderer: ({ value }) => (
                <Text>{value ? startCase(value) : 'N/A'}</Text>
            )
        },
        {
            headerName: "Crypto Partner Order ID", field: "crypto.orderId", customerVisibility: false, cellRenderer: ({ value }) => (
                <Text>{value ? value: 'N/A'}</Text>
            )
        },
        {
            headerName: "Digital Assets Sent",
            field: "withdrawAmount",
            
            customerVisibility: true,
            filter: 'agNumberColumnFilter',
            cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            headerName: "Crypto Ticker", field: "withdrawCurrency", customerVisibility: false, cellRenderer: ({ value }) => (
                <Text>{value}</Text>
            )
        },
        {
            headerName: "Gas Fees Actual", field: "", customerVisibility: false, filter: 'agNumberColumnFilter', cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : 'N/A'}</Text>
            )
        },
        {
            headerName: "Net (of gas fees) Crypto paid", field: "", filter: 'agNumberColumnFilter', aggFunc: 'sum', customerVisibility: false, cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : 'N/A'}</Text>
            )
        },
        {
            headerName: "Crypto Wallet Address", field: "walletAddress", customerVisibility: true, cellRenderer: ({ value }) => (
                <Text>{ value ? value : "N/A"  }</Text>
            )
        },
        {
            headerName: "Transaction hash", field: "metaData.txnIdForCryptoReceived[0]", customerVisibility: true, cellRenderer: ({ value }) => (
                <Text>{value ? value : "N/A"}</Text>
            )
        },
    ];

    return (
        <Stack
            direction={"column"}
            justifyContent={"space-between"}
            flexGrow={1}
            p={5}
        >

            {!isLoading ? <Box >
                <HStack pb={5} justifyContent={"flex-end"}>
                    <Button
                        leftIcon={<BiReset style={{ fontSize: "20px" }} />}
                        color={"#525C76"}
                        variant={"outline"}
                        borderColor={"#d2d2d2"}
                        _hover={{ bg: "#ffffff" }}
                        onClick={getOrdersData}
                    >
                        Reset
                    </Button>
                    <Button
                        leftIcon={<HiOutlineFilter style={{ fontSize: "20px" }} />}
                        color={"#525C76"}
                        variant={"outline"}
                        borderColor={"#d2d2d2"}
                        _hover={{ bg: "#ffffff" }}
                        onClick={onOpen}
                    >
                        Filter
                    </Button>
                </HStack>
                <Grid columns={columns} data={orderData} />
                <OrderFilter isOpen={isOpen} onClose={onClose} type={"payin"} />
            </Box> : <Loader />}
        </Stack>
    );
};

export default PayinOrdersV2;