import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.min.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.min.css"; // Theme
import { useCallback, useMemo, useRef } from "react";

import './styles.css';
import { Text } from "@chakra-ui/react"

export default function SummaryTable(props) {
    const { summaryColumns, data } = props;
    const quickFilterText = '';
    const gridRef = useRef();

    // Apply settings across all columns
    const defaultColDef = useMemo(() => ({
        filter: true // Enable filtering on all columns
    }));

    const quickFilterParser = useCallback((quickFilter) => {
        let quickFilterParts = [];
        let lastSpaceIndex = -1;
        const isQuote = (index) => quickFilter[index] === '"';
        const getQuickFilterPart = (lastSpaceIndex, currentIndex) => {
            const startsWithQuote = isQuote(lastSpaceIndex + 1);
            const endsWithQuote = isQuote(currentIndex - 1);
            const startIndex =
                startsWithQuote && endsWithQuote
                    ? lastSpaceIndex + 2
                    : lastSpaceIndex + 1;
            const endIndex =
                startsWithQuote && endsWithQuote ? currentIndex - 1 : currentIndex;
            return quickFilter.slice(startIndex, endIndex);
        };
        for (let i = 0; i < quickFilter.length; i++) {
            const char = quickFilter[i];
            if (char === ' ') {
                if (!isQuote(lastSpaceIndex + 1) || isQuote(i - 1)) {
                    quickFilterParts.push(getQuickFilterPart(lastSpaceIndex, i));
                    lastSpaceIndex = i;
                }
            }
        }
        if (lastSpaceIndex !== quickFilter.length - 1) {
            quickFilterParts.push(
                getQuickFilterPart(lastSpaceIndex, quickFilter.length)
            );
        }
        return quickFilterParts;
    }, []);

    return (
        // Container
        <div className="ag-theme-quartz" style={{ height: 300, marginTop: 50, marginBottom: 50 }} >
            {/* The AG Grid component */}
            <Text fontSize="xl" fontWeight="normal" marginBottom={6}>Summary</Text>
            <AgGridReact
                ref={gridRef}
                rowData={data}
                columnDefs={summaryColumns}
                defaultColDef={defaultColDef}
                pagination={true}
                onCellValueChanged={event => console.log(`New Cell Value: ${event.value}`)}
                quickFilterText={quickFilterText}
                quickFilterParser={quickFilterParser}
                sideBar={false}
                suppressMenuHide={false}
                enableAdvancedFilter={false}
                suppressMultiRangeSelection={true}
                enableRangeSelection={true}
                rowGroupPanelShow={'never'}
                groupIncludeFooter={true}
                rowHeight={50}
            />
        </div>
    )
}