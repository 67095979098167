module.exports = {
  quickLinks: [
    {
      title: "Send Money",
      description: "Fast, low cost transfer",
      path: "/send-money",
      svgCardPath: "/assets/card1.svg",
      svgCardHoverPath: "/assets/card-hover-1.svg",
    },
    {
      title: "Add Contact",
      description: "Add a contact in few steps",
      path: "/contact",
      svgCardPath: "/assets/card2.svg",
      svgCardHoverPath: "/assets/card-hover-2.svg",
    },
    {
      title: "Need Help",
      description: "Contact our team",
      path: "",
      svgCardPath: "/assets/card3.svg",
      svgCardHoverPath: "/assets/card-hover-3.svg",
    },
  ],
};
