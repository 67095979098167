import { callApiWithToken } from "../utils/http_common";


const getConfigs = (name = "") => {
    return callApiWithToken.get(`/configuration/getProduct/${name}`);
}

const getStablecoinNetworks = (currency = "") => {
    return callApiWithToken.get(`/configuration/getStablecoinNetworks/${currency}`);
}

const getConfigurations = (payload) => {
    return callApiWithToken.post(`/configuration/getConfigs`, { ...payload });
}

const ConfigurationService = {
    getConfigs,
    getStablecoinNetworks,
    getConfigurations
}

export default ConfigurationService;
