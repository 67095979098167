import React, { useEffect, useState } from 'react';
import { formatDateTime } from '../../../../utils/formatter';
import { CheckIcon } from '@chakra-ui/icons';
import { Box, Center, Circle, Divider, Flex, Image, Text } from '@chakra-ui/react';
import Typography from '../../../../components/Common/Typography';


function formatDate(dateString) {
    if (!dateString) return '';
    return `${formatDateTime(dateString, 'hh:mm A')} | ${formatDateTime(dateString, 'DD MMM YYYY')}`;
}

const StepIcon = ({ status, step }) => {
    switch (status) {
        case 'completed':
            return <CheckIcon />;
        case 'inProgress':
            return step;
        default:
            return step
    }
};

const Step = (props) => {
    const { status, step } = props;

    const backgroundColor = () => {
        switch (status) {
            case 'completed':
                return "brand.green";
            case 'inProgress':
                return "brand.primary";
            case 'expired':
            case 'failed':
                return "brand.danger";
            default:
                return "";
        }
    };

    const borderColor = () => {
        if (status === 'completed') {
            return "none";
        } else if (status === 'inProgress') {
            return "brand.primary";
        } else if (status === 'expired' || status === 'failed') {
            return "brand.danger";
        } else {
            return "brand.grey.400";
        }
    };

    const textColor = () => {
        switch (status) {
            case 'completed':
            case 'inProgress':
            case 'expired':
            case 'failed':
                return "white";
            default:
                return "brand.grey.400";
        }
    }


    return (
        <Flex justifyContent={"center"} alignItems={"center"} w={"35px"} h={"35px"} border={status !== "completed" ? "1px solid" : "none"} borderColor={borderColor()} borderRadius={"50%"} direction="column">
            <Circle
                size="25px"
                bg={backgroundColor()}
                color={textColor()}
            >
                <StepIcon status={status} step={step} />
            </Circle>
        </Flex>
    );
}

const textColors = {
    completed: {
        color: 'secondary',
        colorweight: '400'
    },
    inProgress: {
        color: 'secondary',
        colorweight: '800'
    },
    pending: {
        color: 'secondary',
        colorweight: '400'
    },
    failed: {
        color: 'error',
        colorweight: '400'
    },
}

const SummaryDetailsCard = ({ summary }) => {

    const data = summary;
    const [steps, setSteps] = useState([
        { label: 'Payment', step: 1, description: 'Initiated', time: 'pending', status: 'pending' },
        { label: 'Payment', step: 2, description: 'Pending', time: 'pending', status: 'pending' },
        { label: 'Payment', step: 3, description: 'Completed', time: 'pending', status: 'pending' }
    ]);

    const updateSteps = (status, timestamps) => {
        const initialSteps = [
            { label: 'Payment', description: 'Initiated', time: 'pending', status: 'pending' },
            { label: 'Payment', description: 'in progress', time: 'check after 12 hours', status: 'pending' },
            { label: 'Payment', step: 3, description: 'Completed', time: 'pending', status: 'pending' }
        ];

        const updateStep = (index, key, value) => {
            initialSteps[index][key] = value;
        };

        switch (status) {
            case 'initiated':
                updateStep(0, 'status', 'completed');
                updateStep(0, 'description', 'Initiated');
                updateStep(0, 'time', formatDate(timestamps.initiatedAt));
                updateStep(1, 'status', 'inProgress');
                break;
            case 'expired':
                updateStep(0, 'status', 'expired');
                updateStep(0, 'description', 'Expired');
                updateStep(0, 'time', formatDate(timestamps.expiredAt));
                break;
            case 'fund_processing':
                updateStep(0, 'status', 'completed');
                updateStep(0, 'time', formatDate(timestamps.initiatedAt));
                updateStep(1, 'status', 'inProgress');
                updateStep(1, 'description', 'in progress');
                updateStep(1, 'time', formatDate(timestamps.fundProcessingAt));
                break;
            case 'asset_deposit_failed':
            case 'fund_failed':
            case 'rejected':
                initialSteps.forEach((step, index) => {
                    if (index < 2) {
                        step.status = 'completed';
                    }
                });
                updateStep(2, 'status', 'failed');
                updateStep(2, 'description', 'Rejected');
                updateStep(2, 'time', '');
                updateStep(0, 'time', formatDate(timestamps.initiatedAt));
                updateStep(1, 'time', formatDate(timestamps.fundProcessingAt));
                updateStep(2, 'time', formatDate(timestamps.fundFailedAt));
                break;

            case 'fund_settled':
                initialSteps.forEach(step => step.status = 'completed');
                updateStep(1, 'description', 'in progress');
                updateStep(0, 'time', formatDate(timestamps.initiatedAt));
                updateStep(1, 'time', formatDate(timestamps.fundProcessingAt));
                updateStep(2, 'time', formatDate(timestamps.fundSettledAt));
                updateStep(2, 'status', 'completed');
                updateStep(2, 'description', 'Successful');
                break;
            default:
                break;
        }

        setSteps(initialSteps.map((item, index) => ({ ...item, step: index + 1 })));
    };

    useEffect(() => {
        if (data?.status) {
            updateSteps(data.status, data.timestamps);
        }

    }, [data])
    return (
        <Box bg='white' w='70%' p={"20px 40px"} borderRadius={"9px"} mt={5}>
            <Box pl={6} w={"95%"} mx={"auto"} pt={5}>

                <Flex mx={"auto"} columnGap={5}>
                    {steps.map((step, index) => (
                        <Flex key={index} alignItems={"center"} flex={1} columnGap={5}>
                            <Step status={step.status} step={step.step} />
                            {index < steps.length - 1 && (
                                <Box flex="1" borderRadius={"24px"} height="3px" bg={step.status === "completed" ? "#26A17B" : "brand.greyPrimary"} />
                            )}
                        </Flex>
                    ))}
                </Flex>
                <Flex mx={"auto"} mt={4} columnGap={5}>
                    {steps.map((step, index) => (
                        <Flex rowGap={"60px"} key={index} alignItems={"start"} flex={1} columnGap={5}>
                            <Flex textAlign={"start"} flexDir={"column"}>
                                <Typography type="caption">
                                    Step {step.step}
                                </Typography>
                                <Typography type="description" weight="bold" color={textColors[step.status].color} colorweight={step.status === "completed" && index === steps.length - 1 ? "800" : textColors[step.status].colorweight}>
                                    {step.label}   {step.description}
                                </Typography>
                                <Typography type="caption" color={step.status === "completed" ? "default" : textColors[step.status].color} colorweight={step.status === "completed" ? "default" : textColors[step.status].colorweight}>
                                    {step.time}
                                </Typography>
                                {step.status === "inProgress" &&
                                    <Typography type="caption" color="primary">
                                        &#40;In Progress&#41;
                                    </Typography>
                                }
                            </Flex>
                        </Flex>
                    ))}
                </Flex>

                <Flex my={40} direction={'column'} justify={'center'} alignItems={'center'}>
                    {data.status === "fund_settled" &&
                        <>
                            <Image src={'/assets/tick.svg'} mb={5} />
                            <Typography weight='bold' >Payment is completed</Typography>
                        </>}

                    {["fund_processing", "initiated"].includes(data.status) &&
                        <>
                            <Image src={'/assets/pending.svg'} mb={5} />
                            <Typography weight='bold' >Payment is being processed!</Typography>
                            <Box mt={3}>
                                <Typography type="regular" weight='body'>The txn may take up to 24 hrs</Typography>
                            </Box>
                        </>}

                    {["fund_failed", "rejected", "expired"].includes(data.status) &&
                        <>
                            <Image src={'/assets/rejected.svg'} mb={5} />
                            <Typography weight='bold' >Payment is rejected</Typography>
                        </>}

                </Flex>
                <Divider mb={'2'} w={'full'} mt={2} />

                <Center mt={5} mb={5}>
                    <Text fontWeight={400} fontSize={'16px'} color={"#999999"}>By making payment, I agree to the <a href='https://www.transfi.com/terms-and-conditions' target="_blank" rel="noopener noreferrer">
                        <Text as={'span'} color={"#1A4FD6"} _hover={{ cursor: 'pointer' }}> terms and conditions</Text></a></Text>
                </Center>

            </Box>
        </Box>
    );
};

export default SummaryDetailsCard;