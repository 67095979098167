import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, Circle, Container, Divider, Flex, HStack, Img, Skeleton, VStack, useDisclosure } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router';
import { CheckIcon } from '@chakra-ui/icons';
import { RxCross2 } from 'react-icons/rx';
import { GoArrowLeft } from 'react-icons/go';

// Custom Function and Components
import Typography from '../../components/Common/Typography';
import { getPayoutOrderData } from '../../store/slices/transactionSlices';

// CSS
import './approval.css';
import TransactionService from '../../services/transactionService';
import { round, startCase } from 'lodash';
import TransactionModal from './modals/TransactionModal';
import { currencyFormatter, formatDateTime } from '../../utils/formatter';


const ApprovalSummary = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { state } = useLocation() || {};
  const { orderId = false } = state || {};

  const [type, setType] = useState("");
  const [summaryData, setSummaryData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const { contactName = '', type: orderType = '', makerName = '', recipientEmail = '', recipientName = {}, purposeCode, paymentType, timestamps = {}, paymentAccountDetails = {}, fees = {}, fiat = {}, depositAmount = 0, depositCurrency = '', withdrawAmount = 0, withdrawCurrency = '', createdAt = '' } = summaryData || {};
  const { firstName = '', lastName = '' } = recipientName;
  const { displayName = '', logo = '' } = paymentAccountDetails;
  const { networkFee = 0, processingFee = 0 } = fees;
  const { cxFee = 0, conversionRate = 1 } = fiat;
  let totalFee = networkFee + processingFee + cxFee;

  if (orderType === "fiat_payout") {
    const roundedDegree = depositCurrency === "BTC" ? 5 : 2
    totalFee = round(processingFee / conversionRate, roundedDegree);
  }

  let approvalPendingTime = new Date(timestamps?.approvalPendingAt || "");
  const add24Hours = new Date(approvalPendingTime.getTime() + (24 * 60 * 60 * 1000));
  const formattedTime = formatDateTime(add24Hours);
  const createdAtFormattedTime = formatDateTime(new Date(createdAt));

  const fetchApprovals = useCallback(async () => {
    setIsLoading(true);
    if (orderId) {
      try {
        const { data, response } = await TransactionService.getPayoutOrders({ filters: { status: "approval_pending", orderId } }) || {};
        if (data) {
          const { list } = data?.data || {};
          setSummaryData(list[0] || []);
          setIsLoading(false);
        }
      }
      catch (err) {
        console.log(err);
        setIsLoading(false);
      }
    }
    else {
      navigate(-1);
    }
  }, [dispatch]);

  const handleBackNavigation = () => {
    window.history.replaceState({}, '');
    navigate(-1);
  };

  const handleOpenRejectModal = () => {
    setType("reject");
    onOpen();
  };

  const handleOpenApproveModal = () => {
    setType("approve");
    onOpen();
  };

  const Step = (props) => {
    const { status, step } = props;

    return (
      <Container flex={1} m={0} p={0}>
        <Flex justifyContent={"center"} alignItems={"center"} w={"32px"} h={"32px"} border={status !== "completed" ? "1px solid" : "none"} borderColor={status === "inProgress" ? "brand.primary" : "brand.grey.400"} borderRadius={"50%"} direction="column">
          <Circle
            size="25px"
            bg={status === 'completed' ? "brand.green" : status === 'inProgress' ? "brand.primary" : ""}
            color={status === 'completed' ? "white" : status === 'inProgress' ? "white" : "brand.grey.400"}
          >
            <StepIcon status={status} step={step} />
          </Circle>
        </Flex>
      </Container>
    );
  };

  const StepIcon = ({ status, step }) => {
    switch (status) {
      case 'completed':
        return <CheckIcon />;
      case 'inProgress':
        return step;
      default:
        return step
    }
  };

  const textColors = {
    completed: {
      color: 'secondary',
      colorweight: '400'
    },
    inProgress: {
      color: 'secondary',
      colorweight: '800'
    },
    pending: {
      color: 'secondary',
      colorweight: '400'
    },
    failed: {
      color: 'error',
      colorweight: '400'
    },
  }

  useEffect(() => {
    fetchApprovals();
  }, []);

  return (
    <Box minH={"92vh"} p={"10px 40px"} bg={"#F2F2F2"}>
      <Flex w={"fit-content"} cursor={"pointer"} onClick={handleBackNavigation} columnGap={2} alignItems={"center"}>
        <GoArrowLeft size={"13px"} />
        <Typography type="body">
          Back
        </Typography>
      </Flex>
      <Typography type='largeheader' weight='bold' >
        Approvals
      </Typography>
      {isLoading ?
        <Box mt={6} display={"flex"} flexDirection={"column"} alignItems={"center"} w={"100%"} >
          <Box display={"flex"} flexDirection={"column"} alignItems={"center"} rowGap={5} textAlign={"center"} width={"756px"}>
            <Skeleton height={"100px"} w={"100%"} />
            <Skeleton height={"100px"} w={"100%"} />
            <Skeleton height={"300px"} w={"100%"} />
          </Box>
        </Box>
        :
        <Box mt={6} display={"flex"} flexDirection={"column"} alignItems={"center"} w={"100%"} >
          <Box display={"flex"} flexDirection={"column"} alignItems={"center"} rowGap={5} textAlign={"center"} flex={1} maxWidth={"800px"}>
            <Flex mb={6} w={"100%"} justifyContent={"space-between"} color={"white"}>
              <HStack height={"34px"} >
                <Img src={process.env.PUBLIC_URL + "/assets/approval_card_arrow.png"} alt='logo-approval' width={"47px"} height={"47px"} />
                <VStack display={"flex"} flexDirection={"column"} gap={"0px"} justifyContent={"center"} alignItems={"flex-start"} >
                  <Typography type='title' weight='medium'>
                    Payment to {`${firstName} ${lastName}`} ({`${displayName}`})
                  </Typography>
                  <Typography type='body' weight='medium' color='secondary' >
                    Txn Id - {orderId}
                  </Typography>
                  <Typography type='body' weight='regular' color='secondary' >
                    - {makerName}
                  </Typography>
                </VStack>
              </HStack>
            </Flex>
            <Flex className='approval-summary-card'>
              <Flex height={"100%"} w={"100%"} flexDirection={"row"} alignItems={"flex-start"} justifyContent={"space-between"} >
                <VStack >
                  <Typography type='subtitle' weight='bold' >
                    Approval required before {formattedTime}
                  </Typography>
                  <Typography type='description' weight='regular' >
                    This transaction will be cancelled if not approved by then
                  </Typography>
                </VStack>
                <HStack h={"58px"} display={"flex"} alignItems={"center"} ml={"50px"} >
                  <Button variant={"danger"} size={"sm"} rightIcon={<RxCross2 width={"10px"} height={"10px"} />} onClick={handleOpenRejectModal}  >
                    Reject
                  </Button>
                  <Button variant={"primary"} size={"sm"} rightIcon={<CheckIcon width={"10px"} height={"10px"} />} onClick={handleOpenApproveModal} >
                    Approve
                  </Button>
                </HStack>
              </Flex>
              <Flex height={"100%"} w={"100%"} flexDirection={"row"} alignItems={"flex-start"} justifyContent={"space-between"} >
                <HStack w={"90%"} display={"flex"} alignItems={"center"} >
                  <Step status={"completed"} step={1} />
                  <Divider borderWidth={"1px"} borderColor={"#26A17B"} borderRadius={"24px"} />
                  <Step status={"inProgress"} step={2} />
                  <Divider borderWidth={"1px"} borderRadius={"24px"} />
                  <Step status={"initiated"} step={3} />
                  <Divider borderWidth={"1px"} borderRadius={"24px"} />
                  <Step status={"initiated"} step={4} />
                </HStack>
              </Flex>
              <Flex mt={4} columnGap={5} alignItems={"flex-start"} w={"110%"} justifyContent={"space-between"} >
                <Flex rowGap={"60px"} alignItems={"start"} flex={1} columnGap={5}>
                  <Flex textAlign={"start"} flexDir={"column"}>
                    <Typography type="description" weight="medium" color={textColors["completed"].color} colorweight={textColors["completed"].colorweight}>
                      {"Payment"}<br />{" Initiated"}
                    </Typography>
                    <Typography type="caption" color={"default"} colorweight={"default"}>
                      {createdAtFormattedTime}
                    </Typography>
                  </Flex>
                </Flex>
                <Flex rowGap={"60px"} alignItems={"start"} flex={1} columnGap={5}>
                  <Flex textAlign={"start"} flexDir={"column"}>
                    <Typography type="description" weight="medium" color={textColors["inProgress"].color} colorweight={textColors["inProgress"].colorweight}>
                      {"Approval"}<br />{"Accepted"}
                    </Typography>
                    <Typography type="caption" color="primary">
                      (In Progress)
                    </Typography>
                  </Flex>
                </Flex>
                <Flex rowGap={"60px"} alignItems={"start"} flex={1} columnGap={5}>
                  <Flex textAlign={"start"} flexDir={"column"}>
                    <Typography type="description" weight="medium" color={textColors["pending"].color} colorweight={textColors["pending"].colorweight}>
                      {orderType === "fiat_payout" ? "Payment" : "Crypto Delivery"}<br />{orderType === "fiat_payout" ? "Released" : " Initiated"}
                    </Typography>
                  </Flex>
                </Flex>
                <Flex rowGap={"60px"} alignItems={"start"} flex={1} columnGap={5}>
                  <Flex textAlign={"start"} flexDir={"column"}>
                    <Typography type="description" weight="medium" color={textColors["pending"].color} colorweight={textColors["pending"].colorweight}>
                      {"Payment"}<br />{" Successful"}
                    </Typography>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
            <Flex className='approval-summary-card' gap={"20px"} >
              <HStack w={"100%"} >
                <Typography type='body' weight='medium' color='primary' >
                  Payment Summary
                </Typography>
              </HStack>
              <HStack w={"100%"} display={"flex"} justifyContent={"space-between"} >
                <Typography type='description' weight='regular' >
                  Payment method
                </Typography>
                <Typography type='body' weight='regular' color='secondary' colorweight="800" >
                  {startCase(paymentType) === "Bank" ? "Bank" : "Wallet"}
                </Typography>
              </HStack>
              <HStack w={"100%"} display={"flex"} justifyContent={"space-between"} >
                <Typography type='description' weight='regular' >
                  Total paid amount
                </Typography>
                <Typography type='body' weight='regular' color='secondary' colorweight="800" >
                  {`${depositCurrency === "BTC" ? currencyFormatter(depositAmount, 5) : currencyFormatter(depositAmount) } ${depositCurrency}`}
                </Typography>
              </HStack>
              <HStack w={"100%"} display={"flex"} justifyContent={"space-between"} >
                <Typography type='description' weight='regular' >
                  Total fee
                </Typography>
                <Typography type='body' weight='regular' color='secondary' colorweight="800" >
                  {`${depositCurrency === "BTC" ? currencyFormatter(totalFee, 5) : currencyFormatter(totalFee) } ${depositCurrency}`}
                </Typography>
              </HStack>
              <HStack w={"100%"} display={"flex"} justifyContent={"space-between"} >
                <Typography type='description' weight='regular' >
                  They receive
                </Typography>
                <Typography type='body' weight='regular' color='secondary' colorweight="800" >
                  {`${withdrawCurrency === "BTC" ? currencyFormatter(withdrawAmount, 5) : currencyFormatter(withdrawAmount) } ${withdrawCurrency}`}
                </Typography>
              </HStack>
              <Divider borderWidth={"1px"} />
              <HStack w={"100%"} >
                <Typography type='body' weight='medium' color='primary' >
                  Recipient Details
                </Typography>
              </HStack>
              <HStack w={"100%"} display={"flex"} justifyContent={"space-between"} >
                <Typography type='description' weight='regular' >
                  Contact
                </Typography>
                <VStack display={"flex"} gap="0px" >
                  <HStack>
                    <Img src={process.env.PUBLIC_URL + logo} alt='contactLogo' w={"25px"} h={"18px"} />
                    <Typography type='body' weight='regular' color='secondary' colorweight="800" >
                      {contactName}
                    </Typography>
                  </HStack>
                  <Typography type='caption' weight='regular' color='secondary' colorweight="400" >
                    {recipientEmail}
                  </Typography>
                </VStack>
              </HStack>
              <HStack w={"100%"} display={"flex"} justifyContent={"space-between"} >
                <Typography type='description' weight='regular' >
                  Payment Account
                </Typography>
                <Typography type='body' weight='regular' color='secondary' colorweight="800" >
                  {`${depositCurrency} Account`}
                </Typography>
              </HStack>
            </Flex>
            <Flex className='approval-summary-card' mb={"10px"}>
              <HStack w={"100%"} >
                <Typography type='body' weight='medium' color='primary' >
                  Additional Details
                </Typography>
              </HStack>
              <HStack w={"100%"} display={"flex"} justifyContent={"space-between"} mt={"20px"} >
                <Typography type='description' weight='regular' >
                  Payment purpose
                </Typography>
                <Typography type='body' weight='regular' color='secondary' colorweight="800" >
                  {purposeCode}
                </Typography>
              </HStack>
            </Flex>
          </Box>
        </Box>
      }
      <TransactionModal onClose={onClose} refreshData={handleBackNavigation} isOpen={isOpen} type={type} data={{ orderId, contactName, accountName: displayName, paidFrom: depositCurrency, depositCurrency, depositAmount }} />
    </Box>

  );
}

export default ApprovalSummary;