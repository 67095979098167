import React, { useEffect, useState } from "react";
import { Box, Flex, Image, Text, useToast } from "@chakra-ui/react";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";

//Custom Conmponents or functions
import OTPInputBoxes from "../login/OTPInputBoxes";
import Loader from "../../utils/Loader";
import { callApi } from "../../utils/http_common";
import TransfiLogo from "../../assets/transfi-logo.svg";
import { createCustomer } from "../../store/slices/loginSlices";
import ProgressBar from "../../components/LoginComponents/ProgressBar";
import BackButton from "../../components/LoginComponents/BackButton";
import Button from "../../components/LoginComponents/Button";

//css
import "./Signup.css"


const OTPVerify = (props) => {
    const toast = useToast();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { email, password, reset, setUserEmail, orgType } = props || {};

    const [otp, setOtp] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [counter, setCounter] = useState(60);
    const [disableResend, setDisableResend] = useState(true);

    useEffect(() => {
        if (counter === 0) {
            setDisableResend(false);
            return;
        }
        const timer = setTimeout(() => {
            setCounter(counter - 1);
        }, 1000);
        return () => clearTimeout(timer);
    }, [counter]);

    const sendOtpToEmail = async () => {
        try {
            setIsLoading(true);
            const data = await callApi.post(`/customer/verifyEmail`, { email, resetPassword: reset, signUp: true });
            const { status = false, message } = data;

            if (status) {
                setIsLoading(false);
            } else {
                throw new Error(message);
            }

            handleToast("OTP sent successfully", 'success');
        } catch (e) {
            handleToast(e?.response?.data?.error?.message || "Something went wrong", 'error');
            navigate('/login');
            setIsLoading(false);
        }
    }

    const handleResendOTP = () => {
        sendOtpToEmail();
        setDisableResend(true);
        setCounter(60);
    };

    const handleToast = (title, type) => {
        toast({
            title,
            status: type,
            duration: 5000,
            isClosable: true
        });
    };

    const verifyOtp = async () => {
        if (otp.length < 6) {
            handleToast("Please Enter Valid OTP", "error");
            return;
        }
        try {
            setIsButtonLoading(true);
            const { data } = await callApi.post(`/customer/verifyNewPasswordOTP`, { email, otp, signUp: true });
            const { otpVerified = false, message } = data?.data;
            if (otpVerified) {
                handleToast("OTP Successfully Verified", 'success');
                dispatch(createCustomer({ email, password, product: orgType })).then(response => response.payload).then((response) => {
                    const { message, status } = response?.data;
                    if (status === 'created') {
                        handleToast(message, 'success');
                        navigate('/fill-details');
                    }
                    else {
                        const { message } = response?.error;
                        handleToast(message, 'error');
                    }
                });
            } else {
                console.log(message);
                throw new Error(message);
            }
            setIsButtonLoading(false);
        } catch (e) {
            handleToast(e?.response?.data?.error?.message || "Something went wrong", 'error');
            setIsButtonLoading(false);
        }
    };

    useEffect(() => {
        if (otp.length === 6) {
            verifyOtp();
        }
    }, [otp]);

    return (
        isLoading ? <Loader /> :
            <Flex bg={'white'} alignItems={'center'} h={'full'} w={'full'}>
                <Box bg={'white'} h={"full"} w={'50%'}>
                    <Box>
                        <div className="pa-login-sub-card">
                            <div className="pa-login-header" style={{ marginBottom: '-50px' }} >
                                <BackButton />
                                <img src={TransfiLogo} alt="logo" />
                                <span style={{ fontWeight: '500' }} >Welcome to TransFi</span>
                                <p>Confirm your Email</p>
                                <ProgressBar level={2} />
                                <div style={{ marginTop: '10px', marginBottom: '15px' }} >
                                    <span style={{ fontSize: '15px' }}>
                                        An OTP has been sent to
                                    </span>
                                    <span style={{ color: "#0b2e4e", fontSize: '15px' }}>
                                        {` ${email}`}
                                    </span>
                                    <span style={{ color: "#1a4fd6", fontSize: '15px', marginLeft: '15px', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => { setUserEmail("") }} >
                                        Edit
                                    </span>
                                </div>
                            </div>
                            <div className="pa-login-body" >
                                <p className="pa-login-heading">Enter OTP</p>
                                <OTPInputBoxes setOtp={setOtp} />
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }} >
                                    <div>
                                        <p style={{ marginTop: '8px' }}>
                                            Resend OTP in {`${counter}s`}
                                        </p>
                                    </div>
                                    <div>
                                        <a className={disableResend ? 'pa-forget-password' : 'pa-forget-password-enabled'} onClick={() => { !disableResend && handleResendOTP() }} >
                                            Resend
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Box>
                </Box>
                <Flex className="gradient-background" flexDirection={'column'} justify={'space-between'} w={'full'} maxH={'100vh'} overflow={'hidden'}>
                    <Box ml={'80px'} mt={'100px'}>
                        <Text color={'#092C4C'} fontFamily={'DM Sans'} fontWeight={'700'} fontSize={'48px'}>Borderless <Text as={'span'} color={'#1A4FD6'}>payments</Text>,</Text>

                        <Text color={'#092C4C'} fontFamily={'DM Sans'} fontWeight={'700'} fontSize={'48px'}>boundless <Text as={'span'} color={'#1A4FD6'}>potential</Text></Text>
                    </Box>
                    <Flex w={'full'} justify={'flex-end'}>
                        <Image src="/assets/login.png" maxH={'809px'} maxW={'992px'} />
                    </Flex>
                </Flex>
            </Flex>
    );
};

export default OTPVerify;
