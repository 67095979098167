import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Box, Button, Flex, HStack, Img, VStack, useDisclosure } from '@chakra-ui/react';
import { IoEyeOutline } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";
import { CheckIcon } from '@chakra-ui/icons';
import { round } from 'lodash';

// Custom Function and Components
import Typography from '../../components/Common/Typography';
import { getPayoutOrderData } from '../../store/slices/transactionSlices';
import TransactionModal from './modals/TransactionModal';

// CSS
import './approval.css';

const CollectionApprovalCard = (props) => {

    const { isOpen, onOpen, onClose } = useDisclosure();

    const dispatch = useDispatch();

    const userDetails = useSelector((state) => state?.login?.userDetails);

    const navigate = useNavigate();

    const [type, setType] = useState("");

    let { makerName, contactName, orderId, customerName, depositAmount, depositCurrency, logo, createdAt = '' } = props;

    const date = createdAt;
    createdAt = new Date(createdAt);

    const formattedTime = createdAt.toLocaleString('en-GB', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        month: 'short',
        day: '2-digit',
        year: 'numeric'
    });

    const handleOpenRejectModal = () => {
        setType("reject");
        onOpen();
    }

    const handleOpenApproveModal = () => {
        setType("approve");
        onOpen();
    }

    const handleNavigateToSummary = () => {
        navigate('/approvals/summary', { state: { orderId } });
    }

    const fetchApprovals = useCallback(() => {
        dispatch(getPayoutOrderData({ filters: { status: "approval_pending" } }));
    }, [dispatch]);

    return (
        <Box px={'40px'} py={'20px'} bg={'white'} border={'1px'} borderColor={'#E4E4E4'} my={'2px'} rounded={'9px'} boxShadow={'sm'}>
            <VStack height={"100%"} width={"100%"} display={"flex"} gap={"16px"} >

                <Flex width="100%" justifyContent={"space-between"} alignItems={"center"} height={"45px"} >
                    <HStack display={"flex"} justifyContent={"center"} alignItems={"center"} >
                        <Img src={process.env.PUBLIC_URL + logo} alt='countryLogo' width={"32px"} height={"32px"} />
                        <VStack display={"flex"} flexDirection={"column"} gap={"0px"} alignItems={"flex-start"} >
                            <Typography type='body' weight='bold' >
                                {`${customerName}`}
                            </Typography>
                            <Typography type='caption' weight='regular' color='secondary' >
                                Txn Id - {orderId}
                            </Typography>
                        </VStack>
                    </HStack>
                    <VStack display={"flex"} justifyContent={"center"} alignItems={"center"} gap={"0px"} >
                        <Typography type='description' weight='regular' color='secondary' >
                            Created by {makerName}
                        </Typography>
                        <Typography type='description' weight='regular' color='secondary' colorweight='800' >
                            {formattedTime}
                        </Typography>
                    </VStack>
                    <VStack display={"flex"} justifyContent={"center"} alignItems={"center"} gap={"0px"} >
                        <Typography type='description' weight='regular' color='secondary' >
                            Invoice amount
                        </Typography>
                        <Typography type='body' weight='bold' color='primary' >
                            {`${round(depositAmount, 2)} ${depositCurrency}`}
                        </Typography>
                    </VStack>
                    {(userDetails?.cxRoles.includes('admin') || userDetails?.cxRoles.includes('approver')) && <HStack display={"flex"} justifyContent={"center"} alignItems={"center"} gap={"12px"} >
                        <Box className='al-transaction-details' onClick={handleNavigateToSummary} _hover={{ cursor: 'pointer' }} >
                            <IoEyeOutline className='al-approval-eye-img' />
                        </Box>
                        <Button variant={"danger"} size={"sm"} rightIcon={<RxCross2 width={"10px"} height={"10px"} />} onClick={handleOpenRejectModal}  >
                            Reject
                        </Button>
                        <Button variant={"primary"} size={"sm"} rightIcon={<CheckIcon width={"10px"} height={"10px"} />} onClick={handleOpenApproveModal} >
                            Approve
                        </Button>
                    </HStack>}
                </Flex>
            </VStack>
            <TransactionModal onClose={onClose} refreshData={fetchApprovals} isOpen={isOpen} type={type} data={{ orderId, contactName, accountName: customerName, paidFrom: depositCurrency, depositCurrency, depositAmount, date }} />
        </Box>
    );
};

export default CollectionApprovalCard;