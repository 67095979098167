import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { isUndefined } from 'lodash';

import ConfigurationService from '../../services/configurationService';
import AccountServide from '../../services/accountService';
import QuoteService from '../../services/quotesService';
import OrderService from '../../services/createOrderService';

const initialStateValues = {
    step: 0,
    selectedCurrency: {
        value: "",
        label: "",
        logo: "",
        type: "" // fiat | stablecoin
    },
    amountToPay: 0,
    amountToReceive: 0,
    fee: 0,
    currencyToPay: "",
    quotes: {
        loading: false,
        data: {}
    },

    supportedCurrencies: {
        loading: false,
        data: [],
    },
    stablecoinNetworks: {
        loading: false,
        data: [],
    },
    walletAddress: {
        loading: false,
        data: ""
    },
    type: "", /** crypto | fiat_to_crypto | fiat */
    loading: false,
    showBanks: false,
    selectedBank: {
        value: "",
        label: "",
        logo: "",
        limit: {
            min: 0,
            max: 0
        }
    },
    beneficiaryDetails: {
        orderId: "",
        loading: false,
        data: {},
        type: "" // bank_transfer | bank
    },
    balance: {},
    successSummaryLoading: false,
}

export const getSupportedPrefundCurrencies = createAsyncThunk(
    "addMoney/getSupportedPrefundCurrencies",
    async (body, { rejectWithValue }) => {
        try {
            const res = await ConfigurationService.getConfigs("supported_currencies_prefund");
            return res?.data;
        } catch (e) {
            return rejectWithValue(e?.response?.data);
        }
    }
);
export const getStablecoinNetworks = createAsyncThunk(
    "addMoney/getStablecoinNetworks",
    async (body, { rejectWithValue }) => {
        try {
            const res = await ConfigurationService.getStablecoinNetworks(body?.currency);
            return res?.data;
        } catch (e) {
            return rejectWithValue(e?.response?.data);
        }
    }
);

export const getQuotes = createAsyncThunk(
    "addMoney/getQuotes",
    async (body, { rejectWithValue }) => {
        try {
            const res = await QuoteService.getQuotes(body);
            return res?.data;
        } catch (e) {
            return rejectWithValue(e?.response?.data);
        }
    });

export const getWalletAddress = createAsyncThunk(
    "addMoney/getWalletAddress",
    async (body, { rejectWithValue }) => {
        try {
            const res = await AccountServide.getWalletAddress(body);
            return res?.data;
        } catch (e) {
            return rejectWithValue(e?.response?.data);
        }
    });

export const createPrefundOrder = createAsyncThunk(
    "addMoney/createPrefundOrder",
    async (body, { rejectWithValue }) => {
        try {
            const res = await OrderService.createPrefundOrder(body);
            return res?.data;
        } catch (e) {
            return rejectWithValue(e?.response?.data);
        }
    });

    export const getBalance = createAsyncThunk(
        "addMoney/getBalance",
        async (currency, { rejectWithValue }) => {
            try {
                const res = await AccountServide.getBalance(currency);
                return res?.data;
            } catch (e) {
                return rejectWithValue(e?.response?.data);
            }
        });


export const addMoneySlice = createSlice({
    name: 'addMoney',
    initialState: initialStateValues,
    reducers: {
        resetAddMoney: () => initialStateValues,
        goToNextStep: (state) => {
            state.step += 1;
        },
        goToPeviousStep: (state) => {
            state.step -= 1;
        },
        setSelectedCurrency: (state, action) => {
            state.selectedCurrency = { ...action.payload }
        },

        setFinalAmounts: (state, action) => {
            state.amountToPay = action.payload.amountToPay;
            state.amountToReceive = action.payload.amountToPay;
        },
        setCurrencyToPay: (state, action) => {
            state.currencyToPay = action.payload;
        },
        setQuotes: (state, action) => {
            state.quotes.data = action.payload;
            state.quotes.loading = false;
        },
        setType: (state, action) => {
            state.type = action.payload;
        },
        setShowBanks: (state, action) => {
            state.showBanks = action.payload;
        },
        setSelectedBank: (state, action) => {
            state.selectedBank = { ...action.payload }
        },
        setSupportedCurrencies: (state, action) => {
            state.supportedCurrencies.data = action.payload;
        },
        setBalance: (state, action) => {
            state.balance = action.payload;
        },
        setSuccessSummaryLoading: (state, action) => {
            state.successSummaryLoading = action.payload;
        }

    },
    extraReducers: {
        [getSupportedPrefundCurrencies.pending]: (state, action) => {
            state.supportedCurrencies.loading = true;
        },
        [getSupportedPrefundCurrencies.fulfilled]: (state, action) => {
            const formattedConfig = action?.payload?.data?.configs.map((config) => {
                return {
                    value: JSON.stringify({
                        symbol: config.symbol,
                        type: "fiat" // default to fiat if no type is provided
                    }),
                    label: `${config.description} - ${config.symbol || ""}`,
                    logo: config.logo,
                    type: "fiat"
                }
            }) || [];
            formattedConfig.unshift({
                value: JSON.stringify({
                    symbol: state.selectedCurrency.value,
                    type: state.selectedCurrency.type
                }),
                label: state.selectedCurrency.label,
                logo: state.selectedCurrency.logo,
                type: state.selectedCurrency.type
            })

            state.supportedCurrencies.data = formattedConfig;
            state.supportedCurrencies.loading = false;
        },
        [getSupportedPrefundCurrencies.rejected]: (state, action) => {
            state.supportedCurrencies.data = [];
            state.supportedCurrencies.loading = false;
        },
        [getStablecoinNetworks.pending]: (state, action) => {
            state.stablecoinNetworks.loading = true;
        },
        [getStablecoinNetworks.fulfilled]: (state, action) => {
            const formattedNetworks = action?.payload?.data?.stablecoinNetworks?.filter((item) => !isUndefined(item.network)) || [];
            const networks = formattedNetworks?.map((network) => {
                return {
                    value: network.network,
                    label: network.network,
                    logo: network.logo,
                }
            }) || [];
            state.stablecoinNetworks.data = networks || [];
            state.stablecoinNetworks.loading = false;
        },
        [getStablecoinNetworks.rejected]: (state, action) => {
            state.stablecoinNetworks.data = [];
            state.stablecoinNetworks.loading = false;
        },
        [getWalletAddress.pending]: (state, action) => {
            state.walletAddress.loading = true;
            state.walletAddress.data = "true";
            state.loading = true;
        },
        [getWalletAddress.fulfilled]: (state, action) => {
            const wallet = action?.payload?.data || {};
            state.walletAddress.data = {...wallet};
            state.walletAddress.loading = false;
            state.loading = false;
        },
        [getWalletAddress.rejected]: (state, action) => {
            state.walletAddress.data = {};
            state.walletAddress.loading = false;
            state.loading = false;
        },
        [getQuotes.pending]: (state, action) => {
            state.quotes.loading = true;
        },
        [getQuotes.fulfilled]: (state, action) => {
            const quote = action?.payload?.data || {};
            const { direction = "forward" } = action?.meta?.arg || {};

            if (direction === "forward") {
                state.quotes.data = {
                    ...quote,
                    amountToPay: quote.sentAmount,
                    amountToReceive: quote.receiveAmount,
                }
            } else {
                state.quotes.data = {
                    ...quote,
                    amountToPay: quote.receiveAmount,
                    amountToReceive: quote.sentAmount,
                }
            }
            state.quotes.loading = false;
        },
        [getQuotes.rejected]: (state, action) => {
            state.quotes.data = {};
            state.quotes.loading = false;
        },
        [createPrefundOrder.pending]: (state, action) => {
            state.loading = true;
        },
        [createPrefundOrder.fulfilled]: (state, action) => {
            const data = action?.payload?.data || {};
            state.beneficiaryDetails.orderId = data?.orderId;
            if (data.type === "bank_transfer") {
                state.beneficiaryDetails.data = data?.beneficiaryDetails || {};
                state.beneficiaryDetails.type = "bank_transfer";
            }else if(data.type === "bank"){
                state.beneficiaryDetails.data = data?.bankDetails || {};
                state.beneficiaryDetails.type = "bank";
            }
            state.loading = false;
        },
        [createPrefundOrder.rejected]: (state, action) => {
            state.loading = false;
            state.beneficiaryDetails.data = {};
        },
        [getBalance.fulfilled]: (state, action) => {
            const balance = action?.payload?.data?.balance || 0;
            state.balance = {
                ...balance
            }
        },
        [getBalance.rejected]: (state, action) => {
            state.balance = {}
        }

    }
})

// Action creators are generated for each case reducer function
export const { resetAddMoney, goToNextStep, goToPeviousStep, setSelectedCurrency, setFinalAmounts, setCurrencyToPay, setQuotes, setType, setShowBanks, setSelectedBank, setSupportedCurrencies, setBalance,setSuccessSummaryLoading } = addMoneySlice.actions

const { reducer: addMoneyReducer } = addMoneySlice;

export default addMoneyReducer