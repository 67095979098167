import {
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    FormControl,
    HStack,
    Input,
    Stack,
    VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import moment from "moment";
import Select from "react-select";
import { split, isEmpty, unset } from "lodash";
import { useDispatch } from "react-redux";

import { getOrders } from "../../store/slices/orderSlices";
import DateRangePicker from "../../components/DateRangePicker";

const OrderFilter = (props) => {
    const { isOpen, onClose } = props;
    const dispatch = useDispatch();
    const defaultDate = {
        startDate: moment(new Date()).subtract(2, "months"),
        endDate: moment(new Date()),
    };
    const [dateRange, setDateRange] = useState(defaultDate);
    const [formValues, setFormValues] = useState({});
    const [inputData, setInputData] = useState({});
    //   const [filters, setFilters] = useState({dateRange: defaultDate});

    const orderStatus = [
        { label: 'Draft', value: 'draft' },
        { label: 'Initiated', value: 'initiated' },
        { label: 'Fund Processing', value: 'fund_processing' },
        { label: 'Payment Received', value: 'fund_settled' },
        { label: 'Payment Not Received', value: 'fund_failed' },
        { label: 'Crypto Processing', value: 'asset_deposited' },
        { label: 'Crypto Delivered', value: 'asset_settled' },
        { label: 'Partially Completed', value: 'partial_asset_settled' },
        { label: 'Crypto Delivery Failed', value: 'asset_failed' },
        { label: 'Order Failed', value: 'failed' },
        { label: 'Expired', value: 'expired' }
    ]

    const onChangeDateRange = (start, end) => {
        setDateRange({ startDate: start, endDate: end });
        setFormValues({
            ...formValues,
            ["dateRange"]: { startDate: start, endDate: end },
        });
    };

    const handleOnChanges = (key, value) => {
        let newValue;
        setInputData({
            ...inputData,
            [key]: value,
        });
        if (key === "status") {
            newValue = value.value
        } else {
            if (key === 'orderId') newValue = value.trim();
            else if (Array.isArray(value)) newValue = value.map((data) => data.value || data);
            else if (key !== 'orderId') newValue = value.trim();
        }
        setFormValues({
            ...formValues,
            [key]: newValue,
            type: props.type,
        });
    }
    const onFilterHandler = () => {
        Object.keys(formValues).map((key) => {
            if (isEmpty(formValues[key])) unset(formValues, key)
        })
        dispatch(getOrders({ filters: formValues }))
        onClose()
    }

    return (
        <Drawer isOpen={isOpen} onClose={onClose} size={"sm"}>
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader style={{ color: "#1A4FD6", fontFamily: "arial" }}>
                    Filter by
                </DrawerHeader>
                <DrawerBody
                    minH={{ base: "100%", md: "max(90vh);" }}
                    height="auto"
                    display="flex"
                    flexDirection="column"
                    position="relative"
                >
                    <Stack
                        direction={"column"}
                        justifyContent={"space-between"}
                        flexGrow={1}
                        p={2}
                    >
                        <VStack gap={5}>
                            <FormControl>
                                <DateRangePicker
                                    width={"100%"}
                                    onChangeDateRange={onChangeDateRange}
                                    start={dateRange.startDate}
                                    end={dateRange.endDate}
                                />
                            </FormControl>
                            <FormControl>
                                <Input
                                    type="text"
                                    placeholder="Order ID"
                                    onChange={(e) => handleOnChanges("orderId", e.target.value)}
                                />
                            </FormControl>
                            <FormControl>
                                <Select isClearable={true} placeholder='Status' options={orderStatus} value={inputData.status} onChange={(e) => handleOnChanges('status', e)} />
                            </FormControl>
                        </VStack>
                        <HStack width={"full"}>
                            <Button
                                width={"full"}
                                color={"#ffffff"}
                                bg={"#1A4FD6"}
                                _hover={{ bg: "#232D95" }}
                                onClick={onFilterHandler}
                            >
                                Filter
                            </Button>
                            <Button
                                width={"full"}
                                color={"#525C76"}
                                variant={"outline"}
                                borderColor={"#d2d2d2"}
                                _hover={{ bg: "#ffffff" }}
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                        </HStack>
                    </Stack>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};

export default OrderFilter
