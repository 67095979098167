import { useEffect, useState } from "react";
import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Input,
  useToast,
  ModalCloseButton,
  Flex,
  Icon,
 Box
} from "@chakra-ui/react";
import { json2csv } from 'json-2-csv'
import { uploadRecipients } from "../../services/recipientService";
import { useSelector, useDispatch } from "react-redux";
import { HiOutlineUpload } from "react-icons/hi";

import { getRecipient, getRecipientTemplate } from "../../store/slices/recipientSlices";
import { useDropzone } from "react-dropzone";


const FileUpload = (props) => {
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const { isOpen, onClose } = props;
  const [selectedFile, setSelectedFile] = useState(null);
  const orderId = useSelector((state) => state.order.orderId);
  const fiatTicker = useSelector((state) => state.order.fiatTicker.label);
  const cryptoTicker = useSelector((state) => state.order.cryptoTicker.value);
  const recipientTemplate = useSelector((state) => state?.recipient?.template)
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRecipientTemplate({ product: "recipient" }));
  }, [])

  const handleToast = (title, type) => {
    toast({
      title,
      status: type,
      duration: 5000,
      isClosable: true,
    });
  };

  const handleUpload = async () => {
    try {
      if (selectedFile) {
        setLoading(true);
        const formData = new FormData();
        formData.append("file", selectedFile);

        const resp = await uploadRecipients(
          formData,
          orderId,
          fiatTicker,
          cryptoTicker
        );
        const { success } = resp?.data;
        if (success) {
          onClose();
          setLoading(false);
          dispatch(getRecipient());
          setSelectedFile(null)
          handleToast("File uploaded successfully.", "success");
        }
      }
    } catch (err) {
      onClose();
      handleToast("Invalid File", "error");
    }
  };

const handleDownload = async () => {
  json2csv(recipientTemplate[0].config).then((resp) => {
    const blob = new Blob([resp], { type: 'text/csv' });
    
    // Create a download link for the Blob
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = 'sample-recipient.csv';
    
    // Trigger the download
    downloadLink.click();
  })
  
}

const acceptedFileFormats = ['.csv',];

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const firstFile = acceptedFiles[0];

      const fileExtension = firstFile.name.split('.').pop().toLowerCase();

      if (acceptedFileFormats.includes('.' + fileExtension)) {
        setSelectedFile(firstFile);
      } else {
        toast({
          title: 'Invalid file format',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        setLoading(false);
      }
    }
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Upload CSV file</ModalHeader>
        <ModalCloseButton />
        <ModalBody textAlign={'center'}>
          <Flex
            direction={"column"}
            {...getRootProps()}
          >
            <Box bg="#f5f7fe" p={5} borderRadius={20}>
              <Input {...getInputProps()} />
              <Icon as={HiOutlineUpload} width={"50px"} height={"50px"} color={'#1A4FD6'} />
              <Text>{selectedFile ? selectedFile.name : `Drag 'n' drop some files here, or click to select files`} </Text>
            </Box>
          </Flex>
        </ModalBody>
        <ModalFooter justifyContent={"center"}>
          <HStack>
            <Button
              variant={"outline"}
              color={"#525C76"}
              fontSize={"14px"}
              border="1px"
              borderColor={"#525C76"}
              _hover={{ bg: "#ffffff" }}
              onClick={handleDownload}
            >
              Download Template
            </Button>
            <Button
              isLoading={loading}
              isDisabled={!selectedFile}
              onClick={handleUpload}
              fontSize={"14px"}
              color={"#ffffff"}
              bg={"#1A4FD6"}
              _hover={{ bg: "#232D95" }}
            >
              Add CSV File
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default FileUpload;
