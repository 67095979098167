import React, { useCallback, useEffect, useState } from "react";
import { Box, Flex, FormControl, Image, Text, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useGoogleLogin } from "@react-oauth/google";
import { useLocation } from 'react-router-dom';

//Custom Components and functions
import { validateEmail } from "../../utils/validations";
import Loader from "../../components/Loader";
import { callApi } from "../../utils/http_common";
import { check2FA, googleLogin } from "../../store/slices/loginSlices";
import TransfiLogo from "../../assets/transfi-logo.svg";
import Button from "../../components/LoginComponents/Button";
import Divider from "../../components/LoginComponents/Divider";
import Input from "../../components/LoginComponents/InputComponents/TextField";
import PasswordInput from "../../components/LoginComponents/InputComponents/PasswordInput";

//css
import "./Signup.css"

//icons
import { FcGoogle } from "react-icons/fc";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { TiTick } from "react-icons/ti";

const CustomerSignup = (props) => {
    const location = useLocation();
    const toast = useToast();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { setMfaStatus, setUserEmail, setUserPassword } = props || {};

    const handleToast = (title, type) => {
        toast({
            title,
            status: type,
            duration: 5000,
            isClosable: true
        });
    };

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [isGoogleButtonLoading, setIsGoogleButtonLoading] = useState(false);
    const [viewPassword, setViewPassword] = useState(false);
    const [orgDetails, setOrgDetails] = useState({});
    const [cxId, setCxId] = useState('');

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const email = queryParams.get('email');
        const formattedEmail = email.replace(/ /g, '+');
        setEmail(formattedEmail);
        getOrgDetails(formattedEmail);

    }, [location.search]);

    const getOrgDetails = async (email) => {
        try {
            const { data } = await callApi.post(`/customer/verifyInvite`, { email });
            setOrgDetails(data?.data?.data);
            if (!data?.data?.status) {
                handleToast(data?.data?.message, 'error');
                navigate('/login');
            }
            setCxId(data?.data?.data?.cxId);
        } catch (e) {
            handleToast("Something went wrong", 'error');
        }
    };

    const verifySingleSignOn = useCallback((token) => {
        dispatch(googleLogin({ token, setCustomer: true }))
            .then(res => res?.payload)
            .then(res => {
                const { error, data } = res;
                if (error) {
                    handleToast(error?.message || "Something went wrong", 'error');
                }
                setIsLoading(false);
            });
    }, [dispatch]);

    const handleChangePassword = (e) => {
        setPassword(e.target.value);
    };

    const signInUsingGoogle = useGoogleLogin({
        onSuccess: async (response) => {
            try {
                setIsLoading(true);
                verifySingleSignOn(response?.access_token);
            } catch (e) {
                handleToast(e?.message || "Something went wrong", 'error');
                setIsLoading(false);
            }
        },
        onError: (error) => {
            setIsLoading(false);
            handleToast(error.message, 'error');
        },
    });

    const setCredentials = async ({ email, password }) => {
        if (!validateEmail(email)) {
            handleToast("Please enter valid email", 'error');
            return;
        }

        if (password === "" || !password) {
            handleToast("Please fill password to continue", 'error');
            return;
        }

        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!_@#$%^&])[a-zA-Z\d!_@#$%^&]{8,}$/;
        const result = regex.test(password);

        if (!result) {
            handleToast("Please enter valid password", 'error');
            return;
        }

        createInvitedUser({ email, password, cxId });
    };

    const createInvitedUser = async ({ email, password, cxId }) => {
        try {
            setIsButtonLoading(true);
            const data = await callApi.post(`/customer/createCollectionCustomer`, { email, password, cxId });
            const { status = false, message } = data;

            if (status) {
                handleToast("Account created. Logging you in.", 'success');
                dispatch(check2FA({ email, accountDetails: password }))
                    .then(res => res.payload)
                    .then(res => {
                        const { data, error, status: responseStatus } = res;
                        if (data) {
                            const { status = false, mfaStatus, message = '' } = data;
                            if (status === 'created') {
                                handleToast(message, 'success');
                                navigate('/fill-details');
                                setIsLoading(false);
                            }
                            else if (status) {
                                if (mfaStatus) {
                                    handleToast("Password Verified!, Verify 2FA to move forward", 'success');
                                } else {
                                    handleToast("Password Verified!, Setup and verify 2FA to move forward", 'success');
                                }
                                setMfaStatus(mfaStatus);
                                setUserEmail(email);
                                setUserPassword(password);
                                setIsButtonLoading(false);
                            }
                            else {
                                throw new Error("Something Went Wrong!");
                            }
                        }
                        else if (error) {
                            const { message } = error;
                            handleToast(message || "Something went wrong", 'error');
                            if (responseStatus === 404 && message === "User not found, Please Signup")
                                navigate('/signup');
                        }
                        else {
                            setIsButtonLoading(false);
                            throw new Error("Something Went Wrong!");
                        }
                    });
            } else {
                setIsButtonLoading(false);
                throw new Error(message);
            }
        } catch (e) {
            handleToast(e?.response?.data?.error?.message || "Something went wrong", 'error');
            navigate('/login');
            setIsButtonLoading(false);
        }
    };

    return (
        isLoading ? <Loader /> :
            <Flex bg={'white'} h={'full'} w={'full'}>
                <Box bg={'white'} h={"full"} w={'50%'}>
                    <Box>
                        <div className="pa-login-sub-card">
                            <div >
                                <img src={TransfiLogo} alt="logo" />
                                <Text textAlign={'left'} mt={'10'} color={"#092C4C"} fontSize={'36px'} fontWeight={500}>Welcome to TransFi</Text>
                                <Button className={"pa-sign-in-with-google-button"} onClickHandler={signInUsingGoogle} isLoading={isGoogleButtonLoading} >
                                    <FcGoogle style={{ width: '22px', height: '22px' }} />
                                    Signup with Google
                                </Button>
                            </div>
                            <Divider>
                                or signup with email
                            </Divider>
                            <div className="pa-login-body">
                                <FormControl>
                                    <p>Email</p>
                                    <Input
                                        type={"email"}
                                        placeholder={"Enter your email address"}
                                        value={email}
                                        disabled={true}
                                    />
                                    <p>New Password</p>
                                    <PasswordInput
                                        placeholder={"Enter your password"}
                                        inputChangeHandler={handleChangePassword}
                                        value={password}
                                        viewPassword={viewPassword}
                                        setViewPassword={setViewPassword}
                                    />
                                </FormControl>
                                {
                                    password.length > 0 &&
                                    <>
                                        <span className={"pa-login-password-rules"} style={(password.length >= 8) ? { color: 'green' } : { color: 'red' }}>
                                            {(password.length >= 8) ? <TiTick /> : <AiOutlineExclamationCircle />}
                                            Should have atleast 8 characters
                                        </span>
                                        <span className={"pa-login-password-rules"} style={/[a-z]/.test(password) ? { color: 'green' } : { color: 'red' }}>
                                            {/[a-z]/.test(password) ? <TiTick /> : <AiOutlineExclamationCircle />}
                                            Should have atleast 1 small case character
                                        </span>
                                        <span className={"pa-login-password-rules"} style={/[A-Z]/.test(password) ? { color: 'green' } : { color: 'red' }}>
                                            {/[A-Z]/.test(password) ? <TiTick /> : <AiOutlineExclamationCircle />}
                                            Should have atleast 1 upper case character
                                        </span>
                                        <span className={"pa-login-password-rules"} style={/[0-9]/.test(password) ? { color: 'green' } : { color: 'red' }}>
                                            {/[0-9]/.test(password) ? <TiTick /> : <AiOutlineExclamationCircle />}
                                            Should have atleast 1 digit
                                        </span>
                                        <span className={"pa-login-password-rules"} style={/[!_@#$%^&*]/.test(password) ? { color: 'green' } : { color: 'red' }}>
                                            {/[!_@#$%^&*]/.test(password) ? <TiTick /> : <AiOutlineExclamationCircle />}
                                            Should have atleast 1 special character from !_@#$%^&*
                                        </span>
                                    </>
                                }
                                <p>Already have an account? <span className="pa-forget-password-enabled" onClick={() => navigate('/login')}>Login</span></p>
                                <Button className={"pa-button"} onClickHandler={() => setCredentials({ email, password })} isLoading={isButtonLoading} >
                                    Signup
                                </Button>
                            </div>
                        </div>
                    </Box>
                </Box>
                <Flex className="gradient-background" flexDirection={'column'} justify={'space-between'} w={'full'} maxH={'100vh'} overflow={'hidden'}>
                    <Box ml={'80px'} mt={'100px'}>
                        <Text color={'#092C4C'} fontFamily={'DM Sans'} fontWeight={'700'} fontSize={'48px'}>Borderless <Text as={'span'} color={'#1A4FD6'}>payments</Text>,</Text>

                        <Text color={'#092C4C'} fontFamily={'DM Sans'} fontWeight={'700'} fontSize={'48px'}>boundless <Text as={'span'} color={'#1A4FD6'}>potential</Text></Text>
                    </Box>
                    <Flex w={'full'} justify={'flex-end'}>
                        <Image src="/assets/login.png" maxH={'809px'} maxW={'992px'} />
                    </Flex>
                </Flex>
            </Flex>
    );
};

export default CustomerSignup;
