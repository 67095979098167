import { isUndefined } from "lodash";
import * as Yup from "yup";

const bankFormConfig = (paymentGateway = '', country = "", paymentCode = 'default', contactType = 'Business') => {
    try {
        const bitssa = {
            PH: {
                default: {
                    initialValues: {
                        city: "",
                        beneficiaryName: "",
                        gender: "",
                        address: "",
                        swiftbic: "",
                        postcode: "",
                        mobNumber: "",
                        addressstate: ""
                    },
                    validationSchema: {
                        city: Yup.string().required('Required'),
                        beneficiaryName: Yup.string().required('Required'),
                        gender: Yup.string().required('Required'),
                        address: Yup.string().required('Required'),
                        swiftbic: Yup.string().required('Required'),
                        postcode: Yup.string().required('Required'),
                        mobNumber: Yup.number().required('Required')
                            .min(5, 'Must be minimum 5 digits'),
                        addressstate: Yup.string().required('Required'),
                    }
                }
            },
            IN: {
                default: {
                    initialValues: {
                        city: "",
                        beneficiaryName: "",
                        gender: "",
                        address: "",
                        swiftbic: "",
                        postcode: "",
                        mobNumber: "",
                        addressstate: ""
                    },
                    validationSchema: {
                        city: Yup.string().required('Required'),
                        beneficiaryName: Yup.string().required('Required'),
                        gender: Yup.string().required('Required'),
                        address: Yup.string().required('Required'),
                        swiftbic: Yup.string().required('Required'),
                        postcode: Yup.string().required('Required'),
                        mobNumber: Yup.string().required('Required')
                            .min(5, 'Must be minimum 5 digits'),
                        addressstate: Yup.string().required('Required'),
                    }
                }
            }
        }

        const directa24 = {
            BR: {
                default: {
                    initialValues: {
                        bank_branch: "",
                        account_type: "",
                        beneficiary_name: "",
                        beneficiary_lastname: "",
                        document_type: "",
                        document_id: "",
                    },
                    validationSchema: {
                        bank_branch: Yup.string().required("Required")
                            .matches(/^[0-9]{1,5}$/, 'Invalid format'),
                        account_type: Yup.string().required("Required"),
                        beneficiary_name: Yup.string().required("Required"),
                        beneficiary_lastname: Yup.string().required("Required"),
                        document_type: Yup.string().required("Required"),
                        document_id: Yup.string().required("Required")
                            .matches(/^[0-9a-zA-Z\\.\\-\\' ]*$/, 'Invalid format'),
                    }
                }
            },
            JP: {
                default: {
                    initialValues: {
                        bank_branch: "",
                        account_type: "",
                        beneficiary_name: "",
                        beneficiary_lastname: "",
                        document_type: "",
                        document_id: "",
                    },
                    validationSchema: {
                        bank_branch: Yup.string().required("Required")
                            .matches(/^[0-9]{1,3}$/, 'Invalid format'),
                        account_type: Yup.string().required("Required"),
                        beneficiary_name: Yup.string().required("Required"),
                        beneficiary_lastname: Yup.string().required("Required"),
                        document_type: Yup.string().required("Required"),
                        document_id: Yup.string().required("Required")
                            .matches(/^[0-9a-zA-Z\\.\\-\\' ]*$/, 'Invalid format'),
                    }
                }
            },
            TH: {
                default: {
                    initialValues: {
                        account_type: "",
                        beneficiary_name: "",
                        beneficiary_lastname: "",
                        document_type: "",
                        document_id: "",
                    },
                    validationSchema: {
                        beneficiary_name: Yup.string().required("Required"),
                        account_type: Yup.string().required("Required"),
                        beneficiary_lastname: Yup.string().required("Required"),
                        document_type: Yup.string().required("Required"),
                        document_id: Yup.string().required("Required")
                            .matches(/^[0-9a-zA-Z\\.\\-\\' ]*$/, 'Invalid format'),
                    }
                }
            },
            MX: {
                default: {
                    initialValues: {
                        account_type: "",
                        beneficiary_name: "",
                        beneficiary_lastname: "",
                        document_type: "",
                        document_id: "",
                    },
                    validationSchema: {
                        account_type: Yup.string().required("Required"),
                        beneficiary_name: Yup.string().required("Required"),
                        beneficiary_lastname: Yup.string().required("Required"),
                        document_type: Yup.string().required("Required"),
                        document_id: Yup.string().required("Required")
                            .matches(/^[0-9a-zA-Z\\.\\-\\' ]*$/, 'Invalid format'),
                    }
                }
            },
            CO: {
                default: {
                    initialValues: {
                        account_type: "",
                        beneficiary_name: "",
                        beneficiary_lastname: "",
                        address: "",
                        document_type: "",
                        document_id: "",
                        bic: "",
                        number: "",
                    },
                    validationSchema: {
                        number: Yup.string().required("Required"),
                        account_type: Yup.string().required("Required"),
                        ...(contactType === "Individual" ? { beneficiary_name: Yup.string().required("Required") } : {} ),
                        beneficiary_lastname: Yup.string().required("Required"),
                        address: Yup.string().required("Required"),
                        document_type: Yup.string().required("Required"),
                        document_id: Yup.string()
                            .required('Required')
                            .test('document-id-validation', 'Invalid format', function (value) {
                                const documentType = this.parent.document_type;

                                // General format check
                                const generalFormatRegex = /^[0-9a-zA-Z\.\-\' ]*$/;
                                if (!generalFormatRegex.test(value)) {
                                    return this.createError({ message: 'Invalid format' });
                                }

                                // Specific check for NIT
                                if (documentType === 'NIT') {
                                    const nitRegex = /^[1-9]\d{9}$/;
                                    if (!nitRegex.test(value)) {
                                        return this.createError({ message: 'NIT must be exactly 10 digits and cannot start with 0' });
                                    }
                                }

                                return true;
                            }),
                        bic: Yup.string().required("Required"),
                    }
                }
            },
            CL: {
                default: {
                    initialValues: {
                        account_type: "",
                        beneficiary_name: "",
                        beneficiary_lastname: "",
                        document_type: "",
                        document_id: "",
                    },
                    validationSchema: {
                        account_type: Yup.string().required("Required"),
                        beneficiary_name: Yup.string().required("Required"),
                        beneficiary_lastname: Yup.string().required("Required"),
                        document_type: Yup.string().required("Required"),
                        document_id: Yup.string().required("Required")
                            .matches(/^[0-9a-zA-Z\\.\\-\\' ]*$/, 'Invalid format'),
                    }
                }
            },
        }

        const openpayd = {
            EU: {
                default: {
                    initialValues: {
                        iban: "",
                        bic: "",
                        country: "",
                    },
                    validationSchema: {
                        iban: Yup.string().matches(/^[A-Z]{2}\d{2}[A-Z0-9]{4}\d{7}([A-Z0-9]?){0,16}$/, 'Invalid IBAN. Ex: GB00BARC12345678901112').required("Please enter valid IBAN number"),
                        bic: Yup.string().required('Required'),
                        country: Yup.string().required('Required')
                    }
                }
            }
        }

        const peer = {
            SG: {
                default: {
                    initialValues: {
                        beneficiary_name: "",
                    },
                    validationSchema: {
                        beneficiary_name: Yup.string().required('Required'),
                    }
                }
            }
        }

        const dlocal = {
            MX: {
                default: {
                    initialValues: {
                        account_type: "",
                        beneficiary_name: "",
                        beneficiary_lastname: "",
                        document_type: "",
                        document_id: "",
                    },
                    validationSchema: {
                        account_type: Yup.string().required("Required"),
                        beneficiary_name: Yup.string().required("Required"),
                        beneficiary_lastname: Yup.string().required("Required"),
                        document_type: Yup.string().required("Required"),
                        document_id: Yup.string().required("Required")
                            .matches(/^[0-9a-zA-Z\\.\\-\\' ]*$/, 'Invalid format'),
                    }
                }
            },
            AR: {
                default: {
                    initialValues: {
                        account_type: "",
                        beneficiary_name: "",
                        beneficiary_lastname: "",
                        document_type: "",
                        document_id: "",
                    },
                    validationSchema: {
                        account_type: Yup.string().required("Required"),
                        beneficiary_name: Yup.string().required("Required"),
                        beneficiary_lastname: Yup.string().required("Required"),
                        document_type: Yup.string().required("Required"),
                        document_id: Yup.string().required("Required")
                            .matches(/^[0-9a-zA-Z\\.\\-\\' ]*$/, 'Invalid format'),
                    }
                }
            },
            BR: {
                default: {
                    initialValues: {
                        bank_branch: "",
                        account_type: "",
                        beneficiary_name: "",
                        beneficiary_lastname: "",
                        document_type: "",
                        document_id: "",
                    },
                    validationSchema: {
                        bank_branch: Yup.string().required("Required")
                            .matches(/^[0-9]{1,5}$/, 'Invalid format'),
                        account_type: Yup.string().required("Required"),
                        beneficiary_name: Yup.string().required("Required"),
                        beneficiary_lastname: Yup.string().required("Required"),
                        document_type: Yup.string().required("Required"),
                        document_id: Yup.string().required("Required")
                            .matches(/^[0-9a-zA-Z\\.\\-\\' ]*$/, 'Invalid format'),
                    }
                }
            },
            CL: {
                default: {
                    initialValues: {
                        account_type: "",
                        beneficiary_name: "",
                        beneficiary_lastname: "",
                        document_type: "",
                        document_id: "",
                    },
                    validationSchema: {
                        account_type: Yup.string().required("Required"),
                        beneficiary_name: Yup.string().required("Required"),
                        beneficiary_lastname: Yup.string().required("Required"),
                        document_type: Yup.string().required("Required"),
                        document_id: Yup.string().required("Required")
                            .matches(/^[0-9a-zA-Z\\.\\-\\' ]*$/, 'Invalid format'),
                    }
                }
            },
            ZA: {
                default: {
                    initialValues: {
                        account_type: "",
                        beneficiary_name: "",
                        beneficiary_lastname: "",
                        document_type: "",
                        document_id: "",
                    },
                    validationSchema: {
                        account_type: Yup.string().required("Required"),
                        beneficiary_name: Yup.string().required("Required"),
                        beneficiary_lastname: Yup.string().required("Required"),
                        document_type: Yup.string().required("Required"),
                        document_id: Yup.string().required("Required")
                            .matches(/^[0-9a-zA-Z\\.\\-\\' ]*$/, "Invalid format"),
                    }
                }
            },
            CO: {
                default: {
                    initialValues: {
                        account_type: "",
                        beneficiary_name: "",
                        beneficiary_lastname: "",
                        address: "",
                        document_type: "",
                        document_id: "",
                        number: "",
                    },
                    validationSchema: {
                        number: Yup.string().required("Required"),
                        account_type: Yup.string().required("Required"),
                        ...(contactType === "Individual" ? { beneficiary_name: Yup.string().required("Required") } : {}),
                        beneficiary_lastname: Yup.string().required("Required"),
                        address: Yup.string().required("Required"),
                        document_type: Yup.string().required("Required"),
                        document_id: Yup.string()
                            .required('Required')
                            .test('document-id-validation', 'Invalid format', function (value) {
                                const documentType = this.parent.document_type;

                                // General format check
                                const generalFormatRegex = /^[0-9a-zA-Z\.\-\' ]*$/;
                                if (!generalFormatRegex.test(value)) {
                                    return this.createError({ message: 'Invalid format' });
                                }

                                // Specific check for NIT
                                if (documentType === 'NIT') {
                                    const nitRegex = /^[1-9]\d{9}$/;
                                    if (!nitRegex.test(value)) {
                                        return this.createError({ message: 'NIT must be exactly 10 digits and cannot start with 0' });
                                    }
                                }

                                return true;
                            }),
                    }
                }
            }
        }

        const yellowcard = {
            KE: {
                default: {
                    initialValues: {
                        beneficiary_name: "",
                        id_type: "",
                        id_number: "",
                        dob: new Date("01-01-2000"),
                        address: "",
                    },
                    validationSchema: {
                        beneficiary_name: Yup.string().required('Required'),
                        id_type: Yup.string().required('Required'),
                        id_number: Yup.string().required('Required'),
                        dob: Yup.string().required('Required'),
                        address: Yup.string().required('Required'),
                    }
                }
            },
            ZM: {
                default: {
                    initialValues: {
                        beneficiary_name: "",
                        id_type: "",
                        id_number: "",
                        dob: new Date("01-01-2000"),
                        address: "",
                    },
                    validationSchema: {
                        beneficiary_name: Yup.string().required('Required'),
                        id_type: Yup.string().required('Required'),
                        id_number: Yup.string().required('Required'),
                        dob: Yup.string().required('Required'),
                        address: Yup.string().required('Required'),
                    }
                }
            },
            CM: {
                default: {
                    initialValues: {
                        beneficiary_name: "",
                        id_type: "",
                        id_number: "",
                        dob: new Date("01-01-2000"),
                        address: "",
                    },
                    validationSchema: {
                        beneficiary_name: Yup.string().required('Required'),
                        id_type: Yup.string().required('Required'),
                        id_number: Yup.string().required('Required'),
                        dob: Yup.string().required('Required'),
                        address: Yup.string().required('Required'),
                    }
                }
            }
        }

        const bitso = {
            AR: {
                default: {
                    initialValues: {
                        beneficiary_name: "",
                        beneficiary_lastname: "",
                    },
                    validationSchema: {
                        beneficiary_name: Yup.string().required("Required"),
                        beneficiary_lastname: Yup.string().required("Required"),
                    }
                }
            },
            CO: {
                default: {
                    initialValues: {
                        account_type: "",
                        beneficiary_name: "",
                        beneficiary_lastname: "",
                        document_type: "",
                        document_id: "",
                        number: "",
                    },
                    validationSchema: {
                        number: Yup.string().required("Required"),
                        account_type: Yup.string().required("Required"),
                        ...(contactType === "Individual" ? { beneficiary_name: Yup.string().required("Required") } : {}),
                        beneficiary_lastname: Yup.string().required("Required"),
                        document_type: Yup.string().required("Required"),
                        document_id: Yup.string()
                            .required('Required')
                            .test('document-id-validation', 'Invalid format', function (value) {
                                const documentType = this.parent.document_type;

                                // General format check
                                const generalFormatRegex = /^[0-9a-zA-Z\.\-\' ]*$/;
                                if (!generalFormatRegex.test(value)) {
                                    return this.createError({ message: 'Invalid format' });
                                }

                                // Specific check for NIT
                                if (documentType === 'NIT') {
                                    const nitRegex = /^[1-9]\d{9}$/;
                                    if (!nitRegex.test(value)) {
                                        return this.createError({ message: 'NIT must be exactly 10 digits and cannot start with 0' });
                                    }
                                }

                                return true;
                            }),
                    }
                }
            }
        }

        const tripleA = {
            AR: {
                default: {
                    initialValues: {
                        beneficiary_name: "",
                        bank_account_type: "",
                        document_type: "",
                        document_id: "",
                        mobile_number: "",
                        dob: new Date("01-01-2000"),
                        number: "",
                    },
                    validationSchema: {
                        number: Yup.string().required("Required"),
                        beneficiary_name: Yup.string().required('Required'),
                        bank_account_type: Yup.string().required("Required"),
                        document_type: Yup.string().required("Required"),
                        document_id: Yup.string().required("Required"),
                        mobile_number: Yup.string().required("Required"),
                        dob: Yup.string().required('Required'),
                    }
                }
            }
        }

        const koywe = {
            CL: {
                default: {
                    initialValues: {
                        beneficiary_name: "",
                        beneficiary_lastname: "",
                        document_type: "",
                        document_id: "",
                        mobNumber: "",
                        address: "",
                        addressstate: "",
                        city: "",
                        postcode: "",
                        account_type: "",
                        bic: "",
                    },
                    validationSchema: {
                        beneficiary_name: Yup.string().required('Required'),
                        beneficiary_lastname: Yup.string().required('Required'),
                        document_type: Yup.string().required('Required'),
                        document_id: Yup.string().required('Required')
                            .matches(/^[0-9a-zA-Z\\.\\-\\' ]*$/, 'Invalid format'),
                        mobNumber: Yup.string().required('Required')
                            .min(5, 'Must be minimum 5 digits'),
                        address: Yup.string().required('Required'),
                        addressstate: Yup.string().required('Required'),
                        city: Yup.string().required('Required'),
                        postcode: Yup.string().required('Required'),
                        account_type: Yup.string().required('Required'),
                        bic: Yup.string().required('Required'),
                    }
                }
            },
            CO: {
                default: {
                    initialValues: {
                        beneficiary_name: "",
                        beneficiary_lastname: "",
                        document_type: "",
                        document_id: "",
                        mobNumber: "",
                        address: "",
                        addressstate: "",
                        city: "",
                        postcode: "",
                        account_type: "",
                        number: "",
                    },
                    validationSchema: {
                        number: Yup.string().required("Required"),
                        ...(contactType === "Individual" ? { beneficiary_name: Yup.string().required("Required") } : {}),
                        beneficiary_lastname: Yup.string().required('Required'),
                        document_type: Yup.string().required('Required'),
                        document_id: Yup.string()
                            .required('Required')
                            .test('document-id-validation', 'Invalid format', function (value) {
                                const documentType = this.parent.document_type;

                                // General format check
                                const generalFormatRegex = /^[0-9a-zA-Z\.\-\' ]*$/;
                                if (!generalFormatRegex.test(value)) {
                                    return this.createError({ message: 'Invalid format' });
                                }

                                // Specific check for NIT
                                if (documentType === 'NIT') {
                                    const nitRegex = /^[1-9]\d{9}$/;
                                    if (!nitRegex.test(value)) {
                                        return this.createError({ message: 'NIT must be exactly 10 digits and cannot start with 0' });
                                    }
                                }

                                return true;
                            }),
                        mobNumber: Yup.string().required('Required')
                            .min(5, 'Must be minimum 5 digits'),
                        address: Yup.string().required('Required'),
                        addressstate: Yup.string().required('Required'),
                        city: Yup.string().required('Required'),
                        postcode: Yup.string().required('Required'),
                        account_type: Yup.string().required('Required'),
                    }
                }
            },
            AR: {
                default: {
                    initialValues: {
                        beneficiary_name: "",
                        beneficiary_lastname: "",
                        document_type: "",
                        document_id: "",
                        mobNumber: "",
                        address: "",
                        addressstate: "",
                        city: "",
                        postcode: "",
                        account_type: "",
                    },
                    validationSchema: {
                        beneficiary_name: Yup.string().required('Required'),
                        beneficiary_lastname: Yup.string().required('Required'),
                        document_type: Yup.string().required('Required'),
                        document_id: Yup.string().required('Required')
                            .matches(/^[0-9a-zA-Z\\.\\-\\' ]*$/, 'Invalid format'),
                        mobNumber: Yup.string().required('Required')
                            .min(5, 'Must be minimum 5 digits'),
                        address: Yup.string().required('Required'),
                        addressstate: Yup.string().required('Required'),
                        city: Yup.string().required('Required'),
                        postcode: Yup.string().required('Required'),
                        account_type: Yup.string().required('Required'),
                    }
                }
            },
            MX: {
                default: {
                    initialValues: {
                        beneficiary_name: "",
                        beneficiary_lastname: "",
                        document_type: "",
                        document_id: "",
                        mobNumber: "",
                        address: "",
                        addressstate: "",
                        city: "",
                        postcode: "",
                        account_type: "",
                    },
                    validationSchema: {
                        beneficiary_name: Yup.string().required('Required'),
                        beneficiary_lastname: Yup.string().required('Required'),
                        document_type: Yup.string().required('Required'),
                        document_id: Yup.string().required('Required')
                            .matches(/^[0-9a-zA-Z\\.\\-\\' ]*$/, 'Invalid format'),
                        mobNumber: Yup.string().required('Required')
                            .min(5, 'Must be minimum 5 digits'),
                        address: Yup.string().required('Required'),
                        addressstate: Yup.string().required('Required'),
                        city: Yup.string().required('Required'),
                        postcode: Yup.string().required('Required'),
                        account_type: Yup.string().required('Required'),
                    }
                },
            },
            PE: {
                default: {
                    initialValues: {
                        beneficiary_name: "",
                        beneficiary_lastname: "",
                        document_type: "",
                        document_id: "",
                        mobNumber: "",
                        address: "",
                        addressstate: "",
                        city: "",
                        postcode: "",
                        account_type: "",
                    },
                    validationSchema: {
                        beneficiary_name: Yup.string().required('Required'),
                        beneficiary_lastname: Yup.string().required('Required'),
                        document_type: Yup.string().required('Required'),
                        document_id: Yup.string().required('Required')
                            .matches(/^[0-9a-zA-Z\\.\\-\\' ]*$/, 'Invalid format'),
                        mobNumber: Yup.string().required('Required')
                            .min(5, 'Must be minimum 5 digits'),
                        address: Yup.string().required('Required'),
                        addressstate: Yup.string().required('Required'),
                        city: Yup.string().required('Required'),
                        postcode: Yup.string().required('Required'),
                        account_type: Yup.string().required('Required'),
                    }
                },
            }
        }

        const local_payment = {
            CO: {
                default: {
                    initialValues: {
                        beneficiary_name: "",
                        beneficiary_lastname: "",
                        document_type: "",
                        document_id: "",
                        mobNumber: "",
                        address: "",
                        addressstate: "",
                        city: "",
                        postcode: "",
                        account_type: "",
                        number: "",
                    },
                    validationSchema: {
                        number: Yup.string().required('Required'),
                        ...(contactType === "Individual" ? { beneficiary_name: Yup.string().required("Required") } : {}),
                        beneficiary_lastname: Yup.string().required('Required'),
                        document_type: Yup.string().required('Required'),
                        document_id: Yup.string()
                            .required('Required')
                            .test('document-id-validation', 'Invalid format', function (value) {
                                const documentType = this.parent.document_type;

                                // General format check
                                const generalFormatRegex = /^[0-9a-zA-Z\.\-\' ]*$/;
                                if (!generalFormatRegex.test(value)) {
                                    return this.createError({ message: 'Invalid format' });
                                }

                                // Specific check for NIT
                                if (documentType === 'NIT') {
                                    const nitRegex = /^[1-9]\d{9}$/;
                                    if (!nitRegex.test(value)) {
                                        return this.createError({ message: 'NIT must be exactly 10 digits and cannot start with 0' });
                                    }
                                }

                                return true;
                            }),
                        mobNumber: Yup.string().required('Required')
                            .min(5, 'Must be minimum 5 digits'),
                        address: Yup.string().required('Required'),
                        addressstate: Yup.string().required('Required'),
                        city: Yup.string().required('Required'),
                        postcode: Yup.string().required('Required'),
                        account_type: Yup.string().required('Required'),
                    }
                }
            },
        }

        const neo = {
            US: {
                default: {
                    initialValues: {
                        beneficiary_address: "",
                        bank_address: "",
                        bank_account_name: "",
                        swift: "",
                        number: "",
                        taxInvoice: "",
                    },
                    validationSchema: {
                        number: Yup.string().required("Required"),
                        beneficiary_address: Yup.string().required("Required"),
                        bank_address: Yup.string().required("Required"),
                        bank_account_name: Yup.string().required("Required"),
                        swift: Yup.string().required("Required"),
                        taxInvoice: Yup.string().optional(),
                    }
                }
            },
            EU: {
                default: {
                    initialValues: {
                        beneficiary_address: "",
                        bank_address: "",
                        bank_account_name: "",
                        swift: "",
                        number: "",
                    },
                    validationSchema: {
                        number: Yup.string().required("Required"),
                        beneficiary_address: Yup.string().required("Required"),
                        bank_address: Yup.string().required("Required"),
                        bank_account_name: Yup.string().required("Required"),
                        swift: Yup.string().required("Required"),
                    }
                },
                sepa_bank_va: {
                    initialValues: {
                        iban: "",
                        bic: "",
                    },
                    validationSchema: {
                        iban: Yup.string().matches(/^[A-Z]{2}\d{2}[A-Z0-9]{4}\d{7}([A-Z0-9]?){0,16}$/, 'Invalid IBAN. Ex: GB00BARC12345678901112').required("Please enter valid IBAN number"),
                        bic: Yup.string().required('Required'),
                    }
                }
            },
            GB: {
                default: {
                    initialValues: {
                        beneficiary_address: "",
                        bank_address: "",
                        bank_account_name: "",
                        swift: "",
                        sort_code: "",
                        number: "",
                    },
                    validationSchema: {
                        number: Yup.string().required("Required"),
                        beneficiary_address: Yup.string().required("Required"),
                        bank_address: Yup.string().required("Required"),
                        bank_account_name: Yup.string().required("Required"),
                        swift: Yup.string().required("Required"),
                        sort_code: Yup.string().required("Required"),
                    }
                }
            }
        }

        let extraInitialValues = {};
        let extraValidationSchema = {};
        let validationObject = {};

        switch (paymentGateway) {
            case 'PG-230824305141379':
                validationObject = isUndefined(bitssa[country]) ? {} : (bitssa[country][paymentCode] || bitssa[country]['default']);
                extraInitialValues = validationObject?.initialValues
                extraValidationSchema = validationObject?.validationSchema
                return {
                    extraInitialValues,
                    extraValidationSchema
                }
            case 'PG-230824305141380':
                validationObject = isUndefined(directa24[country]) ? {} : (directa24[country][paymentCode] || directa24[country]['default']);
                extraInitialValues = validationObject?.initialValues
                extraValidationSchema = validationObject?.validationSchema

                return {
                    extraInitialValues,
                    extraValidationSchema
                }
            case 'PG-230914305141379':
                validationObject = isUndefined(openpayd[country]) ? {} : (openpayd[country][paymentCode] || openpayd[country]['default']);
                extraInitialValues = validationObject?.initialValues
                extraValidationSchema = validationObject?.validationSchema

                return {
                    extraInitialValues,
                    extraValidationSchema
                }
            case 'PG-240321191632238':
                validationObject = isUndefined(openpayd[country]) ? {} : (openpayd[country][paymentCode] || openpayd[country]['default']);
                extraInitialValues = validationObject?.initialValues
                extraValidationSchema = validationObject?.validationSchema

                return {
                    extraInitialValues,
                    extraValidationSchema
                }
            case 'PG-231226142412429':
                validationObject = isUndefined(neo[country]) ? {} : (neo[country][paymentCode] || neo[country]['default']);
                extraInitialValues = validationObject?.initialValues
                extraValidationSchema = validationObject?.validationSchema

                return {
                    extraInitialValues,
                    extraValidationSchema
                }
            case 'PG-231017161345295':
                validationObject = isUndefined(peer[country]) ? {} : (peer[country][paymentCode] || peer[country]['default']);
                extraInitialValues = validationObject?.initialValues
                extraValidationSchema = validationObject?.validationSchema

                return {
                    extraInitialValues,
                    extraValidationSchema
                }
            case 'PG-231226142202982':
                validationObject = isUndefined(dlocal[country]) ? {} : (dlocal[country][paymentCode] || dlocal[country]['default']);
                extraInitialValues = validationObject?.initialValues
                extraValidationSchema = validationObject?.validationSchema

                return {
                    extraInitialValues,
                    extraValidationSchema
                }
            case 'PG-290124014234498':
                validationObject = isUndefined(yellowcard[country]) ? {} : (yellowcard[country][paymentCode] || yellowcard[country]['default']);
                extraInitialValues = validationObject?.initialValues
                extraValidationSchema = validationObject?.validationSchema

                return {
                    extraInitialValues,
                    extraValidationSchema
                }
            case 'PG-240226201619253':
                validationObject = isUndefined(bitso[country]) ? {} : (bitso[country][paymentCode] || bitso[country]['default']);
                extraInitialValues = validationObject?.initialValues
                extraValidationSchema = validationObject?.validationSchema

                return {
                    extraInitialValues,
                    extraValidationSchema
                }
            case 'PG-240312134719876':
                validationObject = isUndefined(tripleA[country]) ? {} : (tripleA[country][paymentCode] || tripleA[country]['default']);
                extraInitialValues = validationObject?.initialValues
                extraValidationSchema = validationObject?.validationSchema

                return {
                    extraInitialValues,
                    extraValidationSchema
                }
            case 'PG-240416072834585':
                validationObject = isUndefined(koywe[country]) ? {} : (koywe[country][paymentCode] || koywe[country]['default']);
                extraInitialValues = validationObject?.initialValues
                extraValidationSchema = validationObject?.validationSchema

                return {
                    extraInitialValues,
                    extraValidationSchema
                }
            case 'PG-240702052513488':
                validationObject = isUndefined(local_payment[country]) ? {} : (local_payment[country][paymentCode] || local_payment[country]['default']);
                extraInitialValues = validationObject?.initialValues
                extraValidationSchema = validationObject?.validationSchema

                return {
                    extraInitialValues,
                    extraValidationSchema
                }
            default:
                return null
        }
    } catch (error) {
        return { extraInitialValues: {}, extraValidationSchema: {} }
    }
};

export default bankFormConfig
