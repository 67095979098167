import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Img, VStack } from '@chakra-ui/react';

// Custom Function and Components
import Typography from '../../components/Common/Typography';

// CSS
import './sendmoney.css';
import { setSendAmount } from '../../store/slices/sendMoneySlices';


const SendMoney = () => {
  const dispatch = useDispatch();

  const customerType = useSelector((state) => state?.login?.userDetails?.cxRoles || [])[0];

  const navigate = useNavigate();

  useEffect(() => {
    if (customerType === "finance_manager") {
      navigate(-1);
    }
  }, []);

  useEffect(() => {
    dispatch(setSendAmount(""));
  }, [])
  

  return (
    <Box className='sm-main-card'>
      <Typography type='largeheader' weight='bold' >
        Send Money
      </Typography>
      <Box className='sm-sub-card'>
        <VStack gap={"12px"}>
          <Img src={process.env.PUBLIC_URL + "/assets/send-money-home.png"} alt={'send-money-logo'} w={"98px"} h={"73.71px"} />
          <Typography type='title' weight='medium' color='secondary' colorweight='800' >
            Individual Payment
          </Typography>
          <Typography type='description' weight='regular' color='secondary' colorweight='800' >
            Send money to individual contacts
          </Typography>
          <Button variant={"primary"} marginTop={"10px"} onClick={() => navigate('/send-money/individual-payment')} >
            Make Payment
          </Button>
        </VStack>
      </Box>
    </Box>
  );
}

export default SendMoney;