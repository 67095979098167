import { Box, Button, Center, Flex, HStack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, VStack, useToast } from '@chakra-ui/react';
import React, { useState } from 'react';
import { Formik } from 'formik';

import Typography from '../../../components/Common/Typography';
import { callApiWithToken } from '../../../utils/http_common';
import InformationIcon from '../../../assets/information-circle.svg';
import { CustomSelectFieldSearch, TextField } from '../../../components/Common/Input';
import { walletValidationSchema } from '../Cards/ValidationSchema';
import RecipientService from '../../../services/recipientService';

const AddWalletModal = ({ isOpen, onClose, refreshData, setRefreshData, selectedOption, setSelectedOption, stableCoins }) => {

    const [loading, setLoading] = useState(false);

    const toast = useToast();
    const handleToast = (title, type) => {
        toast({
            title,
            status: type,
            duration: 5000,
            isClosable: true
        });
    };

    const initialValue = {
        stableCoin: "",
        walletAddress: "",
        walletName: "",
    };

    const validationSchema = walletValidationSchema;
    const handleSubmit = async (values, actions) => {
        try {
            setLoading(true)
            const validation = await RecipientService.postWalletAddress({
                address: values.walletAddress,
                cryptoTicker: selectedOption.value,
                cryptoNetwork: selectedOption.network
            });

            const payload = {
                currency: selectedOption.value,
                network: selectedOption.network,
                walletAddress: values.walletAddress,
                currencyLogo: selectedOption.logo,
                nickname: values.walletName
            }
            const { data } = (await callApiWithToken.post("/customer/saveWalletDetails", payload)) || {};
            if (data?.success) {
                toast({
                    title: "Wallet details added",
                    status: 'success',
                    duration: 5000,
                    isClosable: true
                });
            }
            setLoading(false)
            setRefreshData(!refreshData)
            onClose()

        } catch (err) {
            console.log(err?.response?.data?.error?.message, "Error");
            if (err?.response?.data?.error?.message) {
                handleToast(err?.response?.data?.error?.message, 'error');
            }
            setLoading(false)
        }
    }

    const handleClose = () => {
        setSelectedOption({})
        setLoading(false)
        onClose()
    };
    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'xl'} >
            <ModalOverlay />
            <ModalContent py={3}>
                <Center mt={4}>
                    <Typography color="primary" colorweight="500" weight='bold'>Add Wallet Details</Typography>
                </Center>
                <ModalCloseButton />
                <ModalBody py={3} px={6}>
                    <Formik
                        initialValues={initialValue}
                        onSubmit={handleSubmit}
                        validateOnChange={false}
                        validationSchema={validationSchema}
                    >

                        {({ isSubmitting, handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                                <HStack mt={3}>
                                    <img src={InformationIcon} alt="icon" className='wa-information-icon' />
                                    <Typography weight='regular' type='description' colorweight='800' >
                                        Please make sure that these details are correct
                                    </Typography>
                                </HStack>

                                <Flex direction={'column'} spacing={5}>
                                    <Box maxW={"387px"}>
                                        <CustomSelectFieldSearch EmptyMenuLabel={"No Digital Asset Found"} required={false} width={"100%"} selectedOption={selectedOption} setSelectedOption={setSelectedOption} name="stableCoin" placeholder="Select Digital Asset" options={stableCoins} />
                                    </Box>
                                    <VStack justifyContent={"space-between"} w="full" spacing={5}>
                                        <TextField label="Wallet Address" name="walletAddress" placeholder="Enter Wallet Address" required={false} />
                                        <TextField label="Nick Name for wallet" name="walletName" placeholder="Enter Nick Name for wallet" required={false} />
                                    </VStack>

                                </Flex>

                                <Flex gap={2} justify={'flex-end'} mt={3}>
                                    <Button
                                        bg={"#F2F2F2"}
                                        _hover={{ bg: "#F2F2F2" }}
                                        color="#808080"
                                        width={'113px'}
                                        height={'40px'}
                                        rounded={'6px'}
                                        fontSize={'14px'}
                                        fontWeight={'400'}
                                        fontFamily={'DM Sans'} mr={3}
                                        onClick={handleClose}>
                                        Cancel
                                    </Button>
                                    <Button
                                        bg={"#1A4FD6"}
                                        _hover={{ bg: '#366cf5' }}
                                        color="white"
                                        width={'113px'}
                                        height={'40px'}
                                        rounded={'6px'}
                                        fontSize={'14px'}
                                        fontWeight={'400'}
                                        fontFamily={'DM Sans'}
                                        onClick={handleSubmit}
                                        isLoading={loading}
                                    >
                                        Add
                                    </Button>
                                </Flex>
                            </form>
                        )}
                    </Formik>
                </ModalBody>


            </ModalContent>
        </Modal>
    );
};

export default AddWalletModal;