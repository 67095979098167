import React, { useState, useEffect } from 'react';
import { Box, Checkbox, Flex, Grid, GridItem } from '@chakra-ui/react';

import { CustomSelectFieldSearch, TextField } from '../contact/add-contact/InputField';
import Typography from '../../components/Common/Typography';

const Business = ({ fiatList, selectedOption, setSelectedOption, initialValues, addressSame, setAddressSame }) => {
    const [values, setValues] = useState(initialValues);
    useEffect(() => {
        setValues(initialValues);
        if (initialValues) {
            setSelectedOption(initialValues.countryObj)
        }
    }, []);

    return (
        <>
            <Grid templateColumns={{ md: 'repeat(1, 1fr)', lg: 'repeat(2, 1fr)' }} gap={8}>
                <GridItem maxW={'386px'}>
                    <TextField label="Business Name" name="name" placeholder="Enter Name" />
                </GridItem>
                <GridItem maxW={'386px'}>
                    <TextField label="Business Registration Number" name="regNumber" placeholder="Enter Number" />
                </GridItem>
                <GridItem>
                    {initialValues && (
                        <CustomSelectFieldSearch EmptyMenuLabel={"No country/Region Found"} label={"Country/Region"} options={fiatList || []} name="country" placeholder="Select Country/Region" selectedOption={selectedOption} setSelectedOption={setSelectedOption} isDisabled={'true'} />
                    )}
                    {!initialValues && (
                        <CustomSelectFieldSearch EmptyMenuLabel={"No country/Region Found"} label={"Country/Region"} options={fiatList || []} name="country" placeholder="Select Country/Region" selectedOption={selectedOption} setSelectedOption={setSelectedOption} />
                    )}
                </GridItem>
                <GridItem maxW={'386px'}>
                    {initialValues && (
                        <TextField label="Email ID" name="email" type="email" placeholder="Enter Email" value={values.email} disabled={true} />
                    )}
                    {!initialValues && (
                        <TextField label="Email ID" name="email" type="email" placeholder="Enter Email" />
                    )}
                </GridItem>
            </Grid>
            <Box mt={7} mb={6}>
                <Typography type='body' weight='medium' color='primary' >
                    Billing Address
                </Typography>
            </Box>
            <Grid templateColumns={{ md: 'repeat(1, 1fr)', lg: 'repeat(2, 1fr)' }} gap={8}>
                <GridItem maxW={'386px'}>
                    <TextField label="Address Line 1" name="billingAddress1" placeholder="Enter Address Line 1" />
                </GridItem>
                <GridItem maxW={'386px'}>
                    <TextField label="Address Line 2" name="billingAddress2" placeholder="Enter Address Line 2" required={false} />
                </GridItem>
                <GridItem maxW={'386px'}>
                    <TextField label="City" name="billingCity" placeholder="Enter City" />
                </GridItem>
                <GridItem maxW={'386px'}>
                    <TextField label="Pincode" name="billingPincode" placeholder="Enter Pincode" />
                </GridItem>
            </Grid>
            <Box mt={7} mb={6}>
                <Typography type='body' weight='medium' color='primary' >
                    Shipping Address
                </Typography>
            </Box>
            <Flex mt={5} mb={5} gap={'8px'}>
                <Checkbox
                    isChecked={addressSame}
                    onChange={() => setAddressSame(!addressSame)}
                />
                <Typography type='body' weight='medium' color='secondary' colorweight='800' >
                    Same as billing address
                </Typography>
            </Flex>
            {!addressSame &&
                <Grid templateColumns={{ md: 'repeat(1, 1fr)', lg: 'repeat(2, 1fr)' }} gap={8}>
                    <GridItem maxW={'386px'}>
                        <TextField label="Address Line 1" name="shippingAddress1" placeholder="Enter Address Line 1" />
                    </GridItem>
                    <GridItem maxW={'386px'}>
                        <TextField label="Address Line 2" name="shippingAddress2" placeholder="Enter Address Line 1" required={false} />
                    </GridItem>
                    <GridItem maxW={'386px'}>
                        <TextField label="City" name="shippingCity" placeholder="Enter City" />
                    </GridItem>
                    <GridItem maxW={'386px'}>
                        <TextField label="Pincode" name="shippingPincode" placeholder="Enter Pincode" />
                    </GridItem>
                </Grid>
            }
        </>
    );
};

export default Business;