import React from 'react';
import "../../../modules/login/PasswordLogin.css";

function TextField(props) {
    const { value, inputChangeHandler, placeholder, type, name = "", disabled = false } = props
    return (
        <div style={{ textAlign: 'center', width: '100%' }}>
            <div className="pa-input-box-div">
                <input
                    type={type}
                    className="pa-input-email"
                    placeholder={placeholder}
                    onChange={(e) => inputChangeHandler(e)}
                    value={value}
                    name={name}
                    disabled={disabled}
                />
            </div>
        </div>
    );
}

export default TextField;