import React from 'react';
import { useNavigate } from 'react-router-dom'; 

// css
import "../../modules/signup/Signup.css";

// icons
import { FaArrowLeft } from "react-icons/fa";


function BackButton(props) {
    const navigate = useNavigate();
    return (
        <div className="pa-header-nav" onClick={()=>{ navigate(-1) }}>
            <FaArrowLeft size={14} />
            <div>
                <span style={{ fontSize: '15px' }} >Back</span>
            </div>
        </div>
    );
}

export default BackButton;
