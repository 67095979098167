import {
  Button,
  Flex,
 HStack,
  Grid,
  GridItem,
  Heading,
  Img,
 Box,
  Stack,
  Text,
  VStack,
  useClipboard,
  UnorderedList,
  ListItem,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import QR from "../../assets/qr.png";
import { IoCopyOutline } from "react-icons/io5";
import { join, slice } from "lodash";
import { useNavigate } from "react-router";
import WarningModal from "../../components/modal";

const CryptoCheckout = () => {
    const navigate = useNavigate();
  const walletAddress = "0x0bB5bF5A5D47CEf4c4b86F42B8B6d54Ac7d9D0aB";
  const amount = '1000';
  const cryptoTicker = 'USDT';
  const cryptoNetwork = 'ERC20';
  const { onCopy: onCopyWalletAddress } = useClipboard(walletAddress);
  const { onCopy: onCopyAmount } = useClipboard(amount);
  const { onClick: onCopyNetwork } = useClipboard(cryptoNetwork);
  const {
    onOpen: warnModalOpen,
    isOpen: warnModalIsOpen,
    onClose: warnModalClose,
  } = useDisclosure();

  const onSubmitHandler = () => {
    navigate('/payout/crypto-status')
  }

  return (
    <Stack
      direction={"column"}
      justifyContent={"space-between"}
      flexGrow={1}
      p={5}
    >
      <Grid templateColumns="repeat(2, 1fr)" gap={5}>
        <GridItem>
          <Heading as="h4" size={"md"}>
            Transfer Your{" "}
          </Heading>
          <Flex direction={"column"} gap={2} p={5}>
            <Text>Scan this QR Code from your Wallet to make the transfer</Text>
            <VStack>
              <Img src={QR} border={"1px"} p={1} width={"180px"} />
              <Text color={"#1A4FD6"}>
                Complete the transaction in : 03:59:45 mins
              </Text>
            </VStack>

            <VStack alignItems={"flex-start"} width="full">
              <Text fontSize={"14px"}>Please send to this Wallet Address</Text>

              <Button
                width="full"
                _focus={{ outline: "none" }}
                justifyContent="space-between"
                size="lg"
                onClick={onCopyWalletAddress}
                rightIcon={<IoCopyOutline />}
              >
                {`${join(slice(walletAddress, 0, 28), "")}...`}
              </Button>
            </VStack>
            <VStack alignItems={'flex-start'} width={'full'}>
                <Text fontSize={'14px'} >Amount to be transferred</Text>
                <Button
                    width={'full'}
                    _focus={{ outline: 'none' }}
                    justifyContent={'space-between'}
                    size={'lg'}
                    onClick={onCopyAmount}
                    rightIcon={<IoCopyOutline />}
                >
                    {cryptoTicker} {amount }
                </Button>
            </VStack>
            <VStack alignItems={'flex-start'} width={'full'}>
                <Text fontSize={'14px'} >Network</Text>
                <Button
                    width={'full'}
                    _focus={{ outline: 'none' }}
                    justifyContent={'space-between'}
                    size={'lg'}
                    onClick={onCopyNetwork}
                    rightIcon={<IoCopyOutline />}
                >   
                    {cryptoNetwork}
                </Button>
            </VStack>
            <Text>
            Once we've received your crypto, we will send money to all the recipient’s bank accounts which may take up to 2 business days.
            </Text>
          </Flex>
        </GridItem>
        <GridItem>
            <Box p={5} border={'1px'}>
                <VStack>
                <Heading as="h3" fontSize={'md'} color={'#1A4FD6'}>Carefully Check the Deposit Wallet Address & Transaction Network </Heading>
                <UnorderedList spacing={5}>
                    <ListItem>Sending crypto to the wrong address or wrong network can result in permanent loss. Please take the time to double-check the deposit wallet address before sending. </ListItem>
                    <ListItem>This page provides the address & network for you to send your crypto, and it's important that you verify all details are correct to ensure the safe arrival of your funds.</ListItem>
                </UnorderedList>
                </VStack>
                <WarningModal path="/payout" isOpen={warnModalIsOpen} onClose={warnModalClose} />
            </Box>
        </GridItem>
      </Grid>
      <HStack justifyContent={"flex-end"}>
        <Button
          minW={"200px"}
          color="#525C76"
          border="1px"
          borderColor="#525C76"
          _hover={{ bg: "#ffffff" }}
          variant={"outline"}
          onClick={warnModalOpen}
        >
          Cancel
        </Button>
        <Button
        //   isLoading={isLoading}
        //   isDisabled={!checked || !quotes}
          color={"#ffffff"}
          bg={"#1A4FD6"}
          _hover={{ bg: "#232D95" }}
          minW={"200px"}
          onClick={onSubmitHandler}
        >
          Continue
        </Button>
      </HStack>
    </Stack>
  );
};

export default CryptoCheckout;
