import {
	Box,
	Button,
	Checkbox,
	Flex,
	HStack,
	Heading,
	Stack,
	Text,
	useDisclosure,
	useToast,
} from "@chakra-ui/react";
import Select from "react-select";
import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router";

import AddRecipient from "./AddRecipient";
import WarningModal from "../../components/modal";
import {
	updateFiatTicker,
	updateCryptoTicker,
	getCurrencyConfigs,
	createOrder,
	updateOrder,
} from "../../store/slices/orderSlices";
import {
	getCountryList,
	getRecipient,
	getRecipientData,
	postRecipient,
} from "../../store/slices/recipientSlices";
import { resetOrder } from "../../store/slices/orderSlices";
import { getItem } from "../../utils/local_storage";
import ResetModal from "../../components/modal/ResetModal";
import SelectRecipient from "./SelectRecipient";
import FileUpload from "./FileUpload";
import CustomTable from "../../utils/customTable";
import { resetRecipinetList } from "../../store/slices/recipientSlices";
import { resetPaymentMethod } from "../../store/slices/paymentMethodsSlices";

const CreateOrder = () => {
	const [recipientList, setRecipientList] = useState([]);
	const cryptoList = useSelector((state) => state.order?.cryptoList);
	const fiatList = useSelector((state) => state.order?.fiatList);
	const payFiat = useSelector((state) => state.order?.fiatTicker);
	const sendCrypto = useSelector((state) => state.order?.cryptoTicker);
	const orderType = useSelector((state) => state.order?.type);
	const USformatter = new Intl.NumberFormat("en-US");

	const loading = useSelector(
		(state) => state.recipient?.loading || state.order?.loading
	);
	const isLoading = useSelector((state) => state.order?.loading);
	const { onOpen, onClose, isOpen } = useDisclosure();

	const {
		onOpen: warnModalOpen,
		onClose: warnModalCloae,
		isOpen: warmModalIsOpen,
	} = useDisclosure();
	const {
		onOpen: resetModalOpen,
		onClose: resetModalClose,
		isOpen: resetModalIsOpen,
	} = useDisclosure();
	const {
		onOpen: recipientModalOpen,
		onClose: recipientModalClose,
		isOpen: recipientModalIsOpen,
	} = useDisclosure();
	const {
		onOpen: recipientUploadModalOpen,
		onClose: recipientUploadModalClose,
		isOpen: recipientUploadModalIsOpen,
	} = useDisclosure();

	const toast = useToast();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		dispatch(resetRecipinetList());
		dispatch(resetPaymentMethod());
		onSubmitOrderHandler();
	}, []);

	const onSubmitOrderHandler = useCallback(() => {
		dispatch(createOrder({ type: 'payin' }))
			.then((resp) => {
				if (!resp.payload?.success) {
					navigate("/order");
				}
			})
			.catch(e => console.log(e));
	}, []);


	const columns = [
		{ Header: "Name", accessor: "name" },
		{ Header: "Country", accessor: "country" },
		{ Header: "Eamil ID", accessor: "email" },
		{ Header: "Type", accessor: "type" },
		{ Header: "Wallet Address", accessor: "walletAddress" },
		{
			Header: "Fiat Amount", accessor: "fiatAmount", Cell: ({ value }) => (
				<Text>{USformatter.format(value)}</Text>
			)
		},
		{ Header: "Purpose of Transaction", accessor: "purpose" },
		{
			Header: "Save Details",
			accessor: "saveDetails",
			Cell: ({ value }) => (
				<Checkbox
					justifyContent={"center"}
					display={"grid"}
					disabled
					defaultChecked={value}
				/>
			),
		},
	];

	const orderId = getItem("orderId");

	useEffect(() => {
		dispatch(getRecipient()).then((resp) => {
			if (resp) {
				setRecipientList(resp?.payload?.data?.list || []);
			}
		});
		dispatch(getCurrencyConfigs({ product: "payin" }));
		dispatch(getCountryList());
	}, []);

	const onAddRecipientHandler = () => {
		if (sendCrypto && payFiat) {
			onOpen();
		} else if (!payFiat) {
			toast({
				title: "Please Select Pay in Currency",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top",
			});
		} else if (!sendCrypto) {
			toast({
				title: "Please Select Send in Crypto",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top",
			});
		}
	};

	const onRecipientUploadHandler = () => {
		if (sendCrypto && payFiat) {
			recipientUploadModalOpen();
		} else if (!payFiat) {
			toast({
				title: "Please Select Pay in Currency",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top",
			});
		} else if (!sendCrypto) {
			toast({
				title: "Please Select Send in Crypto",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top",
			});
		}
	};

	const onAddRecipient = (recipientData) => {
		recipientData.orderId = orderId;
		recipientData.fiatTicker = payFiat?.value?.description;
		recipientData.cryptoTicker = {
			currency: sendCrypto?.value?.formattedSymbol,
			network: sendCrypto?.value?.network,
			symbol: sendCrypto?.value?.symbol
		};
		recipientData.orderType = orderType;
		dispatch(postRecipient(recipientData)).then((resp) => {
			if (resp) {
				dispatch(getRecipient()).then((resp) => {
					if (resp) {
						setRecipientList(resp?.payload?.data?.list || []);
					}
				});
			}
		});
	};

	// const onSelectRecipient = () => {
	// 	dispatch(getRecipientData());
	// 	recipientModalOpen()
	// }

	const onSubmitToPayment = () => {
		if (recipientList.length === 0) {
			toast({
				title: "No recipients found",
				description: "Please add recipients",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top",
			});
		} else {
			dispatch(updateOrder());
			navigate("/payin/payment-methods");
		}
	};

	const onOrderReset = () => {
		// dispatch(createOrder({ type: 'payin' }));
		dispatch(resetOrder()).then((resp) => {
			if (resp.payload?.success) {
				dispatch(getRecipient()).then((resp) => {
					if (resp) {
						console.log("hello", resp?.payload?.data?.list);
						setRecipientList(resp?.payload?.data?.list || []);
					}
				});
				resetModalClose();
			}
		});
	};

	return (
		<Stack direction={'column'} justifyContent={'space-between'} flexGrow={1} p={5}>
			<Box>
				<Flex
					dir="row"
					justifyContent={"flex-start"}
					gap="5"
					alignItems={"center"}
				>
					<>
						<Text fontSize="1xl">Pay In Currency </Text>
						<Select
							isDisabled={recipientList.length}
							styles={{
								control: (baseStyles, state) => ({
									...baseStyles,
									width: "200px",
								}),
							}}
							options={fiatList}
							value={payFiat}
							onChange={(value) => dispatch(updateFiatTicker(value))}
						/>
					</>
					<>
						<Text>Send In Crypto</Text>
						<Select
							isDisabled={recipientList.length}
							styles={{
								control: (baseStyles, state) => ({
									...baseStyles,
									width: "200px",
								}),
							}}
							value={sendCrypto}
							options={cryptoList}
							onChange={(value) => dispatch(updateCryptoTicker(value))}
						/>
					</>
				</Flex>
				<Flex
					direction={"column"}
					gap={3}
					border={"1px"}
					borderColor={"#D2D2D2"}
					mt={5}
				>
					<>
						<HStack px={5} pt={5} justifyContent={"space-between"}>
							<Heading as="h5" size="md">
								Recipients
							</Heading>
							<Box>
								<HStack>
									<Button
										variant={"outline"}
										fontSize={"12px"}
										color="#525C76"
										border="1px"
										borderColor="#525C76"
										_hover={{ bg: "#ffffff" }}
										onClick={resetModalOpen}
									>
										Reset
									</Button>
									{/* <Button
										fontSize={"12px"}
										color={"#ffffff"}
										bg={"#1A4FD6"}
										_hover={{ bg: "#232D95" }}
										onClick={onRecipientUploadHandler}
									>
										Prefill Using CSV
									</Button> */}
								</HStack>
							</Box>
						</HStack>
					</>
					<>
						<CustomTable
							columns={columns}
							data={recipientList}
							showPagination={false}
						/>
						<AddRecipient
							isLoading={loading}
							isOpen={isOpen}
							onClose={onClose}
							onSubmit={onAddRecipient}
						/>
						<SelectRecipient
							isOpen={recipientModalIsOpen}
							onClose={recipientModalClose}
						/>
						<WarningModal path="/payin" isOpen={warmModalIsOpen} onClose={warnModalCloae} />
						<FileUpload isOpen={recipientUploadModalIsOpen} onClose={recipientUploadModalClose} />
						<ResetModal
							isOpen={resetModalIsOpen}
							onClose={resetModalClose}
							onConfirm={onOrderReset}
						/>
					</>

					<>
						<HStack px={5} pb={5}>
							{/* <Button
                fontSize={"14px"}
                variant={"outline"}
                color={"#1A4FD6"}
                borderColor={"#1A4FD6"}
                _hover={{ bg: "none" }}
                onClick={onSelectRecipient}
              >
                Add Previously saved Recipient
              </Button> */}
							<Button
								color={"#ffffff"}
								bg={"#1A4FD6"}
								_hover={{ bg: "#232D95" }}
								leftIcon={<AddIcon w={3} h={3} />}
								fontSize={"14px"}
								onClick={onAddRecipientHandler}
							>
								Add recipient
							</Button>
						</HStack>
					</>
				</Flex>
			</Box>
			<HStack justifyContent={'flex-end'}>
				<Button
					minW={"200px"}
					color="#525C76"
					border="1px"
					borderColor="#525C76"
					_hover={{ bg: "#ffffff" }}
					variant={"outline"}
					onClick={warnModalOpen}
				>
					Cancel
				</Button>
				<Button
					isLoading={isLoading}
					isDisabled={recipientList.length === 0}
					color={"#ffffff"}
					bg={"#1A4FD6"}
					_hover={{ bg: "#232D95" }}
					minW={"200px"}
					onClick={onSubmitToPayment}
				>
					Continue
				</Button>
			</HStack>
		</Stack>
	)


};

export default CreateOrder;
