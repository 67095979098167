import { useCallback, useEffect, useRef, useState } from "react";
import { Box, Button, Checkbox, Img, Input, InputGroup, InputLeftElement, Menu, MenuButton, MenuItem, MenuList, Spinner, Textarea } from "@chakra-ui/react";
import { differenceWith, isEqual } from 'lodash';

import { ChevronDownIcon, SearchIcon, AddIcon } from '@chakra-ui/icons';
import Typography from "../../components/Common/Typography";
import { callApiWithToken } from "../../utils/http_common";

const RightIcon = ({ isOpen }) => {
    return (
      <Box
        w={"34px"}
        height={"32px"}
        borderRadius={"4px"}
        backgroundColor={"#E8EDFB"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <ChevronDownIcon w={"23px"} h={"23px"}
          transition="transform 0.2s ease-in-out"
          transform={isOpen ? "rotate(-180deg)" : "rotate(0deg)"}
  
        />
      </Box>
    )
  }

const SelectMenu = ({currency, item, direction, setInitialPaymentMethodData, initialPaymentMethodData, setSaveStatus, saveStatus, handleCheckboxChange, index, isDisabled, addedData, setAddedData, currencyData, initialCurrencyData}) => {
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [paymentMethodLoading, setPaymentMethodLoading] = useState(false);  
    const [searchText, setSearchText] = useState('');
    
    useEffect(() => {
      const changesOccurred = JSON.stringify(initialPaymentMethodData) !== JSON.stringify(filteredData);
      const toggleData = currencyData.filter((item,i)=>item?.currency==currency);
      if (changesOccurred && item[direction]) {
        handleCheckboxChange(true, index);
        const added = differenceWith(filteredData, initialPaymentMethodData, isEqual);
        const removed = differenceWith(initialPaymentMethodData, filteredData, isEqual);

        setAddedData(prevState => ({
          ...prevState,
          [currency]: {
            ...prevState[currency], 
            [direction]: added,
            [`${direction}_length`]: added?.length, 
          }
        }));

      }else{
        const toggleData = currencyData.filter((item,i)=>i==index);
        if(toggleData?.length > 0){
          const initialToggleData = initialCurrencyData.filter((item)=>item.currency === toggleData[0]?.currency)
          const isEqual = JSON.stringify(initialToggleData) === JSON.stringify(toggleData);
          if(isEqual){
            const updatedSaveStatus = [...saveStatus];
            updatedSaveStatus[index] = false;
            setSaveStatus(updatedSaveStatus);
          }
        }
      }
    }, [filteredData]);
    
    const handleFetchPaymentMethods = useCallback(async () => {
        try {
          setPaymentMethods([]);
          setPaymentMethodLoading(true);
          const response = await callApiWithToken.get(
            `/setting/paymentMethods?currency=${currency}&direction=${direction}`
          );
          setPaymentMethods(response?.data?.data?.paymentMethods);
          setFilteredData(response?.data?.data?.paymentMethods);
          setInitialPaymentMethodData(response?.data?.data?.paymentMethods);
          setAddedData(prevState => {
            const newState = { ...prevState };
            delete newState[currency];
            return newState;
          });
        } catch (error) {
          console.log(error);
        } finally {
          setPaymentMethodLoading(false);
        }
      }, [currency, direction]);

      const handleSearchInputChange = (e) => {
        const text = e.target.value;
        setSearchText(text);
        filterData(text);
      };
    
      const filterData = (input) => {
        const lowercasedInput = input.toLowerCase();
        const filtered = paymentMethods.filter(
          (item) =>
            item.name.toLowerCase().includes(lowercasedInput) 
        );
        setFilteredData(filtered);
      };

      const handleCheckboxStatus = (index) => {
        const updatedData = filteredData.map((item, i) => i === index ? { ...item, status: !item.status } : { ...item });
        setFilteredData(updatedData);
      }
    return (
    <Box opacity={isDisabled ? 1 : 0.5} pointerEvents={isDisabled ? "auto" : "none"}>
    <Menu closeOnSelect={false}>
      {({ isOpen }) => (
        <>
          <MenuButton
            _hover={{ bg: "#F5F5F5" }}
            _expanded={{ bg: "#F5F5F5" }}
            h={"45px"}
            pr={1}
            pl={4}
            rightIcon={<RightIcon isOpen={isOpen} />}
            bg={"white"}
            className="select-menu-panel"
            borderRadius={"5px"}
            as={Button}
            w="full"
            onClick={()=>handleFetchPaymentMethods()}>
            <Box display={"flex"} alignItems={"center"} columnGap={2}>
              { <Typography className="selectBankDropdown" type="description" weight="regular">{addedData?.[currency]?.[direction]?.length 
                    ? `${addedData[currency][direction].length} Selected` 
                    : `Select`}
                   </Typography>
              }
            </Box>
          </MenuButton>
          <MenuList padding={"12px"} paddingTop={0} w="200px" maxH="280px" overflowY="auto" position="relative">
            <Box marginBottom={"16px"}>
              <InputGroup>
                <InputLeftElement pointerEvents='none'>
                  <SearchIcon color='brand.primary' />
                </InputLeftElement>
                <Input
                  placeholder="Search..."
                  name="search"
                  id="search-input"
                  value={searchText}
                  onChange={(e)=>handleSearchInputChange(e)}
                  width="100%"/>
              </InputGroup>
            </Box>
            {paymentMethodLoading ?
             <Box display='flex' justifyContent='center' h={"50px"} alignItems='center' >
               <Spinner/>
             </Box>:
            filteredData.length === 0?
             <Box display='flex' justifyContent='center' h={"50px"} alignItems='center' >
              <Typography type="description" weight="regular">No result found!</Typography>
             </Box>:
             filteredData.map((option, index)=>{
                return(
                 <MenuItem key={index} paddingY={6} paddingX={2} className="select-menu-option-box">
                  <Checkbox isChecked={option?.status} onChange={()=>handleCheckboxStatus(index)}>
                    <Box display="flex" alignItems="center">
                      {option.logoUrl &&
                        <Img width={6} height={6} borderRadius="full" src={process.env.PUBLIC_URL + option.logoUrl} alt="" mr="10px" />
                      }
                      <Typography type="description" weight="regular">{option.name}</Typography>
                      </Box>
                   </Checkbox>
                  </MenuItem>  
                )
            })}
          </MenuList>
        </>
      )}
    </Menu>
    </Box>
  )
}

export default SelectMenu