import { createSlice } from "@reduxjs/toolkit";

const draftRecipientSlice = createSlice({
    name: 'stageRecipient',
    initialState: {
        draftRecipients: [],
    },
    reducers: {
        addRecipientList(state, action) {
            const newRecipient = action.payload
            state.recipients.push(newRecipient)
        }
    }
})

export const stageRecipientAction = draftRecipientSlice.actions;

const { reducer: draftRecipientReducer } = draftRecipientSlice;

export default draftRecipientReducer