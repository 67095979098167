import React, { useState } from 'react';
import { Box, Grid, GridItem, Button, HStack, Stack } from '@chakra-ui/react'
import { Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import Typography from '../../components/Common/Typography';
import { CustomSelectFieldSearch } from '../contact/add-contact/InputField';
import { setActiveStep, setSelectedCustomerDetails } from '../../store/slices/collectMoneySlice';
import { selectCustomerValidationSchema } from './validationSchema';
import { SelectMenu } from '../../components/Common/InputComponents/InputComponents';

const CustomerDetails = ({ customerList, showAddCustomer, setShowAddCustomer }) => {


    const loading = useSelector((state) => state.customer.loading) || false;

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const initialValues = {
        customer: ''
    }

    const handleSubmit = async (values) => {
        try {
            dispatch(setSelectedCustomerDetails(values.customer));
            dispatch(setActiveStep(1))
        } catch (error) {
            console.error("Error adding customer:", error);
        }
    };

    return (
        <Box mt={10}>
            <HStack justifyContent="space-between">
                <Typography type="body" weight="medium" color="primary">
                    Customer Details
                </Typography>
                <Button onClick={setShowAddCustomer} ml="3rem" textColor={'white'} bg={'#1A4FD6'} _hover={{ bg: '#1A4FD6' }}>
                    Add New Customer +
                </Button>
            </HStack>
            <Formik
                initialValues={initialValues}
                validateOnChange={false}
                validationSchema={selectCustomerValidationSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid templateColumns={{ md: 'repeat(1, 1fr)', lg: 'repeat(2, 1fr)' }} gap={8} mt={5}>

                            <GridItem maxW={'386px'}>
                                <SelectMenu
                                    label={"Select Customer"}
                                    isMandatory={true}
                                    options={customerList || []}
                                    name="customer"
                                    placeholder="Select"
                                    searchBoxPlaceholder='Search Customer'
                                    optionBoxPlaceHolder='No Customer Added'
                                    isDisabled={false}
                                />
                            </GridItem>
                        </Grid>
                        <Stack pt={8} bg={"white"} direction="row" gap={"20px"} justifyContent={"end"} w='100%' marginTop={0}>
                            <Button variant={"secondary"} size={"sm"} type="button" p={"9px 16px 9px 16px"} onClick={() => navigate(-1)}>
                                Back
                            </Button>
                            <Button type='submit' textColor={'white'} bg={'#1A4FD6'} size={"sm"} p={"9px 16px 9px 16px"} isLoading={loading} _hover={{ bg: '#1A4FD6' }}>
                                Confirm
                            </Button>
                        </Stack>
                    </form>
                )}

            </Formik>
        </Box>
    );
}
export default CustomerDetails;