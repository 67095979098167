import * as Yup from "yup";

export const bankValidationSchema = Yup.object().shape({
    currency: Yup.string().required("Currency is required"),
    nickname: Yup.string().required("Bank account holder name is required"),
    number: Yup.string().required("Bank account number is required"),
    bankName: Yup.string().required("Bank Name is required"),
});

export const walletValidationSchema = Yup.object().shape({
    stableCoin: Yup.string().required('Stable Coin is required'),
    walletAddress: Yup.string().required('Wallet Address is required'),
    walletName: Yup.string().required('Nick Name for wallet is required'),
});

