import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from "redux";
import { encryptTransform } from 'redux-persist-transform-encrypt';

import reducers from './rootReducers';

const encryptor = encryptTransform({
    secretKey: process.env.REACT_APP_ENC_KEY,
    onError: function (error) {
      console.error('Encryption Error:', error);
    },
  });

const persistConfig = {
    key: 'root',
    storage,
    transforms: [encryptor]
  };

const persistedReducers = persistReducer(persistConfig, reducers);

const store = configureStore({
    reducer: persistedReducers,
    middleware: (getDefaultMiddleware) => process.env.REACT_APP_NODE_ENV === 'development' ? getDefaultMiddleware().concat(logger) : getDefaultMiddleware(),
    devTools: process.env.NODE_ENV !== 'production'
});

if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./rootReducers', () => store.replaceReducer(persistedReducers))
}

const persistor = persistStore(store);

export { store, persistor };