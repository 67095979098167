import React, { useEffect, useState } from 'react';
import { Box, Button, Flex, Icon, Table, TableContainer, Tbody, Td, Th, Tr, useDisclosure } from '@chakra-ui/react';
import { MdOutlineModeEdit } from "react-icons/md";
import { BsTrash } from "react-icons/bs";
import { InfoOutlineIcon, CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { useSelector } from 'react-redux';

import Typography from '../../../components/Common/Typography';
import InvitationModal from '../modals/InvitationModal';
import DeleteModal from '../modals/DeleteModal';
import DeleteInviteModal from '../modals/DeleteInviteModal';
import EditModal from '../modals/EditModal';
import AcceptReqModal from '../modals/AcceptReqModal';
import DeleteReqModal from '../modals/DeleteReqModal';

const Teams = ({ team, joinRequests, refreshData }) => {

    const userDetails = useSelector((state) => state?.login?.userDetails)

    const [isAdmin, setIsAdmin] =  useState(false)
    
    useEffect(()=> {
        if(userDetails?.cxRoles.includes('admin')){
            setIsAdmin(true)
        }
     },[userDetails])

    const [modalData, setModalData] = useState({});

    const { isOpen: invitationIsOpen, onOpen: invitationOnOpen, onClose: invitationOnClose } = useDisclosure()
    const { isOpen: deleteIsOpen, onOpen: deleteOnOpen, onClose: deleteOnClose } = useDisclosure()
    const { isOpen: deleteInviteIsOpen, onOpen: deleteInviteOnOpen, onClose: deleteInviteOnClose } = useDisclosure()
    const { isOpen: editIsOpen, onOpen: editOnOpen, onClose: editOnClose } = useDisclosure()
    const { isOpen: acceptIsOpen, onOpen: acceptOnOpen, onClose: acceptOnClose } = useDisclosure()
    const { isOpen: deleteReqIsOpen, onOpen: deleteReqOnOpen, onClose: deleteReqOnClose } = useDisclosure()

    const formattedDate = (date) => {
        const originalDate = new Date(date);
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        const formattedDate = originalDate.toLocaleDateString('en-US', options);
        return formattedDate;
    }
    const handleInviteTeammate = () => {
        invitationOnOpen();
    };

    const handleDelete = (e) => {
        setModalData(e);
        deleteOnOpen();
    }

    const handleDeleteInvite = (e) => {
        setModalData(e);
        deleteInviteOnOpen()
    }

    const handleEditTeammate = (e) => {
        setModalData(e);
        editOnOpen()
    }

    const handleAcceptRequest = (e) => {
        setModalData(e);
        acceptOnOpen()
    }

    const handleDeleteRequest = (e) => { 
        setModalData(e);
        deleteReqOnOpen();
    }

    return (
        <Box py={10}>
            <Flex alignItems={"center"} justify={'space-between'}>
                <Box>
                    <Typography color="primary" colorweight="500" type='body' weight='medium'>Manage Team</Typography>
                    <Typography color="secondary" colorweight="800" type='caption' weight='regular'>Invite team members, assign roles & give them permissions</Typography>
                </Box>
                {isAdmin && <Button bg={'#1A4FD6'} fontWeight={'400'} color={'#FFFFFF'} h={'32px'} w={'150px'} fontSize={'12px'} fontFamily={'DM Sans'} onClick={handleInviteTeammate} _hover={{ bg: '#366cf5' }}>Invite Teammate  +</Button>}
            </Flex>

          {joinRequests.length > 0 && isAdmin &&
          <Box mt={8}>
          <Typography color="secondary" colorweight="800" type='description' weight='medium'>
              <InfoOutlineIcon color={'#E90000'} mr={1} mb={1} /> Join requests {`(${joinRequests.length})`}</Typography>

          {joinRequests.map((e, i) =>
              <Box key={i} mt={5} px={'16px'} py={'22px'} bg={'#F2F2F2'} border={'1px'} borderColor={'#E4E4E4'} rounded={'6px'}>
                  <Flex justify={'space-between'} alignItems={'center'}>
                      <Typography color="secondary" colorweight="800" type='description' weight='medium'>{e.email}</Typography>
                      <Flex gap={5}>
                          <Button h={'32px'} size={'sm'} bg={'#FFFFFF'} border={"1px"} borderColor={"#F2F2F2"} rounded={'6px'} _hover={{ cursor: 'pointer' }} onClick={() => handleDeleteRequest(e)} >
                              <Icon as={CloseIcon} color={'red.400'} h={'12px'} />
                          </Button>
                          <Button h={'32px'} size={'sm'} bg={'#1A4FD6'} border={"1px"} borderColor={"#F2F2F2"} rounded={'6px'} _hover={{ cursor: 'pointer' }} onClick={() => handleAcceptRequest(e)}>
                              <Icon as={CheckIcon} color={'#FFFFFF'} />
                          </Button>
                      </Flex>
                  </Flex>
              </Box>)}
      </Box>}

            <TableContainer mt={10} height={'70vh'} overflowY={'scroll'} position={'relative'}>
                <Table variant='simple'>
                    <Tbody >
                        <Tr position={'sticky'} zIndex={2} top={0} bg={'#FFFFFF'}>
                            <Td>
                                <Typography color="secondary" colorweight="400" type='body' weight='medium'>User</Typography>
                            </Td>
                            <Td>
                                <Typography color="secondary" colorweight="400" type='body' weight='medium'>Role</Typography>
                            </Td>
                            <Th></Th>
                        </Tr>
                        {team?.map((e, index) =>

                            <Tr key={index}>
                                <Td>
                                    <Typography color="secondary" colorweight="800" type='description' weight='medium'>{e.email}</Typography>
                                </Td>
                                <Td>
                                    {e.appRole?.map((e, index) => <Typography key={index} color="secondary" colorweight="800" type='description' weight='medium'>{e}</Typography>)}
                                </Td>
                                <Td>
                                    <Flex justify={'flex-end'} gap={5}>
                                        {e.inviteOptions && e.inviteOptions?.isInvitationValid && !e.inviteOptions?.isInvitationAccepted &&
                                            <Flex direction={"column"} justify={"center"} align={'center'}>
                                                <Typography key={index} color="secondary" colorweight="400" type='caption' weight='medium'>Invite Sent</Typography>
                                                <Typography key={index} color="secondary" colorweight="400" type='caption' weight='medium'>{formattedDate(e.inviteOptions.invitationSentOn)}</Typography>
                                            </Flex>}

                                        {e.inviteOptions && e.inviteOptions?.isInvitationValid && e.inviteOptions?.invitationAcceptedOn &&
                                            <Flex direction={"column"} justify={"center"} align={'center'}>
                                                <Typography key={index} color="secondary" colorweight="400" type='caption' weight='medium'>Invite Accepted</Typography>
                                                <Typography key={index} color="secondary" colorweight="400" type='caption' weight='medium'>{formattedDate(e.inviteOptions.invitationAcceptedOn)}</Typography>
                                            </Flex>}

                                        <Flex justify={'center'} align={'center'} gap={5}>
                                            <Button h={'32px'} size={'sm'} bg={'#FFFFFF'} border={"1px"} borderColor={"#F2F2F2"} rounded={'6px'} _hover={{ cursor: 'pointer' }} onClick={() => handleEditTeammate(e)} isDisabled={e.appRole.includes("admin") || !isAdmin} >
                                                <Icon as={MdOutlineModeEdit} color={'#1A4FD6'} />
                                            </Button>
                                            {e.inviteOptions && e.inviteOptions?.isInvitationValid && !e.inviteOptions?.isInvitationAccepted ? <Button h={'32px'} size={'sm'} bg={'#FFFFFF'} border={"1px"} borderColor={"#F2F2F2"} rounded={'6px'} _hover={{ cursor: 'pointer' }} onClick={() => handleDeleteInvite(e)} isDisabled={e.appRole.includes("admin") || !isAdmin}>
                                                <Icon as={BsTrash} color={'red.400'} />
                                            </Button> :
                                                <Button h={'32px'} size={'sm'} bg={'#FFFFFF'} border={"1px"} borderColor={"#F2F2F2"} rounded={'6px'} _hover={{ cursor: 'pointer' }} onClick={() => handleDelete(e)} isDisabled={e.appRole.includes("admin") || !isAdmin}>
                                                    <Icon as={BsTrash} color={'red.400'} />
                                                </Button>}
                                        </Flex>
                                    </Flex>
                                </Td>
                            </Tr>

                        )}
                    </Tbody>
                </Table>
            </TableContainer>
            <InvitationModal onClose={invitationOnClose} isOpen={invitationIsOpen} refreshData={refreshData} />
            <DeleteModal onClose={deleteOnClose} isOpen={deleteIsOpen} data={modalData} refreshData={refreshData} />
            <DeleteInviteModal onClose={deleteInviteOnClose} isOpen={deleteInviteIsOpen} data={modalData} refreshData={refreshData} />
            <EditModal onClose={editOnClose} isOpen={editIsOpen} data={modalData} refreshData={refreshData} />
            <AcceptReqModal onClose={acceptOnClose} isOpen={acceptIsOpen} data={modalData} refreshData={refreshData} />
            <DeleteReqModal onClose={deleteReqOnClose} isOpen={deleteReqIsOpen} data={modalData} refreshData={refreshData} />
        </Box>
    );
};

export default Teams;