import { useEffect, useState, useCallback, useRef, forwardRef } from 'react';
import { ceil, isEmpty, round, debounce } from 'lodash';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Button } from '@chakra-ui/button';
import { Field, useFormikContext } from "formik";
import { Box, HStack, Stack, VStack } from '@chakra-ui/layout';
import { Img } from '@chakra-ui/image';
import { Input, InputGroup, InputLeftElement, Spinner, useToast } from '@chakra-ui/react';

// Custom Function and Components
import Typography from '../../../../components/Common/Typography';
import Card from '../../../../components/Common/Card';
import { goToPreviousStep, setSendAmount, setReceivedAmount } from '../../../../store/slices/sendMoneySlices';
import TransactionService from '../../../../services/transactionService';
import { saveDataToSession } from '../../../../utils/session-storing-utils';
import { currencyFormatter } from '../../../../utils/formatter';

// CSS
import '../../sendmoney.css';


const SetAmountTab = (props) => {

  const { setFieldValue, setFieldError, setFieldTouched } = useFormikContext();
  const dispatch = useDispatch();
  const toast = useToast();

  const [quotesState, setQuotesState] = useState(true);
  const [apiLoading, setApiLoading] = useState(false);
  const [fieldChange, setFieldChange] = useState(false);
  const [fxRate, setFxRate] = useState(0);
  const [payoutFees, setPayoutFees] = useState(0);
  const [otherFees, setOtherFees] = useState(0);

  const balanceDetails = useSelector((state) => state.sendMoney.values.walletName, shallowEqual);
  const paymentAccountDetails = useSelector((state) => state.sendMoney.values.paymentAccountName, shallowEqual);
  const sendAmount = useSelector((state) => state.sendMoney.sendAmount);
  const receivedAmount = useSelector((state) => state.sendMoney.receivedAmount);
  const [direction, setDirection] = useState("forward");

  const leftFieldRef = useRef(null);
  const rightFieldRef = useRef(null);

  function handleBack() {
    dispatch(goToPreviousStep());
    setFieldValue("enteredAmount", 0);    // Resetting states on clicking back
    setFieldValue("receivedAmount", 0);
    dispatch(setSendAmount(0));
    dispatch(setReceivedAmount(0));
  }

  const handleToast = (title, type) => {
    toast({
      title,
      status: type,
      duration: 5000,
      isClosable: true,
      position: "top",
    });
  };

  function getFilterForQuotes({ amount, direction = "forward" }) {
    const depositType = balanceDetails.value;
    const withdrawType = paymentAccountDetails.type;

    let filter = {};
    if (withdrawType === "crypto_wallet") {
      if (depositType === "crypto") {
        filter = {
          product: "stable_to_stable",
          amount,
          baseCurrency: balanceDetails.label,
          quoteCurrency: paymentAccountDetails.currency,
          paymentType: "DEFAULT",
          extraDetails: "true",
          ...(direction === "reverse" ? { direction: "reverse" } : {})
        };
      }
      else {
        filter = {
          product: "payout_fiat_to_stable",
          cryptoTicker: paymentAccountDetails.currency,
          fiatTicker: balanceDetails.label,
          amount,
          quoteVersion: "v2",
          paymentType: "DEFAULT",
          extraDetails: "true",
          ...(direction === "reverse" ? { direction: "reverse" } : {})
        };
      }
    }

    else {
      filter = {
        product: "payout_stable_to_fiat",
        amount,
        cryptoTicker: balanceDetails.label,
        fiatTicker: paymentAccountDetails.currency,
        extraDetails: "true",
        ...(direction === "reverse" ? { direction: "reverse" } : {})
      };
    }
    return filter;
  }

  const getQuotes = useCallback(
    debounce(async (direction = "forward") => {
      let errorMessageForField = "";
      if (Number(sendAmount) === 0 && Number(receivedAmount) === 0) {
        return;
      }

      try {
        setApiLoading(true);
        if (sendAmount && Number(sendAmount) !== 0 && direction === "forward") {
          const filters = getFilterForQuotes({ amount: sendAmount });
          const { data: response } = await TransactionService.getQuotesData({ filters }) || {};

          const { errorCode, message } = response?.data || {};

          if (["QUOTES_MIN_AMOUNT_ERROR", "QUOTES_LIMIT_ERROR", "QUOTES_MAX_AMOUNT_ERROR", "QUOTES_PAIR_NOT_AVAILABE_ERROR", "UNSUPPORTED_CRYPTO_CURRENCY"].includes(errorCode)) {
            errorMessageForField = message;
            if (isEmpty(message))
              handleToast("Something Went Wrong!", 'error');
          } else {

            let { receiveAmount = 0, processingFee = 0, networkFee = 0, cryptoPriceWithoutMarkup = 1, cryptoPrice = 1, quoteId = "" } = response?.data || {};
            setFieldValue("quoteId", quoteId);

            if (filters.product === "payout_fiat_to_stable") {
              if (receiveAmount < 0) {
                receiveAmount = 0;
                errorMessageForField = `Minimum Limit: ${(Number(processingFee) + Number(networkFee))} ${balanceDetails.label}`;
              }

              setFxRate(round((1 / cryptoPrice), 6));
              setPayoutFees(processingFee);
              setOtherFees(networkFee);
              saveDataToSession("payout-quotes-data", "fxRate", round((1 / cryptoPrice), 6));
              saveDataToSession("payout-quotes-data", "processingFee", processingFee);
              saveDataToSession("payout-quotes-data", "otherFee", networkFee);
            }
            else if (filters.product === "payout_stable_to_fiat") {

              if (receiveAmount < 0) {
                receiveAmount = 0;
                errorMessageForField = `Minimum Limit: ${ceil(((processingFee / cryptoPriceWithoutMarkup) || 0), 2)} ${balanceDetails.label}`;
              }

              setFxRate(round((cryptoPrice || 0), 6));
              setPayoutFees(round(((processingFee / cryptoPriceWithoutMarkup) || 0), 6));
              setOtherFees(0);
              saveDataToSession("payout-quotes-data", "fxRate", round(cryptoPrice, 6));
              saveDataToSession("payout-quotes-data", "processingFee", round((processingFee / cryptoPriceWithoutMarkup), 6));
              saveDataToSession("payout-quotes-data", "otherFee", 0);
            }
            else {
              if (receiveAmount < 0) {
                receiveAmount = 0;
                errorMessageForField = `Minimum Limit: ${(Number(processingFee) + Number(networkFee))} ${balanceDetails.label}`;
              }

              setFxRate(round((1 / cryptoPrice || 0), 6));
              setPayoutFees(processingFee);
              setOtherFees(networkFee);
              saveDataToSession("payout-quotes-data", "fxRate", round((1 / cryptoPrice), 6));
              saveDataToSession("payout-quotes-data", "processingFee", Number(processingFee));
              saveDataToSession("payout-quotes-data", "otherFee", Number(networkFee));
            }

            dispatch(setReceivedAmount(paymentAccountDetails.currency === "BTC" ?
              currencyFormatter(receiveAmount, 5, false) :
              currencyFormatter(receiveAmount, 2, false)
            ));

            setApiLoading(false);
          }
        }

        else if (receivedAmount && Number(receivedAmount) !== 0 && direction === "reverse") {

          const filters = getFilterForQuotes({ amount: receivedAmount, direction: "reverse" });
          const { data: response } = await TransactionService.getQuotesData({ filters }) || {};

          const { errorCode, message } = response?.data || {};

          if (["QUOTES_MIN_AMOUNT_ERROR", "QUOTES_LIMIT_ERROR", "QUOTES_MAX_AMOUNT_ERROR", "QUOTES_PAIR_NOT_AVAILABE_ERROR", "UNSUPPORTED_CRYPTO_CURRENCY"].includes(errorCode)) {
            errorMessageForField = message;
            if (isEmpty(message))
              handleToast("Something Went Wrong!", 'error');
          } else {

            let { receiveAmount = 0, processingFee = 0, networkFee = 0, processingFeeDetails = {}, cryptoPriceWithoutMarkup = 1, cryptoPrice = 1, receiveFiatAmount = 0, quoteId = "" } = response?.data || {};
            setFieldValue("quoteId", quoteId);

            if (filters.product === "payout_fiat_to_stable") {
              if (receiveAmount < 0) {
                receiveAmount = 0;
                errorMessageForField = `Minimum Limit: ${(Number(processingFee) + Number(networkFee))} ${balanceDetails.label}`;
              }

              setFxRate(round((1 / cryptoPrice), 6));
              setPayoutFees(processingFee);
              setOtherFees(networkFee);
              saveDataToSession("payout-quotes-data", "fxRate", round((1 / cryptoPrice), 6));
              saveDataToSession("payout-quotes-data", "processingFee", processingFee);
              saveDataToSession("payout-quotes-data", "otherFee", networkFee);
            }
            else if (filters.product === "payout_stable_to_fiat") {

              if (receiveAmount < 0) {
                receiveAmount = 0;
                errorMessageForField = `Minimum Limit: ${ceil(((processingFee / cryptoPriceWithoutMarkup) || 0), 2)} ${balanceDetails.label}`;
              }

              setFxRate(round((cryptoPrice || 0), 6));
              setPayoutFees(round(((processingFee / cryptoPriceWithoutMarkup) || 0), 6));
              setOtherFees(0);
              saveDataToSession("payout-quotes-data", "fxRate", round(cryptoPrice, 6));
              saveDataToSession("payout-quotes-data", "processingFee", round((processingFee / cryptoPriceWithoutMarkup), 6));
              saveDataToSession("payout-quotes-data", "otherFee", 0);
            }
            else {
              if (receiveAmount < 0) {
                receiveAmount = 0;
                errorMessageForField = `Minimum Limit: ${(Number(processingFee) + Number(networkFee))} ${balanceDetails.label}`;
              }

              setFxRate(round((1 / cryptoPrice || 0), 6));
              setPayoutFees(processingFee);
              setOtherFees(networkFee);
              saveDataToSession("payout-quotes-data", "fxRate", round((1 / cryptoPrice), 6));
              saveDataToSession("payout-quotes-data", "processingFee", Number(processingFee));
              saveDataToSession("payout-quotes-data", "otherFee", Number(networkFee));
            }

            const valueToBeSet = balanceDetails?.label === "BTC" ?
              currencyFormatter(receiveAmount, 5, false) :
              currencyFormatter(receiveAmount, 2, false)
              ;
            dispatch(setSendAmount(valueToBeSet));
            setFieldValue("enteredAmount", valueToBeSet);

            setApiLoading(false);
          }
        }

      } catch (error) {
        const { response: errorObject = {} } = error || {};
        const { message: errorMessage } = errorObject?.data?.error || {};

        if (!isEmpty(errorMessage)) {
          errorMessageForField = errorMessage;
        } else {
          handleToast("Something went wrong", "error");
        }

        dispatch(setReceivedAmount(0));
        dispatch(setSendAmount(0));

      } finally {
        setApiLoading(false);
        setFieldChange(false);

        await setFieldTouched('enteredAmount', true, true);
        setFieldError("enteredAmount", errorMessageForField);
      }
    }, 500),
    [sendAmount, receivedAmount]
  );

  useEffect(() => {
    let timeoutId;

    if (direction === "forward") {
      timeoutId = setTimeout(() => {
        getQuotes(direction);
      }, 1000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [sendAmount]);

  useEffect(() => {
    let timeoutId;

    if (direction === "reverse") {
      timeoutId = setTimeout(() => {
        getQuotes(direction);
      }, 1000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [receivedAmount]);

  useEffect(() => {
    //whenever any field gets changed, they gets disabled until API response
    //this code ensure that previous field gets refocused after that
    if (!fieldChange) {
      if (direction === "forward") {
        leftFieldRef.current.focus();
      } else if (direction === "reverse") {
        rightFieldRef.current.focus();
      }
    }
  }, [fieldChange]);

  return (
    <Card>
      <HStack w={"100%"} display={"flex"} justifyContent={"space-between"} >
        <Typography type='body' weight='medium' color='primary' >
          Set Amount
        </Typography>
        <Box display={"flex"} flexDirection={"row"} alignItems={"center"} justifyContent={"center"} gap={"10px"} >
          <Typography type='caption' weight='medium' color='headertext' >
            Balance :
          </Typography>
          <Typography type='body' weight='medium' color='headertext' >
            {`${balanceDetails.label} ${balanceDetails.label === "BTC" ? currencyFormatter(balanceDetails.balance, 5) : currencyFormatter(balanceDetails.balance)}`}
          </Typography>
        </Box>
      </HStack>
      <HStack w={"100%"} display={"flex"} justifyContent={"space-between"} alignItems={"flex-start"} >
        <VStack align={'flex-start'} >
          <Typography type='description' weight='regular' color='secondary' colorweight='800' >
            You Send
          </Typography>
          <Box className='sa-body-box' >
            <Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"} gap={"16px"} >
              <Img src={process.env.PUBLIC_URL + balanceDetails.logo} alt={"logo"} w={"24px"} h={"24px"} />
              <Typography type='body' weight='regular' color='secondary' colorweight='900' >
                {`${balanceDetails.label}`}
              </Typography>
            </Box>
            <Box >
              <Field name='enteredAmount'>
                {({ field, form }) =>
                  <CustomTextField type='number' name="enteredAmount" placeholder="Enter Amount" w={"151px"} h={"40px"} fontWeight={"400"} fontSize={"14px"} textAlign={"right"} marginTop={"2px"} marginBottom={"5px"}
                    value={sendAmount}
                    ref={leftFieldRef}
                    onChange={(event) => {
                      const { value } = event.target;
                      const regex = balanceDetails?.label === "BTC" ? /^\d+(\.\d{0,5})?$/ : /^\d+(\.\d{0,2})?$/
                      if (!value || regex.test(value)) {
                        setFieldChange(true);
                        dispatch(setSendAmount(value));
                        setDirection(prev => "forward");
                        field.onChange(event);
                      }
                    }}
                    //direction makes sure that only opposite textfield is showing loader
                    loading={apiLoading && direction === "reverse"}
                    isDisabled={
                      apiLoading || //disable field when api is loading
                      //this ensures that opposite field gets disabled when we start typing on field
                      (fieldChange && direction === "reverse")
                    }
                  />
                }
              </Field>
            </Box>
          </Box>
        </VStack>
        <VStack align={'flex-start'} >
          <Typography type='description' weight='regular' color='secondary' colorweight='800' >
            They Receive
          </Typography>
          <Box className='sa-body-box' >
            <Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"} gap={"16px"} >
              <Img src={process.env.PUBLIC_URL + paymentAccountDetails.logo} alt={"logo"} w={"24px"} h={"24px"} />
              <Typography type='body' weight='regular' color='secondary' colorweight='900' >
                {`${paymentAccountDetails.currency}`}
              </Typography>
            </Box>
            <Box>
              <Field name='receivedAmount'>
                {({ field, form }) =>
                  <CustomTextField type='number' name="receivedAmount" placeholder="Enter Amount" w={"151px"} h={"40px"} fontWeight={"400"} fontSize={"14px"} textAlign={"right"} marginBottom={"6px"}
                    value={receivedAmount}
                    ref={rightFieldRef}
                    onChange={(event) => {
                      const { value } = event.target;
                      const regex = paymentAccountDetails.currency === "BTC" ? /^\d+(\.\d{0,5})?$/ : /^\d+(\.\d{0,2})?$/
                      if (!value || regex.test(value)) {
                        setFieldChange(true);
                        dispatch(setReceivedAmount(value));
                        setDirection(prev => "reverse");
                        field.onChange(event);
                      }
                    }}
                    //direction makes sure that only opposite textfield is showing loader
                    loading={apiLoading && direction === "forward"}
                    isDisabled={
                      apiLoading || //disable field when api is loading
                      //this ensures that opposite field gets disabled when we start typing on field
                      (fieldChange && direction === "forward")
                    }
                  />
                }
              </Field>

            </Box>
          </Box>
          <Box w={"100%"} display={"flex"} textAlign={"right"} flexDirection={"column"} justifyContent={'flex-end'} gap={"8px"} >
            <Typography type='caption' weight='regular' color='primary' textDecoration={"underline"} cursor={"pointer"} onClick={() => { setQuotesState((prev) => !prev); }} >
              {quotesState ? "Hide fee" : "Show fee"}
            </Typography>
            {quotesState &&
              <>
                <Typography type='caption' weight='regular'>
                  (On hold for 10 mins) FX Rate: 1 {balanceDetails.label} = {paymentAccountDetails.currency === "BTC" ? currencyFormatter(fxRate, 5) : currencyFormatter(fxRate, 3)} {paymentAccountDetails.currency}
                </Typography>
                <Typography type='caption' weight='regular'>
                  Payout fee: {balanceDetails.label === "BTC" ? currencyFormatter(payoutFees, 5) : currencyFormatter(payoutFees)} {balanceDetails.label}
                </Typography>
                <Typography type='caption' weight='regular'>
                  Other fees: {balanceDetails.label === "BTC" ? currencyFormatter(otherFees, 5) : currencyFormatter(otherFees)} {balanceDetails.label}
                </Typography>
              </>
            }
          </Box>
        </VStack>
      </HStack>
      <Stack direction="row" gap={"20px"} justifyContent={"end"} w='100%' marginTop={0}>
        <Button variant={"secondary"} size={"sm"} type="button" p={"9px 16px 9px 16px"} onClick={handleBack}>
          Back
        </Button>
        <Button variant={"primary"} type="submit" size={"sm"} p={"9px 16px 9px 16px"} isDisabled={(fieldChange) || (Number(sendAmount) || 0) === 0 || (Number(receivedAmount) || 0) === 0} >
          Next
        </Button>
      </Stack>
    </Card>
  );
}

const CustomTextField = forwardRef(({ loading, ...rest }, ref) => {
  return <>
    <InputGroup>
      {loading && <InputLeftElement>
        <Spinner size={"sm"} />
      </InputLeftElement>}
      <Input ref={ref} {...rest}></Input>
    </InputGroup>
  </>
})


export default SetAmountTab;
