import loginReducer from "./slices/loginSlices";
import orderReducer from "./slices/orderSlices";
import recipientReducer from "./slices/recipientSlices";
import draftRecipientReducer from "./slices/draftRecipientSlices";
import paymentMethodsReducer from "./slices/paymentMethodsSlices";
import transactionReducer from "./slices/transactionSlices";
import contactReducer from "./slices/contactSlices";
import sendMoneyReducer from "./slices/sendMoneySlices";
import addMoneyReducer from "./slices/AddMoneySlice";
import customerReducer from "./slices/customerSlice";
import collectMoneyReducer from "./slices/collectMoneySlice"
import commonReducer from "./slices/commonSlice";
import { combineReducers } from "redux";

const appReducer = combineReducers({
    /* app’s top-level reducers */
    login: loginReducer,
    draftRecipient: draftRecipientReducer,
    order: orderReducer,
    recipient: recipientReducer,
    paymentMethods: paymentMethodsReducer,
    transaction: transactionReducer,
    contact: contactReducer,
    sendMoney: sendMoneyReducer,
    addMoney: addMoneyReducer,
    customer: customerReducer,
    collectMoney: collectMoneyReducer,
    common: commonReducer
});

const rootReducer = (state, action) => {
    if (action.type === 'user/signOutUser/fulfilled') {
        localStorage.removeItem('persist:root')
        state = undefined;
        return appReducer(undefined, action)
    }

    return appReducer(state, action)
}

export default rootReducer;