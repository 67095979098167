import { callApiWithToken } from "../utils/http_common";

const getBalanceList = () => {
  return callApiWithToken.get(`/account/list`);
}

const getCollectionOverview = () => {
  return callApiWithToken.get(`/transaction/collectionOverview`);
};


const BalanceService = {
  getBalanceList,
  getCollectionOverview
}

export default BalanceService;