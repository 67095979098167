import { Badge, Stack, Text, Box, HStack, Button } from "@chakra-ui/react";
import { useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import { BiReset } from 'react-icons/bi'
import { startCase, round } from 'lodash';

import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme

import Loader from "../../../components/Loader";
import { statusMap, colorMap } from "../../../constants";
import OrderService from '../../../services/createOrderService';
import GridV2 from "../../../utils/agTablesV2";
import SummaryTable from "../SummaryTable";

const PayOrdersV2 = (props) => {
    const [refreshGrid, setRefreshGrid] = useState(false);
    const [summaryData, setSummaryData] = useState([]);
    const isLoading = useSelector((state) => state?.order?.loading);
    const orgId = useSelector((state) => state?.login?.orgDetails?.orgId);

    const USformatter = new Intl.NumberFormat('en-US', { maximumFractionDigits: 20 });
    
    function roundToSignificantBits(number, significantBits) {
    // Split the number into integer and decimal parts
    const integerPart = Math.floor(Math.abs(number));
    const decimalPart = Math.abs(number) - integerPart;

    // Round the decimal part to the specified number of significant bits
    const roundedDecimalPart = parseFloat(decimalPart.toFixed(significantBits));

    // Combine the integer part and the rounded decimal part
    let result = integerPart + roundedDecimalPart;

    // Handle negative numbers
    if (number < 0) {
        result *= -1;
    }

    return result;
}

    var filterParams = {
        comparator: (filterLocalDateAtMidnight, cellValue) => {
            var dateAsString = cellValue;
            if (dateAsString == null) return -1;
            var cellDate = new Date(cellValue);
            if (filterLocalDateAtMidnight.toLocaleDateString() === cellDate.toLocaleDateString()) {
                return 0;
            }
            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            }
            if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            }
            return 0;
        },
        minValidYear: 2000,
    };

    const handleReset = () => {
        setRefreshGrid(!refreshGrid);
    };

    const getOrderData = async (filters) => {
        filters["orgId"] = orgId;
        filters["type"] = "pay";
        const responseData = await OrderService.getOrder({ filters });
        setSummaryData(responseData.data.data.summaryArray);
        return responseData;
    }

    const columns = [
        {
            headerName: "Order ID TransFi",
            field: "orderId",
            getQuickFilterText: params => {
                return params.value.name;
            },
            customerVisibility: true,
            width: '250',
            cellRenderer: ({ value }) => {
                return <Link style={{ color: "#1A4FD6", fontWeight: "bold", fontSize: '14px' }} >{value}</Link>;
            },
        },
        {
            headerName: "Order Status",
            field: "status",
            filter: 'agSetColumnFilter',
            enableRowGroup: true,
            
            filterParams: {
                applyMiniFilterWhileTyping: true,
            },
            customerVisibility: true,
            cellRenderer: ({ value }) => {
                const newStatus = statusMap[value] || value;
                const color = colorMap[value];
                return <Badge width={"100%"} textAlign={"center"} colorScheme={color}>{newStatus}</Badge>;
            },
        },
        // { headerName: "Order ID Customer", field: "customer.orgId", customerVisibility: true, width: '250' },
        {
            headerName: "Customer Name",
            enableRowGroup: true,
            field: "customer.formattedName", width: '200', customerVisibility: false, cellRenderer: ({ value }) => (
                <Text>{value ? startCase(value) : ''}</Text>
            )
        },
        {
            headerName: "Customer User ID/Email",
            enableRowGroup: true,
            field: "customer.email", customerVisibility: true, width: '300'
        },
        { headerName: "Sender User ID", field: "userId", customerVisibility: true, width: '250' },
        { headerName: "Sender Email", field: "email", enableRowGroup: true, customerVisibility: true, width: '250' },
        {
            headerName: "Purpose of Transaction",
            enableRowGroup: true,
            field: "purposeCode", customerVisibility: true, width: '250', cellRenderer: ({ value }) => (
                <Text>{value ? startCase(value) : ''}</Text>
            )
        },
        {
            headerName: "Nature of Sender",
            enableRowGroup: true,
            field: "senderDetails.type", customerVisibility: true, width: '200', cellRenderer: ({ value }) => (
                <Text>{value ? startCase(value) : ''}</Text>
            )
        },
        {
            headerName: "Nature of Recipient",
            enableRowGroup: true,
            field: "recipientDetails.type", customerVisibility: true, width: '200', cellRenderer: ({ value }) => (
                <Text>{value ? startCase(value) : ''}</Text>
            )
        },
        {
            headerName: "Jurisdiction of Sender",
            enableRowGroup: true,
            field: "userCountry", customerVisibility: true, width: '200'
        },
        {
            headerName: "Jurisdiction of Recipient",
            enableRowGroup: true,
            field: "recipientDetails.country", customerVisibility: true, width: '200'
        },
        {
            headerName: "Initiated Date",
            field: "createdAt",
            enableRowGroup: true,
            filterParams: filterParams,
            customerVisibility: true,
            width: '220',
            filter: 'agDateColumnFilter',
            cellRenderer: ({ value }) => (
                <Text>{moment(value).format("DD-MMM-YYYY @ h:mm A")}</Text>
            ),
        },
        {
            headerName: "Updated Date",
            field: "updatedAt",
            enableRowGroup: true,
            filter: 'agDateColumnFilter',
            filterParams: filterParams,
            customerVisibility: true,
            width: '220',
            cellRenderer: ({ value }) => (
                <Text>{moment(value).format("DD-MMM-YYYY @ h:mm A")}</Text>
            ),
        },
        {
            headerName: "Fiat Payment Partner(Fiat 1)",
            field: "senderPaymentMethodDetails.paymentGateway",
            customerVisibility: false,
            cellRenderer: ({ value }) => (
                <Text>{value ? startCase(value) : ''}</Text>
            )
        },
        {
            headerName: "Fiat Payment Partner Order ID(Fiat 1)",
            field: "senderPaymentPartnerOrderId",
            width: '250',
            customerVisibility: false,
            cellRenderer: ({ value }) => (
                <Text>{value}</Text>
            )
        },
        {
            headerName: "Fiat Payment Method(Fiat 1)",
            field: "paymentFormattedName",
            customerVisibility: true,
            cellRenderer: ({ value }) => (
                <Text>{value ? startCase(value) : 'N/A'}</Text>
            )
        },
        {
            headerName: "Fiat Currency(Fiat 1)",
            field: "depositCurrency",
            customerVisibility: true,
            width: '200'
        },
        {
            headerName: "Gross Fiat Amount",
            field: "depositAmount",
            aggFunc: 'sum',
            customerVisibility: true,
            filter: 'agNumberColumnFilter',
            cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(roundToSignificantBits(value,5)) : '0'}</Text>
            )
        },
        {
            headerName: "Processing Fees %",
            field: "processingFeesPercentage",
            aggFunc: 'avg',
            filter: 'agNumberColumnFilter',
            customerVisibility: true,
            cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(roundToSignificantBits(value*100,5)) + '%' : '0%'}</Text>
            )
        },
        {
            headerName: "Processing Fees Amount(Fiat 1)",
            field: "processingFeesAmountFiat1",
            aggFunc: 'sum',
            filter: 'agNumberColumnFilter',
            customerVisibility: true,
            cellRenderer: ({ value }) => (
                <Text>{value ?  USformatter.format(roundToSignificantBits(value,5)) : '0'}</Text>
            )
        },
        {
            headerName: "Net Amount",
            field: "netAmount",
            aggFunc: 'sum',
            customerVisibility: true,
            filter: 'agNumberColumnFilter',
            cellRenderer: ({ value }) => (
                <Text>{value ?  USformatter.format(roundToSignificantBits(value,5)) : '0'}</Text>
            )
        },
        {
            headerName: "Transaction Monitoring Fees",
            field: "",
            customerVisibility: false,
            filter: 'agNumberColumnFilter',
            width: '200'
        },
        {
            headerName: "Amount Received in Sub-Account(Fiat 1)",
            field: "amountReceivedSubAccountFiat1",
            filter: 'agNumberColumnFilter',
            customerVisibility: false,
            cellRenderer: ({ value }) => (
                <Text>{value ?  USformatter.format(roundToSignificantBits(value,5)) : '0'}</Text>
            )
        },
        {
            headerName: "Payment Partner Fees(Fiat 1)",
            field: "paymentPartnerFeesFiat1",
            filter: 'agNumberColumnFilter',
            customerVisibility: false,
            cellRenderer: ({ value }) => (
                <Text>{value ?  USformatter.format(roundToSignificantBits(value,5)) : '0'}</Text>
            )
        },
        {
            headerName: "Sub-Account Receipt Date",
            field: "subAmountReceiptDate",
            customerVisibility: false,
            cellRenderer: ({ value }) => (
                <Text>{value}</Text>
            )
        },
        {
            headerName: "Conversion rate to Customer (Fiat to fiat) if zero fx markup",
            filter: 'agNumberColumnFilter',
            field: "conversionRateFiatToFiat",
            customerVisibility: false,
            cellRenderer: ({ value }) => (
                <Text>{value ?  USformatter.format(roundToSignificantBits(value,5)) : '0'}</Text>
            )
        },
        {
            headerName: "FX Markup charged",
            field: "fxMarkupCharged",
            filter: 'agNumberColumnFilter',
            customerVisibility: false,
            cellRenderer: ({ value }) => (
                <Text>{value ?  USformatter.format(roundToSignificantBits(value*100,5)) + "%" : '0'}</Text>
            )
        },
        {
            headerName: "Conversion rate to Customer charged (Fiat to fiat)",
            filter: 'agNumberColumnFilter',
            field: "conversionAmountFiatToFiat",
            customerVisibility: true,
            cellRenderer: ({ value }) => (
                <Text>{value ?  USformatter.format(roundToSignificantBits(value,5)) : '0'}</Text>
            )
        },
        {
            headerName: "Revenue from Forex",
            field: "revenueFromForex",
            aggFunc: 'sum',
            filter: 'agNumberColumnFilter',
            customerVisibility: false,
            cellRenderer: ({ value }) => (
                <Text>{value ?  USformatter.format(roundToSignificantBits(value,20)) : '0'}</Text>
            )
        },
        {
            headerName: "Conversion Rate to USD",
            field: "conversionRateToUsd",
            aggFunc: 'avg',
            filter: 'agNumberColumnFilter',
            customerVisibility: false,
            cellRenderer: ({ value }) => (
                <Text>{value ?  USformatter.format(roundToSignificantBits(value,5)) : '0'}</Text>
            )
        },
        {
            headerName: "GTV",
            field: "gtv",
            aggFunc: 'sum',
            customerVisibility: false,
            filter: 'agNumberColumnFilter',
            cellRenderer: ({ value }) => (
                <Text>{value ?  USformatter.format(roundToSignificantBits(value,5)) : '0'}</Text>
            )
        },
        {
            headerName: "Amount Received in Sub-Account(USD)",
            field: "amountReceivedSubAccountUsd",
            aggFunc: 'sum',
            filter: 'agNumberColumnFilter',
            customerVisibility: false,
            cellRenderer: ({ value }) => (
                <Text>{value ?  USformatter.format(roundToSignificantBits(value,5)) : '0'}</Text>
            )
        },
        {
            headerName: "Processing Fees Amount(USD)",
            field: "processingFeesAmountUsd",
            aggFunc: 'sum',
            filter: 'agNumberColumnFilter',
            customerVisibility: false,
            cellRenderer: ({ value }) => (
                <Text>{value ?  USformatter.format(roundToSignificantBits(value,5)) : '0'}</Text>
            )
        },
        {
            headerName: "Crypto to be sold if zero forex markup",
            field: "cryptoSoldIfZeroForexMarkup",
            aggFunc: 'sum',
            filter: 'agNumberColumnFilter',
            customerVisibility: false,
            cellRenderer: ({ value }) => (
                <Text>{value ?  USformatter.format(roundToSignificantBits(value,5)) : '0'}</Text>
            )
        },
        {
            headerName: "Conversion rate charged by TransFi",
            field: "conversionRatesChargedTransfi",
            filter: 'agNumberColumnFilter',
            customerVisibility: false,
            cellRenderer: ({ value }) => (
                <Text>{value ?  USformatter.format(roundToSignificantBits(value,5)) : '0'}</Text>
            )
        },
        {
            headerName: "Crypto To Be Sold",
            field: "cryptoToBeSold",
            filter: 'agNumberColumnFilter',
            customerVisibility: false,
            cellRenderer: ({ value }) => (
                <Text>{value ?  USformatter.format(roundToSignificantBits(value,5)) : '0'}</Text>
            )
        },
        {
            headerName: "Fiat Payment Partner",
            field: "recipientPaymentMethodDetails.paymentGateway",
            customerVisibility: false,
            cellRenderer: ({ value }) => (
                <Text>{value ? startCase(value) : ''}</Text>
            )
        },
        {
            headerName: "Fiat Payment Partner Order ID(Fiat 2)",
            field: "receiverPaymentPartnerOrderId",
            filter: 'agNumberColumnFilter',
            customerVisibility: false,
            width: '250',
            cellRenderer: ({ value }) => (
                <Text>{value}</Text>
            )
        },
        {
            headerName: "Fiat Payment Method(Fiat 2)",
            field: "recipientPaymentCode",
            customerVisibility: true,
            cellRenderer: ({ value }) => (
                <Text>{value ? startCase(value) : ""}</Text>
            )
        },
        {
            headerName: "Fiat Currency(Recipient)",
            field: "withdrawCurrency",
            customerVisibility: true,
            cellRenderer: ({ value }) => (
                <Text>{value}</Text>
            )
        },
        {
            headerName: "Conversion Rate To Fiat 2",
            field: "conversionRateToFiat2",
            filter: 'agNumberColumnFilter',
            customerVisibility: false,
            cellRenderer: ({ value }) => (
                <Text>{value ?  USformatter.format(roundToSignificantBits(value,8)) : '0'}</Text>
            )
        },
        {
            headerName: "Fiat To Be Sent(Fiat 2)",
            field: "fiatToBeSent",
            aggFunc: 'sum',
            customerVisibility: true,
            filter: 'agNumberColumnFilter',
            cellRenderer: ({ value }) => (
                <Text>{value ?  USformatter.format(roundToSignificantBits(value,5)) : '0'}</Text>
            )
        },
        {
            headerName: "Fiat Amount Delivered(Fiat 2)",
            field: "fiatAmountDelivered",
            customerVisibility: false,
            filter: 'agNumberColumnFilter',
            cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(roundToSignificantBits(value,5)) : '0'}</Text>
            )
        },
        {
            headerName: "Payment Partner Fees(Fiat 2)",
            field: "paymentPartnerFeesFiat2",
            customerVisibility: false,
            filter: 'agNumberColumnFilter',
            cellRenderer: ({ value }) => (
                <Text>{value ?  USformatter.format(roundToSignificantBits(value,5)) : '0'}</Text>
            )
        },
        {
            headerName: "Amount Sent From Sub Account",
            field: "amountSentSubAccount",
            aggFunc: 'sum',
            customerVisibility: false,
            filter: 'agNumberColumnFilter',
            cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(roundToSignificantBits(value,5)) : '0'}</Text>
            )
        },
        {
            headerName: "Payment Partner Fees(Fiat 2 in USD)",
            field: "paymentPartnerFeesFiat2Usd",
            aggFunc: 'sum',
            filter: 'agNumberColumnFilter',
            customerVisibility: false,
            cellRenderer: ({ value }) => (
                <Text>{ value ?  USformatter.format(roundToSignificantBits(value,5)) : '0'}</Text>
            )
        },
        {
            headerName: "Gross Profit Of TransFi",
            field: "grossProfitOfTransfi",
            aggFunc: 'sum',
            customerVisibility: false,
            filter: 'agNumberColumnFilter',
            cellRenderer: ({ value }) => (
                <Text>{value ?  USformatter.format(roundToSignificantBits(value,5)) : '0'}</Text>
            )
        },
    ];

    const summaryColumns = [
        {
            headerName: "Currency",
            field: "depositCurrency",
            width: '200',
            headerClass: 'center-column',
            flex: 1,
            cellRenderer: ({ value }) => (
                <Text>{value ? value : "N/A"}</Text>
            )
        },
        {
            headerName: "Total Count",
            field: "count",
            width: '200',
            headerClass: 'center-column',
            flex: 1,
            cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            headerName: "Sum Amount Received",
            field: "depositAmount",
            width: '200',
            headerClass: 'center-column',
            flex: 1,
            cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            headerName: "Sum Processing Fee",
            field: "processingFeesAmountFiat1",
            width: '200',
            headerClass: 'center-column',
            flex: 1,
            cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            headerName: "Sum Net Amount",
            field: "netAmount",
            width: '200',
            headerClass: 'center-column',
            flex: 1,
            cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
    ]

    return (
        <Stack
            direction={"column"}
            justifyContent={"space-between"}
            flexGrow={1}
            p={5}
        >

            {!isLoading ? <Box >
                <HStack pb={5} justifyContent={"flex-end"}>
                    <Button
                        leftIcon={<BiReset style={{ fontSize: "20px" }} />}
                        color={"#525C76"}
                        variant={"outline"}
                        borderColor={"#d2d2d2"}
                        _hover={{ bg: "#ffffff" }}
                        onClick={handleReset}
                    >
                        Reset
                    </Button>
                    {/* <Button
                        leftIcon={<HiOutlineFilter style={{ fontSize: "20px" }} />}
                        color={"#525C76"}
                        variant={"outline"}
                        borderColor={"#d2d2d2"}
                        _hover={{ bg: "#ffffff" }}
                        onClick={onOpen}
                    >
                        Filter
                    </Button> */}
                </HStack>
                <GridV2 columns={columns} key={refreshGrid.toString()} getOrderData={getOrderData} />
                <SummaryTable summaryColumns={summaryColumns} data={summaryData} />
                {/* <OrderFilter isOpen={isOpen} onClose={onClose} type={"pay"} /> */}
            </Box> : <Loader />}
        </Stack>
    );
};

export default PayOrdersV2;