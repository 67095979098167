import { Button, Checkbox, HStack, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import CustomTable from '../../utils/customTable';

const SelectRecipient = (props) => {
    const { isOpen, onClose } = props;
    const recipientList = useSelector(state => state.recipient.recipientList) || [];
    const [selectedRowId, setSelectedRowId] = useState([])

    const data = recipientList.map((recipient, index) => {
        return {
          serial: index + 1,
          id: recipient.recipientId,
          name: recipient.name,
          country: recipient.country,
          createdAt: new Date(recipient.createdAt).toLocaleDateString(),
          email: recipient.email,
          type: recipient.type,
          walletAddress: recipient.walletAddress,
        };
      });

    const columns = [
        {Header: 'Add', accessor: 'id',width: '60px', Cell: (props) => {
            return <Checkbox value={props.value} onChange={(e) => { setSelectedRowId(...selectedRowId, e.target.value)} } />
        // return  <Checkbox
        //   display={"grid"}
        //   value={props.value}
        //   onChange={(e) => console.log(e.target.value)}
        // />
        }},
        {Header: 'Name', accessor: 'name'},
        {Header: 'Country', accessor: 'country'},
        {Header: 'Type', accessor: 'type'},
        {Header: 'WalletAddress', accessor: 'walletAddress'}
    ]
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'4xl'} isCentered>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>
                Add Saved Recipients
            </ModalHeader>
            <ModalBody>
                <CustomTable data={data} columns={columns} rowSelection={true} />
            </ModalBody>
            <ModalFooter>
                <HStack>
                    <Button w={'150px'} color={"#525C76"} bg="#ffffff" _hover={{bg: '#fffffff'}} borderColor={'#525C76'} variant={'outline'} onClick={onClose}>Cancel</Button>
                    <Button w={'150px'} color={'#ffffff'} bg="#1A4FD6" _hover={{ bg: "#232d95" }}>Add Recipient</Button>
                </HStack>
            </ModalFooter>
        </ModalContent>
    </Modal>
  )
}

export default SelectRecipient