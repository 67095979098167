import { Box, Flex, Stack } from '@chakra-ui/react';
import React from 'react';
import SumsubWebSdk from '@sumsub/websdk-react';
import { GoArrowLeft } from 'react-icons/go';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';

import Typography from '../../../../components/Common/Typography';
import { getDataFromSession } from '../../../../utils/session-storing-utils';
import { getThemeForKYCWidget } from '../../../../Theme/kybWidgetTheme';
import CustomerService from '../../../../services/customerService';

const CompleteKyb = () => {

    const buttonColor = '#1A4FD6';
    const backgroundColor = 'none';
    const accessToken = getDataFromSession('tokenData', 'sumsupAccessToken') || "";
    const { customerType = "" } = useSelector((state) => state?.customer?.kybDetails);
    let type = "KYB"
    if (customerType === 'individual') {
        type = "KYC"
    }

    const { cxId = "" } = useSelector((state) => state?.login?.userDetails)

    const navigate = useNavigate()

    const updateKYCStatus = async (type, payload) => {
        try {
            console.log(type, payload);
            if (type === 'idCheck.onApplicantSubmitted') {
                if (process.env.REACT_APP_NODE_ENV === 'sandbox') {
                    const { data: response = {} } = await CustomerService.getKybComplete({ cxId }) || {};
                    if (response?.data) {
                        navigate('/customer/home')
                    }
                }
            }
        } catch (e) {
            return;
        }
    };

    const handleBackNavigation = () => {
        window.history.replaceState({}, '');
        navigate(-1);
    }

    return (
        <Box px={8} py={5} bg={'#F2F2F2'} minH={'95vh'} >
            <Flex w={"fit-content"} cursor={"pointer"} onClick={handleBackNavigation} columnGap={2} alignItems={"center"}>
                <GoArrowLeft size={"13px"} />
                <Typography type="body">
                    Back
                </Typography>
            </Flex>
            <Typography color="default" type="largeheader" weight="bold">Complete {type}</Typography>

            <Stack direction={"column"} h={'screen'}>
                <SumsubWebSdk
                    accessToken={accessToken}
                    updateAccessToken={() => console.log("updateAccessToken")}
                    expirationHandler={() => Promise.resolve(accessToken)}
                    config={{
                        lang: 'en',
                        i18n: {
                            document: {
                                subTitles: {
                                    IDENTITY: "Upload a document that proves your identity"
                                }
                            }
                        },
                        onMessage: (type, payload) => {
                            console.log("WebSDK onMessage", type, payload);
                        },
                        uiConf: {
                            customCssStr:
                                getThemeForKYCWidget(buttonColor, backgroundColor)
                        },
                        onError: (error) => {
                            console.error("WebSDK onError", error);
                        }
                    }}
                    options={{ addViewportTag: false, adaptIframeHeight: true }}
                    onError={(data) => console.log("onError", data)}
                    onMessage={updateKYCStatus}
                />
            </Stack>

        </Box>
    );
};

export default CompleteKyb;