import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Box, Button, Divider, Flex, HStack, Image, Text } from '@chakra-ui/react';

import Typography from '../../../../components/Common/Typography';
import { GoArrowLeft } from 'react-icons/go';
import PaymentMethodsService from '../../../../services/paymentMethodService';
import PaymentMethods from './PaymentMethods';
import { useSelector } from 'react-redux';
import OrderService from '../../../../services/createOrderService';
import BankDetails from './BankDetails';
import OrderDetailsCard from './OrderDetailsCard';

const MakePayment = () => {

    const navigate = useNavigate();
    const { state } = useLocation() || {};
    const userDetails = useSelector((state) => state?.login?.userDetails)
    const cxId = userDetails.cxId;
    const [loading, setLoading] = useState(false)
    const [btnLoading, setBtnLoading] = useState(false)
    const [paymentMethods, setPaymentMethods] = useState([])
    const [selectedBank, setSelectedBank] = useState(null);
    const [isAccepted, setIsAccepted] = useState(false);
    const [paymentResponse, setPaymentResponse] = useState(false);

    const { orderDetails } = state || {};


    const { depositCurrency, depositAmount, orderId, customer } = orderDetails;
    const { customerCurrency = "", customerCurrencyAmount = "" } = customer || {};

    useEffect(() => {
        fetchPaymentMethods(customerCurrency, customerCurrencyAmount)
    }, []);

    const fetchPaymentMethods = async (currency, amount) => {
        setLoading(true)
        try {
            const resp = await PaymentMethodsService.getPaymentMethodList({ currency, amount });
            const methods = resp.data?.data?.paymentMethods || [];
            const banks = methods?.filter((item, index, self) =>
                index === self.findIndex((t) => (['bank_transfer', 'sepa_bank', 'sepa_bank_va', 'netbanking'].includes(t.paymentType) && t.paymentCode === item.paymentCode))
            );
            setPaymentMethods(banks || []);

        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    const handlePaymentSelect = (method) => {
        setSelectedBank({
            value: method.paymentCode,
            label: method.name,
            logo: method.icon,
            limit: {
                min: method.minAmount,
                max: method.maxAmount
            }
        })
    };

    const handleBackNavigation = () => {
        window.history.replaceState({}, '');
        navigate(-1);
    }

    const handleMakePayment = async () => {
        setBtnLoading(true);
        const payload = { cxId, currency: customerCurrency, amount: customerCurrencyAmount, orderId, paymentCode: selectedBank.value }
        const { data } = await OrderService.createCollectionPayment(payload)
        setPaymentResponse(data?.data)
        setBtnLoading(false);
    };

    const handleGotoSummary = async () => {
        navigate('/customer/home/make-payment/summary', { state: { orderDetails } })
    };


    return (
        <Box px={8} py={5} bg={'#F2F2F2'} minH={'92vh'} >
            <Flex w={"fit-content"} cursor={"pointer"} onClick={handleBackNavigation} columnGap={2} alignItems={"center"}>
                <GoArrowLeft size={"13px"} />
                <Typography type="body">
                    Back
                </Typography>
            </Flex>
            <Typography color="default" type="largeheader" weight="bold">Make Payment</Typography>

            <HStack alignItems={'flex-start'} gap={5}>
                {paymentResponse ? <BankDetails beneficiaryDetails={paymentResponse.beneficiaryDetails} type={paymentResponse.type} orderDetails={orderDetails} />
                    : <PaymentMethods loading={loading} paymentMethods={paymentMethods} handlePaymentSelect={handlePaymentSelect} selectedBank={selectedBank} setSelectedBank={setSelectedBank} isAccepted={isAccepted} setIsAccepted={setIsAccepted} />}

                <OrderDetailsCard orderDetails={orderDetails} handleMakePayment={handleMakePayment} isAccepted={isAccepted} paymentResponse={paymentResponse} btnLoading={btnLoading} selectedBank={selectedBank} handleGotoSummary={handleGotoSummary} />
            </HStack>

        </Box>
    );
};

export default MakePayment;