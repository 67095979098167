import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import OrderService from '../../services/createOrderService';

const initialStateValues = {
    step: 0,
    totalSteps: 2,
    values: {},
    selectedCountry: null,
    firstStepDone: false,
    loading: false,
    fiatList: [],
    selectedCustomerDetails: {}
}

export const createCollectionOrder = createAsyncThunk(
    "contact/createCollectionOrder",
    async (body, { rejectWithValue }) => {
        try {
            const res = await OrderService.createCollectionOrder(body);
            return res?.data;
        } catch (e) {
            return rejectWithValue(e?.response?.data);
        }
    }
);

export const sendPaymentLink = createAsyncThunk(
    "contact/sendPaymentLink",
    async (body, { rejectWithValue }) => {
        try {
            const res = await OrderService.sendPaymentLink(body);
            return res?.data;
        } catch (e) {
            return rejectWithValue(e?.response?.data);
        }
    }
);

export const collectMoneySlice = createSlice({
    name: 'collectMoney',
    initialState: initialStateValues,
    reducers: {
        resetContact: () => initialStateValues,
        goToNextStep: (state) => {
            state.step += 1;
            state.firstStepDone = true;
        },
        goToPreviousStep: (state) => {
            if (state.step !== 0) {
                state.step -= 1;
            }
        },
        setActiveStep: (state, payload) => {
            state.step = payload.payload
        },
        setSelectedCustomerDetails: (state, action) => {
            state.selectedCustomerDetails = action.payload;
        },
    },
    extraReducers: {
        [createCollectionOrder.pending]: (state, action) => {
            state.loading = true;
        },
        [createCollectionOrder.fulfilled]: (state, action) => {
            const { message = '', status, data } = action.payload?.data || {};
            state.loading = false;
            state.message = message;
            state.isSuccess = status;
            state.orderData = data;
        },
        [createCollectionOrder.rejected]: (state, action) => {
            const { message } = action?.payload?.error || { message: "Some Internal Error Occurred" };
            state.loading = false;
            state.isSuccess = false;
            state.message = message;
        }
    }
}
)

export const { resetContact, setActiveStep, goToNextStep, goToPreviousStep, setValues, setSelectedCustomerDetails } = collectMoneySlice.actions

const { reducer: collectMoneyReducer } = collectMoneySlice;

export default collectMoneyReducer;