import React, { useEffect, useState } from "react";
import { Box, Flex, Image, Text, useClipboard, useToast } from "@chakra-ui/react";

//Custom Components and functions
import { callApi } from "../../utils/http_common";
import Loader from "../../utils/Loader";
import Divider from "../../components/LoginComponents/Divider";
import Button from "../../components/LoginComponents/Button";

//css
import "./Login.css"
import "./PasswordLogin.css"

//icons
import CopyIcon from "./icons/CopyIcon.png";
import TransfiLogo from "../../assets/transfi-logo.svg"

const MFASetup = (props) => {
    const toast = useToast();
    const { value, setValue, hasCopied, onCopy } = useClipboard("");

    const { email, setMfaStatus } = props || {};
    const [isLoading, setIsLoading] = useState(false);

    const handleToast = (title, type) => {
        toast({
            title,
            status: type,
            duration: 5000,
            isClosable: true
        });
    };

    const [qrCodeUrl, setQrCodeUrl] = useState('');
    const [base32Code, setBase32Code] = useState('');

    useEffect(() => {
        fetchQrCodeUrl();
    }, []);

    const fetchQrCodeUrl = async () => {
        try {
            setIsLoading(true);
            const { data } = await callApi.post('/customer/getMfaCode', JSON.stringify({ email })) || {};
            const { status, qrImage, secret, message } = data?.data;
            if (status) {
                setQrCodeUrl(qrImage);
                setBase32Code(secret);
                setValue(secret);
            } else {
                throw new Error(message);
            }
            setIsLoading(false);
        } catch (e) {
            handleToast(e?.response?.data?.error?.message || "Something went wrong", 'error');
            setIsLoading(false);
        }
    }

    return (
        isLoading ? <Loader /> :
            <Flex bg={'white'} h={'full'} w={'full'}>
                <Box bg={'white'} h={"full"} w={'50%'}>
                    <Box>
                        <div className="pa-login-sub-card">
                            <div className="pa-login-header">
                                <img src={TransfiLogo} alt="logo" />
                                <span>Setup 2FA</span>
                                <p>Scan this code in your Authenticator App.</p>
                                <img className="pa-qr-image-login" src={qrCodeUrl} alt="QR code" />
                            </div>
                            <div className="pa-login-body">
                                <Divider>
                                    or enter the code manually
                                </Divider>
                                <div style={{ textAlign: 'center', width: '100%' }}>
                                    <div className="pa-mfa-input-box-div">
                                        <span className="pa-mfa-code">{base32Code}</span>
                                        {hasCopied ? <span className="pa-copy-icon" style={{ width: '60px', marginLeft: '10px' }}>Copied</span> : <img src={CopyIcon} className="pa-copy-icon" onClick={() => onCopy()} alt="copy" />}
                                    </div>
                                </div>
                                <Button className={'pa-button'} styles={{ marginTop: '30px' }} onClickHandler={() => { setMfaStatus(true) }} >
                                    Verify
                                </Button>
                            </div>
                        </div>
                    </Box>
                </Box>
                <Flex className="gradient-background" flexDirection={'column'} justify={'space-between'} w={'full'} maxH={'100vh'} overflow={'hidden'}>
                    <Box ml={'80px'} mt={'100px'}>
                        <Text color={'#092C4C'} fontFamily={'DM Sans'} fontWeight={'700'} fontSize={'48px'}>Borderless <Text as={'span'} color={'#1A4FD6'}>payments</Text>,</Text>

                        <Text color={'#092C4C'} fontFamily={'DM Sans'} fontWeight={'700'} fontSize={'48px'}>boundless <Text as={'span'} color={'#1A4FD6'}>potential</Text></Text>
                    </Box>
                    <Flex w={'full'} justify={'flex-end'}>
                        <Image src="/assets/login.png" maxH={'809px'} maxW={'992px'} />
                    </Flex>
                </Flex>
            </Flex>
    );
};

export default MFASetup;
