import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Image, Input, Textarea, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';

import Typography from '../../../components/Common/Typography';
import Loader from '../../../utils/Loader';
import KybCard from './KybStatusCard/KybCard';
import InvoicesTable from './InvoicesTable/InvoicesTable';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { callApiWithToken } from '../../../utils/http_common';
import { getKybStatus } from '../../../store/slices/customerSlice';

const CustomerHome = () => {
    const { email: userEmail, cxId = "" } = useSelector((state) => state?.login?.userDetails)
    const { kybStatus } = useSelector((state) => state?.customer?.kybDetails);
    const [loading, setLoading] = useState(false)
    const [supportLoading, setSupportLoading] = useState(false)

    const toast = useToast();

    const dispatch = useDispatch();

    useEffect(() => {
        getKyb();
    }, [])

    const getKyb = async () => {
        setLoading(true)
        dispatch(getKybStatus({ userEmail, cxId }));
        setLoading(false)
    }

    const validationSchema = yup.object({
        problem: yup.string("Write your problem here.").required("Please describe your problem before sending."),
    });

    const formik = useFormik({
        initialValues: {
            problem: ''
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {
                setSupportLoading(true);
                const problem = values.problem;
                const payload = { userEmail, problem };
                const { data } = (await callApiWithToken.post("/customer/support", payload)) || {};
                if (data?.data?.status) {
                    toast({
                        title: "Your problem has been sent to support",
                        status: 'success',
                        duration: 5000,
                        isClosable: true
                    });
                    setSupportLoading(false);
                    formik.handleReset()
                }
                else {
                    toast({
                        title: data?.data?.message || "Something went wrong",
                        status: 'error',
                        duration: 5000,
                        isClosable: true
                    });
                }
                setSupportLoading(false);
            }
            catch (e) {
                setSupportLoading(false);
                toast({
                    title: "Something went wrong",
                    status: 'error',
                    duration: 5000,
                    isClosable: true
                })
            }
        },
    });

    return (
        loading ? <Loader /> :
            <Box px={8} py={5} bg={'#F2F2F2'} minH={'90vh'} >
                <Typography color="default" type="largeheader" weight="bold">Welcome to TransFi</Typography>
                <Box>
                    <KybCard />
                </Box>
                <Flex width={'100%'} gap={3} flexDirection={{ md: 'column', lg: 'row' }}>
                    <Box width={'full'} bg={'white'} rounded={'12px'} boxShadow={'sm'} mt={'5'} px={'16px'} py={'20px'} border={'1px'} borderColor={"#E4E4E4"}>
                        <Flex gap={4} justify={'flex-start'} alignItems={'flex-start'}>
                            <Image src='/assets/invoice-icon.svg' />
                            <Flex direction={'column'} gap={3}>
                                <Typography type='subtitle' weight='bold' >Invoices</Typography>
                                <Typography type='description' weight='regular' color='secondary'>See all the invoices sent to you</Typography>
                            </Flex>
                        </Flex>
                        <InvoicesTable kybStatus={kybStatus} />
                    </Box>
                    <Box width={{ md: '100%', lg: '30%' }} bg={'white'} rounded={'12px'} boxShadow={'sm'} mt={'5'} px={'16px'} py={'20px'} border={'1px'} borderColor={"#E4E4E4"} height={'40%'}>
                        <Flex gap={4} justify={'flex-start'} alignItems={'flex-start'}>
                            <Image src='/assets/customer-care.svg' />
                            <Flex direction={'column'} gap={3}>
                                <Typography type='subtitle' weight='bold' >Customer Service</Typography>
                                <Typography type='description' weight='regular' color='secondary'>We are here to resolve your problem 24*7</Typography>
                            </Flex>

                        </Flex>
                        <FormControl mt={5} isInvalid={formik?.touched?.problem && formik?.errors?.problem}>
                            <Textarea
                                name="problem"
                                id='problem'
                                placeholder="Write your problem here"
                                h={{ md: '100px', lg: "150px" }}
                                mt={6}
                                onChange={formik.handleChange}
                                value={formik.values.problem}
                            />
                            <FormErrorMessage>{formik?.errors?.problem}</FormErrorMessage>
                        </FormControl>

                        <Flex justify={'flex-end'} mt={2}>
                            <Button
                                variant={"primary"}
                                bg={"white"}
                                _hover={{ bg: 'white' }}
                                color="#1A4FD6"
                                height={'40px'}
                                rounded={'6px'}
                                fontSize={'14px'}
                                fontWeight={'400'}
                                border={'1px'}
                                borderColor={'#F2F2F2'}
                                isLoading={supportLoading}
                                rightIcon={<ArrowForwardIcon />}
                                onClick={formik.handleSubmit}
                            >
                                Send
                            </Button>
                        </Flex>
                    </Box>
                </Flex>
            </Box>
    );
};

export default CustomerHome;