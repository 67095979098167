import { Box, FormControl, FormErrorMessage, FormLabel, Input, InputGroup, InputLeftAddon, SimpleGrid, VStack } from "@chakra-ui/react";
import { useField } from "formik";
import { TextField } from "../../../../components/Common/Input";

const currencyCodeMap = {
    "PHP": '+63',
    "IDR": '+62',
    "EGP": "+20"
}

const AddWallet = (props) => {
    const [currency] = useField("currency")
    const [field, meta] = useField("number");

    return (
        <Box>
            <SimpleGrid columns={2} spacing={6}>
                {/* <Box width={"386px"}> */}
                <TextField label="Please Enter Nickname here" name="nickname" placeholder="Please enter nick name for account here" />
                {/* </Box> */}
                <FormControl width={"386px"} isInvalid={meta.error && meta.touched}>
                    <FormLabel htmlFor={"number"}>{"Please enter mobile number here"}</FormLabel>
                    <InputGroup>
                        {currencyCodeMap[currency.value] &&
                            <InputLeftAddon >
                                {currencyCodeMap[currency.value]}
                            </InputLeftAddon>
                        }
                        <Input type="number" placeholder="Enter Contact Mobile Number.." name="number" {...field} />
                    </InputGroup>
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                </FormControl>

            </SimpleGrid>
        </Box>

    );
};

export default AddWallet;
