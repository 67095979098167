import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  SkeletonCircle,
  Text,
  useDisclosure,
  Skeleton,
  VStack,
  Stack,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useSelector, connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router";

import {
  getPaymentMethods,
  updatePaymentMethod,
} from "../../store/slices/paymentMethodsSlices";
import { getQuotes } from "../../store/slices/orderSlices";
import WarningModal from "../../components/modal";
import { isEmpty } from "lodash";

const PaymentMethods = (props) => {
  const fiatTicker = useSelector((state) => state.order?.fiatTicker?.label);
  const isLoading = useSelector((state) => state.order?.loading);
  const paymentMethodsStatus = useSelector(
    (state) => state.paymentMethods?.loading
  );
  const paymentMethodsSelected = useSelector((state) => state.paymentMethods?.seletedPaymentMethod);

  const {
    onOpen: warnModalOpen,
    isOpen: warnModalIsOpen,
    onClose: warnModalClose,
  } = useDisclosure();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    getPaymentMethod();
  }, [fiatTicker]);

  const getPaymentMethod = (async) => {
    props.getPaymentMethods({ fiatTicker, product: "payin" });
  };

  const onSubmitToPayment = () => {
    dispatch(getQuotes()).then((res) => {
      navigate("/payin/confirm-order");
    });
  };

  return (
    <Stack
      direction={"column"}
      justifyContent={"space-between"}
      flexGrow={1}
      p={5}
    >
      <Box>
        <Text style={{ fontSize: "20px", fontWeight: "700" }}>Bank</Text>
        <Text mb={"10"} style={{ fontSize: "12px", fontWeight: "400" }}>
          {props.processingFee}% Processing fees
        </Text>
        <Flex flexWrap={"wrap"} direction={"row"} gap={5}>
          {paymentMethodsStatus ? (
            <Flex gap={5}>
              <VStack
                spacing={10}
                height={"25vh"}
                borderRadius={"12px"}
                border={"1px"}
                borderColor={"#d2d2d2"}
                width={"130px"}
                alignContent={"center"}
                justifyContent={"center"}
              >
                <SkeletonCircle size={20} bg="blackAlpha.100" />
                <Skeleton h="10px" w="80%" bg="blackAlpha.100" />
              </VStack>

              <VStack
                spacing={10}
                height={"25vh"}
                borderRadius={"12px"}
                border={"1px"}
                borderColor={"#d2d2d2"}
                width={"130px"}
                alignContent={"center"}
                justifyContent={"center"}
              >
                <SkeletonCircle size={20} bg="blackAlpha.100" />
                <Skeleton h="10px" w="80%" bg="blackAlpha.100" />
              </VStack>

              <VStack
                spacing={10}
                height={"25vh"}
                borderRadius={"12px"}
                border={"1px"}
                borderColor={"#d2d2d2"}
                width={"130px"}
                alignContent={"center"}
                justifyContent={"center"}
              >
                <SkeletonCircle
                  size={20}
                  bg="blackAlpha.100"
                  boxShadow={"lg"}
                />
                <Skeleton
                  h="10px"
                  w="80%"
                  bg="blackAlpha.100"
                  boxShadow={"lg"}
                />
              </VStack>
            </Flex>
          ) : (
            props.paymentMethods.map((bank, idx) => (
              <Box
                maxWidth={'160px'}
                textAlign={"center"}
                alignItems={'center'}
                cursor={"pointer"}
                key={bank.pmId}
                border="1px"
                borderRadius="15px"
                onClick={() =>
                  props.selectedPaymentMethod.pmId !== bank.pmId &&
                  props.updatePaymentMethod(bank)
                }
                borderColor={
                  props.selectedPaymentMethod.pmId === bank.pmId
                    ? "#1A4FD6"
                    : "#F1EEEF"
                }
                padding="3"
              >
                <HStack spacing="24px" justifyContent={'center'} alignItems={'center'}>
                  <Image src={bank?.icon} width={"107px"} height={"90px"} />
                </HStack>
                <Text pt={"10"} style={{ fontSize: "16px", fontWeight: "700" }}>
                  {bank.name}
                </Text>
              </Box>
            ))
          )}
        </Flex>
        <WarningModal path={'/payin'} isOpen={warnModalIsOpen} onClose={warnModalClose} />
      </Box>
      <HStack justifyContent={"flex-end"}>
        <Button
          minW={"200px"}
          color="#525C76"
          border="1px"
          borderColor="#525C76"
          _hover={{ bg: "#ffffff" }}
          variant={"outline"}
          onClick={warnModalOpen}
        >
          Cancel
        </Button>
        <Button
          isLoading={isLoading}
          isDisabled={isEmpty(paymentMethodsSelected)}
          color={"#ffffff"}
          bg={"#1A4FD6"}
          _hover={{ bg: "#232D95" }}
          minW={"200px"}
          onClick={onSubmitToPayment}
        >
          Continue
        </Button>
      </HStack>
    </Stack>
  );
};

const mapStateToProps = (state) => {
  return {
    paymentMethods: state.paymentMethods.list,
    selectedPaymentMethod: state.paymentMethods.seletedPaymentMethod,
    fiatTicker: state.order.fiatTicker,
    processingFee: state.paymentMethods.processingFee,
  };
};

const mapDispatchToProps = {
  updatePaymentMethod,
  getPaymentMethods,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethods);
