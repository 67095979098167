import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import {
  Box,
  Button,
  Flex,
  Icon,
  Img,
  Tbody,
  Td,
  Tr,
  VStack,
  TableContainer,
  Table,
  Text,
  Menu,
  MenuItem,
  MenuButton,
  MenuList,
  Image,
  Stack,
  Skeleton,
  HStack,
  Spacer,
  Link,
} from "@chakra-ui/react";
import { FaArrowUp, FaEye } from "react-icons/fa";
import { BiPlus } from "react-icons/bi";
import EmptyIcon from "../../assets/emptypage.svg";
import { useSearchParams } from "react-router-dom";

import Typography from "../../components/Common/Typography";
import Loader from "../../utils/Loader";
import TransactionService from "../../services/transactionService";
import { setType } from "../../store/slices/AddMoneySlice";
import { STATUS_MAP, colorMapPrefund, colorMapPayout } from "../../constants";
import moment from "moment";
import {
  IconLabel,
  lastStatusMapPayout,
} from "../transaction/TransactionSummary";
import { CiWallet } from "react-icons/ci";
import { callApiWithToken } from "../../utils/http_common";
import { isEmpty } from "lodash";
import { currencyFormatter, formatDateTime } from "../../utils/formatter";

export const finalTransactionType = (type) => {
  if (
    [
      "fiat_to_crypto_prefund",
      "fiat_to_fiat_prefund",
      "crypto_to_crypto_prefund",
    ].includes(type)
  ) {
    return "prefunding";
  } else if (["fiat_payout", "crypto_payout"].includes(type)) {
    return "payout";
  } else if (type === "collections") {
    return "collections";
  }
};
const TableColumn = (props) => {
  const {
    currency,
    amount,
    depositAmount,
    depositCurrency,
    status,
    createdAT,
    handleClick,
    type,
    name,
    txId,
  } = props;
  const color =
    finalTransactionType(type) === "prefunding"
      ? colorMapPrefund[status || "initiated"]
      : colorMapPayout[lastStatusMapPayout(status) || "initiated"];

  const [buttonLoading, setButtonLoading] = useState(false);
  const confirmPayout = async (orderId) => {
    // call api to mock the webhook
    try {
      setButtonLoading(true);
      const response = await callApiWithToken.get(
        `/transaction/confirm?orderId=${orderId}&type=payout`
      );
      if (response.data.success) {
        const data = response.data.data;
      }
    } catch (error) {
    } finally {
      setButtonLoading(false);
    }
  };

  return (
    <Tr height={"90px"} py={20}>
      <Td>
        {[
          "fiat_to_crypto_prefund",
          "fiat_to_fiat_prefund",
          "crypto_to_crypto_prefund",
        ].includes(type) && (
          <IconLabel currency={currency} label="Add Money" icon={BiPlus} />
        )}
        {type === "collections" && (
          <IconLabel currency={currency} label="Collections" icon={CiWallet} />
        )}
        {["fiat_payout", "crypto_payout"].includes(type) && (
          <IconLabel
            currency={`Txn Id - ${txId}`}
            label={`Payment to ${name || ""} (${currency || ""} acc)`}
            icon={FaArrowUp}
          />
        )}
      </Td>

      <Td>
        <Flex width={"150px"} direction={"column"}>
          <Typography
            color="secondary"
            colorweight="800"
            textAlign="right"
            type="body"
          >
            {[`fiat_payout`, `crypto_payout`].includes(type) ? "- " : "+ "}
            {currencyFormatter(amount)} {currency}
          </Typography>
          <Typography
            textAlign="right"
            weight="regular"
            type="caption"
            color="secondary"
          >
            {currencyFormatter(depositAmount)} {depositCurrency}
          </Typography>
        </Flex>
      </Td>

      <Td>
        <Flex columnGap={2} alignItems={"center"}>
          <Flex direction={"column"}>
            <Typography type="description">
              <Flex
                justifyContent={"right"}
                w={"200px"}
                columnGap={2}
                alignItems={"center"}
              >
                <Box
                  bg={color}
                  width={"8px"}
                  height={"8px"}
                  borderRadius={"100%"}
                ></Box>
                <Text textAlign={"right"} size={"14px"} color={color}>
                  {finalTransactionType(type) === "prefunding"
                    ? STATUS_MAP[finalTransactionType(type)][
                        status || "initiated"
                      ]
                    : STATUS_MAP[finalTransactionType(type)][type][
                        lastStatusMapPayout(status) || "initiated"
                      ]}
                </Text>
              </Flex>
            </Typography>
            <Typography
              textAlign="right"
              weight="regular"
              type="caption"
              color="secondary"
            >
              {formatDateTime(createdAT)}
            </Typography>
          </Flex>
        </Flex>
      </Td>

      <Td>
        <Box display={"flex"} columnGap={2} justifyContent={"start"}>
          <Button onClick={handleClick} variant="outline" size="sm">
            <Icon as={FaEye} />
          </Button>
          {["fiat_payout", "crypto_payout"].includes(type) &&
            ["development", "sandbox"].includes(
              process.env.REACT_APP_NODE_ENV
            ) &&
            status === "fund_processing" && (
              <Button
                onClick={() => confirmPayout(txId)}
                variant="outline"
                size="sm"
                isLoading={buttonLoading}
              >
                Confirm Payout
              </Button>
            )}
        </Box>
      </Td>
    </Tr>
  );
};

export default function RecentActivityTable() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [searchParams] = useSearchParams();
  const status = searchParams.get("status");
  const currency = searchParams.get("currency");
  const type = searchParams.get("type") || "all";

  const getOrders = async () => {
    try {
      setLoading(true);
      const limit = 4;
      const response = await TransactionService.getPrefundTransactions({
        type,
        status,
        currency,
        limit
      });
      setOrders(response.data?.data?.transactions);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleEyeClick = (type, orderId) => {
    dispatch(setType(type));
    navigate(`/payout-transactions/summary/${orderId}`);
  };

  useEffect(() => {
    getOrders();
  }, [type, status, currency]); // Include all relevant parameters that `getOrders` uses.

  if (loading)
    return (
      <Box minH={"92vh"} bg={"#F2F2F2"}>
        <Box bg="white" w="100%" mt={6} borderRadius={"9px"} p={"20px 40px"}>
          <HStack w="100%">
            <Typography type="body" weight="medium" color="primary">
              Recent Activity
            </Typography>
            <Spacer />
            <Typography
              type="body"
              weight="medium"
              color="primary"
              cursor="pointer"
              onClick={() => navigate("/payout-transactions")}
            >
              View All
            </Typography>
          </HStack>
          <Stack borderRadius={"9px"} mt={"15px"} rowGap={5} bg={"white"}>
            {Array(4)
              .fill(0)
              .map((_, index) => (
                <Skeleton height="60px" />
              ))}
          </Stack>
        </Box>
      </Box>
    );

  if (isEmpty(orders))
    return (
      <Box className="al-main-card">
        <Flex
          bg={"white"}
          width={"full"}
          minHeight={"100vh"}
          mt={5}
          rounded={"9px"}
          justify={"center"}
          alignItems={"center"}
          border={"1px"}
          borderColor={"#E4E4E4"}
          h={"full"}
          direction={"column"}
        >
          <Image src={EmptyIcon} mb={"2"} />
          <Typography
            color="secondary"
            colorweight="800"
            type="title"
            weight="medium"
          >
            No Transactions yet
          </Typography>
        </Flex>
      </Box>
    );

  return (
    <Box minH={"92vh"} bg={"#F2F2F2"}>
      <VStack w={"100%"} marginTop={"15px"} gap="24px">
        <Box borderRadius={"9px"} bg="white" w="100%" p={"20px 40px"}>
          <HStack w="100%">
            <Typography type="body" weight="medium" color="primary">
              Recent Activity
            </Typography>
            <Spacer />
            <Typography
              type="body"
              weight="medium"
              color="primary"
              cursor="pointer"
              onClick={() => navigate("/payout-transactions")}
            >
              View All
            </Typography>
          </HStack>
          <TableContainer mt={6} maxH={"70vh"} overflowY="auto">
            <Table>
              <Tbody>
                {orders
                  .map((item, index) => (
                    <TableColumn
                      key={index}
                      name={item?.recipientName?.firstName}
                      type={item?.type}
                      txId={item?.orderId}
                      createdAT={item?.createdAt}
                      currency={item?.withdrawCurrency}
                      amount={Number(item.withdrawAmount || 0).toFixed(2)}
                      depositAmount={item?.depositAmount}
                      depositCurrency={item?.depositCurrency}
                      status={item?.status}
                      handleClick={() =>
                        handleEyeClick(item?.type, item?.orderId)
                      }
                    />
                  ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </VStack>
    </Box>
  );
}
