import React, { useEffect, useState } from "react";
import { Box, Flex, Image, Text, useToast } from "@chakra-ui/react";

//Custom Conmponents or functions
import { validateEmail } from "../../utils/validations";
import Loader from "../../utils/Loader";
import { callApi } from "../../utils/http_common";
import Input from "../../components/LoginComponents/InputComponents/TextField";
import BackButton from "../../components/LoginComponents/BackButton";
import Typography from "../../components/Common/Typography";

//css
import "./Login.css"
import "./PasswordLogin.css"

// logo/icons
import TransfiLogo from "../../assets/transfi-logo.svg"
import Button from "../../components/LoginComponents/Button";

const EmailForNewPassword = (props) => {
    const toast = useToast();

    const { setUserEmail, reset, setMfaStatus, urlEmail = false } = props || {};
    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isButtonLoading, setIsButtonLoading] = useState(false);

    useEffect(() => {
        if (urlEmail) {
            sendOtpToEmail(urlEmail);
        }
    }, [urlEmail])

    const handleEmailInputChange = (e) => {
        setEmail(e.target.value);
    }

    const handleToast = (title, type) => {
        toast({
            title,
            status: type,
            duration: 5000,
            isClosable: true
        });
    };

    const sendOtpToEmail = async (userEmail) => {
        if (!validateEmail(userEmail)) {
            handleToast("Please enter valid email", 'error');
            return;
        }
        try {
            setIsButtonLoading(true);
            const { data } = await callApi.post(`/customer/verifyEmail`, { email: userEmail, resetPassword: reset });
            const { status = false, message, mfaStatus } = data?.data;
            if (status) {
                setUserEmail(userEmail);
                if (mfaStatus) {
                    setMfaStatus(mfaStatus);
                }
                setIsButtonLoading(false);
            } else {
                throw new Error(message);
            }

            if (mfaStatus) {
                handleToast("Email Verified, Please Verify 2FA to reset your password", 'success');
            } else {
                handleToast("OTP sent successfully", 'success');
            }
        } catch (e) {
            handleToast(e?.response?.data?.error?.message || "Something went wrong", 'error');
            setIsButtonLoading(false);
            console.log("Reset error", e)
        }
    }

    return (
        isLoading ? <Loader /> :
            <Flex bg={'white'} h={'full'} w={'full'}>
                <Box bg={'white'} h={"full"} w={'50%'}>
                    <Box>
                        <div className="pa-login-sub-card">
                            <div className="pa-login-header">
                                <BackButton />
                                <img src={TransfiLogo} alt="logo" />
                                <span style={{ fontWeight: '500' }} >Forgot Password?</span>
                                <Box display={"flex"} flexDirection={"column"} gap={"30px"}>
                                    <Typography type="title" color="secondary" colorweight="900" >
                                        Enter your email to verify
                                    </Typography>
                                    <Typography type="description" >
                                        You will be asked to enter OTP or 2FA
                                    </Typography>
                                </Box>
                            </div>
                            <div className="pa-login-body">
                                <p>Work Email</p>
                                <Input
                                    type={"email"}
                                    placeholder={"Enter your email address"}
                                    inputChangeHandler={handleEmailInputChange}
                                    value={email}
                                />
                                <Button className={"pa-button"} onClickHandler={() => sendOtpToEmail(email)} isLoading={isButtonLoading} >
                                    Verify Email
                                </Button>
                            </div>
                        </div>
                    </Box>
                </Box>
                <Flex className="gradient-background" flexDirection={'column'} justify={'space-between'} w={'full'} maxH={'100vh'} overflow={'hidden'}>
                    <Box ml={'80px'} mt={'100px'}>
                        <Text color={'#092C4C'} fontFamily={'DM Sans'} fontWeight={'700'} fontSize={'48px'}>Borderless <Text as={'span'} color={'#1A4FD6'}>payments</Text>,</Text>

                        <Text color={'#092C4C'} fontFamily={'DM Sans'} fontWeight={'700'} fontSize={'48px'}>boundless <Text as={'span'} color={'#1A4FD6'}>potential</Text></Text>
                    </Box>
                    <Flex w={'full'} justify={'flex-end'}>
                        <Image src="/assets/login.png" maxH={'809px'} maxW={'992px'} />
                    </Flex>
                </Flex>
            </Flex>
    );
};

export default EmailForNewPassword;
