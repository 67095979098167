import { useSearchParams } from 'react-router-dom';
import { Menu, MenuButton, Button, MenuList, MenuItem, Box } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';

import Typography from '../../components/Common/Typography';

const FilterButton = ({ filterOptions, queryKey = "status" }) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const currentStatus = searchParams.get(queryKey) || filterOptions[0].value;

    // Update URL
    const handleItemClick = (value) => {
        setSearchParams({ [queryKey]: value });
    };

    if (!filterOptions || filterOptions.length === 0) return null;

    return (
        <Box w={"200px"}>
            <Menu>
                {({ isOpen }) => (
                    <>
                        <MenuButton w={"full"} _hover={{ bg: "#F5F5F5" }} _expanded={{ bg: "#F5F5F5" }} h={"40px"} px={"14px"} bg={"white"} borderRadius={"5px"} as={Button}
                            rightIcon={<ChevronDownIcon color={"#525C76"} w={"23px"} h={"23px"} transition="transform 0.2s ease-in-out" transform={isOpen ? "rotate(-180deg)" : "rotate(0deg)"} />}
                            variant='outline' size='sm'>
                            <Typography textAlign="left" type="description" weight="regular">
                                {filterOptions.find(option => option.value === currentStatus)?.label || filterOptions[0].label}
                            </Typography>
                        </MenuButton>
                        <MenuList paddingTop={0} w={"full"} maxW={"full"} maxH="40vh" overflowY="auto" zIndex={9999}>
                            {filterOptions.map(option => (
                                <MenuItem paddingY={3} paddingX={2} className="select-menu-option-box" key={option.value} onClick={() => handleItemClick(option.value)} isDisabled={currentStatus === option.value}>
                                    <Typography type="description" weight="regular">{option.label}</Typography>
                                </MenuItem>
                            ))}
                        </MenuList>
                    </>
                )}
            </Menu>
        </Box>
    );
};

export default FilterButton;
