import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { PiWarningBold } from "react-icons/pi";

import Typography from "../Common/Typography";

const TabChangeModal = (props) => {
  const { isOpen, onClose, headingText = "", bodyText = "", bodySubText = "", cancelBtnText = "", confirmBtnText = "", onConfirm } = props;

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader >
          <HStack justifyContent={"center"} >
            <Typography weight="bold" type="title" color="primary">
              {headingText}
            </Typography>
          </HStack>
        </ModalHeader>
        <ModalBody justifyItems={'center'} >
          <Typography type="body" textAlign={'center'} >
            {bodyText}
          </Typography>
          <Typography type="body" textAlign={'center'} >
            {bodySubText}
          </Typography>
        </ModalBody>
        <ModalFooter justifyContent={"right"}>
          <HStack>
            <Button
              color="#525C76"
              border="1px"
              borderColor="#525C76"
              _hover={{ bg: "#ffffff" }}
              variant={"outline"}
              onClick={onClose}
              size={"md"}
            >
              {cancelBtnText}
            </Button>
            <Button
              onClick={onConfirm}
              bg="#E90000"
              color="#ffffff"
              _hover={{ bg: "#c53030" }}
              size={"md"}
            >
              {confirmBtnText}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TabChangeModal;
