import React from 'react';
import { Button, useToast, Center, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalOverlay, VStack } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import Typography from '../../components/Common/Typography';
import { deleteCustomer } from '../../store/slices/customerSlice';

const DeleteModal = ({ isOpen, onClose, data, setRefreshData }) => {

    const dispatch = useDispatch();

    const loading = useSelector((state) => state.customer.loading) || false;

    const toast = useToast();

    const handleToast = (title, type) => {
        toast({
            title,
            status: type,
            duration: 5000,
            isClosable: true
        });
    };

    const { cxId = "" } = data;

    const handleDelete = async () => {
        const response = await dispatch(deleteCustomer({ cxId }));

        if (response.payload.data.status) {
            handleToast(response.payload.data.message, 'success');
            setRefreshData((prevState) => !prevState)
            onClose();
        } else {
            handleToast(response.payload.data.message || 'Something went wrong', 'error');
            setRefreshData((prevState) => !prevState)
            onClose();
        }
    }
    const onModalClose = () => {
        onClose();
    };

    return (
        <Modal isOpen={isOpen} onClose={onModalClose}>
            <ModalOverlay />
            <ModalContent>
                <Center mt={4}>
                    <Typography color="primary" colorweight="500" weight='bold'>Remove Customer</Typography>
                </Center>
                <ModalCloseButton />
                <ModalBody>
                    <Center mt={4}>
                        <VStack>
                            <Typography color="secondary" colorweight="800" type='description' weight='medium'>Are you sure you want to remove this customer details?
                            </Typography>
                        </VStack>
                    </Center>
                </ModalBody>

                <ModalFooter>
                    <Button
                        bg={"#F2F2F2"}
                        _hover={{ bg: "#F2F2F2" }}
                        color="#808080"
                        width={'113px'}
                        height={'40px'}
                        rounded={'6px'}
                        fontSize={'14px'}
                        fontWeight={'400'}
                        fontFamily={'DM Sans'} mr={3} onClick={onModalClose}>
                        Cancel
                    </Button>
                    <Button
                        bg={"#E90000"}
                        _hover={{ bg: '#E90000' }}
                        color="white"
                        width={'113px'}
                        height={'40px'}
                        rounded={'6px'}
                        fontSize={'14px'}
                        fontWeight={'400'}
                        fontFamily={'DM Sans'}
                        onClick={handleDelete}
                        isLoading={loading}
                    >
                        Remove
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default DeleteModal;