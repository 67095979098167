import { useCallback, useEffect, useRef, useState } from "react";
import { debounce, isEmpty, isUndefined } from "lodash";
import { useField, useFormikContext } from "formik";
import { Box, Button, FormControl, FormErrorMessage, FormLabel, Img, Input, InputGroup, InputLeftElement, Menu, MenuButton, MenuItem, MenuList, Textarea } from "@chakra-ui/react";

//Custom css and icons
import './InputComponents.css';
import { ChevronDownIcon, SearchIcon, AddIcon } from '@chakra-ui/icons';

// Custom Components and Functions
import Typography from "../Typography";

const RightIcon = ({ isOpen }) => {
  return (
    <Box
      w={"34px"}
      height={"32px"}
      borderRadius={"4px"}
      backgroundColor={"#E8EDFB"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <ChevronDownIcon w={"23px"} h={"23px"}
        transition="transform 0.2s ease-in-out"
        transform={isOpen ? "rotate(-180deg)" : "rotate(0deg)"}

      />
    </Box>
  )
}

export const SelectMenu = ({ label, width, id, options, isTouch = true, placeholder, searchBoxPlaceholder = 'Search', optionBoxPlaceHolder = 'Nothing added', buttonPlaceholder, isMandatory, handleAddFunction, isDisabled, isLoading = false, onChange = () => { }, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const { setFieldValue, setFieldTouched, getFieldProps } = useFormikContext();
  const fieldValue = getFieldProps(props.name)?.value;

  const [filteredOptions, setFilteredOptions] = useState([]);
  const inputRef = useRef(null);

  useEffect(() => {
    if (!options || isEmpty(options)) {
      setFilteredOptions([]);
      return;
    }
    setFilteredOptions(options)
  }, [options])


  const handleDebounceFn = useCallback((searchText) => {
    const lowercasedFilter = searchText.toLowerCase();
    const filteredData = options.filter(item =>
      item.label.toLowerCase().includes(lowercasedFilter)
    );
    setFilteredOptions(filteredData);
    setTimeout(() => {
      inputRef.current.focus()
    }, 0);
  }, [options]);

  const debounceFn = useCallback(debounce(handleDebounceFn, 300), [handleDebounceFn]);

  const isInvalid = isTouch ? (meta.error && meta.touched) : !meta.error;

  const handleSelect = option => {
    setFieldValue(props.name, option);
    onChange(props.name);
  };

  const handleOptionClick = option => {
    handleSelect(option);
    setFilteredOptions(options);
    inputRef.current.value = '';
  };

  return (
    <FormControl isInvalid={isInvalid} h="95px" maxW={width || "386px"}>
      <FormLabel htmlFor={id}>
        <Typography type="description" weight="regular" color="secondary" colorweight={"800"}>
          {label}
          {isMandatory &&
            <span style={{ color: 'red' }} > *</span>
          }
        </Typography>
      </FormLabel>
      <Menu>
        {({ isOpen }) => (
          <>
            <MenuButton
              _hover={{ bg: "#F5F5F5" }}
              _expanded={{ bg: "#F5F5F5" }}
              h={"45px"}
              pr={1}
              pl={4}
              rightIcon={<RightIcon isOpen={isOpen} />}
              bg={"white"}
              className="select-menu-panel"
              borderRadius={"5px"}
              as={Button}
              w="full"
              border={isInvalid ? "2px solid #e85151" : ""}
              onClick={() => { setFieldTouched(props.name, false) }}
              isDisabled={isDisabled}
              isLoading={isLoading}
            >
              <Box display={"flex"} alignItems={"center"} columnGap={2}>
                {
                  fieldValue.logo && fieldValue.value ? <> <Img className="select-menu-option-box-img" boxSize={"1.5rem"} src={process.env.PUBLIC_URL + fieldValue.logo} alt="logo" />
                    <Typography className="selectBankDropdown" type="description" weight="regular">{fieldValue.label}</Typography>
                  </> : fieldValue.value ? <Typography className="selectBankDropdown" type="description" weight="regular">{fieldValue.label}</Typography> : <>{placeholder}</>
                }
              </Box>
            </MenuButton>
            <MenuList padding={"12px"} paddingTop={0} w={width || "386px"} maxH="280px" overflowY="auto" zIndex={100} >
              <Box marginBottom={"16px"}>
                <InputGroup>
                  <InputLeftElement pointerEvents='none'>
                    <SearchIcon color='brand.primary' />
                  </InputLeftElement>
                  <Input
                    placeholder={searchBoxPlaceholder}
                    name="search"
                    id="search-input"
                    ref={inputRef}
                    onChange={(e) => {
                      debounceFn(e.target.value);
                    }}
                  />
                </InputGroup>
              </Box>
              {filteredOptions.length === 0 && (
                <Box display='flex' justifyContent='center' h={"150px"} alignItems='center' >
                  <Typography type="description" weight="regular">{optionBoxPlaceHolder}</Typography>
                </Box>
              )}
              {filteredOptions?.map(option => (
                <MenuItem key={isUndefined(option.key) ? option.value : option.key} paddingY={6} paddingX={2} className="select-menu-option-box" onClick={() => { handleOptionClick(option) }}>
                  <Box display="flex" alignItems="center">
                    {option.logo &&
                      <Img width={8} height={8} borderRadius="full" src={process.env.PUBLIC_URL + option.logo} alt="" mr="10px" />
                    }
                    <Typography type="description" weight="regular">{option.label}</Typography>
                  </Box>
                </MenuItem>
              ))}
              {handleAddFunction && buttonPlaceholder &&
                <Box display='flex' justifyContent='center' alignItems='center' marginTop={"12px"} gap={"10px"} _hover={{ cursor: 'pointer' }} onClick={() => handleAddFunction()} >
                  <Typography type="description" weight="regular" color="primary" >{`${buttonPlaceholder}`}</Typography><AddIcon style={{ width: '8px', height: '8px', color: '#1A4FD6' }} />
                </Box>
              }
            </MenuList>
          </>
        )}
      </Menu>
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};

export const TextField = ({ label, id, isTouch = true, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const isInvalid = isTouch ? (meta.error && meta.touched) : !!meta.error;

  return (
    <FormControl isInvalid={isInvalid} zIndex={0} display={"flex"} flexDirection={"column"} alignItems={"flex-end"} >
      <FormLabel htmlFor={id}>
        {label &&
          <Typography type="body" weight="regular" color="secondary" colorweight={"800"}>
            {label}
          </Typography>
        }
      </FormLabel>
      <Input {...field} {...props} fontFamily={"DM Sans"} />
      <FormErrorMessage mt={"15px"} >
        {meta.error}
      </FormErrorMessage>
    </FormControl>
  );
};

export const TextArea = ({ label, id, isTouch = true, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const isInvalid = isTouch ? (meta.error && meta.touched) : !!meta.error;

  return (
    <FormControl isInvalid={isInvalid} zIndex={0} >
      <FormLabel htmlFor={id}>
        {label &&
          <Typography type="body" weight="regular" color="secondary" colorweight={"800"}>
            {label}
          </Typography>
        }
      </FormLabel>
      <Textarea {...field} {...props}
        fontFamily={"DM Sans"}
        w={"100%"}
        fontWeight={"400"}
        fontSize={"14px"}
      />
      <FormErrorMessage>
        {meta.error}
      </FormErrorMessage>
    </FormControl>
  );
};
