import React, { useState } from "react";
import { useSelector } from "react-redux";
import { upperCase } from "lodash";
import Loader from "react-js-loader";
import {
    Box,
    Text
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { MdAccountBalanceWallet, MdOutlineAccountBalanceWallet } from "react-icons/md";

export default function OrderDashboard() {

    const [loading, setLoading] = useState(false);
    const orgType = useSelector((state) => state?.login?.orgDetails?.orgType || "default");

    const dashboards = [
        { dashboard: "COLLECTION", subtext: "Fiat To Stablecoin", icon: MdAccountBalanceWallet, link: "/order/pay-in-order", visibility: true },
        { dashboard: "PAYOUT", subtext: "Stablecoin To Fiat", icon: MdOutlineAccountBalanceWallet, link: "/order/pay-out-order", visibility: (orgType === "default") },
        { dashboard: "Gaming", icon: MdOutlineAccountBalanceWallet, link: "/order/gaming-order", visibility: (orgType === "default") },
    ];

    const Dashboard = (data) => {
        const details = data?.data;
        return <>

            {
                details.visibility && <div className="balance-card-zoom" style={{ width: '250px', padding: '15px 20px', boxShadow: '0 4px 10px 2px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)', borderRadius: '4px', height: '130px' }} >
                    <Link to={`${details.link}`}>
                        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '10px' }} >
                            <div style={{ height: '85px' }}>
                                <h3 style={{ fontSize: '20px', fontWeight: '600' }}>{upperCase(details.dashboard)}</h3>
                            </div>
                            <div style={{ width: '150px', height: '85px', display: 'flex', flexDirection: "column", alignItems: "flex-end" }}>
                                <details.icon style={{ fontSize: '50px', marginBottom: '10px' }} />
                                <span style={{ fontSize: '14px', fontWeight: "500" }} >{details.subtext}</span>
                            </div>
                        </div>
                    </Link>

                </div>
            }

        </>
    }

    const renderTransfiDashboards = () => {
        const transfiDashboards = Object.keys(dashboards).sort();

        return <div style={{ display: 'flex', justifyContent: 'left', padding: '20px 10px', flexWrap: 'wrap', gap: '4.45%' }}>
            {transfiDashboards.map(value => {
                return <div style={{ marginBottom: '40px' }}><Dashboard data={dashboards[value]} /></div>
            })}
        </div>
    }

    return !loading ? (
        <>
            <Box w="100%" p={4}>
                <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '20px', padding: '0 10px', marginBottom: '5px' }}>
                    <Text fontWeight={500}>Orders Dashboard</Text>

                </div>

                {renderTransfiDashboards()}
            </Box>

        </>
    ) : (
        <Loader
            type="spinner-cub"
            bgColor={"#FFFFFF"}
            title={"Loading..."}
            color={"cyan.400"}
            size={100}
        />
    );
};