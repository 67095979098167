import React, { useEffect, useState } from "react";
import { Box, Flex, Image, Text, useToast } from "@chakra-ui/react";

//Custom Conmponents or functions
import OTPInputBoxes from "./OTPInputBoxes";
import Loader from "../../utils/Loader";
import { callApi } from "../../utils/http_common";
import TransfiLogo from "../../assets/transfi-logo.svg";
import BackButton from "../../components/LoginComponents/BackButton";
import Button from "../../components/LoginComponents/Button";

//css
import "./Login.css"
import "./PasswordLogin.css";

const OTPVerify = (props) => {
    const toast = useToast();

    const { email, setOTPVerified, setMfaStatus, reset, setUserEmail } = props || {};
    const [otp, setOtp] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [counter, setCounter] = useState(60);
    const [disableResend, setDisableResend] = useState(true);
    const [passwordAreadySetteled, setPasswordSettled] = useState(false);

    useEffect(() => {
        if (counter === 0) {
            setDisableResend(false);
            return;
        }
        const timer = setTimeout(() => {
            setCounter(counter - 1);
        }, 1000);
        return () => clearTimeout(timer);
    }, [counter]);

    const sendOtpToEmail = async () => {
        try {
            setIsLoading(true);
            const data = await callApi.post(`/customer/verifyEmail`, { email, resetPassword: reset });
            const { status = false, message, alreadyPresent = false } = data;

            if (alreadyPresent) {
                setPasswordSettled(true);
                setIsLoading(false);
                return;
            }

            if (status) {
                setIsLoading(false);
            } else {
                throw new Error(message);
            }

            handleToast("Otp sent successfully", 'success');
        } catch (e) {
            handleToast(e?.response?.data?.error?.message || "Something went wrong", 'error');
            setIsLoading(false);
        }
    }

    const handleResendOTP = () => {
        sendOtpToEmail();
        setDisableResend(true);
        setCounter(60);
    };

    const handleToast = (title, type) => {
        toast({
            title,
            status: type,
            duration: 5000,
            isClosable: true
        });
    };

    const verifyOtp = async () => {
        if (otp.length < 6) {
            handleToast("Please Enter Valid OTP", "error");
            return;
        }
        try {
            setIsButtonLoading(true);
            const { data } = await callApi.post(`/customer/verifyNewPasswordOTP`, { email, otp });
            const { otpVerified = false, message, mfaStatus } = data?.data;
            if (otpVerified) {
                if (mfaStatus) {
                    setMfaStatus(mfaStatus);
                } else {
                    setOTPVerified(otpVerified);
                }
                setIsButtonLoading(false);
            } else {
                throw new Error(message);
            }

            handleToast("Otp Successfully Verified", 'success');
        } catch (e) {
            handleToast(e?.response?.data?.error?.message || "Something went wrong", 'error');
            setIsButtonLoading(false);
        }
    }

    useEffect(() => {
        if (otp.length === 6) {
            verifyOtp();
        }
    }, [otp]);

    return (
        isLoading ? <Loader /> :
            <Flex bg={'white'} h={'full'} w={'full'}>
                <Box bg={'white'} h={"full"} w={'50%'}>
                    <Box>
                        <div className="pa-login-sub-card">
                            <div className="pa-login-header" style={{ marginBottom: '-50px' }} >
                                <BackButton />
                                <img src={TransfiLogo} alt="logo" />
                                <span style={{ fontWeight: '500' }} >Forgot Password?</span>
                                <div style={{ marginTop: '-40px', marginBottom: '15px' }} >
                                    <span style={{ fontSize: '15px' }}>
                                        An OTP has been sent to
                                    </span>
                                    <span style={{ color: "#0b2e4e", fontSize: '15px' }}>
                                        {` ${email}`}
                                    </span>
                                    <span style={{ color: "#1a4fd6", fontSize: '15px', marginLeft: '15px', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => { setUserEmail("") }} >
                                        Edit
                                    </span>
                                </div>
                            </div>
                            <div className="pa-login-body" >
                                <p className="pa-login-heading">Enter OTP</p>
                                <OTPInputBoxes setOtp={setOtp} />
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '80%' }} >
                                    <div>
                                        <p style={{ marginTop: '8px' }}>
                                            Resend OTP in {`${counter}s`}
                                        </p>
                                    </div>
                                    <div>
                                        <a className={disableResend ? 'pa-forget-password' : 'pa-forget-password-enabled'} onClick={() => { !disableResend && handleResendOTP() }} >
                                            Resend
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Box>
                </Box>
                <Flex className="gradient-background" flexDirection={'column'} justify={'space-between'} w={'full'} maxH={'100vh'} overflow={'hidden'}>
                    <Box ml={'80px'} mt={'100px'}>
                        <Text color={'#092C4C'} fontFamily={'DM Sans'} fontWeight={'700'} fontSize={'48px'}>Borderless <Text as={'span'} color={'#1A4FD6'}>payments</Text>,</Text>

                        <Text color={'#092C4C'} fontFamily={'DM Sans'} fontWeight={'700'} fontSize={'48px'}>boundless <Text as={'span'} color={'#1A4FD6'}>potential</Text></Text>
                    </Box>
                    <Flex w={'full'} justify={'flex-end'}>
                        <Image src="/assets/login.png" maxH={'809px'} maxW={'992px'} />
                    </Flex>
                </Flex>
            </Flex>
    );
};

export default OTPVerify;
