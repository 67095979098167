import { Flex, Box, Skeleton, SkeletonText, SkeletonCircle, Stack, VStack } from '@chakra-ui/react';
import React from 'react';
import Typography from '../../components/Common/Typography';

const FinanceLoader = () => {
  return (
    <Box w={"full"} minH={"92vh"} bg={"#F2F2F2"}>
      <VStack w={"100%"} >
          <Box bg='white' w='100%' borderRadius={"9px"} p={"20px 40px"}>
              <Typography type='body' weight='medium' color='primary' >Bank Details</Typography>
                 <Stack borderRadius={"9px"} mt={"15px"} rowGap={5} bg={"white"}>
                    {Array(2).fill(0).map((_, index) => (
                      <Skeleton height='60px' />
                    ))}
                 </Stack>
           </Box> 
          <Box bg='white' w='100%' borderRadius={"9px"} p={"20px 40px"} mt={4}>
              <Typography type='body' weight='medium' color='primary' >Wallet Details</Typography>
                 <Stack borderRadius={"9px"} mt={"15px"} rowGap={5} bg={"white"}>
                    {Array(2).fill(0).map((_, index) => (
                       <Skeleton height='60px' />
                    ))}
                 </Stack>
           </Box> 
        </VStack>   
    </Box>   
  );
};

export default FinanceLoader;
