import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'; 
import { Box, Flex, Image } from '@chakra-ui/react';
import EmptyIcon from '../../assets/emptypage.svg';
import { isEmpty } from 'lodash';

// Custom Function and Components
import Typography from '../../components/Common/Typography';
import { getPayoutOrderData } from '../../store/slices/transactionSlices';
import PayoutApprovals from './PayoutApprovals';
import CollectionApprovals from './CollectionApprovals';

// CSS
import './approval.css';

const Approval = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const approvalsList = useSelector((state) => state?.transaction?.list || []);
  const orgType = useSelector((state) => state?.login?.orgDetails?.orgType || "default");
  const customerType = useSelector((state) => state?.login?.userDetails?.cxRoles || [])[0];

  const payoutApprovalList = approvalsList?.filter((item) => (item?.type === "fiat_payout" || item?.type === "crypto_payout")) || [];

  const collectionApprovalList = approvalsList?.filter((item) => (item?.type === "collections")) || [];

  useEffect(() => {
    if (!["admin", "approver"].includes(customerType)) {
      navigate(-1);
    }
  }, [])

  const fetchApprovals = useCallback(() => {
    dispatch(getPayoutOrderData({ filters: { status: "approval_pending" } }));
  }, [dispatch]);

  useEffect(() => {
    fetchApprovals();
  }, [])

  if(isEmpty(payoutApprovalList) && isEmpty(collectionApprovalList)){
    return(
      <Box className='al-main-card'>
        <Typography type='largeheader' weight='bold' >
          Approvals
        </Typography>
          <Flex bg={'white'} width={'full'} minHeight={'100vh'} mt={5} rounded={'9px'} justify={'center'} alignItems={'center'} border={'1px'} borderColor={'#E4E4E4'}  h={'full'} direction={'column'}>
                      <Image src={EmptyIcon} mb={'2'} />
                      <Typography color="secondary" colorweight='800' type="title" weight="medium">No Approvals requested yet</Typography>
            </Flex>
        </Box>
    );
  }
  return (
    <Box className='al-main-card'>
      <Typography type='largeheader' weight='bold' >
        Approvals
      </Typography>
      {orgType === 'payout' && <PayoutApprovals payoutApprovalList={payoutApprovalList} />}
      {orgType === 'collections' && <CollectionApprovals collectionApprovalList={collectionApprovalList} />}
    </Box>
  );
}

export default Approval;