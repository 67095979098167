import React from "react";
import { Box, Flex, Grid, GridItem } from '@chakra-ui/react';

import Typography from '../../../components/Common/Typography';
import { StaticTextField } from "../../../components/Common/Input";

export default function PersonalDetailsCard(props) {
  const { customerDetails = {} } = props || {};
  const { country, email } = customerDetails;

  return (
    <Box py={10} >
      <Flex alignItems={"center"} justify={'space-between'}>
        <Box>
          <Typography color="primary" colorweight="500" type='body' weight='medium'>Personal Details</Typography>
        </Box>
      </Flex>
      <Grid mt={"40px"} templateColumns={{ md: 'repeat(1, 1fr)', lg: 'repeat(2, 1fr)' }} gap={2}>
        <GridItem maxW={'386px'}>
          <StaticTextField isDisabled={"true"} label="Country/Region" name="country" value={country || 'N/A'} placeholder="" required={true} />
        </GridItem>
        <GridItem maxW={'386px'}>
          <StaticTextField isDisabled={"true"} label="Email ID" name="emailId" value={email || 'N/A'} placeholder="" required={true} />
        </GridItem>
      </Grid>
    </Box>
  );
}