import React, { useEffect, useState } from 'react';
import { Field, useField, useFormikContext } from 'formik';
import {
  RadioGroup,
  Radio,
  Stack,
  Select,
  FormControl,
  SimpleGrid,
  Flex,
  Box,
  Img,
} from '@chakra-ui/react';

import ContactService from '../../../../services/contactService';
import { CustomSelectField, CustomSelectFieldSearch, SelectField } from '../../../../components/Common/Input';

const RadioDropdown = () => {
  const [selectedOption, setSelectedOption] = useState({})
  const [selectedWalletOption, setSelectedWalletOption] = useState({})
  const [currency] = useField('currency')
  const [fiatType, meta, helpers] = useField('fiatType')
  const { setFieldValue } = useFormikContext();
  const [paymentMethods, setPaymentMethods] = useState([])
  const [menuDisabled, setMenuDisabled] = useState(false);
  useEffect(() => {
    if (!fiatType.value) {
      setFieldValue("fiatType", "bank");
    }
  }, []);

  const getPaymentMethods = async () => {
    try {
      const resp = await ContactService.getFiatPaymentMethods({ fiatTicker: currency.value, type: "sell", pmType: fiatType.value === "bank" ? "bank_transfer" : "local_wallet" });
      setPaymentMethods(resp?.data?.data?.docs.map((pm) => ({ logo: pm.icon, value: JSON.stringify({ referenceName: pm.referenceName, pgId: pm?.pgId, pmId: pm?.pmId }), label: pm.name })));

      // if the payment method is one only then show the payment method as preselected and disable the menu.
      if (resp?.data?.data?.docs?.length === 1) {
        const pm = resp?.data?.data?.docs[0];
        setFieldValue("bankName", JSON.stringify({ referenceName: pm.referenceName, pgId: pm?.pgId, pmId: pm?.pmId }));
        setSelectedOption({
          logo: pm.icon,
          value: JSON.stringify({ referenceName: pm.referenceName, pgId: pm?.pgId, pmId: pm?.pmId }),
          label: pm.name
        });
        setMenuDisabled(true);
      }
    } catch (error) {
    }
  }

  useEffect(() => {
    if (!currency.value || !fiatType.value) return;
    setSelectedOption({})
    setSelectedWalletOption({})
    setFieldValue("bankName", "{}");
    setMenuDisabled(false);
    getPaymentMethods()
  }, [fiatType.value, currency.value])

  return (
    <RadioGroup defaultValue='bank'>
      <SimpleGrid columns={2} spacing={6}>
        <Field name={"fiatType"}>
          {({ field }) => (
            <>
              <FormControl height={fiatType.value === "bank" ? "120px" : "52px"} border={`1px solid ${fiatType.value === "bank" ? "#1A4FD6" : "#E4E4E4"}`} padding={"8px 10px"} borderRadius={"8px"}>
                <Flex justifyContent={"space-between"}>

                  <Radio {...field} value={"bank"} onChange={() => setFieldValue("fiatType", "bank")}>
                    {"Select Bank"}
                  </Radio>
                  <Box>
                    <Img src={process.env.PUBLIC_URL + "/assets/bank_grid_icons.svg"} />
                  </Box>
                </Flex>

                {fiatType.value === "bank" && (
                  <Box maxW={"387px"}>

                    <CustomSelectFieldSearch disabled={menuDisabled} EmptyMenuLabel={"No Banks Found"} required={false} width={"100%"} selectedOption={selectedOption} setSelectedOption={setSelectedOption} name="bankName" placeholder="Select Bank" options={paymentMethods} />
                  </Box>
                )}
              </FormControl>
            </>
          )}
        </Field>
        {
          ["PHP", "IDR", "EGP"].includes(currency.value) && (
            <Field name={"fiatType"}>
              {({ field }) => (
                <>
                  <FormControl height={fiatType.value === "local_wallet" ? "120px" : "52px"} border={`1px solid ${fiatType.value === "local_wallet" ? "#1A4FD6" : "#E4E4E4"}`} padding={"8px 10px"} borderRadius={"8px"}>
                    <Flex justifyContent={"space-between"}>

                      <Radio {...field} value={"local_wallet"} onChange={() => setFieldValue("fiatType", "local_wallet")}>
                        {"Select Wallet"}
                      </Radio>
                      <Box>
                        <Img src={process.env.PUBLIC_URL + "/assets/bank_grid_icons.svg"} />
                      </Box>
                    </Flex>

                    {fiatType.value === "local_wallet" && (
                      <Box maxW={"387px"}>
                        <CustomSelectFieldSearch EmptyMenuLabel={"No Wallets Found"} required={false} width={"100%"} selectedOption={selectedWalletOption} setSelectedOption={setSelectedWalletOption} name="bankName" placeholder="Select Bank" options={paymentMethods} />
                      </Box>
                    )}
                  </FormControl>
                </>
              )}
            </Field>
          )
        }

      </SimpleGrid>
    </RadioGroup>
  );
};

export default RadioDropdown;