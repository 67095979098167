import React from "react";
import { Box, Flex, Grid, GridItem, Img } from '@chakra-ui/react';

import Typography from '../../../components/Common/Typography';
import { StaticTextField } from "../../../components/Common/Input";

export default function KybDetailsCard(props) {
  const { customerDetails = {}, kybType = "" } = props || {};
  const { businessName = '', registrationNumber = '', country = '', uboDetails = {} } = customerDetails?.response || {};
  const kybStatus = customerDetails?.kybStatus === "completed" ? true : false;

  return (
    <>
      {kybStatus ?
        < Box py={10} >
          <Flex alignItems={"center"} justify={'space-between'}>
            <Box>
              <Typography color="primary" colorweight="500" type='body' weight='medium'>{kybType} Details</Typography>
            </Box>
          </Flex>
          <Grid mt={"40px"} templateColumns={{ md: 'repeat(1, 1fr)', lg: 'repeat(2, 1fr)' }} gap={2}>
            <GridItem maxW={'386px'}>
              <StaticTextField isDisabled={"true"} label="Business Name" value={businessName} name="bussinessName" placeholder="" required={true} />
            </GridItem>
            <GridItem maxW={'386px'}>
              <StaticTextField isDisabled={"true"} label="Business Registration Number" value={registrationNumber} name="businessRegistrationNumber" placeholder="" required={true} />
            </GridItem>
            <GridItem maxW={'386px'}>
              <StaticTextField isDisabled={"true"} label="Country/Region" name="country" value={country} placeholder="" required={true} />
            </GridItem>
          </Grid>
          <Flex alignItems={"center"} justify={'space-between'} mt={"20px"}>
            <Box>
              <Typography color="primary" colorweight="500" type='body' weight='medium'>UBO Details</Typography>
            </Box>
          </Flex>
          <Grid mt={"40px"} templateColumns={{ md: 'repeat(1, 1fr)', lg: 'repeat(2, 1fr)' }} gap={2}>
            <GridItem maxW={'386px'}>
              <StaticTextField isDisabled={"true"} label="Name" name="name" value={`${uboDetails?.fixedInfo?.firstName} ${uboDetails?.fixedInfo?.lastName}`} placeholder="" required={false} />
            </GridItem>
            <GridItem maxW={'386px'}>
              <StaticTextField isDisabled={"true"} label="DOB" name="dob" placeholder="" value={uboDetails?.dob || 'N/A'} required={false} />
            </GridItem>
          </Grid>
        </Box > :
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"} >
          <Box padding={"70px 20px 70px 20px"} display={"flex"} flexDirection={"column"} alignItems={"center"} gap={"5px"} >
            <Img src={process.env.PUBLIC_URL + "/assets/kyb_incomplete_logo.png"} alt="logo" height={"98px"} width={"90px"} />
            <Typography type="title" weight="regular" color="secondary" colorweight="800"  >
              Complete {kybType} to view the details
            </Typography>
          </Box>
        </Box>
      }
    </>
  );
}