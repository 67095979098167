import { Box, VStack } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useField } from "formik";

import { getFiatCountryList, getFiatList } from "../../../store/slices/contactSlices";
import { RadioField, TextField, CustomSelectField, CustomSelectFieldSearch, DatePickerField } from "../../../components/Common/Input";
import { entityType } from "../../../constants";
import MainCard from "./MainCard";


// let's use the useField feature to show the values.. let's stringify the values for the logos.

const AddContactForm = (props) => {
  const { selectedOption, setSelectedOption, selectEntityOptions, setSelectEntityOptions } = props
  const dispatch = useDispatch();

  const contactTypeOptions = [{ value: 'organization', label: 'Business' }, { value: 'individual', label: 'Individual' }]
  const fiatList = useSelector((state) => state?.contact?.countryList?.map(item => ({
    label: `${item.symbol}`,
    value: `${item.countryCode2Digit}`,
    logo: `${item.logo}`
  })));

  // const [selectedOption, setSelectedOption] = useState({ label: "Select Country" });
  const [selectedDate, setSelectedDate] = useState({ label: "dd-mm-yyyy", value: "" });

  const [contactType] = useField('contactType');
  const [entity] = useField('entityType');
  useEffect(() => {
    dispatch(getFiatCountryList());
  }, [])
  return (
    <MainCard marginTop={-4} title="Basic Details">
      <VStack width="386px" spacing={5}>
        <RadioField label={"Contact Type"} options={contactTypeOptions} name="contactType" placeholder="Contact Type" />
        <CustomSelectFieldSearch EmptyMenuLabel={"No country/Region Found"} label={"Country/Region"} options={fiatList || []} name="country" placeholder="Select Country/Region" selectedOption={selectedOption} setSelectedOption={setSelectedOption} />
        <TextField label={contactType.value === "organization" ? "Entity Name" : "Individual Name"} name="name" placeholder="Enter Name" />
        <TextField label={contactType.value === "organization" ? "Entity Email ID" : "Individual Email ID"} type="email" name="email" placeholder="Enter Email" />

        {
          contactType.value === "organization" ?
            <>
              <TextField isTouch={false} label={"Business Registration Number"} name="regNo" placeholder="Enter valid number" />
              <DatePickerField isTouch={false} label={"Business Registration Date"} name="dob" placeholderText="dd-mm-yyyy" selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
            </> :
            <DatePickerField isTouch={false} label={"Date of Birth"} name="dob" placeholderText="dd-mm-yyyy" selectedDate={selectedDate} setSelectedDate={setSelectedDate} />

        }
        <Box>
          {
            contactType.value === "organization" && <CustomSelectField label={"Entity Type"} options={entityType || []} name="entityType" placeholder="Entity Type" selectedOption={selectEntityOptions} setSelectedOption={setSelectEntityOptions} />
          }
        </Box>

        {
          entity.value === "other" && <TextField label="Entity Type" name="otherEntityType" placeholder="Enter Entity Type" />
        }

      </VStack>
    </MainCard>
  );
};

export default AddContactForm;
