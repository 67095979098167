import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, Circle, Container, Divider, Flex, HStack, Image, Skeleton, Text, VStack, useDisclosure } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router';
import { CheckIcon } from '@chakra-ui/icons';
import { RxCross2 } from 'react-icons/rx';
import { GoArrowLeft } from 'react-icons/go';

// Custom Function and Components
import Typography from '../../components/Common/Typography';
import TransactionService from '../../services/transactionService';
import TransactionModal from './modals/TransactionModal';

// CSS
import './approval.css';

const CollectionApprovalSummary = () => {

    const { isOpen, onOpen, onClose } = useDisclosure();

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const { state } = useLocation() || {};

    const [type, setType] = useState("");
    const [summaryData, setSummaryData] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const { orderId = false } = state || {};

    const { contactName = '', email = '', purposeCode, fees = {}, fiat = {}, depositAmount = 0, depositCurrency = '', createdAt = '', senderName = {}, additionalDetails = {}, referenceId = '' } = summaryData || {};
    const { invoiceUrl = '', countryLogo = '' } = additionalDetails;
    const { firstName = '', lastName = '' } = senderName;
    const { networkFee = 0, processingFee = 0 } = fees;
    const { cxFee = 0 } = fiat;
    const totalFee = networkFee + processingFee + cxFee;

    const createdAtFormattedTime = new Date(new Date(createdAt).getTime()).toLocaleString('en-GB', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        month: 'short',
        day: '2-digit',
        year: 'numeric'
    });

    const fetchApprovals = useCallback(async () => {
        setIsLoading(true);
        if (orderId) {
            try {
                const { data, response } = await TransactionService.getPayoutOrders({ filters: { status: "approval_pending", orderId } }) || {};
                if (data) {
                    const { list } = data?.data || {};
                    setSummaryData(list[0] || []);
                    setIsLoading(false);
                }
            }
            catch (err) {
                console.log(err);
                setIsLoading(false);
            }
        }
        else {
            navigate(-1);
        }
    }, [dispatch]);

    const handleBackNavigation = () => {
        window.history.replaceState({}, '');
        navigate(-1);
    };

    const handleOpenRejectModal = () => {
        setType("reject");
        onOpen();
    };

    const handleOpenApproveModal = () => {
        setType("approve");
        onOpen();
    };

    const Step = (props) => {
        const { status, step } = props;

        return (
            <Container flex={1} m={0} p={0}>
                <Flex justifyContent={"center"} alignItems={"center"} w={"32px"} h={"32px"} border={status !== "completed" ? "1px solid" : "none"} borderColor={status === "inProgress" ? "brand.primary" : "brand.grey.400"} borderRadius={"50%"} direction="column">
                    <Circle
                        size="25px"
                        bg={status === 'completed' ? "brand.green" : status === 'inProgress' ? "brand.primary" : ""}
                        color={status === 'completed' ? "white" : status === 'inProgress' ? "white" : "brand.grey.400"}
                    >
                        <StepIcon status={status} step={step} />
                    </Circle>
                </Flex>
            </Container>
        );
    };

    const StepIcon = ({ status, step }) => {
        switch (status) {
            case 'completed':
                return <CheckIcon />;
            case 'inProgress':
                return step;
            default:
                return step
        }
    };

    function formatNumber(num) {
        return num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    const textColors = {
        completed: {
            color: 'secondary',
            colorweight: '400'
        },
        inProgress: {
            color: 'secondary',
            colorweight: '800'
        },
        pending: {
            color: 'secondary',
            colorweight: '400'
        },
        failed: {
            color: 'error',
            colorweight: '400'
        },
    }

    useEffect(() => {
        fetchApprovals();
    }, []);

    return (
        <Box minH={"92vh"} p={"10px 40px"} bg={"#F2F2F2"}>
            <Flex w={"fit-content"} cursor={"pointer"} onClick={handleBackNavigation} columnGap={2} alignItems={"center"}>
                <GoArrowLeft size={"13px"} />
                <Typography type="body">
                    Back
                </Typography>
            </Flex>
            <Typography type='largeheader' weight='bold' >
                Approvals
            </Typography>
            {isLoading ?
                <Box mt={6} display={"flex"} flexDirection={"column"} alignItems={"center"} w={"100%"} >
                    <Box display={"flex"} flexDirection={"column"} alignItems={"center"} rowGap={5} textAlign={"center"} width={"756px"}>
                        <Skeleton height={"100px"} w={"100%"} />
                        <Skeleton height={"100px"} w={"100%"} />
                        <Skeleton height={"300px"} w={"100%"} />
                    </Box>
                </Box>
                :
                <Box mt={6} display={"flex"} flexDirection={"column"} alignItems={"center"} w={"100%"}>
                    <Box display={"flex"} flexDirection={"column"} alignItems={"center"} rowGap={5} textAlign={"center"} flex={1} maxWidth={"800px"} px={'60px'} width={{ lg: "756px" }}>
                        <Flex mb={6} w={"100%"} justifyContent={"space-between"} color={"white"}>
                            <HStack height={"34px"} >
                                <VStack display={"flex"} flexDirection={"column"} gap={"0px"} justifyContent={"center"} alignItems={"flex-start"} >
                                    <Typography type='title' weight='medium'>
                                        Collect money from {firstName}
                                    </Typography>
                                    <Typography type='body' weight='medium' color='secondary' >
                                        Txn Id - {orderId}
                                    </Typography>
                                    <Typography type='body' weight='regular' color='secondary' >
                                        - {additionalDetails.maker}
                                    </Typography>
                                </VStack>
                            </HStack>
                            <HStack h={"58px"} display={"flex"} alignItems={"center"} ml={"50px"} >
                                <Button variant={"danger"} size={"sm"} rightIcon={<RxCross2 width={"10px"} height={"10px"} />} onClick={handleOpenRejectModal}  >
                                    Reject
                                </Button>
                                <Button variant={"primary"} size={"sm"} rightIcon={<CheckIcon width={"10px"} height={"10px"} />} onClick={handleOpenApproveModal} >
                                    Approve
                                </Button>
                            </HStack>
                        </Flex>
                        <Flex className='approval-summary-card'>
                            <Flex height={"100%"} w={"100%"} flexDirection={"row"} alignItems={"flex-start"} justifyContent={"space-between"} >
                                <HStack w={"90%"} display={"flex"} alignItems={"center"} >
                                    <Step status={"completed"} step={1} />
                                    <Divider borderWidth={"1px"} borderColor={"#26A17B"} borderRadius={"24px"} />
                                    <Step status={"inProgress"} step={2} />
                                    <Divider borderWidth={"1px"} borderRadius={"24px"} />
                                    <Step status={"initiated"} step={3} />
                                    <Divider borderWidth={"1px"} borderRadius={"24px"} />
                                    <Step status={"initiated"} step={4} />
                                </HStack>
                            </Flex>
                            <Flex mt={4} columnGap={5} alignItems={"flex-start"} w={"110%"} justifyContent={"space-between"} >
                                <Flex rowGap={"60px"} alignItems={"start"} flex={1} columnGap={5}>
                                    <Flex textAlign={"start"} flexDir={"column"}>
                                        <Typography type="description" weight="medium" color={textColors["completed"].color} colorweight={textColors["completed"].colorweight}>
                                            {"Transaction"}<br />{" Initiated"}
                                        </Typography>
                                        <Typography type="caption" color={"default"} colorweight={"default"}>
                                            {createdAtFormattedTime}
                                        </Typography>
                                    </Flex>
                                </Flex>
                                <Flex rowGap={"60px"} alignItems={"start"} flex={1} columnGap={5}>
                                    <Flex textAlign={"start"} flexDir={"column"}>
                                        <Typography type="description" weight="medium" color={textColors["inProgress"].color} colorweight={textColors["inProgress"].colorweight}>
                                            {"Approval"}
                                        </Typography>
                                        <Typography type="caption" color="primary">
                                            (In Progress)
                                        </Typography>
                                    </Flex>
                                </Flex>
                                <Flex rowGap={"60px"} alignItems={"start"} flex={1} columnGap={5}>
                                    <Flex textAlign={"start"} flexDir={"column"}>
                                        <Typography type="description" weight="medium" color={textColors["pending"].color} colorweight={textColors["pending"].colorweight}>
                                            {"Payment in"}<br />{"progress"}
                                        </Typography>
                                    </Flex>
                                </Flex>
                                <Flex rowGap={"60px"} alignItems={"start"} flex={1} columnGap={5}>
                                    <Flex textAlign={"start"} flexDir={"column"}>
                                        <Typography type="description" weight="medium" color={textColors["pending"].color} colorweight={textColors["pending"].colorweight}>
                                            {"Payment"}<br />{" Successful"}
                                        </Typography>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                        <Box bg={'white'} p={'40px'} border={'1px'} borderColor={'#E4E4E4'} rounded={'10px'} className='approval-summary-card'>
                            <Flex justify={'start'}>
                                <Typography type='body' color='primary'>Order Details</Typography>
                            </Flex>

                            <Flex mt={6} flexDirection={'column'} gap={7}>
                                <Flex justify={'space-between'} alignItems={'center'}>
                                    <Typography type='description' weight='medium'>Reference Id</Typography>
                                    <Typography type='body' weight='regular' color='secondary' colorweight={'800'}>{referenceId}</Typography>
                                </Flex>
                                <Flex justify={'space-between'} alignItems={'center'}>
                                    <Typography type='description' weight='medium'>Transaction Id</Typography>
                                    <Typography type='body' weight='regular' color='secondary' colorweight={'800'}>{orderId}</Typography>
                                </Flex>
                                <Flex justify={'space-between'} alignItems={'center'}>
                                    <Typography type='description' weight='medium'>Customer</Typography>
                                    <Flex gap={1} alignItems={'end'} flexDirection={'column'} justify={'start'}>
                                        <Flex gap={1} alignItems={'center'}>
                                            <Image src={countryLogo} w={'25px'} />
                                            <Typography type='body' weight='regular' color='secondary' colorweight={'800'}>{senderName?.firstName} {senderName?.lastName}</Typography>
                                        </Flex>
                                        <Text fontSize={'12px'} fontWeight={'400'} color={'#A8A8A8'} fontFamily={'DM Sans'}>{email}</Text>
                                    </Flex>
                                </Flex>

                            </Flex>

                            <Divider mt={'5'} mb={'2'} w={'full'} />

                            <Flex mt={6} flexDirection={'column'} gap={7}>
                                {invoiceUrl &&
                                    <Flex justify={'space-between'} alignItems={'center'}>
                                        <Typography type='description' weight='medium'>Invoice</Typography>
                                        <a target="_blank" rel="noopener noreferrer" href={invoiceUrl} >
                                            <Text color={'#1A4FD6'} textDecoration={'underline'} _hover={{ cursor: 'pointer' }}>View invoice</Text>
                                        </a>
                                    </Flex>}
                                <Flex justify={'space-between'} alignItems={'center'}>
                                    <Typography type='description' weight='medium'>Invoice amount</Typography>
                                    <Typography type='body' weight='regular' color='secondary' colorweight={'800'}>{formatNumber(depositAmount)}  {depositCurrency}</Typography>
                                </Flex>
                                <Flex justify={'space-between'} alignItems={'center'}>
                                    <Typography type='description' weight='medium'>Processing fee</Typography>
                                    <Typography type='body' weight='regular' color='secondary' colorweight={'800'}>{formatNumber(processingFee)}  {depositCurrency}</Typography>
                                </Flex>
                                <Flex justify={'space-between'} alignItems={'center'}>
                                    <Typography type='description' weight='medium'>Amount to be received</Typography>
                                    <Typography type='body' weight='regular' color='secondary' colorweight={'800'}>{formatNumber((Number(depositAmount) - Number(processingFee)))}  {depositCurrency}</Typography>
                                </Flex>

                            </Flex>
                        </Box>
                        <Flex className='approval-summary-card' mb={"10px"}>
                            <HStack w={"100%"} >
                                <Typography type='body' weight='medium' color='primary' >
                                    Additional Details
                                </Typography>
                            </HStack>
                            <HStack w={"100%"} display={"flex"} justifyContent={"space-between"} mt={"20px"} >
                                <Typography type='description' weight='regular' >
                                    Payment purpose
                                </Typography>
                                <Typography type='body' weight='regular' color='secondary' colorweight="800" >
                                    {purposeCode}
                                </Typography>
                            </HStack>
                        </Flex>
                    </Box>
                </Box>
            }
            <TransactionModal onClose={onClose} refreshData={handleBackNavigation} isOpen={isOpen} type={type} data={{ orderId, contactName, accountName: firstName, paidFrom: depositCurrency, depositCurrency, depositAmount, date: createdAt }} />
        </Box>

    );
}

export default CollectionApprovalSummary;