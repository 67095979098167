import React, { useState } from 'react'
import { Box, Button, Flex, Heading } from '@chakra-ui/react'
import { useSelector } from 'react-redux'

import ContactLayout from '../ContactLayout'
import VerticalStepper from '../../../components/stepper/VerticalStepper'
import AddContactMain from './AddContactMain'

const AddContact = () => {
    const steps = [
        { name: 'Contact Details' },
        { name: 'Account Details' },
    ];

    const { step } = useSelector((state) => state.contact);

    return (
        <ContactLayout title="Add Contact">
            <Flex marginLeft={8}>
                <Box flex="1">
                    <VerticalStepper steps={steps} currentStep={step} />
                </Box>
                <Box flex="3" ml="8">
                    <AddContactMain />
                </Box>
            </Flex>
        </ContactLayout>
    )
}

export default AddContact