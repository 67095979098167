import React, { useState } from "react";
import Select from "react-select";
import {
	HStack,
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Button,
	SimpleGrid,
	FormControl,
	FormLabel,
	Input,
	Checkbox,
	FormErrorMessage,
	useToast,
	FormHelperText,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { useDispatch, useSelector } from "react-redux";

import constants from "../../constants";
import { walletAddressValidation } from "../../store/slices/recipientSlices";

const AddRecipient = (props) => {
	const { isOpen, onClose, onSubmit, isLoading } = props;
	const { recipientType } = constants
	const sendCrypto = useSelector(state => state.order?.cryptoTicker)

	const countryList = useSelector((state) => state.recipient.countries)

	const dispatch = useDispatch();
	const toast = useToast()

	const [loading, setLoading] = useState(false)
	const [buttonClicked, setButtonClicked] = useState(false);

	const formik = useFormik({
		initialValues: {
			name: '',
			country: '',
			email: '',
			type: '',
			walletAddress: '',
			fiatAmount: "",
			purpose: '',
			saveDetails: false
		},
		validationSchema: Yup.object({
			name: Yup.string().required('Please add recipient name'),
			country: Yup.string().required('Please select recipinet country'),
			email: Yup.string().email().required('Please add email address'),
			type: Yup.string().required('Please select recipient type'),
			walletAddress: Yup.string().required('Please add valid wallet address'),
			fiatAmount: Yup.number().required('Please add crypto amount').test('Is positive?', 'The crypto amount must be positive', (value) => value > 0),
			purpose: Yup.string().required('Please add purpose of transaction')
		}),
		onSubmit: async (values, { resetForm }) => {
			try {
				setLoading(true);
				setButtonClicked(true);
				dispatch(walletAddressValidation({ walletAddress: values?.walletAddress, currency: sendCrypto?.value?.formattedSymbol, network: sendCrypto?.value?.network })).then(async (resp) => {
					if (resp.payload?.data?.status) {
						onSubmit(values);
						onClose();
						setButtonClicked(false);
						resetForm();
					} else {
						toast({
							title: resp?.payload?.error?.message,
							status: 'error',
							duration: 5000,
							position: 'top',
							isClosable: true,
						})
						setButtonClicked(false);
					}
				})
				setLoading(false);
			} catch (e) {
				setButtonClicked(false);
				console.log(e.message)
				setLoading(false);
			}
		},
		enableReinitialize: true,
	})

	const handleCloseModal = () => {
		formik.resetForm();
		onClose();
	}

	return (
		<Modal isOpen={isOpen} size={"3xl"} isCentered>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Add Recipient</ModalHeader>
				<ModalBody>
					<SimpleGrid columns={2} spacing={5} mb={5}>
						<FormControl isRequired isInvalid={formik.touched.name && formik.errors.name}>
							<FormLabel>Name</FormLabel>
							<Input type="text" name="name" onChange={formik.handleChange} />
							<FormErrorMessage>{formik.errors.name}</FormErrorMessage>
						</FormControl>
						<FormControl isRequired isInvalid={formik.touched.country && formik.errors.country}>
							<FormLabel>Country</FormLabel>
							<Select options={countryList} onChange={(value) => formik.setFieldValue('country', value.value)} />
							<FormErrorMessage>{formik.errors.country}</FormErrorMessage>
						</FormControl>
						<FormControl isRequired isInvalid={formik.touched.email && formik.errors.email}>
							<FormLabel>Email Id</FormLabel>
							<Input type="email" name="email" onChange={formik.handleChange} />
							<FormErrorMessage>{formik.errors.email}</FormErrorMessage>
						</FormControl>
						<FormControl isRequired isInvalid={formik.errors.type && formik.touched.type}>
							<FormLabel>Type</FormLabel>
							<Select options={recipientType} onChange={(value) => formik.setFieldValue('type', value.value)} />
							<FormErrorMessage>{formik.errors.type}</FormErrorMessage>
						</FormControl>
						<FormControl isRequired isInvalid={formik.touched.walletAddress && formik.errors.walletAddress}>
							<FormLabel>Wallet Address</FormLabel>
							<Input type="text" name="walletAddress" onChange={formik.handleChange} />
							<FormHelperText fontSize={'12px'}>Please provide the wallet address where you would like to receive the cryptocurrency.</FormHelperText>
							<FormErrorMessage>{formik.errors.walletAddress}</FormErrorMessage>
						</FormControl>
						<FormControl isRequired isInvalid={formik.touched.fiatAmount && formik.errors.fiatAmount}>
							<FormLabel>Fiat Amount</FormLabel>
							<Input type="number" name="fiatAmount" onChange={formik.handleChange} />
							<FormErrorMessage>{formik.errors.fiatAmount}</FormErrorMessage>
						</FormControl>
						<FormControl isRequired isInvalid={formik.touched.purpose && formik.errors.purpose}>
							<FormLabel>Purpose of Transaction</FormLabel>
							<Input type="text" name="purpose" onChange={formik.handleChange} />
							<FormErrorMessage>{formik.errors.purpose}</FormErrorMessage>
						</FormControl>

					</SimpleGrid>
					<Checkbox onChange={(e) => formik.setFieldValue('saveDetails', e.target.checked)} >Save this recipient details for future orders</Checkbox>
				</ModalBody>
				<ModalFooter>
					<HStack>
						<Button
							w={"150px"}
							bg="#ffffff"
							color="#525C76"
							border={"1px"}
							borderColor={"#525C76"}
							variant={"outline"}
							onClick={handleCloseModal}
							_hover={{ bg: '#ffffff' }}
						>
							Cancel
						</Button>
						<Button
							isDisabled={buttonClicked || !formik.values.name || !formik.values.country || !formik.values.email || !formik.values.type || !formik.values.walletAddress || !formik.values.fiatAmount || !formik.values.purpose}
							isLoading={isLoading || loading}
							bg="#1A4FD6"
							w={"150px"}
							color={"#ffffff"}
							_hover={{ bg: "#232d95" }}
							onClick={formik.handleSubmit}
						>
							Continue
						</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default AddRecipient;
