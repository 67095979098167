import { callApiWithToken, callApiWithTokenForFile } from "../utils/http_common";

const postRecipientData = (data) => {
  return callApiWithToken.post('/recipient/draft', data)
}

const getRecipientData = (orderId) => {
  return callApiWithToken.get(`/recipient/draft`, {
    params: {
      orderId,
    }
  });
};

const getRecipientList = (orgId) => {
  return callApiWithToken.get(`/recipient`, {
    params: {
      filters: {
        orgId,
      }
    }
  })
}

const postWalletAddress = (data) => {
  return callApiWithToken.post(`/recipient/validate`, { data })
}

const getCountries = () => {
  return callApiWithToken.get('/recipient/countries')
}

export const uploadRecipients = (file, orderId, fiatTicker, cryptoTicker) => {
  const { formattedSymbol, network } = cryptoTicker;
  return callApiWithTokenForFile.post(`/recipient/upload?orderId=${orderId}&fiatTicker=${fiatTicker}&cryptoTicker=${formattedSymbol}&network=${network}`, file);
}

export const getRecipientTemplate = ({ product }) => {
  return callApiWithToken.get(`/recipient/recipientTemplate/${product}`)
}

const RecipientService = {
  getRecipientData,
  postRecipientData,
  postWalletAddress,
  getCountries,
  getRecipientList,
  uploadRecipients,
  getRecipientTemplate,
};

export default RecipientService;
