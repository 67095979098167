export const phoneMap = {
    "NZ": {
        code: '+64',
        example: '2XX XXX XXXX'
    },
    "GB": {
        code: '+44',
        example: '7XXX XXXXXX'
    },
    "PH": {
        code: '+63',
        example: '9XX XXX XXXX'
    },
    "ID": {
        code: '+62',
        example: '8XX XXXX XXXX'
    },
    "MY": {
        code: '+60',
        example: '3-XXXX XXXX'
    },
    "JP": {
        code: '+81',
        example: 'XX XXXX XXXX'
    },
    "TH": {
        code: '+66',
        example: 'XX XXXX XXXX'
    },
    "VN": {
        code: '+84',
        example: 'XX XXXX XXXX'
    },
    "NG": {
        code: '+234',
        example: 'XX XXX XXXX'
    },
    "MX": {
        code: '+52',
        example: 'XX XXXX XXXX'
    },
    "CO": {
        code: '+57',
        example: 'XX XXXX XXXX'
    },
    "CL": {
        code: '+56',
        example: 'XX XXXX XXXX'
    },
    "BR": {
        code: '+55',
        example: 'XX XXXX XXXX'
    },
    "EG": {
        code: '+20',
        example: 'XXX XXX XXXX'
    },
    "AR": {
        code: '+54',
        example: '9 XXX XXX XXXX'
    },
    "ZA": {
        code: '+27',
        example: 'XX XXX XXXX'
    },
    "KE": {
        code: '+254',
        example: 'XX XXX XXXX'
    },
    "UG": {
        code: '+256',
        example: 'XXX XXX XXX'
    },
    "CM": {
        code: '+237',
        example: 'XXX XXX XXX'
    },
    "ZM": {
        code: '+260',
        example: 'X XXXXXX'
    },
    "GH": {
        code: '+233',
        example: 'XXX XXX XXXX'
    },
    "AU": {
        code: '+61',
        example: 'X XXXX XXXX'
    },
    "SG": {
        code: '+65',
        example: 'XXXX XXXX'
    },
    "AE": {
        code: '+971',
        example: 'XX XXXXXXX'
    },
    "HK": {
        code: '+852',
        example: 'XXXXXXXX'
    },
    "CA": {
        code: '+1',
        example: 'XXXXXXXXXX'
    },
    "DK": {
        code: '+45',
        example: 'XX XX XX XX '
    },
    "NO": {
        code: '+47',
        example: 'XX XX XX XX '
    },
    "SE": {
        code: '+46',
        example: 'X XXX XXXX '
    },
    "CH": {
        code: '+41',
        example: 'XX XXX XXXX XX '
    },
    "PE": {
        code: '+51',
        example: 'XXXXXXXX '
    },
    "BE": {
        code: '+32',
        example: 'XXXXXXXX '
    },
    "AT": {
        code: '+43',
        example: 'XXXXXXXX '
    },
    "BG": {
        code: '+359',
        example: 'XXXXXXXX '
    },
    "CY": {
        code: '+357',
        example: 'XXXXXXXX '
    },
    "CZ": {
        code: '+420',
        example: 'XXXXXXXX '
    },
    "EE": {
        code: '+372',
        example: 'XXXXXXXX '
    },
    "FI": {
        code: '+358',
        example: 'XXXXXXXX '
    },
    "FR": {
        code: '+33',
        example: 'XXXXXXXX '
    },
    "DE": {
        code: '+49',
        example: 'XXXXXXXX '
    },
    "GR": {
        code: '+30',
        example: 'XXXXXXXX '
    },
    "HU": {
        code: '+36',
        example: 'XXXXXXXX '
    },
    "IE": {
        code: '+353',
        example: 'XXXXXXXX '
    },
    "IT": {
        code: '+39',
        example: 'XXXXXXXX '
    },
    "LV": {
        code: '+371',
        example: 'XXXXXXXX '
    },
    "LT": {
        code: '+370',
        example: 'XXXXXXXX '
    },
    "LU": {
        code: '+352',
        example: 'XXXXXXXX '
    },
    "MT": {
        code: '+356',
        example: 'XXXXXXXX '
    },
    "NL": {
        code: '+31',
        example: 'XXXXXXXX '
    },
    "PL": {
        code: '+31',
        example: 'XXXXXXXX '
    },
    "PT": {
        code: '+351',
        example: 'XXXXXXXX '
    },
    "RO": {
        code: '+40',
        example: 'XXXXXXXX '
    },
    "SK": {
        code: '+421',
        example: 'XXXXXXXX '
    },
    "SI": {
        code: '+386',
        example: 'XXXXXXXX '
    },
    "ES": {
        code: '+34',
        example: 'XXXXXXXX '
    },
    "HR": {
        code: '+385',
        example: 'XXXXXXXX '
    },
}