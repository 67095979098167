import React, { useState } from 'react'
import { Box, Button, Flex, Heading, VStack } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'

import CollectMoneyLayout from './CollectMoneyLayout'
import Typography from '../../components/Common/Typography'
import { setActiveStep } from '../../store/slices/collectMoneySlice'
import CollectMoneyMain from './CollectMoneyMain'

const CollectMoney = () => {
    const steps = [
        { name: 'Add Customer' },
        { name: 'Add Invoice Details' },
    ];

    const dispatch = useDispatch()

    const { step: currentStep } = useSelector((state) => state.collectMoney);

    return (
        <CollectMoneyLayout title="Collect Money">
            <Flex marginLeft={8} mt={'30px'} ml={'40px'} gap={6}>
                <Box>
                    <VStack borderLeft={"2px solid"} paddingX={2} borderLeftColor={"brand.primary"} spacing={2} align="stretch">
                        {steps.map((step, index) => (
                            <Button
                                key={index}
                                variant="ghost"
                                justifyContent="flex-start"
                                _hover={{}} _active={{}} className='stepper-button'
                                // color={step === index ? 'brand.primary' : '#BACAF3'}
                                onClick={() => { dispatch(setActiveStep(index)) }}
                                isDisabled={index > currentStep} // Disable future steps
                            >
                                <Typography color="primary" colorweight={currentStep === index ? "500" : "200"} type='body' weight='bold' >
                                    {step.name}
                                </Typography >
                            </Button>
                        ))}
                    </VStack>
                </Box>
                <Box px={"10"} bg={"#FFFFFF"} w={"full"} rounded={"9px"} minH={'600px'} border={'1px'} borderColor={"#E4E4E4"}>
                    <CollectMoneyMain />
                </Box>
            </Flex>
        </CollectMoneyLayout>
    )
}

export default CollectMoney;