import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { LicenseManager } from 'ag-grid-enterprise'

import { getItem } from './utils/local_storage';
import { getUser } from './store/slices/loginSlices';
import { getPayoutOrderData } from './store/slices/transactionSlices';
import UnauthorizedRoutes from './routers/UnAuthorizedRoutes';
import AuthorizedRoutes from './routers/AuthorizedRoutes';
import Loader from './components/Loader';
import LoginService from './services/loginService';

function App() {
	const dispatch = useDispatch();
	const [authState, setAuthState] = useState("loading");
	const loginDetails = useSelector(state => state.login?.loginDetails);
	const isLoading = useSelector(state => state.login?.userDetails?.loading);
	const orgType = useSelector((state) => state?.login?.orgDetails?.orgType || "default");
	
	LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_KEY)
	const fetchUser = useCallback(() => {
		dispatch(getUser());
	}, [dispatch]);

	const fetchApprovals = useCallback(() => {
		dispatch(getPayoutOrderData({ filters: { status: "approval_pending" } }));
	}, [dispatch]);

	useEffect(() => {
		const authStatus = getItem('loggedIn');
		if (authStatus === "true") {
			setAuthState("signedIn");
		} else {
			setAuthState("signIn")
		}
	}, []);

	useEffect(() => {
		const authStatus = getItem('loggedIn');
		if (loginDetails?.loggedIn || authStatus) {
			setAuthState("signedIn");
			if (orgType === "payout") {
				fetchApprovals();
			}
		} else {
			setAuthState("signIn")
		}
	}, [loginDetails])

	useEffect(() => {
		if (authState === "signedIn") {
			fetchUser();
			setInterval(async () => {
				await LoginService.heartBeat();
			}, 300000);
		}
	}, [authState]);

	return (
		<BrowserRouter>
			{(isLoading || authState === "loading") && <Loader />}
			{authState === 'signIn' && !isLoading && <UnauthorizedRoutes />}
			{authState === 'signedIn' && !isLoading && <AuthorizedRoutes />}
		</BrowserRouter>
	);
}

export default App;
