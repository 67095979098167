import React from 'react'
import { Img, useToast } from '@chakra-ui/react';
import { isEmpty, isUndefined, round, startCase } from 'lodash'
import { Box, Flex, } from '@chakra-ui/react'

import Typography from '../../components/Common/Typography'
import { OrderDetailsColumn } from '../my-account/Prefund/WalletToWalletSuccess'
import Loader from '../../utils/Loader'
import { currencyFormatter } from '../../utils/formatter'

export default function PayoutSummary({ data }) {
    let totalFee = Number(data?.fees?.networkFee || 0) + Number(data?.fees?.processingFee || 0) + Number(data?.fiat?.cxFee || 0);
    const toast = useToast();
    if (!data) return <Loader />;

    const paymentMethod = data?.paymentType === "bank" ? "Bank" : "Crypto Wallet";

    if (data?.type === "fiat_payout")
        totalFee = data?.fees?.processingFee / data?.fiat?.conversionRate;
    const handleToast = (title, type) => {
        toast({
            title,
            status: type,
            duration: 5000,
            isClosable: true,
            position: "top",
        });
    };

    const handleDownload = async (url) => {
        try {
            if (isEmpty(url))
                return;

            const response = await fetch(url, { method: 'GET' });
            if (!response.ok) {
                throw new Error('Failed to download file');
            }
            const blob = await response.blob();
            const blobUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = blobUrl;
            const fileNameArray = url?.split('/');
            const fileName = fileNameArray[fileNameArray.length - 1];
            link.setAttribute('download', fileName);

            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            window.URL.revokeObjectURL(blobUrl);

        } catch (error) {
            console.log(error);
            handleToast('Error downloading invoice', "error");
        }
    }

    return (
        <Box w={"100%"}>
            <Flex flexDir={"column"}>
                <OrderDetailsColumn label="Payment Method" value={startCase(paymentMethod)} />
                <OrderDetailsColumn label="Total paid amount" value={`${data?.depositCurrency === "BTC" ? currencyFormatter(data?.depositAmount, 5) : currencyFormatter(data?.depositAmount) } ${data?.depositCurrency}`} />
                <OrderDetailsColumn label="Total fee" value={`${data?.depositCurrency === "BTC" ? currencyFormatter(totalFee, 5) : currencyFormatter(totalFee) } ${data?.depositCurrency}`} />
                <OrderDetailsColumn label="FX Rate" value={`1 ${data?.depositCurrency} = ${currencyFormatter(data?.fiat?.conversionRate || round((1 / data?.crypto?.conversionRate), 6))} ${data?.withdrawCurrency}`} />
                <OrderDetailsColumn label="They Receive" value={`${currencyFormatter(data?.withdrawAmount)} ${data?.withdrawCurrency}`} />
                {/* {
                    data?.fees?.networkFee > 0 &&
                    <OrderDetailsColumn label="Network fee" value={`${data?.fees?.networkFee} ${data?.depositCurrency}`} />
                } */}
                {/* <OrderDetailsColumn label="Processing fee" value={`${processingFee.toFixed(2)} ${data?.depositCurrency}`} /> */}
                {
                    !isUndefined(data?.timestamps.fundSettledAt) ?
                        paymentMethod === "Bank" ?
                            <OrderDetailsColumn label="Bank Reference Number" value={data?.fiat?.orderId} /> :
                            <OrderDetailsColumn label="Txn Hash" value={data?.crypto?.orderId} /> :
                        null
                }
            </Flex>
            <hr style={{ marginTop: "40px" }} />
            <Typography pt="40px" pb="20px" textAlign="left" weight='medium' type='subtitle' color='primary' >
                Recipient Details
            </Typography>

            {/* Contact Details */}
            <Flex flexDir={"column"}>
                <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={"full"}>
                    <Typography weight='medium' type='description' >
                        Contact
                    </Typography>
                    <Flex textAlign={"right"} direction={"column"}>
                        <Flex alignItems={"center"} justifyContent={"right"} columnGap={1}>
                            {
                                data?.paymentAccount?.logo &&
                                <Img src={process.env.PUBLIC_URL + data?.paymentAccount?.logo} alt='contactLogo' w={"25px"} h={"18px"} />
                            }
                            <Typography type='body' color="secondary" colorweight="800">
                                {data?.recipientName?.firstName}
                            </Typography>
                        </Flex>
                        <Typography weight='regular' type='caption' color='secondary'>
                            {data?.email}
                        </Typography>
                    </Flex>
                </Box>
                {/* <OrderDetailsColumn label="Payment method" value={`${paymentMethod} - ${data?.paymentType === "bank" ? startCase(data?.paymentFormattedName || "") : data?.walletAddress}`} /> */}
                <OrderDetailsColumn label="Payment account" value={`${data?.paymentAccount?.displayName}`} />
            </Flex>
            <hr style={{ marginTop: "40px" }} />

            <Typography pt="40px" pb="20px" textAlign="left" weight='medium' type='subtitle' color='primary' >
                Additional Details
            </Typography>
            <Flex flexDir={"column"}>
                {data?.additionalDetails?.notes &&
                    <OrderDetailsColumn label="Note" value={`${data?.additionalDetails?.notes}`} />
                }
                <OrderDetailsColumn label="Payment purpose" value={`${data?.purposeCode}`} />
                {(isUndefined(data?.timestamps.fundSettledAt) || isEmpty(data?.additionalDetails?.invoiceUrl)) ? null :
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={"full"}>
                        <Typography weight='medium' type='description' >
                            Invoice
                        </Typography>
                        <Flex alignItems={"center"} w='fit-content' bg='white' p='10px' columnGap={2} borderRadius='5px'>
                            <Typography weight='regular' textDecoration='underline' type='body' color='primary' cursor='pointer' onClick={() => handleDownload(data?.additionalDetails?.invoiceUrl)} >
                                Download
                            </Typography>
                        </Flex>
                    </Box>
                }
            </Flex>

        </Box>
    )
}
