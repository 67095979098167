import React, { useEffect, useState } from "react";
import { Box, Button, Input, InputGroup, InputLeftElement, Icon, Table, TableContainer, Tbody, Td, Th, Thead, Tr, Menu, MenuButton,
  MenuList, MenuItem, Checkbox, Switch, Spinner, useToast, Portal, Modal, ModalOverlay, ModalContent, ModalBody, Text, Skeleton, Stack } from "@chakra-ui/react";
import { SearchIcon, ChevronDownIcon } from "@chakra-ui/icons";
import _ from 'lodash';

import Typography from "../../../components/Common/Typography";
import { callApiWithToken } from "../../../utils/http_common";
import { settings } from "../constant";
import SelectMenu from "../SelectMenu";

const Currency = () => {
  const [initialCurrencyData, setInitialCurrencyData] = useState([]);
  const [initialDepositMethodData, setInitialDepositMethodData] = useState([]);
  const [initialWithdrawMethodData, setInitialWithdrawMethodData] = useState([]);
  const [addedDepositData, setAddedDepositData] = useState({});
  const [addedWithdrawData, setAddedWithdrawData] = useState({});
  const [currencyData, setCurrencyData] = useState([]);  
  const [filteredData, setFilteredData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [loading, setLoading] = useState(true);
  const [saveLoader, setSaveLoader] = useState(false);
  const [saveStatus, setSaveStatus] = useState([]);
  const toast = useToast();

  useEffect(() => {
    getCurrencies();
  }, []);

  const handleToast = (title, type) => {
    toast({
      title,
      status: type,
      duration: 5000,
      isClosable: true,
    });
  };

  const getCurrencies = async () => {
    try {
      setLoading(true);
      const response = await callApiWithToken.get("/setting/currencies");
      if (response) {
        setCurrencyData(response?.data?.data?.response);
        setFilteredData(response?.data?.data?.response);
        setInitialCurrencyData(response?.data?.data?.response);
        setSaveStatus(response?.data?.data?.response.map(() => false)); 
      } 
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(true);
    }
  };

const handleSearchInputChange = (e) => {
  const input = e.target.value;
  setSearchInput(input);
  filterData(input);
};

const filterData = (input) => {
  const lowercasedInput = input.toLowerCase();
  const filtered = currencyData.filter(
    (item) =>
      item.currency.toLowerCase().includes(lowercasedInput) ||
      item.country.toLowerCase().includes(lowercasedInput)
  );
  setFilteredData(filtered);
};

const handleSwitchChange = (direction, index, currency) => {
  setFilteredData((prevData) => {
    const updatedData = prevData.map((item, i) =>
      i === index ? { ...item, [direction]: !item[direction] } : item
    );

    let newAddedDepositData = { ...addedDepositData };
    let newAddedWithdrawData = { ...addedWithdrawData };

    // Check if direction (toggle) is going to false
    if (!updatedData[index]['deposit']) {
      delete newAddedDepositData[currency];
      setAddedDepositData(newAddedDepositData);
    }
    if (!updatedData[index]['withdraw']) {
      delete newAddedWithdrawData[currency];
      setAddedWithdrawData(newAddedWithdrawData);
    }

    const depositMethodsChanged = newAddedDepositData.hasOwnProperty(currency);
    const withdrawMethodsChanged = newAddedWithdrawData.hasOwnProperty(currency);

    updateSaveStatusForIndex(index, updatedData, currency, depositMethodsChanged, withdrawMethodsChanged);

    return updatedData;
  });
};

const updateSaveStatusForIndex = (index, data, currency, depositMethodsChanged, withdrawMethodsChanged) => {
    const item = data[index];
    const initialItem = initialCurrencyData[index];
    const hasChanged = item.deposit !== initialItem.deposit || item.withdraw !== initialItem.withdraw;

    const depositChanged = depositMethodsChanged.hasOwnProperty(currency);
    const withdrawChanged = withdrawMethodsChanged.hasOwnProperty(currency);

    const updatedSaveStatus = [...saveStatus];
    updatedSaveStatus[index] = hasChanged || depositChanged || withdrawChanged;
    setSaveStatus(updatedSaveStatus);
};

const handleCheckboxChange = (value, index) => {
  const updatedSaveStatus = [...saveStatus];
  updatedSaveStatus[index] = value;
  setSaveStatus(updatedSaveStatus);
}

const handleSaveData = async(index, currency) => {
  try{
    setSaveLoader(true);
    const changedData = filteredData.filter((item,i)=>i==index);
    let initialData, toggleChanges = {};
    if(changedData?.length > 0){
      initialData = initialCurrencyData.filter((item)=>item.currency === changedData[0]?.currency)

      if (changedData[0]?.deposit !== initialData[0]?.deposit) {
        toggleChanges.deposit = changedData[0]?.deposit;
      }

      if (changedData[0]?.withdraw !== initialData[0]?.withdraw) {
        toggleChanges.withdraw = changedData[0]?.withdraw;
    }
  }
    
    const response = await callApiWithToken.post(
      `/setting/updatePaymentMethod`, { initialData: initialData[0], toggleChanges, depositData: addedDepositData[currency], withdrawData: addedWithdrawData[currency]}
    );
    if(response){
      handleToast("Successfully updated", 'success');
      setSaveLoader(false);
      setSaveStatus((prevSaveStatus) =>
      prevSaveStatus.map((status, idx) => (idx === index ? false : status))
      );
      setAddedDepositData(prevState => {
        const newState = { ...prevState };
        delete newState[currency];
        return newState;
      });
      setAddedWithdrawData(prevState => {
        const newState = { ...prevState };
        delete newState[currency];
        return newState;
      });
      if (toggleChanges?.deposit !== undefined) {
        setInitialCurrencyData((prevData) => {
          const newData = [...prevData];
          newData[index] = { ...newData[index], deposit: toggleChanges.deposit };
          return newData;
        });
      }   
      if (toggleChanges?.withdraw !== undefined) {
        setInitialCurrencyData((prevData) => {
          const newData = [...prevData];
          newData[index] = { ...newData[index], withdraw: toggleChanges.withdraw };
          return newData;
        });
      }   
    } 
  }catch(error){
    console.error(error);
  }finally{
    setSaveLoader(false);
  }  
}

  return (
    <Box>
      <Box display="flex">
        <Box pt={4}>
          <Typography color="primary" variant="h6">
            Currency Configurations
          </Typography>
          <Typography variant="body" style={{ fontSize: "14px" }}>
            Choose to control any currencies that will be available for deposit
            and withdrawal
          </Typography>
        </Box>
        <Box pt={6} pl={20}>
          <InputGroup width="300px">
            <InputLeftElement pointerEvents="none">
              <Icon as={SearchIcon} color="#1A4FD6" />
            </InputLeftElement>
            <Input
              type="search"
              placeholder="Search..."
              id="query"
              value={searchInput}
              onChange={handleSearchInputChange}
              borderRadius="md"
              border="1px solid"
              borderColor="gray.300"
              _hover={{ borderColor: "gray.400" }}
              _focus={{
                borderColor: "blue.500",
                boxShadow: "0 0 0 1px blue.500",
              }}
            />
          </InputGroup>
        </Box>
      </Box>
      <Box paddingX={7}>
        { filteredData?.length === 0  ? 
        (
          searchInput ? (
            <Box display='flex' justifyContent='center' h={"250px"} pt={10} alignItems='center'>
              <Typography type="description" weight="regular">No result found!</Typography>
            </Box>
          ) : (
            <Box bg="white" mt={10} p={2} borderRadius={10}>
              <Stack rowGap={5} bg={"white"} padding={2}>
                {Array(5).fill(0).map((_, index) => (
                  <Skeleton height='60px' key={index} />
                ))}
              </Stack>
            </Box>
          )
        ) 
       :
        (<TableContainer maxH="70vh" overflowY="auto" mt={4} position="relative">
          <Table>
            <Thead bg={"white"} position={"sticky"} top={0} 
            zIndex={10}
            >
              <Tr>
                {settings.map((label) => (
                  <Th
                    fontWeight={"normal"}
                    paddingY={6}
                    color={"brand.greySecondary"}
                    key={label.key}
                  >
                    {label.label}
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {filteredData?.length > 0 &&
                filteredData?.map((item, index) => (
                  <Tr key={index}>
                    <Td>
                      <Box display="flex">
                        <img
                        src={`assets/fiat/${item?.logoUrl}`}
                          alt="..."
                          style={{ width: "24px" }}
                        />
                        <Box pt={1} pl={2}>
                          <Typography style={{fontSize: '14px'}}>{item.country}</Typography>
                        </Box>
                      </Box>
                    </Td>
                    <Td><Typography style={{fontSize: '14px'}}>{item.currency}</Typography></Td>
                    <Td>
                    <SelectMenu 
                        isMandatory={true}
                        item={item}
                        currency={item?.currency}
                        direction="deposit"
                        setInitialPaymentMethodData={setInitialDepositMethodData}
                        initialPaymentMethodData={initialDepositMethodData}
                        setSaveStatus={setSaveStatus}
                        saveStatus={saveStatus}
                        index={index}
                        isDisabled={item?.deposit}
                        handleCheckboxChange={handleCheckboxChange}
                        setAddedData={setAddedDepositData}
                        addedData={addedDepositData}
                        currencyData={filteredData}
                        initialCurrencyData={initialCurrencyData}/>
                    </Td>
                    <Td>
                    <SelectMenu
                        isMandatory={true}
                        currency={item?.currency}
                        item={item}
                        direction="withdraw"
                        setInitialPaymentMethodData={setInitialWithdrawMethodData}
                        initialPaymentMethodData={initialWithdrawMethodData}
                        setSaveStatus={setSaveStatus}
                        saveStatus={saveStatus}
                        index={index}
                        isDisabled={item?.withdraw}
                        handleCheckboxChange={handleCheckboxChange}
                        setAddedData={setAddedWithdrawData}
                        addedData={addedWithdrawData}
                        currencyData={filteredData}
                        initialCurrencyData={initialCurrencyData}/>
                    </Td>
                    <Td>
                      <Switch id="deposit" size='sm' isChecked={item?.deposit} onChange={(e)=>handleSwitchChange('deposit',index, item?.currency)}/>
                    </Td>
                    <Td>
                      <Switch id="withdrawal" size='sm' isChecked={item?.withdraw} onChange={(e)=>handleSwitchChange('withdraw',index, item?.currency)}/>
                    </Td>
                    <Td>
                    <Button
                        variant={"primary"}
                        color="white"
                        bg={saveStatus[index] ? "#1A4FD6" : "gray.400"}
                        _hover={saveStatus[index] ? { bg: '#366cf5' } : {}}
                        disabled={!saveStatus[index]}
                        size='md'
                        width={'90px'}
                        height={'40px'}
                        rounded={'10px'}
                        onClick={()=>saveStatus[index] && handleSaveData(index, item?.currency)}
                        >
                       Save
                      </Button>
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </TableContainer>
        )}
      </Box>
      {saveLoader && (
        <Modal isOpen={saveLoader} size="md" maxW="80%" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalBody textAlign="center">
            <Spinner size="md" color="blue.500" />
            <Text mt={2} color="gray.600">
              Saving...
            </Text>
          </ModalBody>
        </ModalContent>
      </Modal>
      )}
   </Box>
  )
}

export default Currency

