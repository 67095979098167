import { Text } from '@chakra-ui/react';

import colors from "./colors.json";

/**
 * 
 * @param {{ 
 * weight: "regular" | "medium" | "bold";
 * type: "bigtitle" | "largeheader" | "title" | "subtitle" | "body" | "description" | "caption";
 * color: "primary" | "secondary" | "default" | "headertext" | "complimentary" | "error" | "success" | "alert" | "text";
 * }} props
 * 
 */
const Typography = (props) => {

  const weight = {
    regular: "400",
    medium: "500",
    bold: "700",
    default: "500"
  };

  const type = {
    "bigtitle": "36px",
    "largeheader": "32px",
    "title": "20px",
    "subtitle": "18px",
    "body": "16px",
    "description": "14px",
    "caption": "12px",
    "default": "20px"
  };

  return (
    <Text color={colors[props.color || "default"][props.colorweight || "default"]}
      style={{
        fontFamily: "DM Sans",
        fontWeight: weight[props.weight || "default"],
        fontSize: type[props.type || "default"],
        color: colors[props.color || "default"][props.colorweight || "default"],
      }}
      {...props}
    >
      {props.children}
    </Text>
  )
}

export default Typography;