const urlMaps = {
    "localhost:3000": {
        env: "local",
        server: "http://localhost:4000/api/pay"
    },
    "localhost:3001": {
        env: "local",
        server: "http://localhost:4000/api/pay"
    },
    "localhost:3002": {
        env: "local",
        server: "http://localhost:4000/api/pay"
    },
    "dev-pay.transfi.com": {
        env: "development",
        server: "https://dev-pay-server.transfi.com/api/pay",
    },
    "qa-pay.transfi.com": {
        env: "qa",
        server: "https://qa-pay-server.transfi.com/api/pay",
    },
    "sandbox-pay.transfi.com": {
        env: "sandbox",
        server: "https://sandbox-pay-server.transfi.com/api/pay",
    },
    "pay.transfi.com": {
        env: "production",
        server: "https://prd-pay-server.transfi.com/api/pay"
    }
};

export default urlMaps;
