import { callApiWithToken } from "../utils/http_common";

const addCustomer = (payload) => {
    return callApiWithToken.post(`/customer/addCustomer`, { ...payload });
}

const getCustomer = () => {
    return callApiWithToken.get(`/customer/getCollectionCustomers`);
}

const deleteCustomer = (payload) => {
    return callApiWithToken.post(`/customer/deleteCollectionCustomer`, { ...payload });
};

const updateCustomer = (payload) => {
    return callApiWithToken.post(`/customer/updateCollectionCustomer`, { ...payload });
}

const getKybStatus = ({ userEmail, cxId }) => {
    return callApiWithToken.get(`/customer/getKybStatus?email=${userEmail}&cxId=${cxId}`);
}

const getKybDetails = ({ cxId }) => {
    return callApiWithToken.get(`/customer/getKybDetails?&cxId=${cxId}`);
}

const getKybComplete = ({ cxId }) => {
    return callApiWithToken.post(`/customer/completeKyb?&cxId=${cxId}`);
}

const CustomerService = {
    addCustomer,
    getCustomer,
    deleteCustomer,
    updateCustomer,
    getKybStatus,
    getKybDetails,
    getKybComplete
}

export default CustomerService;